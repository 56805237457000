<template>
  <div class="view view-account">
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.account.title')"
      icon="mdi-account"
    />

    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <v-avatar>
            <v-img :src="auth.avatar_img_url" />
          </v-avatar>
        </v-col>
        <v-col cols="12" class="text-center">
          <span>{{ auth.user_name }}</span>
        </v-col>
        <v-col sm="6" class="text-center">
          <span class="text-h5">{{ auth.score.toLocaleString() }}</span
          ><br />
          <span class="text-caption">{{ $t("ng.score") }}</span>
        </v-col>
        <v-col sm="6" class="text-center">
          <span class="text-h5">{{ auth.level_id }}</span
          ><br />
          <span class="text-caption">{{ $t("resources.users.level") }}</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <span class="text-caption mr-3">{{ $t("general.email") }}</span>
          <span>{{ auth.email }}</span>
        </v-col>
        <v-col cols="12" sm="6">
          <span class="text-caption mr-3">{{
            $t("resources.users.gender")
          }}</span>
          <span>{{ $t("resources.users.genders." + auth.gender) }}</span>
        </v-col>
        <v-col cols="12" sm="6">
          <span class="text-caption mr-3">{{
            $t("resources.users.first_name")
          }}</span>
          <span>{{ auth.first_name }}</span>
        </v-col>
        <v-col cols="12" sm="6">
          <span class="text-caption mr-3">{{
            $t("resources.users.last_name")
          }}</span>
          <span>{{ auth.last_name }}</span>
        </v-col>
        <v-col cols="12">
          <span class="text-caption mr-3">{{
            $t("resources.substructures.plural")
          }}</span>
          <div v-if="substructures">
            <v-chip
              v-for="substructure_id in auth.substructures_ids"
              :key="substructure_id"
              x-small
              class="mx-1 my-1"
            >
              {{ substructureById(substructure_id).structure.name }} /
              {{ substructureById(substructure_id).name }}
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12" sm="6">
          <span class="text-caption mr-3">{{
            $t("resources.users.role")
          }}</span>
          <span>{{ $t("general.roles." + role) }}</span>
        </v-col>
        <v-col v-if="['manager'].includes(role)" cols="12" sm="6">
          <span class="text-caption mr-3">{{
            $t("resources.substructures.manager")
          }}</span>
          <div v-if="substructures">
            <v-chip
              v-for="substructure_id in auth.related_substructures"
              :key="substructure_id"
              x-small
              class="mx-1 my-1"
            >
              {{ substructureById(substructure_id).structure.name }} /
              {{ substructureById(substructure_id).name }}
            </v-chip>
          </div>
        </v-col>
        <v-col cols="12">
          <LanguageChooserInline
            v-if="$i18n.availableLocales.length > 1"
            @change="changeLanguage"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import ViewHeader from "@/components/_layout/ViewHeader.vue";

export default {
  name: "AccountView",
  components: {
    LanguageChooserInline,
    ViewHeader,
  },
  data() {
    return {
      tab: "tab-overview",
    };
  },
  computed: {
    ...mapGetters("structures", { substructures: "get_substructures" }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.account.title"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    console.log("app_version:", process.env.VUE_APP_VERSION);
    this.allow_roles(["superadmin", "admin", "editor", "manager"]);
  },
  methods: {
    ...mapActions("auth", ["update_language"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      this.fetch_structures({ cancelToken: this.source.token });
    },
    unloadData() {
      this.set_structures(null);
    },
    async updateLanguage(lang) {
      let success = await this.update_language({ language: lang });
      if (success) {
        this.$i18n.locale = lang;
        localStorage.setItem("locale", lang);
        this.$notify({
          type: success ? "success" : "error",
          title: success
            ? this.$t("general.success")
            : this.$t("general.error"),
          text: success
            ? this.$t("general.updateLanguageSuccess")
            : this.$t("general.updateError"),
        });
      }
    },
    async changeLanguage(lang) {
      await this.updateLanguage(lang);

      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$i18n.locale);
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
  },
};
</script>
