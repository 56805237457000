<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text small v-bind="attrs" v-on="on">
        <span>{{ $t("general.details") }}</span>
      </v-btn>
    </template>

    <v-card v-if="dialog">
      <v-card-title class="title font-weight-bold secondary--text ml-3">
        {{ $t("resources.support.request") }}
      </v-card-title>

      <v-card-text>
        <SectionHeader title="Nachricht" />
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-sheet color="grey lighten-3" class="pa-2">
                <span class="body-2 font-weight-bold">{{
                  request.message
                }}</span>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>

        <SectionHeader :title="$t('resources.support.props.status')" />
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" class="pt-0">
              <v-select
                v-model="status_select"
                :items="options.status"
                item-text="name"
                item-value="type"
                attach
                :label="$t('resources.users.status')"
                filled
                hide-details
                dense
                @change="changeStatus"
              >
                <template #item="{ item }">
                  {{ item.name }}
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>

        <SectionHeader :title="$t('resources.support.contact')" />
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-sheet color="grey lighten-3" elevation="0" class="px-2 pt-2">
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <h5 class="secondary--text">
                      {{ $t("resources.support.props.name") }}
                    </h5>
                    <p>{{ request.name }}</p>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h5 class="secondary--text">
                      {{ $t("resources.support.props.created_at") }}
                    </h5>
                    <p>{{ getLocaleDateString(request.created_at) }}</p>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h5 class="secondary--text">
                      {{ $t("resources.support.props.email") }}
                    </h5>
                    <p>{{ request.email }}</p>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <h5 class="secondary--text">
                      {{ $t("resources.support.props.phone") }}
                    </h5>
                    <p>{{ request.phone ? request.phone : "-" }}</p>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn color="primary" small :href="`mailto:${request.email}`">
                <v-icon small> mdi-email-outline </v-icon>
                <span class="ml-2">{{
                  $t("resources.support.send_mail")
                }}</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>

        <SectionHeader :title="$t('resources.support.images')" />
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-sheet color="grey lighten-3">
                <div v-if="request.images.length === 0" class="pa-2">
                  {{ $t("resources.support.no_images") }}
                </div>
                <v-avatar
                  v-for="(image, i) in request.images"
                  :key="i"
                  size="96"
                  color="grey"
                  tile
                  class="ma-2"
                >
                  <v-img :src="image">
                    <div
                      class="d-flex align-start justify-end pa-2"
                      style="width: 100%"
                    >
                      <v-btn
                        x-small
                        fab
                        :href="image"
                        target="_blank"
                        background-color="primary"
                      >
                        <v-icon small> mdi-fullscreen </v-icon>
                      </v-btn>
                    </div>
                  </v-img>
                </v-avatar>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>

        <SectionHeader :title="$t('resources.support.specs')" />
        <v-container>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-sheet color="grey lighten-3" class="pa-2">
                {{ request.user_agent }}
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn small text @click="closeDialog()">
          {{ $t("general.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import { Supportrequest } from "@/models/Supportrequest";

export default {
  name: "SupportRequestDialog",
  components: {
    SectionHeader,
  },
  props: {
    request: {
      type: Supportrequest,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      status_select: "open",
      options: {
        status: [
          { type: "open", name: this.$t("resources.support.status.open") },
          {
            type: "pending",
            name: this.$t("resources.support.status.pending"),
          },
          {
            type: "resolved",
            name: this.$t("resources.support.status.resolved"),
          },
        ],
      },
    };
  },
  watch: {
    dialog(val) {
      if (val) this.status_select = this.request.status;
    },
  },
  methods: {
    ...mapActions("support", ["patch_support_request"]),
    closeDialog() {
      this.dialog = false;
    },
    async changeStatus(new_status) {
      let payload = {
        id: this.request.id,
        properties: [{ name: "status", value: new_status }],
      };
      let res = await this.patch_support_request({
        payload: payload,
        cancelToken: this.source.token,
      });

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
  },
};
</script>

<style></style>
