<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text pb-0">
          Substruktur bearbeiten
        </v-card-title>
        <v-card-text>
          <div class="caption mb-4">
            Bitte trage den gewünschten Namen ein. Dieser kann im Anschluss
            wieder geändert werden.
          </div>
          <v-row>
            <v-col cols="12">
              <InputTextfield
                v-model="input.name"
                :label="$t('resources.categories.name')"
                dense
                hide-details
                :rules="rules.name"
                listen-to-input
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="input.name.length === 0 || loading"
            @click="editSubstructure()"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "EditSubstructureDialog",
  props: {
    substructure: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      name: "",
      loading: false,
      rules: {
        name: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          (v) =>
            (!!v && v.length <= 64) ||
            this.$t("ng.too_many_chars_hint", { count: v.length, max: 64 }),
        ],
      },
      input: null,
    };
  },
  methods: {
    ...mapActions("structures", ["patch_substructure"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.showDialog = true;
      this.input = { ...this.substructure };
    },
    async editSubstructure() {
      this.loading = true;
      let payload = {
        id: this.input.id,
        structure_id: this.input.structure.id,
        name: this.input.name,
        isactive: true,
      };
      let res = await this.patch_substructure({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Erfolgreich",
          text: "Substruktur erfolgreich bearbeitet.",
        });
        this.closeDialog();
      } else {
        this.$notify({
          type: "error",
          title: "Fehler",
          text: "Fehler beim Bearbeiten der Substruktur.",
        });
      }
    },
    reset() {
      this.input = null;
    },
  },
};
</script>
