<template>
  <v-card
    :loading="loading"
    :disabled="loading"
    elevation="0"
    color="grey lighten-3"
    width="100%"
  >
    <v-card-title class="overline py-1 justify-space-between">
      <span>{{ $t("resources.questions.report") }}</span>
      <div>
        <v-btn
          x-small
          color="primary"
          depressed
          class="mr-2"
          :to="{ name: 'QuestionSettings' }"
        >
          {{ $t("resources.questions.editQuestion") }}
        </v-btn>
        <v-chip label x-small>
          {{ $t(`resources.questions.reportStatus.${report.status}`) }}
        </v-chip>
      </div>
    </v-card-title>

    <v-card-text>
      <v-sheet outlined color="grey lighten-2">
        <p class="subtitle-1 pa-2 mb-1">
          {{ report.body }}
        </p>
      </v-sheet>
      <p class="mt-1">
        <small
          v-html="
            $t('resources.questions.report_created_at', {
              date: getLocaleDateString(report.created_at),
              username: report.user.user_name,
            })
          "
        />
      </p>

      <div>
        <h5 class="caption d-flex align-center justify-space-between">
          <span
            >{{ $t("general.comments") }} ({{ report.comments.length }})</span
          >
          <v-btn icon small @click="showCommentSection = !showCommentSection">
            <v-icon>{{
              showCommentSection ? "mdi-chevron-up" : "mdi-chevron-down"
            }}</v-icon>
          </v-btn>
        </h5>

        <div v-if="showCommentSection" class="showComments">
          <CommentSection
            :comments="report.comments"
            :report-id="report.id"
            :comments-disabled="disabled"
          />
          <v-textarea
            v-model="newComment"
            outlined
            background-color="white"
            no-resize
            :label="$t('general.comment_placeholder')"
            class="mt-4"
            :disabled="disabled"
            :placeholder="$t('general.comment_placeholder')"
          />

          <div class="d-flex align-center justify-end">
            <v-checkbox
              v-model="closeReport"
              :disabled="!newComment || disabled"
              dense
              hide-details
              class="d-flex pb-2"
            >
              <template #label>
                <span
                  class="text-uppercase look-like-a-btn"
                  :class="{ disabled: !newComment || disabled }"
                  >{{ $t("general.close_report") }}</span
                >
              </template>
            </v-checkbox>
            <v-btn
              :disabled="!newComment || disabled"
              class="ml-2"
              small
              text
              color="success"
              @click="patchReport()"
            >
              {{ $t("general.to_comment") }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import CommentSection from "@/components/_layout/CommentSection";

export default {
  name: "ReportCard",
  components: {
    CommentSection,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    questionId: {
      type: Number,
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideComments: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      newComment: "",
      showCommentSection: false,
      closeReport: false,
    };
  },
  beforeMount() {
    this.showCommentSection = !this.hideComments;
  },
  methods: {
    ...mapActions("questions", ["patch_report"]),
    async patchReport() {
      this.loading = true;
      let payload = {
        question_id: this.questionId,
        report_id: this.report.id,
        status: this.closeReport ? "closed" : "pending",
        comment: this.newComment,
        language: this.language,
      };
      let res = await this.patch_report({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.newComment = "";
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.closeReport
            ? this.$t("resources.questions.close_report_success")
            : this.$t("resources.questions.add_comment_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("general.createError"),
        });
      }
    },
  },
};
</script>

<style scoped>
.look-like-a-btn {
  font-size: 0.75rem;
  letter-spacing: 0.0892857143em;
  text-indent: 0.0892857143em;
  color: rgba(0, 0, 0, 0.87);
}
.look-like-a-btn.disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}
</style>
