<template>
  <div>
    <GwAddPartDialog :session-id="sessionId" :input-options="options">
      <template #action="{ openDialog }">
        <v-list-item
          class="grey lighten-3 mb-2"
          :disabled="disabled"
          @click="openDialog()"
        >
          <v-list-item-icon>
            <v-icon :color="disabled ? 'grey' : 'primary'"> mdi-plus </v-icon>
          </v-list-item-icon>
          <v-list-item-content
            class="overline"
            :class="disabled ? 'grey--text' : 'primary--text'"
          >
            {{ $t("gw.addPart.newPart") }}
          </v-list-item-content>
        </v-list-item>
      </template>
    </GwAddPartDialog>
    <v-expansion-panels
      v-model="current"
      flat
      multiple
      :accordion="false"
      :mandatory="false"
    >
      <v-expansion-panel v-for="part in parts" :key="part.id" class="mb-2">
        <v-expansion-panel-header color="grey lighten-3">
          <div class="d-flex align-center">
            <span class="overline secondary--text"
              >{{ part.content.slice(0, 60)
              }}{{ part.content.length >= 60 ? "..." : "" }}</span
            >
            <v-spacer />
            <div class="d-flex align-center py-2 px-4">
              <v-tooltip left color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    label
                    color="grey lighten-2"
                    class="mr-2 secondary--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-comment-question </v-icon>
                    <span class="ml-2">{{ part.question_count }}</span>
                  </v-chip>
                </template>
                <span>{{ $t("resources.questions.plural") }}</span>
              </v-tooltip>
              <v-tooltip left color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    small
                    label
                    color="grey lighten-2"
                    class="mr-2 secondary--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-school </v-icon>
                    <span class="ml-2">{{ part.nugget_count }}</span>
                  </v-chip>
                </template>
                <span>{{ $t("resources.nuggets.plural") }}</span>
              </v-tooltip>

              <v-tooltip left color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <GwAddPartDialog :session-id="part.session_id">
                    <template #action="{ openDialog }">
                      <v-btn
                        color="secondary"
                        icon
                        x-small
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="openDialog(part.content)"
                      >
                        <v-icon small> mdi-file-document-multiple </v-icon>
                      </v-btn>
                    </template>
                  </GwAddPartDialog>
                </template>
                <span>{{ $t("gw.addPart.duplicatePart") }}</span>
              </v-tooltip>
              <v-tooltip left color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="deletePart(part)"
                  >
                    <v-icon small> mdi-delete </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("gw.addPart.deletePart") }}</span>
              </v-tooltip>
            </div>
          </div>

          <template #actions>
            <v-avatar class="ml-4" color="transparent" tile size="32">
              <v-icon color="secondary"> mdi-chevron-down </v-icon>
            </v-avatar>
          </template>
        </v-expansion-panel-header>

        <v-expansion-panel-content color="grey lighten-3">
          <v-row class="my-4">
            <v-col cols="12">
              <GwSessionPart :part="part" :show-dialog="openPartDialog" />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import GwSessionPart from "@/components/ghostwriter/GwSessionPart";
import GwAddPartDialog from "@/components/ghostwriter/GwAddPartDialog";

export default {
  name: "GwSessionParts",
  components: {
    GwSessionPart,
    GwAddPartDialog,
  },
  props: {
    parts: {
      type: Array,
      required: false,
      default: () => [],
    },
    sessionId: {
      type: Number,
      required: true,
    },
    options: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      part_count: this.parts.length,
      current: [],
      openPartDialog: false,
    };
  },
  watch: {
    parts: {
      handler(v) {
        if (this.part_count !== v.length) {
          this.current = [v.length - 1];
          this.part_count = v.length;
        }
      },
      deep: false,
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["delete_part"]),
    async deletePart(part) {
      let payload = { part_id: part.id, session_id: part.session_id };
      let res = await this.delete_part({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
  },
};
</script>
