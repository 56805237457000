<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-container>
        <v-data-table
          :headers="tableHeaders"
          :items="clients"
          :items-per-page="10"
          :loading="!clients"
        >
          <template #item="{ item }">
            <tr>
              <td>
                <v-icon :color="item.is_active ? 'success' : 'error'" small>
                  mdi-checkbox-marked-circle-outline
                </v-icon>
              </td>
              <td>{{ item.name }}</td>
              <td class="d-flex align-center">
                <IDPClientDialog :client="item">
                  <template #button="{ openDialog }">
                    <v-btn small icon @click="openDialog">
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                </IDPClientDialog>
                <v-btn
                  small
                  icon
                  color="error"
                  class="ml-2"
                  @click="deleteClient(item)"
                >
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import IDPClientDialog from "@/components/administration/IDPClientDialog.vue";

export default {
  name: "IDPClientAdministration",
  components: {
    ViewLoader,
    IDPClientDialog,
  },
  data() {
    return {
      loading: true,
      tableHeaders: [
        {
          text: this.$t("resources.journeys.status"),
          value: "is_active",
          width: "10%",
        },
        { text: "Name", value: "language", width: "auto" },
        { text: "Aktionen", value: "id", sortable: false, width: "10%" },
      ],
    };
  },
  computed: {
    ...mapGetters("administration", { clients: "get_idp_clients" }),
  },
  beforeMount() {
    this.allow_roles(["superadmin"]);
  },
  destroyed() {
    this.set_idp_clients([]);
  },
  methods: {
    ...mapActions("administration", ["fetch_idp_clients", "delete_idp_client"]),
    ...mapMutations("administration", ["set_idp_clients"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_idp_clients({ cancelToken: this.source.token });
      this.loading = false;
    },
    async deleteClient(client) {
      let confirm = window.confirm(
        "Willst Du den Client wirklich löschen? Dies kann nicht rückgängig gemacht werden."
      );
      if (!confirm) {
        return false;
      }
      this.loading = true;
      await this.delete_idp_client({
        payload: client,
        cancelToken: this.source.token,
      });
      this.loading = false;
    },
  },
};
</script>

<style></style>
