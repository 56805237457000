export class Userjourney {
  constructor(obj) {
    this.id = obj.id;
    this.journey_id = obj.journey_id;
    this.user_id = obj.user_id;

    this.status = obj.status;
    this.attempts_left = obj.attempts_left;
    this.criteria = obj.criteria;

    this.bundles = obj.bundles;

    this.created_at = obj.created_at;
    this.updated_at = obj.updated_at;

    this.user_bundles = this.generate_bundles();
    this.quiz_results = this.calculate_quiz_results();
  }

  generate_bundles() {
    try {
      var user_bundles = [];
      this.bundles.forEach((b) => {
        var quiz_criterias = b.criterias.filter(
          (c) => c.origin.type === "quiz_question_percent"
        )[0];
        var new_user_bundle = {
          id: b.id,
          journey_id: this.journey_id,
          bundle_id: b.bundle_id,
          user_id: this.user_id,
          status: b.status,
          status_icon:
            b.status === "locked"
              ? "mdi-lock"
              : b.status === "finished" &&
                ((quiz_criterias &&
                  quiz_criterias.value >= quiz_criterias.origin.value) ||
                  !quiz_criterias)
              ? "mdi-check-circle"
              : b.status === "unlocked"
              ? "mdi-lock-open"
              : "mdi-close-circle",
          status_color:
            b.status === "locked"
              ? "grey"
              : b.status === "finished" &&
                ((quiz_criterias &&
                  quiz_criterias.value >= quiz_criterias.origin.value) ||
                  !quiz_criterias)
              ? "success"
              : b.status === "unlocked"
              ? "primary"
              : "error",
          name: b.name,
          description: b.description,
          level: b.level,
          criterias: b.criterias,
          steps: this.generate_steps(b.steps),
        };
        user_bundles.push(new_user_bundle);
      });
      return user_bundles;
    } catch (e) {
      console.log(e);
    }
  }

  generate_steps(steps_ori) {
    var newSteps = [];
    var lastOrder = 0;
    steps_ori
      .sort((a, b) => a.origin.order - b.origin.order)
      .forEach((step) => {
        if (step.origin.order === lastOrder) {
          return false;
        }
        if (step.origin.type_id === 1) {
          step.nugget = step.origin.nugget;
          step.type = "nugget";
        }
        if (step.origin.type_id === 2) {
          step.type = "quiz";
          step.question_count = steps_ori.filter(
            (s) => s.origin.order === step.origin.order
          ).length;
        }
        step.icon =
          step.status === "finished"
            ? "mdi-check"
            : step.status === "unlocked"
            ? "mdi-lock-open"
            : step.status === "failed"
            ? "mdi-close"
            : "mdi-lock";
        step.icon_color =
          step.status === "finished"
            ? "success"
            : step.status === "unlocked"
            ? "primary"
            : step.status === "failed"
            ? "error"
            : "grey";
        newSteps.push(step);
        lastOrder = step.origin.order;
      });
    return newSteps;
  }

  calculate_quiz_results() {
    var values = [];
    this.user_bundles.forEach((b) => {
      if (b.status === "locked") return false;
      var finished_question_count = 0;
      var quizzes = b.steps.filter((s) => s.type === "quiz");
      quizzes.forEach((q) => {
        if (q.status === "finished") {
          finished_question_count += q.question_count;
        }
      });
      var bundle_quiz_result = b.criterias.filter(
        (c) => c.origin.type === "quiz_question_percent"
      )[0];
      if (!bundle_quiz_result) return false;
      if (finished_question_count > 0) {
        values.push(bundle_quiz_result.value);
      }
    });
    return values.length === 0
      ? "-"
      : values.reduce((a, b) => a + b, 0) / values.length / 100;
  }
}
