<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('certificate.singular')"
      icon="mdi-certificate"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          color="grey lighten-2"
          exact
          :to="{ name: 'JourneyCertificates' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="loading" />

    <section v-if="!loading && !!certificate">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.generalInfo.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.generalInfo.subtitle')"
      >
        <template #action>
          <div class="d-flex">
            <v-btn
              color="primary"
              depressed
              small
              class="mx-2"
              :disabled="!canBeActivated"
              :loading="sub_loading.download"
              @click="showPreview()"
            >
              {{ $t("certificate.preview") }}
            </v-btn>
            <CertificateActivator :certificate="certificate">
              <template #button="{ openDialog }">
                <v-tooltip
                  :disabled="!certificate.is_active"
                  left
                  color="error"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <v-btn
                        color="grey lighten-3"
                        elevation="0"
                        small
                        :loading="sub_loading.activating"
                        :disabled="
                          certificate.is_locked ||
                          !canBeActivated ||
                          loading ||
                          certificate.is_active
                        "
                        @click="openDialog()"
                      >
                        <v-icon
                          x-small
                          class="mr-2"
                          :color="certificate.is_active ? 'success' : 'error'"
                        >
                          mdi-checkbox-marked-circle-outline
                        </v-icon>
                        <span class="mx-2 black--text">{{
                          certificate.is_active
                            ? $t("certificate.activation.activated")
                            : $t("certificate.activation.deactivated")
                        }}</span>
                      </v-btn>
                    </div>
                  </template>
                  <span>{{
                    $t("certificate.activation.cannot_deactivate")
                  }}</span>
                </v-tooltip>
              </template>
            </CertificateActivator>
          </div>
        </template>
      </SectionHeader>
      <v-container>
        <v-expand-transition>
          <v-alert
            v-show="certificate.is_locked"
            type="info"
            color="error"
            dense
          >
            <span class="caption">{{ $t("certificate.locked_hint") }}</span>
          </v-alert>
        </v-expand-transition>
        <v-expand-transition>
          <v-alert
            v-show="!canBeActivated"
            type="error"
            color="error"
            dense
            text
          >
            <span class="caption">{{
              $t("certificate.missing_prop_hint")
            }}</span>
          </v-alert>
        </v-expand-transition>
        <v-expand-transition>
          <v-alert
            v-show="
              hasChanged.general ||
              hasChanged.text ||
              hasChanged.signature ||
              hasChanged.background
            "
            type="error"
            color="error"
            dense
            text
          >
            <span class="caption">{{ $t("certificate.unsaved_changes") }}</span>
          </v-alert>
        </v-expand-transition>
        <v-expand-transition>
          <v-alert
            v-show="
              hasChanged.text || hasChanged.signature || hasChanged.background
            "
            type="info"
            color="secondary"
            dense
            text
          >
            <span class="caption">{{ $t("certificate.save_first") }}.</span>
          </v-alert>
        </v-expand-transition>

        <v-row>
          <v-col
            cols="12"
            sm="6"
            :order="$vuetify.breakpoint.smAndUp ? 'first' : 'last'"
          >
            <v-card flat color="transparent">
              <v-card-text>
                <InputTextfield
                  v-model="input.name"
                  outlined
                  dense
                  :label="$t('certificate.form.labels.name')"
                  :hint="$t('certificate.form.hints.name')"
                  background-color="white"
                  counter="64"
                  disabled
                  :rules="rules.name"
                  translatable
                  @is-editing="setEditing"
                  @save="updateName"
                >
                  <template
                    #actions="{
                      editing,
                      startEditing,
                      cancelEditing,
                      saveInput,
                      inputChanged,
                    }"
                  >
                    <div v-if="!editing" class="d-flex ml-2">
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              icon
                              :disabled="
                                isEditing || loading || certificate.is_locked
                              "
                              @click="startEditing()"
                            >
                              <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.edit") }}</span>
                      </v-tooltip>

                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <CertificateTranslationDialog
                              prop-name="name"
                              :certificate="certificate"
                              :disabled="
                                !input.name || loading || certificate.is_locked
                              "
                              @close="fetchData()"
                            >
                              <template #button="{ onClick }">
                                <v-btn
                                  icon
                                  :disabled="
                                    !input.name ||
                                    input.name.length === 0 ||
                                    isEditing ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @click="onClick"
                                >
                                  <v-icon small> mdi-translate </v-icon>
                                </v-btn>
                              </template>
                            </CertificateTranslationDialog>
                          </div>
                        </template>
                        <span>{{ $t("general.translations") }}</span>
                      </v-tooltip>
                    </div>
                    <div v-if="editing" class="d-flex ml-2">
                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn icon color="error" @click="cancelEditing()">
                              <v-icon small> mdi-close </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.cancel") }}</span>
                      </v-tooltip>

                      <v-tooltip left color="primary">
                        <template #activator="{ on, attrs }">
                          <div v-on="on" v-bind="attrs">
                            <v-btn
                              icon
                              color="success"
                              :disabled="!inputChanged"
                              @click="saveInput()"
                            >
                              <v-icon small> mdi-check </v-icon>
                            </v-btn>
                          </div>
                        </template>
                        <span>{{ $t("general.save") }}</span>
                      </v-tooltip>
                    </div>
                  </template>
                </InputTextfield>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            :order="$vuetify.breakpoint.smAndUp ? 'last' : 'first'"
            class="pt-6"
          >
            <LanguageChooserInline
              colored
              dense
              class="pb-0"
              :label="$t('certificate.form.labels.language')"
              :hint="$t('certificate.form.hints.language')"
              :initial="language"
              :disabled="
                hasChanged.general ||
                hasChanged.text ||
                hasChanged.signature ||
                hasChanged.background
              "
              @change="changeLanguage"
            />
            <span class="caption">{{ $t("certificate.general_info") }}</span>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="!loading" class="my-6" />

    <section v-if="!loading && !!certificate" class="mt-4">
      <SectionHeader
        :title="$t('certificate.text.title')"
        :subtitle="$t('certificate.text.subtitle')"
      />
      <v-container>
        <v-expand-transition>
          <v-alert
            v-show="
              hasChanged.general ||
              hasChanged.signature ||
              hasChanged.background
            "
            type="info"
            color="secondary"
            dense
            text
          >
            <span class="caption">{{ $t("certificate.save_first") }}.</span>
          </v-alert>
        </v-expand-transition>
        <v-card flat color="grey lighten-3" class="pt-4">
          <v-card-text>
            <v-row>
              <v-col cols="6" offset="3" md="4" offset-md="0">
                <v-img
                  src="@/assets/img/misc/cert_prev.png"
                  max-height="400"
                  contain
                />
              </v-col>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="1">
                    <v-avatar color="error lighten-1" size="24" class="mr-2">
                      <v-icon small color="white"> mdi-numeric-1 </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="11">
                    <InputTextfield
                      v-model="input.title"
                      outlined
                      dense
                      :label="$t('certificate.form.labels.title')"
                      :hint="$t('certificate.form.hints.title')"
                      background-color="white"
                      counter="64"
                      disabled
                      :rules="rules.title"
                      translatable
                      @is-editing="setEditing"
                      @save="updateTitle"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <CertificateTranslationDialog
                                  prop-name="title"
                                  :certificate="certificate"
                                  :disabled="
                                    !input.title ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @close="fetchData()"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.title ||
                                        input.title.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        certificate.is_locked
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </CertificateTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="!inputChanged"
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextfield>
                  </v-col>
                  <v-col cols="1">
                    <v-avatar color="error lighten-1" size="24" class="mr-2">
                      <v-icon small color="white"> mdi-numeric-2 </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="11">
                    <InputTextfield
                      v-model="input.subtitle"
                      outlined
                      dense
                      :label="$t('certificate.form.labels.subtitle')"
                      :hint="$t('certificate.form.hints.subtitle')"
                      background-color="white"
                      counter="64"
                      disabled
                      :rules="rules.subtitle"
                      translatable
                      @is-editing="setEditing"
                      @save="updateSubtitle"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <CertificateTranslationDialog
                                  prop-name="subtitle"
                                  :certificate="certificate"
                                  :disabled="
                                    !input.subtitle ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @close="fetchData()"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.subtitle ||
                                        input.subtitle.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        certificate.is_locked
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </CertificateTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="!inputChanged"
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextfield>
                  </v-col>
                  <v-col cols="1">
                    <v-avatar color="error lighten-1" size="24" class="mr-2">
                      <v-icon small color="white"> mdi-numeric-3 </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="11">
                    <InputTextarea
                      v-model="input.intro"
                      outlined
                      dense
                      :label="$t('certificate.form.labels.intro')"
                      :hint="$t('certificate.form.hints.intro')"
                      background-color="white"
                      counter="64"
                      disabled
                      :rules="rules.intro"
                      translatable
                      @is-editing="setEditing"
                      @save="updateIntro"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <CertificateTranslationDialog
                                  prop-name="intro"
                                  :certificate="certificate"
                                  :disabled="
                                    !input.intro ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @close="fetchData()"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.intro ||
                                        input.intro.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        certificate.is_locked
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </CertificateTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="!inputChanged"
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextarea>
                  </v-col>
                  <v-col cols="1">
                    <v-avatar color="error lighten-1" size="24" class="mr-2">
                      <v-icon small color="white"> mdi-numeric-4 </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="11">
                    <InputTextarea
                      v-model="input.outro"
                      outlined
                      dense
                      :label="$t('certificate.form.labels.outro')"
                      :hint="$t('certificate.form.hints.outro')"
                      background-color="white"
                      counter="64"
                      disabled
                      :rules="rules.outro"
                      translatable
                      @is-editing="setEditing"
                      @save="updateOutro"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <CertificateTranslationDialog
                                  prop-name="outro"
                                  :certificate="certificate"
                                  :disabled="
                                    !input.outro ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @close="fetchData()"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.outro ||
                                        input.outro.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        certificate.is_locked
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </CertificateTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="!inputChanged"
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextarea>
                  </v-col>
                  <v-col cols="1">
                    <v-avatar color="error lighten-1" size="24" class="mr-2">
                      <v-icon small color="white"> mdi-numeric-5 </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="11">
                    <InputTextfield
                      v-model="input.sign"
                      outlined
                      dense
                      :label="$t('certificate.form.labels.sign')"
                      :hint="$t('certificate.form.hints.sign')"
                      background-color="white"
                      counter="64"
                      disabled
                      :rules="rules.sign"
                      translatable
                      @is-editing="setEditing"
                      @save="updateSign"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <CertificateTranslationDialog
                                  prop-name="sign"
                                  :certificate="certificate"
                                  :disabled="
                                    !input.sign ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @close="fetchData()"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.sign ||
                                        input.sign.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        certificate.is_locked
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </CertificateTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="!inputChanged"
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextfield>
                  </v-col>
                  <v-col cols="1">
                    <v-avatar color="error lighten-1" size="24" class="mr-2">
                      <v-icon small color="white"> mdi-numeric-6 </v-icon>
                    </v-avatar>
                  </v-col>
                  <v-col cols="11">
                    <InputTextarea
                      v-model="input.appendix"
                      outlined
                      dense
                      :label="$t('certificate.form.labels.appendix')"
                      :hint="$t('certificate.form.hints.appendix')"
                      background-color="white"
                      counter="64"
                      disabled
                      :rules="rules.appendix"
                      translatable
                      @is-editing="setEditing"
                      @save="updateAppendix"
                    >
                      <template
                        #actions="{
                          editing,
                          startEditing,
                          cancelEditing,
                          saveInput,
                          inputChanged,
                        }"
                      >
                        <div v-if="!editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  :disabled="
                                    isEditing ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @click="startEditing()"
                                >
                                  <v-icon small> mdi-pencil </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.edit") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <CertificateTranslationDialog
                                  prop-name="appendix"
                                  :certificate="certificate"
                                  :disabled="
                                    !input.appendix ||
                                    loading ||
                                    certificate.is_locked
                                  "
                                  @close="fetchData()"
                                >
                                  <template #button="{ onClick }">
                                    <v-btn
                                      icon
                                      :disabled="
                                        !input.appendix ||
                                        input.appendix.length === 0 ||
                                        isEditing ||
                                        loading ||
                                        certificate.is_locked
                                      "
                                      @click="onClick"
                                    >
                                      <v-icon small> mdi-translate </v-icon>
                                    </v-btn>
                                  </template>
                                </CertificateTranslationDialog>
                              </div>
                            </template>
                            <span>{{ $t("general.translations") }}</span>
                          </v-tooltip>
                        </div>
                        <div v-if="editing" class="d-flex ml-2">
                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="error"
                                  @click="cancelEditing()"
                                >
                                  <v-icon small> mdi-close </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.cancel") }}</span>
                          </v-tooltip>

                          <v-tooltip left color="primary">
                            <template #activator="{ on, attrs }">
                              <div v-on="on" v-bind="attrs">
                                <v-btn
                                  icon
                                  color="success"
                                  :disabled="!inputChanged"
                                  @click="saveInput()"
                                >
                                  <v-icon small> mdi-check </v-icon>
                                </v-btn>
                              </div>
                            </template>
                            <span>{{ $t("general.save") }}</span>
                          </v-tooltip>
                        </div>
                      </template>
                    </InputTextarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </section>

    <v-divider v-if="!loading && !!certificate" class="my-6" />

    <section v-if="!loading && !!certificate">
      <SectionHeader
        :title="$t('certificate.form.labels.color_text')"
        :subtitle="$t('certificate.form.hints.color_text_hint')"
      />
      <v-container>
        <v-card color="grey lighten-3" flat>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <ColorPicker
                  v-model="input.color_text"
                  :label="$t('certificate.form.labels.color_text')"
                  :hint="$t('certificate.form.hints.color_text')"
                  :disabled="
                    hasChanged.general ||
                    hasChanged.signature ||
                    hasChanged.background ||
                    certificate.is_locked
                  "
                />
              </v-col>
              <v-col cols="6">
                <v-spacer />
                <ColorPicker
                  v-model="input.color_accent"
                  :label="$t('certificate.form.labels.color_accent')"
                  :hint="$t('certificate.form.hints.color_accent')"
                  :disabled="
                    hasChanged.general ||
                    hasChanged.signature ||
                    hasChanged.background ||
                    certificate.is_locked
                  "
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              text
              color="error"
              :disabled="loading || !hasChanged.text"
              @click="setInput()"
            >
              {{ $t("general.reset") }}
            </v-btn>
            <v-btn
              small
              color="primary"
              class="mx-2"
              text
              :loading="sub_loading.updating"
              :disabled="loading || !hasChanged.text"
              @click="updateCertificate()"
            >
              {{ $t("general.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </section>

    <v-divider v-if="!loading && !!certificate" class="my-6" />

    <section v-if="!loading" class="my-4">
      <SectionHeader
        :title="$t('certificate.media.title')"
        :subtitle="$t('certificate.media.subtitle')"
      />
      <v-container>
        <v-expand-transition>
          <v-alert
            v-show="
              hasChanged.general || hasChanged.text || hasChanged.signature
            "
            type="info"
            color="secondary"
            dense
            text
          >
            <span class="caption">{{ $t("certificate.save_first") }}.</span>
          </v-alert>
        </v-expand-transition>
        <v-card flat color="grey lighten-3">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" order-sm="last">
                <div>
                  <SectionHeader
                    :title="$t('certificate.background.title')"
                    class="px-0"
                  />
                  <span>{{ $t("certificate.background.hint") }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <ImageUpload
                  :image-hash="input.img_bg_hash"
                  :disabled="
                    hasChanged.general ||
                    hasChanged.text ||
                    hasChanged.signature ||
                    certificate.is_locked
                  "
                  card-color="white"
                  @image-changed="backgroundChange"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              text
              color="error"
              :disabled="loading || !hasChanged.background"
              @click="setInput()"
            >
              {{ $t("general.reset") }}
            </v-btn>
            <v-btn
              small
              color="primary"
              class="mx-2"
              text
              :loading="sub_loading.updating"
              :disabled="loading || !hasChanged.background || !input.img_bg"
              @click="updateCertificate()"
            >
              {{ $t("general.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>

      <v-container>
        <v-expand-transition>
          <v-alert
            v-show="
              hasChanged.general || hasChanged.text || hasChanged.background
            "
            type="info"
            color="secondary"
            dense
            text
          >
            <span class="caption">{{ $t("certificate.save_first") }}.</span>
          </v-alert>
        </v-expand-transition>
        <v-card flat color="grey lighten-3">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <div>
                  <SectionHeader
                    :title="$t('certificate.signature.image')"
                    class="px-0"
                  />
                  <span>{{ $t("certificate.signature.hint") }}</span>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <ImageUpload
                  :image-hash="input.img_sign_hash"
                  :disabled="
                    hasChanged.general ||
                    hasChanged.text ||
                    hasChanged.background ||
                    certificate.is_locked
                  "
                  card-color="white"
                  @image-changed="signatureChange"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              text
              color="error"
              :disabled="loading || !hasChanged.signature"
              @click="setInput()"
            >
              {{ $t("general.reset") }}
            </v-btn>
            <v-btn
              small
              color="primary"
              class="mx-2"
              text
              :loading="sub_loading.updating"
              :disabled="loading || !hasChanged.signature || !input.img_sign"
              @click="updateCertificate()"
            >
              {{ $t("general.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import ImageUpload from "@/components/global/ImageUpload";
import ColorPicker from "@/components/global/ColorPicker";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";
import CertificateActivator from "@/components/journeys/dialogs/CertificateActivator";
import CertificateTranslationDialog from "@/components/journeys/dialogs/CertificateTranslationDialog";

export default {
  name: "JourneyCertificate",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    ImageUpload,
    ColorPicker,
    LanguageChooserInline,
    CertificateActivator,
    CertificateTranslationDialog,
  },
  data() {
    return {
      loading: true,
      id: this.$route.params.id,
      language: this.$i18n.locale,
      input: null,
      sub_loading: {
        download: false,
        changing_default: false,
        activating: false,
        updating: false,
      },
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.categories.name"),
            }),
          (v) =>
            (v && v.length <= 64) ||
            this.$t("forms.rules.maxCharacters", { max: 64 }),
        ],
        title: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.nuggets.title"),
            }),
          (v) =>
            (v && v.length <= 64) ||
            this.$t("forms.rules.maxCharacters", { max: 64 }),
        ],
        subtitle: [
          //(v) => !!v || this.$t('forms.rules.isRequired', { inputFieldName: this.$t('resources.nuggets.title') }),
          (v) =>
            (v && v.length <= 255) ||
            this.$t("forms.rules.maxCharacters", { max: 256 }),
        ],
        sign: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("certificate.signature.title"),
            }),
          (v) =>
            (v && v.length <= 128) ||
            this.$t("forms.rules.maxCharacters", { max: 128 }),
        ],
      },
      valid: {
        general: false,
        signature: false,
        background: false,
      },
      isEditing: false,
    };
  },
  computed: {
    ...mapGetters("journeys", { certificate: "get_certificate" }),
    hasChanged() {
      let changed = {
        general: false,
        signature: false,
        background: false,
      };
      if (
        this.input.color_text !== this.certificate.color_text ||
        this.input.color_accent !== this.certificate.color_accent
      )
        changed.text = true;

      if (this.input.img_sign !== this.certificate.img_sign)
        changed.signature = true;
      if (this.input.img_bg !== this.certificate.img_bg)
        changed.background = true;
      return changed;
    },
    canBeActivated() {
      if (!this.certificate) return false;
      var c = { ...this.certificate };
      return (
        !!c.name &&
        !!c.title &&
        !!c.sign &&
        !!c.color_text &&
        !!c.color_accent &&
        !!c.img_bg &&
        !!c.img_sign
      );
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("resources.journeys.journey.plural"),
          disabled: false,
          to: { name: "JourneyCurrent" },
        },
        {
          text: this.$t("certificate.plural"),
          disabled: false,
          to: { name: "JourneyCertificates" },
        },
      ];
    },
  },
  watch: {
    certificate: {
      handler() {
        this.setInput();
      },
    },
    language: {
      handler: function () {
        this.fetchData();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager", "editor"]);
  },
  destroyed() {
    this.set_certificate(null);
  },
  methods: {
    ...mapActions("journeys", [
      "fetch_certificate",
      "update_certificate",
      "preview_certificate",
      "update_certificate_default",
      "patch_certificate_translation",
    ]),
    ...mapMutations("journeys", ["set_certificate"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_certificate({
        id: this.id,
        language: this.language,
        cancelToken: this.source.token,
      });
      this.loading = false;
    },
    setInput() {
      this.input = { ...this.certificate };
    },
    async updateCertificate() {
      this.sub_loading.updating = true;
      var payload = { ...this.input };
      payload.language = this.language;
      var res = await this.update_certificate({ certificate: payload });
      this.sub_loading.updating = false;
      if (res && res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          text: this.$t("certificate.activation.change_success"),
          type: "success",
        });
      } else {
        this.$notify({
          title: this.$t("general.error"),
          text: this.$t("certificate.activation.change_error"),
          type: "error",
        });
      }
    },
    backgroundChange(image) {
      this.input.img_bg_hash = image.hash;
      this.input.img_bg = image.filename;
    },
    signatureChange(image) {
      this.input.img_sign_hash = image.hash;
      this.input.img_sign = image.filename;
    },
    async showPreview() {
      this.sub_loading.download = true;
      let config = {
        cancelToken: this.source.token,
        responseType: "blob",
      };
      var res = await this.preview_certificate({
        id: this.id,
        language: this.language,
        config: config,
      });
      let contentType = res._headers["content-type"];
      let blob = new Blob([res], { type: contentType });
      let filename = this.filename
        ? this.filename
        : res._headers["content-disposition"]
            .split(";")[1]
            .trim()
            .replace("filename=", "");
      var link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      this.sub_loading.download = false;
    },
    changeLanguage(e) {
      this.language = e;
    },
    setEditing(val) {
      this.isEditing = val;
    },
    async patchCertificate(props, translate) {
      this.loading = true;
      let payload = {
        id: this.certificate.id,
        language: this.language,
        override: translate,
        properties: props,
      };
      let res = await this.patch_certificate_translation({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;

      if (res._status === 200) {
        this.fetchData();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    updateName(value, translate) {
      let properties = [{ name: "name", value: value }];
      this.patchCertificate(properties, translate);
    },
    updateTitle(value, translate) {
      let properties = [{ name: "title", value: value }];
      this.patchCertificate(properties, translate);
    },
    updateSubtitle(value, translate) {
      let properties = [{ name: "subtitle", value: value }];
      this.patchCertificate(properties, translate);
    },
    updateIntro(value, translate) {
      let properties = [{ name: "intro", value: value }];
      this.patchCertificate(properties, translate);
    },
    updateOutro(value, translate) {
      let properties = [{ name: "outro", value: value }];
      this.patchCertificate(properties, translate);
    },
    updateSign(value, translate) {
      let properties = [{ name: "sign", value: value }];
      this.patchCertificate(properties, translate);
    },
    updateAppendix(value, translate) {
      let properties = [{ name: "appendix", value: value }];
      this.patchCertificate(properties, translate);
    },
  },
};
</script>

<style />
