import api from "@/services/api";

export const fetch_sso_clients = async ({ commit }, { config = {} }) => {
  try {
    let res = await api.get("admin/oauth2/sso/clients", config);
    if (res._status === 200) {
      commit("set_sso_clients", res.clients);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_idp_clients = async ({ commit }, { config = {} }) => {
  try {
    let res = await api.get("admin/oauth2/idp/clients", config);
    if (res._status === 200) {
      commit("set_idp_clients", res.clients);
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_sso_client = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post("admin/oauth2/sso/clients", payload, config);
    if (res._status === 200) {
      dispatch("fetch_sso_clients", { config: config });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_idp_client = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post("admin/oauth2/idp/clients", payload, config);
    if (res._status === 200) {
      dispatch("fetch_idp_clients", { config: config });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_sso_client = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      "admin/oauth2/sso/clients/" + payload.name,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_sso_clients", { config: config });
      return res;
    } else {
      // 403
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const update_idp_client = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      "admin/oauth2/idp/clients/" + payload.name,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_idp_clients", { config: config });
      return res;
    } else {
      // 403
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const delete_sso_client = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete(
      "admin/oauth2/sso/clients/" + payload.name,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_sso_clients", { config: config });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const delete_idp_client = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete(
      "admin/oauth2/idp/clients/" + payload.name,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_idp_clients", { config: config });
      return true;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_gw_licence = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post("admin/gw/licences", payload, config);
    if (res._status === 200) {
      dispatch(
        "ghostwriter/fetch_licences",
        { config: config },
        {
          root: true,
        }
      );
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_gw_licence = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put("admin/gw/licences/" + payload.id, payload, config);
    if (res._status === 200) {
      dispatch(
        "ghostwriter/fetch_licences",
        { config: config },
        {
          root: true,
        }
      );
      return res;
    } else {
      // 403
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const delete_gw_licence = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete("admin/gw/licences/" + payload.id, config);
    if (res._status === 200) {
      dispatch(
        "ghostwriter/fetch_licences",
        { config: config },
        {
          root: true,
        }
      );
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_gw_user_licence = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.post("admin/gw/user_licences", payload, config);
    if (res._status === 200) {
      await dispatch(
        "ghostwriter/fetch_licences",
        { config: config },
        {
          root: true,
        }
      );
      if (payload.reload)
        await dispatch(
          "users/fetch_user",
          { id: payload.user_id, config: config },
          { root: true }
        );
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const update_gw_user_licence = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      "admin/gw/user_licences/" + payload.id,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch(
        "ghostwriter/fetch_licences",
        { config: config },
        {
          root: true,
        }
      );
      return res;
    } else {
      // 403
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const delete_gw_user_licence = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.delete("admin/gw/user_licences/" + payload.id, config);
    if (res._status === 200) {
      dispatch(
        "ghostwriter/fetch_licences",
        { config: config },
        {
          root: true,
        }
      );
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const extend_gw_user_licence = async (
  { dispatch },
  { id, payload, config = {} }
) => {
  try {
    let res = await api.post(
      `admin/gw/user_licences/${id}/extend`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch(
        "ghostwriter/fetch_licences",
        { config: config },
        {
          root: true,
        }
      );
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};
