<template>
  <div>
    <slot name="button" :onClick="openDialog" />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? 800 : '100%'"
      @click:outside="closeDialog()"
    >
      <v-card color="grey lighten-3">
        <v-card-title class="secondary--text overline">
          {{ $t("ng.nuggets.edit_videos") }}
        </v-card-title>

        <v-card-text>
          <p>
            {{ $t("ng.nuggets.edit_videos_hint") }}
          </p>
          <v-list-item>
            <v-list-item-avatar>
              <v-icon :color="getSourceIcon(videoSource).color">{{
                getSourceIcon(videoSource).icon
              }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                videoSource ? videoSource : $t("ng.nuggets.no_video_source")
              }}</v-list-item-title>
              <v-list-item-subtitle>
                {{ $t("ng.nuggets.edit_videos_source") }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text>{{
                $t("general.reset")
              }}</v-list-item-action-text>
              <v-btn
                icon
                color="error"
                :disabled="!videoSource"
                @click="resetVideos()"
              >
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-text>

        <v-divider v-if="!loading && !reinitializing" />

        <v-slide-y-reverse-transition leave-absolute>
          <div v-if="!loading && reinitializing">
            <v-card-text>
              <VideoUpload
                :initial-type="videoSource"
                @video-changed-dialog="reinitVideos"
              />
            </v-card-text>
          </div>
        </v-slide-y-reverse-transition>

        <v-slide-y-transition leave-absolute>
          <div v-if="!loading && !reinitializing">
            <v-card-text>
              <v-expansion-panels
                :value="activePanels"
                multiple
                flat
                dense
                :disabled="!nugget"
              >
                <v-expansion-panel
                  v-for="(language, i) in languages"
                  :key="i"
                  dense
                  :disabled="reinitializing"
                  class="transparent my-0"
                >
                  <v-expansion-panel-header>
                    <div class="d-flex align-center">
                      <v-avatar size="32" tile class="mr-2">
                        <country-flag :country="flagName(language)" />
                      </v-avatar>
                      <div>{{ $t(`languages.${language}.display`) }}</div>

                      <!-- <div v-if="translations[language]">
                    {{ translations[language][propName] }}
                  </div> -->
                      <div v-if="!translations[language]">
                        {{ $t("ng.no_translations") }}
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <VideoUpload
                      v-if="videoSource"
                      :initial-type="videoSource"
                      :initial-link="inputs[language].value"
                      :initial-lang="language"
                      @video-changed-single="reinitVideo(language, $event)"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </div>
        </v-slide-y-transition>

        <v-card-actions>
          <v-btn text small color="secondary" @click="closeDialog()">
            <span>{{ $t("general.close") }}</span>
          </v-btn>
          <!-- <v-btn text small color="error">
            <v-icon small>mdi-close</v-icon>
            <span class="ml-2">{{ $t("general.cancel") }}</span>
          </v-btn> -->
          <v-spacer />
          <v-btn
            depressed
            small
            color="success"
            :disabled="!videosHaveChanged"
            @click="saveVideos()"
          >
            <v-icon small>mdi-content-save</v-icon>
            <span class="ml-2">{{ $t("general.save") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";
import VideoUpload from "@/components/global/VideoUpload";

export default {
  name: "NuggetVideoDialog",
  components: {
    CountryFlag,
    VideoUpload,
  },
  props: {
    nugget: {
      type: Object,
      required: true,
    },
    language: {
      // endpoint needs it, no further function
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      activePanels: [],
      inputs: {},
      compareInputs: {},
      loading: true,
      propName: "media_uid",
      videoSource: "",
      videosHaveChanged: false,
      reinitializing: false,
    };
  },
  computed: {
    ...mapGetters("nuggets", { translations: "get_nugget_translations" }),
    languages() {
      return this.$i18n.availableLocales;
    },
  },
  watch: {
    dialog(v) {
      if (v) this.loadTranslations();
    },
  },
  methods: {
    ...mapActions("nuggets", [
      "fetch_nugget_translations",
      "patch_nugget",
      "patch_nugget_old",
    ]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.activePanels = [];
      this.dialog = false;
      this.reinitializing = false;
      this.$emit("close");
    },
    flagName(lang) {
      return lang.slice(-2);
    },
    showAllPanels() {
      this.activePanels = [...this.languages.keys()].map((k, i) => i);
    },
    hideAllPanels() {
      this.activePanels = [];
    },
    async loadTranslations() {
      let payload = { id: this.nugget.id };
      this.loading = true;
      await this.fetch_nugget_translations({
        payload,
        token: this.source.token,
      });
      this.generateInput();
      //this.showAllPanels();
      this.loading = false;
    },
    generateInput() {
      var languages = JSON.parse(JSON.stringify([...this.languages]));
      languages.forEach((lang) => {
        let input = { language: lang, value: "" };
        if (this.translations[lang])
          input.value = this.translations[lang][this.propName];
        this.inputs[lang] = input;
      });
      this.compareInputs = JSON.parse(JSON.stringify({ ...this.inputs }));
      this.videoSource = this.nugget.source;
    },
    resetInput() {
      this.generateInput();
    },
    setValue(lang, e) {
      this.inputs[lang].value = e;
    },
    getSourceIcon(source) {
      if (source === "youtube") return { icon: "mdi-youtube", color: "red" };
      if (source === "vimeo") return { icon: "mdi-vimeo", color: "secondary" };
      if (source === "ted")
        return { icon: "mdi-alpha-t-box", color: "secondary" };
      if (source === "p3lmedia")
        return { icon: "mdi-upload", color: "primary" };
      return { icon: "mdi-help-circle", color: "warning" };
    },
    resetVideos() {
      this.videoSource = "";
      this.languages.forEach((lang) => (this.inputs[lang].value = ""));
      this.reinitializing = true;
    },
    reinitVideo(lang, data) {
      this.videoSource = data[0];
      this.inputs[lang].value = data[1];
      this.reinitializing = false;
      this.videosHaveChanged = true;
    },
    reinitVideos(type, link) {
      this.videoSource = type;
      this.languages.forEach((lang) => (this.inputs[lang].value = link));
      this.reinitializing = false;
      this.videosHaveChanged = true;
    },
    async saveVideos() {
      this.loading = true;

      var langs = this.$i18n.availableLocales;
      var media_uids = {};
      langs.forEach((lang) => {
        media_uids[lang] = this.inputs[lang].value;
      });

      let payload = {
        id: this.nugget.id,
        language: this.language,
        override: false,
        media_uids: media_uids,
      };
      let res = await this.patch_nugget_old({
        payload: payload,
        type: "video",
        language: this.language,
        token: this.source.token,
      });
      let payload_src = {
        id: this.nugget.id,
        language: this.language,
        properties: [{ name: "ng_source", value: this.videoSource }],
      };
      await this.patch_nugget({
        payload: payload_src,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
      this.videosHaveChanged = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>
