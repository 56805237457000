<template>
  <div>
    <slot name="button" :onClick="openDialog" />

    <v-dialog
      v-model="dialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? 800 : '100%'"
      @click:outside="closeDialog()"
    >
      <v-card color="grey lighten-4">
        <v-card-title class="secondary--text overline">
          {{ $t("ng.edit_translations") }}
        </v-card-title>
        <v-card-text v-if="translations && !loading" class="px-0">
          <v-expansion-panels
            :value="activePanels"
            multiple
            flat
            dense
            :disabled="!bundle"
          >
            <v-expansion-panel
              v-for="(language, i) in languages"
              :key="i"
              dense
              class="transparent my-0"
            >
              <v-expansion-panel-header>
                <div class="d-flex align-center">
                  <v-avatar size="32" tile class="mr-2">
                    <country-flag :country="flagName(language)" />
                  </v-avatar>
                  <!-- <div>{{ $t(`languages.${language}.display`) }}</div> -->
                  <div v-if="translations[language]">
                    {{ translations[language][propName] }}
                  </div>
                  <div v-if="!translations[language]">
                    {{ $t("ng.no_translations") }}
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <InputTextfield
                  v-if="!textarea"
                  :value="inputs[language].value"
                  counter="255"
                  :rules="rules.input"
                  listen-to-input
                  :disabled="disabled"
                  @change="setValue(language, $event)"
                >
                  <template #actions>
                    <div class="ml-2">
                      <v-btn
                        icon
                        color="error"
                        :disabled="
                          disabled ||
                          translations[language][propName] ===
                            inputs[language].value
                        "
                        @click="resetInput(language)"
                      >
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="
                          disabled ||
                          translations[language][propName] ===
                            inputs[language].value ||
                          inputs[language].value.length === 0 ||
                          inputs[language].value.length > 255
                        "
                        icon
                        color="success"
                        @click="saveTranslation(language)"
                      >
                        <v-icon small> mdi-check </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </InputTextfield>
              </v-expansion-panel-content>
              <v-expansion-panel-content>
                <!-- :rules="rules.input" counter="255" -->
                <InputTextarea
                  v-if="textarea"
                  v-model="inputs[language].value"
                  listen-to-input
                  :disabled="disabled"
                  html
                  @input="setValue(language, $event)"
                >
                  <template #actions>
                    <div class="ml-2 d-flex flex-column">
                      <v-btn
                        icon
                        color="error"
                        :disabled="
                          disabled ||
                          translations[language][propName] ===
                            inputs[language].value
                        "
                        @click="resetInput(language)"
                      >
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="
                          disabled ||
                          translations[language][propName] ===
                            inputs[language].value ||
                          inputs[language].value.length === 0
                        "
                        icon
                        color="success"
                        @click="saveTranslation(language)"
                      >
                        <v-icon small> mdi-check </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </InputTextarea>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              small
              :disabled="activePanels.length === 0"
              @click="hideAllPanels()"
            >
              {{ $t("ng.close_all") }}
            </v-btn>
            <v-btn
              text
              small
              :disabled="activePanels.length === languages.length - 1"
              @click="showAllPanels()"
            >
              {{ $t("ng.show_all") }}
            </v-btn>
            <v-btn text small @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";

export default {
  name: "journeyTranslationDialog",
  components: {
    CountryFlag,
  },
  props: {
    propName: {
      type: String,
      required: true,
    },
    bundle: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    textarea: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      activePanels: [],
      rules: {
        input: [
          (v) => !!v || this.$t("ng.add_chars_hint"),
          (v) =>
            (v && v.length < 255) ||
            this.$t("ng.too_many_chars_hint", { max: 254, count: v.length }),
        ],
      },
      inputs: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      translations: "get_journey_bundle_translations",
      journey: "get_journey",
    }),
    languages() {
      return this.$i18n.availableLocales;
    },
    defaultLanguage() {
      return process.env.VUE_APP_I18N_LOCALE;
    },
  },
  watch: {
    dialog(v) {
      if (v) this.loadTranslations();
    },
  },
  methods: {
    ...mapActions("journeys", [
      "fetch_journey_bundle_translations",
      "patch_bundle_translation",
    ]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.activePanels = [];
      this.dialog = false;
    },
    flagName(lang) {
      return lang.slice(-2);
    },
    showAllPanels() {
      //this.activePanels = [...this.languages.filter(lang => { return lang !== this.defaultLanguage } ).keys()].map((k, i) => i+1 );
      this.activePanels = [...this.languages.keys()].map((k, i) => i);
    },
    hideAllPanels() {
      this.activePanels = [];
    },
    async loadTranslations() {
      let payload = { id: this.bundle.id };
      this.loading = true;
      await this.fetch_journey_bundle_translations({
        payload,
        token: this.source.token,
      });
      this.generateInput();
      this.loading = false;
    },
    generateInput() {
      var languages = JSON.parse(JSON.stringify([...this.languages]));
      languages.forEach((lang) => {
        let input = { language: lang, value: "" };
        if (this.translations[lang])
          input.value = this.translations[lang][this.propName];
        this.inputs[lang] = input;
      });
    },
    resetInput() {
      this.generateInput();
    },
    setValue(lang, e) {
      this.inputs[lang].value = e;
    },
    async saveTranslation(lang) {
      this.loading = true;
      let payload = {
        id: this.bundle.id,
        journey_id: this.journey.id,
        language: lang,
        override: false,
        properties: [{ name: this.propName, value: this.inputs[lang].value }],
      };
      let res = await this.patch_bundle_translation({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>
