<template>
  <div>
    <v-btn
      color="error"
      small
      depressed
      :disabled="disabled"
      @click="showDialog = true"
    >
      <span>{{ $t("resources.questions.delete_title") }}</span>
      <v-icon color="white" small class="ml-2"> mdi-delete </v-icon>
    </v-btn>

    <v-dialog :value="showDialog" width="500" @click:outside="closeDialog()">
      <v-card :loading="loading">
        <v-card-title class="text-overline pb-0">
          <span>{{ $t("resources.questions.delete_title") }}</span>
        </v-card-title>

        <v-card-text class="pt-2">
          <p class="primary white--text font-weight-bold text-center pa-2">
            {{ question.body }}
          </p>
          <p>
            {{ $t("resources.questions.delete_description") }}
          </p>
          <v-alert
            v-if="!question.can.delete"
            color="error"
            text
            type="error"
            dense
            class="caption"
          >
            <div v-html="$t('ng.delete_question_hint')" />
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text small @click="closeDialog(false)">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn color="error" text small @click="deleteQuestion">
            {{ $t("general.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "QuestionDelete",
  components: {},
  props: {
    question: {
      type: Object,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      showDialog: false,
    };
  },
  methods: {
    ...mapActions("questions", ["delete_question"]),
    closeDialog(wasDeleted) {
      this.showDialog = false;
      this.$emit("close", wasDeleted);
    },
    async deleteQuestion() {
      this.loading = true;
      let res = await this.delete_question({
        id: this.question.id,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          type: "success",
          text: this.$t("resources.questions.delete_success"),
        });
        this.closeDialog(true);
      } else {
        this.$notify({
          title: this.$t("general.error"),
          type: "error",
          text: res.msg
            ? this.$t("errors." + res.error)
            : this.$t("resources.questions.delete_error"),
        });
      }
    },
  },
};
</script>

<style></style>
