<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="show_dialog"
      max-width="640"
      :persistent="loading"
      @click:outside="loading ? false : closeDialog()"
    >
      <v-card
        v-if="show_dialog"
        color="grey lighten-3"
        max-width="640"
        :loading="loading"
      >
        <template #progress>
          <v-progress-linear indeterminate color="primary" height="6" />
        </template>
        <v-card-title class="overline secondary--text pb-0">
          {{ $t("gw.transcriptions.add") }}
        </v-card-title>
        <v-card-text>
          <v-expand-transition>
            <v-alert v-show="upload_error" type="error" color="error">{{
              upload_error
            }}</v-alert>
          </v-expand-transition>
          <v-row>
            <v-col cols="12">
              <v-chip-group
                v-model="mode"
                mandatory
                active-class="primary"
                column
              >
                <v-chip label :disabled="loading">
                  <v-avatar left>
                    <v-icon>mdi-upload</v-icon>
                  </v-avatar>
                  <span>{{ $t("gw.transcriptions.fileupload") }}</span>
                </v-chip>
                <v-chip label :disabled="loading">
                  <v-avatar left>
                    <v-icon>mdi-youtube</v-icon>
                  </v-avatar>
                  <span>{{ $t("gw.transcriptions.types.youtube") }}</span>
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-card-text>

        <v-form v-model="form_valid">
          <v-card-text>
            <v-slide-y-transition hide-on-leave>
              <v-row v-show="mode === 0 && !loading">
                <v-col cols="12">
                  <v-file-input
                    v-model="file"
                    prepend-inner-icon="mdi-multimedia"
                    prepend-icon=""
                    :append-icon="!file ? 'mdi-upload' : ''"
                    background-color="white"
                    dense
                    show-size
                    outlined
                    accept="audio/mp3,video/mp4,audio/mpeg,audio/x-m4a,audio/wav,video/webm,video/mpg"
                    :hint="$t('gw.transcription.accepted_file_types')"
                    persistent-hint
                    :disabled="loading"
                    :rules="rules.file"
                    :label="$t('resources.invitations.fileInput')"
                  />
                </v-col>
              </v-row>
            </v-slide-y-transition>

            <v-slide-y-transition hide-on-leave>
              <v-row v-show="mode === 0 && loading">
                <v-col cols="12">
                  <v-card flat color="grey lighten-2">
                    <v-card-text
                      class="d-flex flex-column align-center justify-center"
                    >
                      <v-progress-circular
                        :value="currentFileProgress"
                        color="primary"
                        :size="96"
                        :width="10"
                        :rotate="-90"
                      >
                        <span v-if="currentFileProgress <= 99">{{
                          currentFileProgress
                        }}</span>
                        <v-icon v-if="currentFileProgress >= 100"
                          >mdi-check</v-icon
                        >
                      </v-progress-circular>
                      <div class="mt-2">
                        {{ $t("resources.media.loading_text") }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-slide-y-transition>

            <v-slide-y-transition hide-on-leave>
              <v-row v-show="mode === 1">
                <v-col cols="12">
                  <v-text-field
                    :value="youtube_url"
                    prepend-inner-icon="mdi-youtube"
                    label="Youtube URL"
                    outlined
                    clearable
                    background-color="white"
                    :hint="
                      !!youtube_error
                        ? youtube_error
                        : $t('gw.transcriptions.add_yt_hint')
                    "
                    persistent-hint
                    dense
                    :disabled="loading"
                    @input="youtubeLinkChanged"
                  >
                    <template #append>
                      <v-icon
                        :color="
                          youtube_url && !youtube_error
                            ? 'success'
                            : 'secondary'
                        "
                      >
                        {{
                          youtube_url ? "mdi-check-circle" : "mdi-help-circle"
                        }}
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-slide-y-transition>

            <v-row>
              <v-col cols="12">
                <LanguageChooserInline
                  colored
                  dense
                  class="pb-0"
                  :disabled="loading"
                  :label="$t('gw.target_language')"
                  :hint="$t('gw.target_language_hint')"
                  persistent-hint
                  :initial="$i18n.locale"
                  @change="changeLanguage"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="description"
                  :label="$t('resources.journeys.propNames.description')"
                  outlined
                  background-color="white"
                  :hint="$t('gw.transcriptions.description_hint')"
                  persistent-hint
                  dense
                  :disabled="loading"
                />
              </v-col>
              <v-col cols="12">
                <v-alert type="info" color="info">
                  <div>{{ $t("gw.transcriptions.medium_lang_hint_1") }}</div>
                  <small>{{
                    $t("gw.transcriptions.medium_lang_hint_2")
                  }}</small>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            color="secondary"
            :disabled="loading"
            @click="closeDialog()"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :disabled="
              loading ||
              !form_valid ||
              (mode === 0 && !file) ||
              (mode === 1 && (!!youtube_error || !youtube_url))
            "
            @click="addTranscription()"
          >
            {{ mode === 0 ? $t("general.upload") : $t("gw.create") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";

export default {
  name: "GwAddTranscriptionDialog",
  components: {
    LanguageChooserInline,
  },
  data() {
    return {
      show_dialog: false,
      mode: 0, // 0 = upload, 1 = youtube
      loading: false,
      language: this.$i18n.locale,
      file: null,
      youtube_url: "",
      youtube_error: "",
      form_valid: false,
      progress_id_token: "",
      upload_error: "",
      upload_progress: null,
      description: "",
      rules: {
        file: [
          (value) =>
            !value ||
            value.size < 500000000 ||
            this.$t("resources.media.validation", { size: "500" }),
        ],
      },
    };
  },
  computed: {
    currentFileProgress() {
      if (!this.upload_progress) return 0;
      let progress = { ...this.upload_progress };
      return Math.round((progress.received / progress.size) * 100);
    },
  },
  watch: {
    mode: {
      handler: function () {
        this.reset();
      },
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "create_transcription",
      "fetch_gw_media_upload_token",
      "fetch_gw_upload_progress",
    ]),
    reset() {
      this.file = null;
      this.youtube_url = "";
      this.description = "";
      this.upload_error = "";
      this.progress_id_token = "";
      this.upload_error = null;
    },
    openDialog() {
      this.show_dialog = true;
    },
    closeDialog() {
      this.show_dialog = false;
      this.reset();
    },
    createProgressToken() {
      let randomString = (Math.random() + 1).toString(36).substring(7);
      let time = new Date().getTime().toString();
      return `video-${randomString}-${time}`;
    },
    async addTranscription() {
      this.loading = true;
      var token = await this.fetch_gw_media_upload_token({
        config: { cancelToken: this.source.token },
      });
      if (!token) this.showError();
      if (this.mode === 0)
        var upload_interval = setInterval(this.checkProgress, 1000);

      var inputs = new FormData();
      inputs.append("Gwtoken", token);
      inputs.append("language", this.language);
      inputs.append("type", this.mode === 0 ? "video" : "youtube");
      inputs.append("description", this.description);
      if (this.mode === 0) {
        inputs.append("file", this.file);
      }
      if (this.mode === 1) {
        inputs.append("url", this.youtube_url);
      }

      var config = {
        cancelToken: this.source.token,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (this.mode === 0) {
        this.progress_id_token = this.createProgressToken();
        config.headers["X-Progress-ID"] = this.progress_id_token;
      }

      var res = await this.create_transcription({
        payload: inputs,
        type: this.mode === 0 ? "audiovideo" : "youtube",
        config: config,
      });
      this.uploading = false;
      this.loading = false;

      if (this.mode === 0) {
        clearInterval(upload_interval);
        this.checkProgress();
        this.progress_id_token = "";
      }

      if (res && res._status === 750) {
        this.showError("yt");
        return false;
      }
      if (!res || res._status === 400 || !res.transcription_id) {
        this.showError();
        return false;
      }

      this.$router.push({
        name: "GwTranscriptionDetail",
        params: { id: res.transcription_id },
      });
    },
    youtubeLinkChanged(value) {
      this.youtube_error = "";
      this.youtube_url = "";
      if (!value) return false;
      if (value.includes("www")) {
        this.youtube_url = value.replace("www.", "");
      }
      if (value.match(/https:\/\/www.youtube.com\/watch\?v=/g)) {
        this.youtube_url = value.replace(
          /https:\/\/www.youtube.com\/watch\?v=/g,
          "https://youtube.com/embed/"
        );
      }
      if (value.match(/https:\/\/youtu.be\//g)) {
        this.youtube_url = value.replace(
          /https:\/\/youtu.be\//g,
          "https://youtube.com/embed/"
        );
      }
      if (!this.youtube_url.match(/https:\/\/youtube.com\/embed\//g)) {
        this.youtube_error = this.$t("resources.nuggets.video_youtube_hint");
      }
    },
    showError(type) {
      this.upload_error = !type
        ? this.$t("gw.transcriptions.upload_error")
        : this.$t("gw.transcriptions.yt_error");
      this.$notify({
        type: "error",
        title: this.$t("general.error"),
        text: !type
          ? this.$t("resources.media.upload_error")
          : this.$t("gw.transcriptions.yt_error"),
      });
    },
    async checkProgress() {
      let config = {
        cancelToken: this.source.token,
        headers: {
          "Content-Type": "application/json",
          "X-Progress-ID": this.progress_id_token,
        },
      };
      let res = await this.fetch_gw_upload_progress({ config: config });

      this.upload_progress = res;
    },
    changeLanguage(v) {
      this.language = v;
    },
  },
};
</script>
