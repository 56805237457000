<template>
  <v-dialog
    v-model="dialog"
    max-width="640"
    @click:outside="loading ? false : closeDialog()"
  >
    <v-form v-model="valid" :disabled="loading">
      <v-card v-if="!!input" color="grey lighten-3">
        <v-card-title class="overline secondary--text">
          <span>Credit Pool bearbeiten</span>
          <v-spacer />
          <v-btn
            x-small
            depressed
            color="error"
            :disabled="loading"
            @click="deleteCreditPool()"
            >{{ $t("general.delete") }}</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="input.credits_count"
                type="number"
                label="Anzahl Credits"
                dense
                hint="Anzahl an Credits, auf welche alle User mit berechtigten Lizenzen zugreifen können."
                persistent-hint
                background-color="white"
                outlined
                min="0"
                :rules="rules.credits_count"
              />
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="startDatePicker"
                v-model="startDatePicker"
                :return-value.sync="input.date_start"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="input.date_start"
                    readonly
                    v-bind="attrs"
                    dense
                    outlined
                    hint="Start der Lizenz"
                    persistent-hint
                    background-color="white"
                    v-on="on"
                    :rules="rules.required"
                  >
                    <template #append>
                      <v-btn icon small @click="input.date_start = ''">
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="input.date_start"
                  scrollable
                  type="month"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="startDatePicker = false">
                    {{ $t("general.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startDatePicker.save(input.date_start)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="6">
              <v-dialog
                ref="endDatePicker"
                v-model="endDatePicker"
                :return-value.sync="input.date_end"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="input.date_end"
                    readonly
                    v-bind="attrs"
                    dense
                    outlined
                    hint="Ende der Lizenz"
                    persistent-hint
                    background-color="white"
                    v-on="on"
                    :rules="rules.required"
                  >
                    <template #append>
                      <v-btn icon small @click="input.date_end = ''">
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker v-model="input.date_end" scrollable type="month">
                  <v-spacer />
                  <v-btn text color="primary" @click="endDatePicker = false">
                    {{ $t("general.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endDatePicker.save(input.date_end)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            small
            text
            color="secondary"
            :disabled="loading"
            @click="closeDialog()"
            >{{ $t("general.close") }}</v-btn
          >
          <v-btn
            small
            text
            color="success"
            :loading="loading"
            :disabled="!valid || loading"
            @click="updateCreditPool()"
            >{{ $t("general.update") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { GwCreditPool } from "@/models/ghostwriter/GwCreditPool";
import { mapActions, mapGetters } from "vuex";
//import { GwCreditPool } from "@/models/ghostwriter/GwCreditPool";

export default {
  name: "GwCreditPoolDialog",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      input: null,
      rules: {
        credits_count: [
          (v) => !!v || this.$t("ng.add_chars_hint"),
          //(v) => typeof v === "number" || this.$t("ng.numeric_needed"),
        ],
      },
      startDatePicker: false,
      endDatePicker: false,
      monthDurations: [
        {
          value: 1,
          label: `1 ${this.$t("general.single_month")}`,
        },
        {
          value: 2,
          label: `2 ${this.$t("general.months")}`,
        },
        {
          value: 3,
          label: `3 ${this.$t("general.months")}`,
        },
        {
          value: 6,
          label: `6 ${this.$t("general.months")}`,
        },
        {
          value: 12,
          label: `12 ${this.$t("general.months")}`,
        },
      ],
    };
  },

  computed: {
    ...mapGetters("ghostwriter", { credit_pool: "get_credit_pool" }),
  },
  watch: {
    visible: {
      handler: function (v) {
        if (v) {
          this.openDialog();
          this.setInput();
        }
      },
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["update_credit_pool", "delete_credit_pool"]),
    setInput() {
      this.input = new GwCreditPool({ ...this.credit_pool });
      this.input.date_start = this.input.date_start.toISOString().substr(0, 10);
      this.input.date_end = this.input.date_end.toISOString().substr(0, 10);
    },
    openDialog() {
      this.dialog = true;
      //this.credit_pool = new GwCreditPool();
    },
    closeDialog() {
      this.dialog = false;
      this.input = null;
      this.$emit("close");
    },
    async updateCreditPool() {
      this.loading = true;
      var pool = { ...this.input };
      if (pool.date_start.length === 7)
        pool.date_start = pool.date_start.concat("-01");
      if (pool.date_end.length === 7)
        pool.date_end = pool.date_end.concat("-01");
      var res = await this.update_credit_pool({
        payload: pool,
        cancelToken: this.source.token,
      });
      if (res && res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          text: "Credit-Pool erfolgreich bearbeitet.",
          type: "success",
        });
      } else {
        this.$notify({
          title: this.$t("general.error"),
          text: "Fehler beim Bearbeiten des Credit-Pools",
          type: "error",
        });
      }
      this.loading = false;
    },
    async deleteCreditPool() {
      var confirm = window.confirm(
        "Willst Du den Credit Pool wirklich löschen? Dies kann nicht rückgängig gemacht werden."
      );
      if (!confirm) return false;
      this.loading = true;
      var res = await this.delete_credit_pool({
        id: this.credit_pool.id,
        cancelToken: this.source.token,
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          text: "Credit-Pool erfolgreich gelöscht.",
          type: "success",
        });
        this.closeDialog();
      } else {
        this.$notify({
          title: this.$t("general.error"),
          text: "Fehler beim Löschen des Credit-Pools",
          type: "error",
        });
      }
    },
  },
};
</script>
