var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ViewHeader',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":"Datenimport","icon":"mdi-database-import"}}),(!_vm.imports)?_c('ViewLoader'):_vm._e(),(_vm.imports)?_c('section',[_c('SectionHeader',{attrs:{"title":`${_vm.$t('general.overview')}`}}),_c('v-container',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"py-1"},[_c('v-chip',{attrs:{"small":"","close":_vm.activeFilters > 0,"color":_vm.activeFilters ? 'primary' : ''},on:{"click:close":_vm.resetFilter}},[_vm._v(" "+_vm._s(_vm.activeFilters)+" "+_vm._s(_vm.$t("general.filtersSelected"))+" ")]),_c('v-spacer'),(_vm.display.filters)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.display.filters = !_vm.display.filters}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1):_vm._e(),(!_vm.display.filters)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.display.filters = !_vm.display.filters}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_vm._e()],1),(_vm.display.filters)?_c('v-card-text',{staticClass:"pb-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","label":_vm.$t('general.search'),"outlined":"","dense":"","hide-details":"","append-icon":"mdi-close"},on:{"click:append":function($event){_vm.filter.search = ''},"keydown":function($event){_vm.page = 1}},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}})],1)],1)],1):_vm._e()],1)],1)],1)],1),_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"headers":[
                {
                  text: 'Name',
                  value: 'name',
                  width: '20%',
                },
                {
                  text: 'Typ',
                  value: 'import_type',
                  width: '20%',
                },
                {
                  text: 'Beschreibung',
                  value: 'description',
                },
              ],"items":_vm.filteredImports,"items-per-page":10,"search":_vm.filter.search,"page":_vm.page,"loading":!_vm.imports},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"item",fn:function(request){return [_c('tr',{class:{
                    disabled: ['categories', 'nuggets'].includes(
                      request.item.import_type
                    ),
                  },on:{"click":function($event){return _vm.rowClicked(request.item)}}},[_c('td',[_vm._v(_vm._s(request.item.name))]),_c('td',[_vm._v(_vm._s(_vm.getTranslation(request.item.import_type)))]),_c('td',[_vm._v(_vm._s(request.item.description))])])]}}],null,false,1330933636)})],1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }