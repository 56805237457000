<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      title="Kategorieimporte"
      icon="mdi-database-import"
    />
    <ViewLoader v-if="!importItem" />

    <section v-if="importItem">
      <SectionHeader :title="`${$t('general.overview')}`" />
      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters" class="pb-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="[
                  /*{
                    text: 'Status',
                    value: 'status',
                    width: '20%'
                  },
                  {
                    text: 'Fragentyp',
                    value: 'question_type'
                  },
                  {
                    text: 'Content ready',
                    value: 'content_ready'
                  },
                  {
                    text: '# Fragen',
                    value: 'body'
                  },
                  {
                    text: 'Meta ready',
                    value: 'meta_ready'
                  },
                  {
                    text: '# Metatags',
                    value: 'metatags'
                  },
                  {
                    text: '# Kategorien',
                    value: 'categories.length'
                  },
                  {
                    text: '# Kommentare',
                    value: 'comments'
                  }*/
                  {
                    text: 'Kategorie',
                    value: '',
                  },
                ]"
                :items="filteredItems"
                :items-per-page="10"
                :search="filter.search"
                :page.sync="page"
                :loading="loading"
              >
                <template #item="request">
                  <tr @click="rowClicked(request.item)">
                    <td>{{ request.item }}</td>
                    <!-- <td>
                      <v-chip
                        x-small
                        :color="request.item.status === 'imported' ? 'success' : 'grey lighten-3'"
                        class="font-weight-bold"
                      >
                        {{ request.item.status }}
                      </v-chip>
                    </td>
                    <td>{{ request.item.question_type }}</td>
                    <td>
                      <v-icon :color="request.item.content_ready ? 'success' : 'error'">
                        {{ request.item.content_ready ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                    <td>{{ request.item.body ? request.item.body.length : '-' }}</td>
                    <td>
                      <v-icon :color="request.item.meta_ready ? 'success' : 'error'">
                        {{ request.item.meta_ready ? 'mdi-check' : 'mdi-close' }}
                      </v-icon>
                    </td>
                    <td>{{ request.item.metatags ? request.item.metatags.length : '-' }}</td>
                    <td>{{ request.item.categories ? request.item.categories.length : '-' }}</td>
                    <td>{{ request.item.comments ? request.item.comments.length : '-' }}</td> -->
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "ImportDetail",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      id: Number(this.$route.params.id),
      page: 1,
      display: {
        filters: false,
      },
      filter: {
        search: "",
      },
      /* options: {
        status: ['unapproved', 'editing', 'imported'],
        meta: [ { value: true, name: 'Ready'}, { value: false, name: 'Not ready' }],
        questions: [ { value: true, name: 'Ja'}, { value: false, name: 'Nein' }]
      }, */
      loading: false,
    };
  },
  computed: {
    ...mapGetters("imports", { importItem: ["get_import"] }),
    filteredItems() {
      if (!this.importItem) return [];

      /* var filtered = [];
      if(this.importItem.questions) filtered.push(this.importItem.questions); */
      var filtered = [...this.importItem.categories];

      /* if (this.filter.status.length > 0) {
        filtered = filtered.filter(item => {
          return this.filter.status.includes(item.status);
        })
      }
      if (this.filter.meta_ready !== null) {
        filtered = filtered.filter(item => {
          return item.meta_ready === this.filter.meta_ready;
        })
      }
      if (this.filter.content_ready !== null) {
        filtered = filtered.filter(item => {
          return item.content_ready === this.filter.content_ready;
        })
      }
      if (this.filter.hasQuestions !== null) {
        filtered = filtered.filter(item => {
          if(this.filter.hasQuestions) return item.body && item.body.length > 0;
          return (!item.body || item.body.length == 0)
        })
      } */
      return filtered;
    },
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return search;
      //return search + this.filter.status.length + (this.filter.meta_ready !== null ? 1 : 0) + (this.filter.content_ready !== null ? 1 : 0) + (this.filter.hasQuestions !== null ? 1 : 0);
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "Datenimport",
          to: { name: "ImportOverview" },
        },
        {
          text: "Kategorieimporte",
          disabled: true,
        },
      ];
    },
  },
  /* watch: {
    filter: {
      deep: true,
      handler() { this.storeFilter(); }
    }
  }, */
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    this.id = Number(this.$route.params.id);
    /* this.getFilter(); */
  },
  methods: {
    ...mapActions("imports", ["fetch_import"]),
    ...mapMutations("imports", ["set_import"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_import({ id: this.id, type: "categories" });
      this.loading = false;
    },
    unloadData() {
      this.set_import(null);
    },
    rowClicked(item) {
      if (!item) return false;
      /* this.$router.push({ name: 'ImportDetailQuestions', params: { id: this.id, question_id: item.id } }); */
    },
    resetFilter() {
      this.filter = {
        search: "",
      };
    },
    /* storeFilter() {
      localStorage.setItem('fil_imp_cat', JSON.stringify(this.filter));
    },
    getFilter() {
      if(localStorage.getItem('fil_imp_cat')) {
        let filter = JSON.parse(localStorage.getItem('fil_imp_cat'));

        this.filter.search = filter.search ? filter.search : '';
      }
    } */
  },
};
</script>

<style></style>
