<template>
  <div>
    <section v-if="question">
      <SectionHeader
        :title="$t('gw.question_nuggets_title')"
        :subtitle="$t('gw.question_nuggets_title')"
      >
        <template #action>
          <QuestionNuggetLinkDialog :question="question" />
        </template>
      </SectionHeader>

      <v-container>
        <v-expand-transition>
          <v-alert
            v-if="!question.nuggets || question.nuggets.length === 0"
            text
            type="info"
          >
            {{ $t("gw.no_link") }}
          </v-alert>
        </v-expand-transition>
        <v-list
          v-if="question.nuggets && question.nuggets.length > 0"
          class="transparent"
        >
          <v-list-item
            v-for="(nugget, i) in question.nuggets"
            :key="i"
            class="grey lighten-3 my-2"
          >
            <v-list-item-avatar
              ><v-icon>mdi-vector-combine</v-icon></v-list-item-avatar
            >
            <v-list-item-content>
              <v-list-item-title>{{ nugget.title }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ $t("resources.nuggets.plural") }}
                {{ i + 1 }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <v-btn
                  depressed
                  small
                  color="error"
                  @click="unlinkNugget(nugget)"
                >
                  <v-icon small> mdi-vector-link </v-icon>
                </v-btn>
                <v-btn
                  depressed
                  small
                  class="ml-2"
                  color="secondary"
                  target="_blank"
                  :to="{ name: 'NuggetDetail', params: { id: nugget.id } }"
                >
                  <v-icon small> mdi-magnify </v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import QuestionNuggetLinkDialog from "@/components/questions/QuestionNuggetLinkDialog";

export default {
  name: "QuestionNuggets",
  components: {
    SectionHeader,
    QuestionNuggetLinkDialog,
  },
  computed: {
    ...mapGetters("questions", {
      question: "get_question",
      //language: 'get_selected_language'
    }),
  },
  methods: {
    ...mapActions("questions", ["unlink_question_nugget"]),
    async unlinkNugget(nugget) {
      let payload = {
        question_id: this.question.id,
        nugget_id: nugget.id,
        language: this.$i18n.locale,
      };
      let res = await this.unlink_question_nugget(payload, this.source.token);
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
  },
};
</script>
