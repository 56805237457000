import api from "@/services/api";

export const fetch_imports = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/imports/", config);
    if (res._status === 200) {
      commit("set_imports", res.imports);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_import = async (
  { commit },
  { id, type, category_id, config = {} }
) => {
  try {
    // TODO: activate category filter again or remove completely
    let cat_str =
      category_id || !category_id ? "" : `?category_id=${category_id}`;
    let res = await api.get(`admin/imports/${id}${cat_str}`, config);
    if (res._status === 200) {
      if (type === "questions") {
        let questions = [];
        questions = res.questions.filter(
          (question) => question.question_type !== null
        );
        res.import.questions = questions;
      }
      if (type === "categories") res.import.categories = res.categories;
      if (type === "nuggets") res.import.nuggets = res.nuggets;
      commit("set_import", res.import);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_import_question = async (
  { commit },
  { question_id, config = {} }
) => {
  try {
    let res = await api.get(`admin/imports/questions/${question_id}`, config);
    if (res._status === 200) {
      //let requests = res.forms.map(request => { return new Supportrequest(request) });
      commit("set_import_question", res.question);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_import_question = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/imports/questions/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_import_question", { question_id: payload.id });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};

/* export const submit_question = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post(`admin/imports/questions/${payload.id}/submit`, payload, config);
    if(res) {
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null
  }
}; */

export const fetch_import_nugget = async (
  { commit },
  { nugget_id, config = {} }
) => {
  try {
    let res = await api.get(`admin/imports/nuggets/${nugget_id}`, config);
    if (res._status === 200) {
      //let requests = res.forms.map(request => { return new Supportrequest(request) });
      commit("set_import_nugget", res.nugget);
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_import_nugget = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.put(
      `admin/imports/nuggets/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_import_nugget", { nugget_id: payload.id });
      return res;
    } else {
      return false;
    }
  } catch (err) {
    return null;
  }
};
