<template>
  <div class="view view-dashboard">
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.dashboard.title')"
      icon="mdi-apps"
    />
    <ViewLoader v-if="loading" />

    <section v-if="!loading && dashboard_stats" class="mt-4">
      <v-container>
        <v-row>
          <v-col
            v-if="
              ['superadmin', 'admin', 'manager'].includes(role) &&
              showStatsForManager
            "
            cols="12"
            sm="6"
          >
            <StatisticsCardLarge
              :title="$t('views.statistics.registers')"
              :title-value="dashboard_stats.users.registers.toLocaleString()"
              :subtitle="$t('views.statistics.logins')"
              :subtitle-value="dashboard_stats.users.logins.toLocaleString()"
              icon="mdi-account-multiple-plus"
              :link="{ name: 'StatisticsUser' }"
            />
          </v-col>
          <v-col
            v-if="
              ['superadmin', 'admin', 'manager'].includes(role) &&
              showStatsForManager
            "
            cols="12"
            sm="6"
          >
            <StatisticsCardLarge
              :title="$t('ng.played_duels')"
              :title-value="dashboard_stats.quizzes.duels.toLocaleString()"
              :subtitle="$t('views.statistics.trainings')"
              :subtitle-value="
                dashboard_stats.quizzes.trainings.toLocaleString()
              "
              icon="mdi-lightbulb"
              icon-background-color="primary"
              :link="{ name: 'StatisticsQuizzes' }"
            />
          </v-col>
          <v-col v-if="['editor'].includes(role)" cols="12" sm="6">
            <StatisticsCardLarge
              :title="$t('ng.active_questions')"
              :title-value="dashboard_stats.questions.count.toLocaleString()"
              :subtitle="$t('ng.currently_active')"
              icon="mdi-comment-question"
              icon-background-color="primary"
              :link="{ name: 'QuestionOverview' }"
              :hint-text="$t('ng.show_all')"
              hint-icon="mdi-arrow-right-bold"
            />
          </v-col>
          <v-col v-if="['editor'].includes(role)" cols="12" sm="6">
            <StatisticsCardLarge
              :title="$t('ng.active_nuggets')"
              :title-value="dashboard_stats.nuggets.count.toLocaleString()"
              :subtitle="$t('ng.currently_active')"
              icon="mdi-school"
              icon-background-color="primary"
              :link="{ name: 'NuggetOverview' }"
              :hint-text="$t('ng.show_all')"
              hint-icon="mdi-arrow-right-bold"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="!loading && dashboard_stats" class="my-2">
      <v-container>
        <v-row dense>
          <v-col
            v-if="['superadmin', 'admin', 'editor'].includes(role)"
            cols="6"
            sm="3"
          >
            <StatisticsCardSmall
              v-if="dashboard_questions.approval === 0"
              :title="dashboard_stats.questions.count.toLocaleString()"
              :subtitle="$t('resources.questions.plural')"
              icon="mdi-comment-question"
              :link="{ name: 'QuestionOverview' }"
            />
            <StatisticsCardSmall
              v-if="dashboard_questions.approval > 0"
              :title="dashboard_questions.approval.toLocaleString()"
              :subtitle="$t('ng.question_submissions')"
              icon="mdi-book-plus"
              color="warning"
              :elevation="8"
              text-color="text"
              icon-color="error"
              click-type="approvals"
              @clicked="goTo"
            />
          </v-col>
          <v-col
            v-if="['superadmin', 'admin', 'editor'].includes(role)"
            cols="6"
            sm="3"
          >
            <StatisticsCardSmall
              v-if="dashboard_questions.reports === 0"
              :title="dashboard_stats.nuggets.count.toLocaleString()"
              :subtitle="$t('resources.nuggets.plural')"
              icon="mdi-school"
              :link="{ name: 'NuggetOverview' }"
            />
            <StatisticsCardSmall
              v-if="dashboard_questions.reports > 0"
              :title="dashboard_questions.reports.toLocaleString()"
              :subtitle="$t('ng.question_reports')"
              icon="mdi-alert"
              color="warning"
              :elevation="8"
              text-color="text"
              icon-color="error"
              click-type="reports"
              @clicked="goTo"
            />
          </v-col>
          <v-col
            v-if="['superadmin', 'admin', 'editor'].includes(role)"
            cols="6"
            sm="3"
          >
            <StatisticsCardSmall
              :title="dashboard_stats.categories.count.toLocaleString()"
              :subtitle="$t('resources.categories.plural')"
              icon="mdi-shape"
              :link="{ name: 'CategoryOverview' }"
            />
          </v-col>
          <v-col
            v-if="
              supportRequestCount > 0 && ['superadmin', 'admin'].includes(role)
            "
            cols="6"
            sm="3"
          >
            <StatisticsCardSmall
              color="warning"
              :elevation="8"
              :title="supportRequestCount"
              :subtitle="$t('resources.support.requests')"
              icon="mdi-face-agent"
              text-color="text"
              icon-color="error"
              :link="{ name: 'SupportOverview' }"
            />
          </v-col>
          <v-col
            v-if="
              ['superadmin', 'admin', 'editor'].includes(role) &&
              showGhostwriter
            "
            cols="6"
            sm="3"
          >
            <StatisticsCardSmall
              v-if="
                supportRequestCount === 0 ||
                !['superadmin', 'admin'].includes(role)
              "
              :title="$t('gw.generateContent')"
              :subtitle="$t('gw.ghostwriter')"
              icon="mdi-typewriter"
              color="secondary"
              :link="{ name: 'GwSessionList' }"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="!loading && dashboard_stats" class="mt-4">
      <v-container>
        <v-row>
          <v-col
            v-if="['superadmin', 'admin', 'editor'].includes(role)"
            cols="12"
            sm="6"
          >
            <StatisticsRankingList
              :data="dashboard_stats.categories.top_three"
              :title="$t('views.statistics.categories_popular_title')"
              :subtitle="$t('views.statistics.categories_popular_descr')"
              item-title="name"
              item-image="image"
              header-route-name="StatisticsCategories"
              item-route-name="CategoryDetail"
              item-route-prop="id"
            />
          </v-col>
          <v-col
            v-if="
              ['superadmin', 'admin', 'manager'].includes(role) &&
              showStatsForManager
            "
            cols="12"
            sm="6"
          >
            <StatisticsRankingList
              :data="structure_statistics"
              :title="$t('ng.largest_structures')"
              :subtitle="$t('ng.structure_statistics_descr')"
              item-title="name"
              header-route-name="StatisticsStructures"
              append-value="users_count"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import StatisticsRankingList from "@/components/statistics/StatisticsRankingList";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";
import StatisticsCardLarge from "@/components/statistics/StatisticsCardLarge";

export default {
  name: "DashboardView",
  components: {
    ViewHeader,
    ViewLoader,
    StatisticsRankingList,
    StatisticsCardSmall,
    StatisticsCardLarge,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("support", { support_requests: "get_support_requests" }),
    ...mapGetters("auth", {
      dashboard_stats: "get_dashboard_stats",
      dashboard_questions: "get_dashboard_questions",
    }),
    ...mapGetters("structures", {
      structure_statistics: "get_structure_statistics",
    }),
    breadcrumbs() {
      if (!this.auth) return [];
      return [
        {
          text: this.$t("views.dashboard.welcome", {
            username: this.auth.user_name,
          }),
          disabled: true,
        },
      ];
    },
    showStatsForManager() {
      if (
        process.env.VUE_APP_HIDE_STATS_FOR_MANAGER === "true" &&
        this.role == "manager"
      )
        return false;
      return true;
    },
    supportRequestCount() {
      if (!this.support_requests) return 0;
      let requests = this.support_requests.filter(
        (request) => request.status !== "resolved"
      );
      return requests.length;
    },
    categoryPlayedSum() {
      if (!this.dashboard_stats) return 0;
      let categories = [...this.dashboard_stats.categories.top_three];
      let sum = 0;
      categories.forEach((cat) => (sum += cat.played_count));
      return sum;
    },
    showGhostwriter() {
      return process.env.VUE_APP_GHOSTWRITER === "true";
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor", "manager"]);
  },
  methods: {
    ...mapActions("support", ["fetch_support_requests"]),
    ...mapMutations("support", ["set_support_requests"]),
    ...mapActions("auth", ["fetch_dashboard_info"]),
    ...mapMutations("auth", ["set_dashboard_stats", "set_dashboard_questions"]),
    ...mapMutations("questions", ["set_reviewmode", "set_reportmode"]),
    ...mapActions("structures", ["fetch_structures_statistics"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      this.loading = true;
      if (["superadmin", "admin"].includes(this.role)) {
        await this.fetch_support_requests({ cancelToken: this.source.token });
      }
      if (["superadmin", "admin", "manager"].includes(this.role)) {
        await this.fetch_structures_statistics({
          cancelToken: this.source.token,
        });
      }
      this.fetch_dashboard_info({ cancelToken: this.source.token });
      this.loading = false;
    },
    unloadData() {
      this.set_support_requests(null);
      this.set_dashboard_stats(null);
      this.set_dashboard_questions(null);
    },
    goTo(action) {
      if (!action) return false;
      if (action === "reports") {
        this.set_reviewmode(false);
        this.set_reportmode(true);
      }
      if (action === "approvals") {
        this.set_reportmode(false);
        this.set_reviewmode(true);
      }
      this.$router.push({ name: "QuestionOverview" });
    },
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.v-avatar--offset {
  top: -12px;
  right: 12px;
  position: absolute;
}
</style>
