<template>
  <v-dialog :value="showDialog" max-width="640" @click:outside="closeDialog()">
    <v-card :loading="isLoading" color="grey lighten-3">
      <v-card-title class="text-overline pb-0">
        <span>{{ $t("resources.users.role_title") }}</span>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-row>
          <v-col cols="12">
            <v-select
              v-if="!isLoading"
              v-model="inputs.role_id"
              :items="roles"
              item-text="name"
              item-value="id"
              :hint="$t('resources.users.role_warning')"
              persistent-hint
              :messages="
                !['superadmin', 'admin'].includes(role)
                  ? $t('general.errors.admin_only')
                  : ''
              "
              :disabled="
                !['superadmin', 'admin'].includes(role) ||
                (['superadmin', 'admin'].includes(role) && user.id == auth.id)
              "
              :label="$t('resources.users.role')"
              @change="changeUserRole"
            >
              <template #item="{ item }">
                {{ item.name }}
              </template>
              <template #append-outer>
                <TutorialCarouselDialog :templates="tutorialUserrole" />
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-autocomplete
              v-if="!isLoading && inputs.role_id == 2"
              v-model="editorCategories"
              :items="sortedCategories"
              item-text="name"
              item-value="id"
              chips
              small-chips
              deletable-chips
              :hint="$t('resources.users.role_warning_short')"
              persistent-hint
              :messages="
                !['superadmin', 'admin'].includes(role)
                  ? $t('general.errors.admin_only')
                  : ''
              "
              :disabled="!['superadmin', 'admin'].includes(role)"
              :label="$t('resources.users.related_categories')"
              multiple
              @change="changeCategories"
            >
              <template #item="{ item }">
                {{ item.name }}
              </template>
              <template #append-outer>
                <TutorialCarouselDialog
                  :templates="tutorialAssignedcategories"
                />
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-if="!isLoading && inputs.role_id == 4"
              v-model="managerSubstructures"
              :items="sortedSubstructures"
              item-text="name"
              item-value="id"
              chips
              small-chips
              deletable-chips
              :hint="$t('resources.users.role_warning_short')"
              persistent-hint
              :messages="
                !['superadmin', 'admin'].includes(role)
                  ? $t('general.errors.admin_only')
                  : ''
              "
              :disabled="!['superadmin', 'admin'].includes(role)"
              :label="$t('resources.users.manager_substructures')"
              multiple
              @change="changeCategories"
            >
              <template #item="{ item }">
                {{ item.structure.name }} / {{ item.name }}
              </template>
              <template #selection="{ item }">
                <v-chip small>
                  {{ item.structure.name }} / {{ item.name }}
                </v-chip>
              </template>
              <template #append-outer>
                <TutorialCarouselDialog
                  :templates="tutorialManagerStructures"
                />
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text small @click="closeDialog()">
          {{ $t("general.close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TutorialCarouselDialog from "@/components/tutorials/TutorialCarouselDialog";

export default {
  name: "UserDialogRole",
  components: {
    TutorialCarouselDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
      inputs: null,
      tutorialUserrole: ["userrole"],
      tutorialAssignedcategories: ["editorcategories"],
      tutorialManagerStructures: ["managersubstructures"],
      editorCategories: [],
      managerSubstructures: [],
      roles: [
        { id: 1, name: this.$t("resources.users.roles.1") },
        { id: 2, name: this.$t("resources.users.roles.2") },
        { id: 3, name: this.$t("resources.users.roles.3") },
        { id: 4, name: this.$t("resources.users.roles.4") },
      ],
    };
  },
  computed: {
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    ...mapGetters("categories", { categories: "get_categories" }),
    showDialog() {
      return this.dialog;
    },
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return (categories = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
    sortedSubstructures() {
      if (!this.substructures) return [];
      let substructures = [...this.substructures];
      return (substructures = substructures.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
    selectedRole() {
      return this.inputs && this.inputs.role_id ? this.inputs.role_id : null;
    },
  },
  watch: {
    showDialog(val) {
      if (val) {
        this.inputs = { ...this.user };
      }
    },
    selectedRole(v) {
      if (v === 2) this.managerSubstructures = [];
      if (v === 4) this.editorCategories = [];
    },
  },
  beforeMount() {
    this.inputs = { ...this.user };
    this.editorCategories = this.user.related_categories;
    this.managerSubstructures = this.user.related_substructures;
    if (["superadmin"].includes(this.role))
      this.roles.unshift({ id: 5, name: this.$t("resources.users.roles.5") });
  },
  methods: {
    ...mapActions("users", ["update_role", "delete_role"]),
    closeDialog() {
      this.$emit("close");
    },
    async changeUserRole() {
      this.loading = true;
      if ([1, 3, 4].includes(this.inputs.role_id)) {
        this.inputs.category_ids = [];
      }
      if (this.inputs.role_id == 2) {
        this.inputs.category_ids = this.editorCategories;
      }
      if (this.inputs.role_id == 4) {
        this.inputs.substructure_ids = [];
      }
      let success = false;
      if (this.inputs.role_id == 3) {
        success = await this.delete_role({
          payload: this.inputs,
          config: { cancelToken: this.source.token },
        });
      } else {
        success = await this.update_role({
          payload: this.inputs,
          config: { cancelToken: this.source.token },
        });
      }
      this.loading = false;
      if (success) {
        this.$notify({
          type: "success",
          text:
            this.$t("resources.users.singular") +
            " " +
            this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          text:
            this.$t("resources.users.singular") +
            " " +
            this.$t("general.updateError"),
        });
      }
    },
    async changeCategories() {
      let payload = {
        id: this.inputs.id,
        role_id: this.inputs.role_id,
        category_ids: this.inputs.role_id == 2 ? this.editorCategories : [],
        substructure_ids:
          this.inputs.role_id == 4 ? this.managerSubstructures : [],
      };
      this.loading = true;
      let success = await this.update_role({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
      if (success) {
        //await this.loadUser(this.inputs.id)
        this.$notify({
          type: "success",
          text:
            this.$t("resources.users.singular") +
            " " +
            this.$t("general.updateSuccess"),
        });
      }
    },
  },
};
</script>

<style></style>
