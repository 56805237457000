<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.support.support')"
      icon="mdi-face-agent"
    />

    <ViewLoader v-if="!support_requests" />

    <section v-if="support_requests">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters" class="pb-0">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.status"
                      :items="options.status"
                      item-text="name"
                      item-value="type"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.users.status')"
                      :placeholder="$t('general.all')"
                      prepend-icon="mdi-checkbox-marked-circle-outline"
                      outlined
                      multiple
                      hide-details
                      dense
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="[
                  {
                    text: $t('resources.support.props.status'),
                    value: 'status',
                    width: '15%',
                  },
                  {
                    text: $t('resources.support.props.created_at'),
                    value: 'created_at',
                    width: '20%',
                  },
                  {
                    text: $t('resources.support.props.name'),
                    value: 'name',
                    width: '40%',
                  },
                  {
                    text: '',
                    value: 'images',
                    width: '10%',
                    sortable: false,
                  },
                  {
                    text: '',
                    width: '15%',
                    sortable: false,
                  },
                ]"
                :items="filteredRequests"
                :items-per-page="10"
                :search="filter.search"
                :page.sync="page"
                :loading="!support_requests"
              >
                <template #item="request">
                  <tr>
                    <td>
                      <v-chip
                        :color="getStatusColor(request.item.status)"
                        x-small
                      >
                        {{
                          $t("resources.support.status." + request.item.status)
                        }}
                      </v-chip>
                    </td>
                    <td>{{ getLocaleDateString(request.item.created_at) }}</td>
                    <td>{{ request.item.name }}</td>
                    <td>
                      <v-badge
                        v-if="request.item.images.length > 0"
                        overlap
                        color="primary"
                        dot
                        bordered
                      >
                        <v-icon color="grey"> mdi-image </v-icon>
                      </v-badge>
                      <v-icon
                        v-if="request.item.images.length === 0"
                        color="grey lighten-3"
                      >
                        mdi-image
                      </v-icon>
                    </td>
                    <td>
                      <SupportRequestDialog :request="request.item" />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import SupportRequestDialog from "@/components/support/SupportRequestDialog";
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";

export default {
  name: "SupportOverview",
  components: {
    SectionHeader,
    SupportRequestDialog,
    ViewHeader,
    ViewLoader,
  },
  data() {
    return {
      page: 1,
      display: {
        filters: false,
      },
      filter: {
        status: [],
        search: "",
      },
      options: {
        status: [
          { type: "open", name: this.$t("resources.support.status.open") },
          {
            type: "pending",
            name: this.$t("resources.support.status.pending"),
          },
          {
            type: "resolved",
            name: this.$t("resources.support.status.resolved"),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("support", { support_requests: "get_support_requests" }),
    filteredRequests() {
      let requests = [...this.support_requests];
      if (this.filter.status.length > 0) {
        requests = requests.filter((request) => {
          return this.filter.status.includes(request.status);
        });
      }
      return requests;
    },
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return this.filter.status.length + search;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("resources.support.support"),
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin"]);
    this.getFilter();
  },
  methods: {
    ...mapActions("support", ["fetch_support_requests"]),
    ...mapMutations("support", ["set_support_requests"]),
    async fetchData() {
      await this.fetch_support_requests({ cancelToken: this.source.token });
    },
    unloadData() {
      this.set_support_requests(null);
    },
    getStatusColor(status) {
      if (status.toLowerCase() === "pending") return "warning";
      if (status.toLowerCase() === "resolved") return "success";
      return "grey lighten-3";
    },
    resetFilter() {
      (this.filter.status = []), (this.search = "");
    },
    storeFilter() {
      localStorage.setItem("fil_sup", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_sup")) {
        let filter = JSON.parse(localStorage.getItem("fil_sup"));

        this.filter.status = filter.status ? filter.status : [];
        this.filter.search = filter.search ? filter.search : "";
      }
    },
  },
};
</script>

<style></style>
