<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      title="Nuggetimporte"
      icon="mdi-database-import"
    />
    <ViewLoader v-if="!nugget_import" />

    <section v-if="nugget_import">
      <v-container>
        <v-card color="grey lighten-3" elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="4">
                <h5 class="secondary--text mb-4">Status ändern</h5>
                <v-menu offset-x left close-on-content-click>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey lighten-2"
                      elevation="0"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar
                        size="6"
                        :color="
                          nugget_import.status === 'imported'
                            ? 'success'
                            : 'error'
                        "
                      />
                      <span class="mx-2 black--text">{{
                        nugget_import.status
                      }}</span>
                      <v-icon v-if="!loading" small> mdi-dots-vertical </v-icon>
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        :size="12"
                        :width="2"
                        color="grey"
                      />
                    </v-btn>
                  </template>
                  <v-list dense class="py-0">
                    <v-subheader
                      class="caption font-weight-bold pl-4 grey lighten-3"
                    >
                      <span class="grey--text">{{
                        $t("resources.journeys.changeStatus")
                      }}</span>
                    </v-subheader>
                    <v-list-item
                      v-for="(status, index) in statuses"
                      :key="index"
                      :disabled="nugget_import.status === status"
                      @change="changeStatus(status)"
                    >
                      <v-list-item-title>{{ status }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="4">
                <h5 class="secondary--text mb-4">Metatags geprüft</h5>
                <v-switch
                  v-model="inputs.meta_ready"
                  :label="inputs.meta_ready ? 'Ja' : 'Nein'"
                  color="success"
                  @change="changeMetaReady()"
                />
              </v-col>
              <v-col cols="4">
                <h5 class="secondary--text mb-4">Inhalt geprüft</h5>
                <v-switch
                  v-model="inputs.content_ready"
                  :label="inputs.content_ready ? 'Ja' : 'Nein'"
                  color="success"
                  @change="changeContentReady()"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </section>

    <section v-if="nugget_import && categories">
      <v-container>
        <v-card elevation="0" color="grey lighten-3">
          <v-card-title class="overline pb-0"> Info </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <h5 class="secondary--text">Kategoriengruppen</h5>
                <span>{{ getCategoryGroupNameById(inputs.category) }}</span>
              </v-col>
              <v-col cols="6" class="">
                <h5 class="secondary--text">Kategorie</h5>
                <span>{{ getCategoryNameById(inputs.category) }}</span>
                <!-- <span>{{ getCategoryById(inputs.category).name }} / {{ getCategoryById(inputs.category).groups[0].name }}</span> -->
                <!-- <v-autocomplete
              v-model="inputs.category"
              :items="categories"
              item-text="name"
              item-value="id"
              disabled
              :label="$t('resources.categories.singular')"
              @change="changeCategories()"
            /> -->
              </v-col>
              <v-col cols="6">
                <h5 class="secondary--text">Nugget Typ</h5>
                <span>{{ nugget_import.ng_format }}</span>
              </v-col>
              <v-col v-if="nugget_import.ng_format === 'video'" cols="6">
                <h5 class="secondary--text">Videoquelle</h5>
                <span>{{ nugget_import.ng_source }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </section>

    <section v-if="nugget_import && categories && !loading">
      <v-container>
        <v-card color="grey lighten-3" elevation="0">
          <v-card-title class="overline pb-0"> Medien </v-card-title>
          <v-card-text>
            <v-row>
              <v-col v-if="nugget_import.ng_format === 'text'" cols="6">
                <h5 class="secondary--text">Titelbild</h5>
                <ImageUpload
                  card-color="grey lighten-2"
                  :image-hash="inputs.image_hash"
                  @image-changed="imageChange"
                />
              </v-col>
              <v-col v-if="nugget_import.ng_format === 'video'" cols="6">
                <a :href="nugget_import.media_uid" target="_blank"
                  >Link zum Video</a
                >
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              text
              :disabled="nugget_import.ng_format === 'video'"
              color="success"
              @click="saveMedia()"
            >
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </section>

    <section v-if="nugget_import">
      <v-container>
        <v-card color="grey lighten-3" elevation="0">
          <v-card-title class="overline pb-0"> Inhalt </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="inputs.title"
                  filled
                  dense
                  label="Titel"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea v-model="inputs.content" dense filled />
                <v-btn
                  small
                  color="primary"
                  @click="showContentPreview = !showContentPreview"
                >
                  {{
                    showContentPreview
                      ? "Inhaltsvorschau ausblenden"
                      : "Inhaltsvorschau anzeigen"
                  }}
                </v-btn>
                <v-alert
                  v-show="showContentPreview"
                  class="mt-2"
                  text
                  color="primary"
                >
                  <MathJaxRenderer
                    :formula="inputs.content"
                    :safe="false"
                    :preview-images="inputs.images_hashes"
                  />
                </v-alert>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="(hash, i) in hashes" :key="i" cols="4">
                <h5>Bild {{ i + 1 }}</h5>
                <ImageUpload
                  :image-hash="hash"
                  @image-changed="contentImageChange(i, ...arguments)"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <h5>Neues Bild</h5>
                <ImageUpload
                  resetable
                  card-color="grey lighten-2"
                  @image-changed="contentImageChange(-1, ...arguments)"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn small text color="success" @click="saveNugget()">
              Speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import MathJaxRenderer from "@/components/global/MathJaxRenderer";
import ImageUpload from "@/components/global/ImageUpload";

export default {
  name: "ImportDetail",
  components: {
    ViewHeader,
    ViewLoader,
    MathJaxRenderer,
    ImageUpload,
  },
  data() {
    return {
      loading: false,
      id: Number(this.$route.params.nugget_id),
      inputs: {
        meta_ready: false,
        content_ready: false,
        status: "unapproved",
        title: "",
        content: "",
        image: "",
        image_hash: "",
        images: [],
        images_hashes: [],
        //metatags: [],
        //comments: [],
        category: [],
      },
      statuses: ["unapproved", "editing", "imported"],
      showContentPreview: false,
    };
  },
  computed: {
    ...mapGetters("imports", { nugget_import: ["get_import_nugget"] }),
    ...mapGetters("categories", { categories: "get_categories" }),
    hashes() {
      return this.inputs.images_hashes;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "Datenimport ",
          to: { name: "ImportOverview" },
        },

        {
          text: "Nuggetimporte",
          to: {
            name: "ImportDetailNuggets",
            params: { id: Number(this.$route.params.id) },
          },
        },
        {
          text: "Nuggetimport ",
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
  methods: {
    //...mapActions('imports', ['fetch_import_nugget', 'patch_import_nugget', 'submit_nugget']),
    ...mapActions("imports", ["fetch_import_nugget", "patch_import_nugget"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("imports", ["set_import_nugget"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_categories({ cancelToken: this.source.token }),
        await this.fetch_import_nugget({
          nugget_id: this.id,
          cancelToken: this.source.token,
        });
      this.inputs.meta_ready = this.nugget_import.meta_ready;
      this.inputs.content_ready = this.nugget_import.content_ready;
      this.inputs.category = this.nugget_import.category_id;
      this.inputs.title = this.nugget_import.title;
      this.inputs.content = this.nugget_import.content;
      this.inputs.images = this.nugget_import.images;
      this.inputs.images_hashes = this.nugget_import.images_hashes;
      this.inputs.image = this.nugget_import.image;
      this.inputs.image_hash = this.nugget_import.image_hash;
      this.loading = false;
    },
    unloadData() {
      this.set_import_nugget(null);
      this.set_categories(null);
    },
    async changeStatus(status) {
      let payload = {
        id: this.id,
        status: status,
      };

      let res = await this.patch_import_nugget({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    async changeMetaReady() {
      let payload = {
        id: this.id,
        meta_ready: this.inputs.meta_ready,
      };

      let res = await this.patch_import_nugget({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    async changeContentReady() {
      let payload = {
        id: this.id,
        content_ready: this.inputs.content_ready,
      };

      let res = await this.patch_import_nugget({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    async changeCategories() {
      let payload = {
        id: this.id,
        categories: this.inputs.category,
      };

      let res = await this.patch_import_nugget({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Kategorien erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Kategorieänderungen fehlgeschlagen ",
        });
      }
    },
    async saveNugget() {
      let payload = {
        id: this.id,
        title: this.inputs.title,
        content: this.inputs.content,
        images: this.inputs.images,
        images_hashes: this.inputs.images_hashes,
      };

      let res = await this.patch_import_nugget({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Inhalte erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Inhaltesänderung fehlgeschlagen ",
        });
      }
    },
    contentImageChange(idx, val) {
      let images = [];
      let hashes = [];

      if (!val) {
        this.inputs.images.forEach((image, i) => {
          if (i !== idx) {
            images.push(image);
            hashes.push(this.inputs.images_hashes[i]);
          }
        });
      }
      if (val) {
        images = [...this.inputs.images];
        hashes = [...this.inputs.images_hashes];
        images.push(val.filename);
        hashes.push(val.hash);
      }
      this.inputs.images = images;
      this.inputs.images_hashes = hashes;
    },
    getCategoryNameById(id) {
      if (!this.categories) return "";
      let cat = this.categories.filter((category) => {
        return category.id == id;
      })[0];
      if (!cat) return "";
      return cat.name;
    },
    getCategoryGroupNameById(id) {
      if (!this.categories) return "";
      let cat = this.categories.filter((category) => {
        return category.id == id;
      })[0];
      if (!cat) return "";
      return cat.groups[0].name;
    },
    imageChange(val) {
      if (!val) {
        this.inputs.image = "";
        this.inputs.image_hash = "";
        return false;
      }
      this.inputs.image = val.filename;
      this.inputs.image_hash = val.hash;
    },
    async saveMedia() {
      let payload = {
        id: this.id,
        image: this.inputs.image,
        image_hash: this.inputs.image_hash,
      };

      let res = await this.patch_import_nugget({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Medien erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Medienänderung fehlgeschlagen ",
        });
      }
    },
  },
};
</script>

<style></style>
