<template>
  <div>
    <slot name="button" :onClick="openDialog" />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? 800 : '100%'"
      @click:outside="closeDialog()"
    >
      <v-card color="grey lighten-4">
        <v-card-title class="secondary--text overline">
          {{ $t("general.edit_translations") }}
        </v-card-title>
        <v-card-text v-if="translations && !loading" class="px-0">
          <v-expansion-panels
            :value="activePanels"
            multiple
            flat
            dense
            :disabled="!communityId"
          >
            <v-expansion-panel
              v-for="(language, i) in languages"
              :key="i"
              dense
              class="transparent my-0"
            >
              <v-expansion-panel-header>
                <div class="d-flex align-center">
                  <v-avatar size="32" tile class="mr-2">
                    <country-flag :country="flagName(language)" />
                  </v-avatar>
                  <!-- <div>{{ $t(`languages.${language}.display`) }}</div> -->
                  <div v-if="translations[language]">
                    {{ translations[language][propName] }}
                  </div>
                  <div v-if="!translations[language]">
                    {{ $t("ng.no_translations") }}.
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <InputTextfield
                  :value="inputs[language].value"
                  counter="255"
                  :rules="rules.input"
                  listen-to-input
                  @change="setValue(language, $event)"
                >
                  <template #actions>
                    <div class="ml-2">
                      <v-btn
                        icon
                        color="error"
                        :disabled="
                          translations[language][propName] ===
                          inputs[language].value
                        "
                        @click="resetInput(language)"
                      >
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                      <v-btn
                        :disabled="
                          translations[language][propName] ===
                            inputs[language].value ||
                          inputs[language].value.length === 0 ||
                          inputs[language].value.length > 255
                        "
                        icon
                        color="success"
                        @click="saveTranslation(language)"
                      >
                        <v-icon small> mdi-check </v-icon>
                      </v-btn>
                    </div>
                  </template>
                </InputTextfield>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-card-actions>
            <v-spacer />
            <v-btn
              text
              small
              :disabled="activePanels.length === 0"
              @click="hideAllPanels()"
            >
              {{ $t("ng.close_all") }}
            </v-btn>
            <v-btn
              text
              small
              :disabled="activePanels.length === languages.length - 1"
              @click="showAllPanels()"
            >
              {{ $t("ng.show_all") }}
            </v-btn>
            <v-btn text small @click="closeDialog()">
              {{ $t("general.close") }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CountryFlag from "vue-country-flag";

export default {
  name: "CommunityTranslationDialog",
  components: {
    CountryFlag,
  },
  props: {
    propName: {
      type: String,
      required: true,
    },
    communityId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      activePanels: [],
      rules: {
        input: [
          (v) => !!v || "Das Feld darf nicht leer sein",
          (v) => (v && v.length < 255) || "Das ist mir aber zu lang jetzt..",
        ],
      },
      inputs: [],
      loading: true,
    };
  },
  computed: {
    ...mapGetters("community", { translations: "get_community_translations" }),
    languages() {
      return this.$i18n.availableLocales;
    },
    defaultLanguage() {
      return process.env.VUE_APP_I18N_LOCALE;
    },
  },
  watch: {
    dialog(v) {
      if (v) this.loadTranslations();
    },
  },
  methods: {
    ...mapActions("community", [
      "fetch_community_translations",
      "patch_translation",
    ]),
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.activePanels = [];
      this.dialog = false;
    },
    flagName(lang) {
      return lang.slice(-2);
    },
    showAllPanels() {
      //this.activePanels = [...this.languages.filter(lang => { return lang !== this.defaultLanguage } ).keys()].map((k, i) => i+1 );
      this.activePanels = [...this.languages.keys()].map((k, i) => i);
    },
    hideAllPanels() {
      this.activePanels = [];
    },
    async loadTranslations() {
      let payload = { id: this.communityId };
      this.loading = true;
      await this.fetch_community_translations({
        payload,
        token: this.source.token,
      });
      this.generateInput();
      this.loading = false;
    },
    generateInput() {
      this.languages.forEach((lang) => {
        let input = { language: lang, value: "" };
        if (this.translations[lang])
          input.value = this.translations[lang][this.propName];
        this.inputs[lang] = input;
      });
    },
    resetInput() {
      this.generateInput();
    },
    setValue(lang, e) {
      this.inputs[lang].value = e;
    },
    async saveTranslation(lang) {
      this.loading = true;
      let payload = {
        id: this.communityId,
        language: lang,
        properties: [{ name: this.propName, value: this.inputs[lang].value }],
      };
      let res = await this.patch_translation({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>
