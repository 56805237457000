import api from "@/services/api";
import mediaapi from "@/services/mediaapi";

export const upload_media = async (_, { payload, token, config = {} }) => {
  try {
    let res = await mediaapi.post(`upload?token=${token}`, payload, config);
    if (res.success) {
      return res.media;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_media_upload_token = async (_, { config = {} }) => {
  try {
    let res = await api.get("media/tokens/upload", config);
    if (res.success) {
      return res.token;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_nugget_video_token = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("media/tokens/nugget/video", payload, config);
    if (res.success) {
      return res.token;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const fetch_upload_progress = async (_, { config = {} }) => {
  let res = await mediaapi.get("progress", config);
  if (res) return res;
  return null;
};
