<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-if="showDialog"
      v-model="showDialog"
      max-width="640"
      scrollable
      @click:outside="closeDialog()"
    >
      <v-card
        v-if="showDialog && licence"
        color="grey lighten-3"
        max-width="640"
      >
        <v-card-title class="d-flex align-center justify-space-between">
          <span class="overline secondary--text">{{
            $t("gw.licences.update_licence")
          }}</span>
          <v-btn x-small depressed color="error" @click="deleteLicence()">{{
            $t("general.delete")
          }}</v-btn>
        </v-card-title>
        <v-card-text class="pt-2">
          <v-form v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="input.name"
                  :label="$t('resources.categories.name')"
                  dense
                  outlined
                  background-color="white"
                  :hint="$t('gw.licences.name_hint')"
                  persistent-hint
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.credits_count"
                  :label="'credits_count'"
                  dense
                  outlined
                  background-color="white"
                  type="number"
                  :hint="$t('gw.licences.credits_count_hint')"
                  persistent-hint
                  min="0"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="input.credits_keep"
                  :label="
                    input.credits_keep ? $t('general.yes') : $t('general.no')
                  "
                  hint="Credits werden in den Folgezeitraum übernommen"
                  persistent-hint
                  color="primary"
                />
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="input.credits_period"
                  :label="$t('gw.licences.credits_period')"
                  dense
                  outlined
                  :hint="$t('gw.licences.credits_period_hint')"
                  persistent-hint
                  background-color="white"
                  :items="credit_periods"
                  item-text="label"
                  item-value="value"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="input.is_superadmin"
                  :label="
                    input.is_superadmin ? $t('general.yes') : $t('general.no')
                  "
                  :hint="$t('gw.licences.is_superadmin_hint')"
                  persistent-hint
                  color="primary"
                  disabled
                />
              </v-col>
              <v-col v-if="!licence.is_superadmin" cols="6">
                <v-text-field
                  v-model="input.licences_total"
                  :label="$t('gw.licences.available_licences')"
                  dense
                  outlined
                  background-color="white"
                  type="number"
                  :hint="$t('gw.licences.available_licences_hint')"
                  persistent-hint
                  min="0"
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="6">
                <v-checkbox
                  v-model="input.can_overload"
                  :label="
                    input.can_overload ? $t('general.yes') : $t('general.no')
                  "
                  :hint="$t('gw.licences.credits_overload_access')"
                  persistent-hint
                  color="primary"
                  :disabled="licence.is_superadmin"
                />
              </v-col>
            </v-row>
            <v-row v-if="!licence.is_superadmin">
              <v-col cols="6">
                <v-dialog
                  ref="startDatePicker"
                  v-model="startDatePicker"
                  :return-value.sync="input.date_start"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="input.date_start"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      :hint="$t('gw.licences.start_date')"
                      persistent-hint
                      background-color="white"
                      :disabled="input.is_superadmin"
                      v-on="on"
                      :rules="rules.required"
                    >
                      <template #append>
                        <v-btn icon small @click="input.date_start = ''">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.date_start"
                    scrollable
                    type="month"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="startDatePicker = false"
                    >
                      {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDatePicker.save(input.date_start)"
                    >
                      {{ $t("general.apply") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-dialog
                  ref="endDatePicker"
                  v-model="endDatePicker"
                  :return-value.sync="input.date_end"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="input.date_end"
                      readonly
                      v-bind="attrs"
                      dense
                      outlined
                      :hint="$t('gw.licences.date_end')"
                      persistent-hint
                      :disabled="input.is_superadmin"
                      background-color="white"
                      v-on="on"
                      :rules="rules.required"
                    >
                      <template #append>
                        <v-btn icon small @click="input.date_end = ''">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="input.date_end"
                    scrollable
                    type="month"
                  >
                    <v-spacer />
                    <v-btn text color="primary" @click="endDatePicker = false">
                      {{ $t("general.cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDatePicker.save(input.date_end)"
                    >
                      {{ $t("general.apply") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-spacer />
          <v-btn text small color="secondary" @click="reset()">
            {{ $t("general.reset") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="loading"
            @click="updateLicence()"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GhostwriterLicenceDialog",
  props: {
    licence: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      valid: false,
      input: null,
      credit_periods: [
        { value: 1, label: "Jährlich" },
        { value: 2, label: "Monatlich" },
      ],
      rules: {
        notEmpty: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
        ],
      },
      startDatePicker: false,
      endDatePicker: false,
    };
  },
  watch: {
    showDialog: {
      handler: function (v) {
        if (v) this.setInput();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("administration", ["update_gw_licence", "delete_gw_licence"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.showDialog = true;
    },
    setInput() {
      this.input = { ...this.licence };
      this.input.date_start = this.input.date_start.toISOString().substr(0, 7);
      this.input.date_end = this.input.date_end.toISOString().substr(0, 7);
    },
    async updateLicence() {
      this.loading = true;
      let payload = { ...this.input };

      delete payload.users;
      delete payload.licences_used;
      delete payload.licences_blocked;

      if (payload.date_start.length === 7)
        payload.date_start = payload.date_start.concat("-01");
      if (payload.date_end.length === 7)
        payload.date_end = payload.date_end.concat("-01");

      let res = await this.update_gw_licence({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("gw.licences.update_licence_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.licences.update_licence_error"),
        });
      }
    },
    async deleteLicence() {
      var confirm = window.confirm(
        this.$t("gw.licences.delete_licence_confirm")
      );
      if (!confirm) return false;

      this.loading = true;
      var res = await this.delete_gw_licence({
        payload: this.licence,
        cancelToken: this.source.token,
      });
      this.loading = false;

      if (res && res._status === 200) {
        this.closeDialog();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("gw.licences.delete_licence_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.licences.delete_licence_error"),
        });
      }
    },
    reset() {
      this.setInput();
    },
  },
};
</script>
