<template>
  <div>
    <video
      ref="videoPlayer"
      class="video-js vjs-big-play-centered"
      data-setup='{"fluid": true}'
    />
  </div>
</template>

<script>
import videojs from "video.js";
import { mapActions } from "vuex";

export default {
  name: "NuggetVideoPlayer",
  props: {
    nugget: {
      type: Object,
    },
    lang: {
      type: String,
    },
  },
  data() {
    return {
      player: null,
    };
  },
  mounted() {
    this.player = videojs(this.$refs.videoPlayer, {
      controls: true,
      preload: false,
    });
    if (this.nugget) {
      /* this.player.poster(
        `${this.media_server_url}/i/${this.nugget.image_hash}`
      ); */
    }
    this.player.bigPlayButton.on("click", () => {
      this.startPlaying();
    });
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
      this.player = null;
    }
  },
  methods: {
    ...mapActions("media", ["fetch_nugget_video_token"]),
    async startPlaying() {
      let token = await this.fetch_nugget_video_token({
        payload: { nugget_id: Number(this.nugget.id), language: this.lang },
        config: { cancelToken: this.source.token },
      });
      this.player.src({
        type: "video/mp4",
        src: `${this.media_server_url}/v/${token}`,
      });
      this.player.play();
    },
  },
};
</script>

<style>
@import url("/public/css/video-js.min.css");
</style>
