import api from "@/services/api";
import { Category } from "@/models/content/categories/Category";
import { Categorygroup } from "@/models/content/categories/Categorygroup";

export const fetch_categories = async (
  { commit, rootGetters },
  config = {}
) => {
  try {
    let res = await api.get("admin/ng_categories/", config);
    if (res._status === 200) {
      let categories = res.categories.map((category) => {
        return new Category(category);
      });
      let user = { ...rootGetters["auth/get_auth"] };
      let visible_categories = [...categories];
      if (user.role_id === 2) {
        visible_categories = visible_categories.filter((c) =>
          user.related_categories.includes(c.id)
        );
      }
      commit("set_categories", categories);
      commit("set_visible_categories", visible_categories);
    } else {
      return null;
    }
  } catch (err) {
    console.log("fetch_questions err:", err);
    return null;
  }
};

export const fetch_category = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/ng_categories/${id}`, config);
    if (res._status === 200) {
      commit("set_category", new Category(res.category));
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const add_category = async ({ dispatch }, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/ng_categories/", payload, config);
    if (res._status === 200) {
      dispatch("auth/init_auth", config, { root: true });
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_category = async (
  { dispatch },
  { id, payload, config = {} }
) => {
  try {
    let res = await api.patch(`admin/ng_categories/${id}`, payload, config);
    if (res._status === 200) {
      dispatch("fetch_category", { id: id });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const delete_category = async (_, { id, config = {} }) => {
  try {
    let res = await api.delete(`admin/ng_categories/${id}`, config);
    return res;
  } catch (err) {
    return false;
  }
};

export const fetch_categorygroups = async ({ commit }, config = {}) => {
  try {
    let res = await api.get("admin/category_groups/", config);
    if (res._status === 200) {
      let groups = res.category_groups.map((group) => {
        return new Categorygroup(group);
      });
      commit("set_categorygroups", groups);
    } else {
      return null;
    }
  } catch (err) {
    console.log("fetch_questions err:", err);
    return null;
  }
};

export const fetch_categorygroup = async ({ commit }, { id, config = {} }) => {
  try {
    let res = await api.get(`admin/category_groups/${id}`, config);
    if (res._status === 200) {
      commit("set_categorygroup", new Categorygroup(res.category_group));
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const patch_categorygroup = async (
  { dispatch },
  { id, payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/category_groups/${id}?language=${payload.language}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_category", { id: id });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};

export const add_categorygroup = async (_, { payload, config = {} }) => {
  try {
    let res = await api.post("admin/category_groups/", payload, config);
    if (res._status === 200) {
      return res;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const delete_categorygroup = async (_, { id, config = {} }) => {
  try {
    let res = await api.delete(`admin/category_groups/${id}`, config);
    if (res._status === 200) {
      return res;
    } else return null;
  } catch (err) {
    return false;
  }
};

export const fetch_category_translations = async (
  { commit },
  { payload, config = {} }
) => {
  try {
    let res = await api.get(
      `admin/ng_categories/${payload.id}/translations`,
      config
    );
    if (res._status === 200) {
      commit("set_category_translations", res.translations);
    }
  } catch (err) {
    console.error(err);
  }
};

export const patch_translation = async (
  { dispatch },
  { payload, config = {} }
) => {
  try {
    let res = await api.patch(
      `admin/ng_categories/${payload.id}`,
      payload,
      config
    );
    if (res._status === 200) {
      dispatch("fetch_category_translations", { payload: { id: payload.id } });
      return res;
    } else {
      return res.status.msg;
    }
  } catch (err) {
    return false;
  }
};
