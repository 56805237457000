<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      title="Nuggetimporte"
      icon="mdi-database-import"
    />
    <ViewLoader v-if="!importItem" />

    <section v-if="importItem">
      <SectionHeader :title="`${$t('general.overview')}`" />
      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-col v-if="categories" cols="12">
              <v-autocomplete
                v-model="category_id"
                :items="categories"
                item-text="name"
                item-value="id"
                attach
                chips
                small-chips
                :label="$t('resources.categories.singular')"
                prepend-icon="mdi-select-group"
                :placeholder="$t('general.all')"
                outlined
                dense
                hide-details
                @change="changeCategory"
              >
                <template #item="{ item }">
                  <small class="mr-2">{{
                    getCategoryGroupNameById(item.id)
                  }}</small>
                  {{ getCategoryNameById(item.id) }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-card outlined>
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters" class="pb-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="[
                  {
                    text: 'Status',
                    value: 'status',
                    width: '20%',
                  },
                  {
                    text: 'Titel',
                    value: 'title',
                  },
                  {
                    text: 'Inhalt geprüft',
                    value: 'content_ready',
                  },
                  {
                    text: 'Metatags geprüft',
                    value: 'meta_ready',
                  },
                  {
                    text: 'Format',
                    value: 'ng_format',
                  },
                  {
                    text: 'Kategorie',
                    value: 'category_id',
                  },
                ]"
                :items="filteredItems"
                :items-per-page="10"
                :search="filter.search"
                :page.sync="page"
                :loading="loading"
              >
                <template #item="request">
                  <tr @click="rowClicked(request.item)">
                    <td>
                      <v-chip
                        x-small
                        :color="
                          request.item.status === 'imported'
                            ? 'success'
                            : 'grey lighten-3'
                        "
                        class="font-weight-bold"
                      >
                        {{ request.item.status }}
                      </v-chip>
                    </td>
                    <td>{{ request.item.title }}</td>
                    <td>
                      <v-icon
                        :color="
                          request.item.content_ready ? 'success' : 'error'
                        "
                      >
                        {{
                          request.item.content_ready ? "mdi-check" : "mdi-close"
                        }}
                      </v-icon>
                    </td>
                    <td>
                      <v-icon
                        :color="request.item.meta_ready ? 'success' : 'error'"
                      >
                        {{
                          request.item.meta_ready ? "mdi-check" : "mdi-close"
                        }}
                      </v-icon>
                    </td>
                    <td>{{ request.item.ng_format }}</td>
                    <td>
                      <span v-if="!request.item.category_id">-</span>
                      <div v-if="request.item.category_id">
                        <v-chip x-small>
                          {{ getCategoryNameById(request.item.category_id) }}
                        </v-chip>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "ImportDetail",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      id: Number(this.$route.params.id),
      page: 1,
      display: {
        filters: false,
      },
      filter: {
        search: "",
      },
      /* options: {
        status: ['unapproved', 'editing', 'imported'],
        meta: [ { value: true, name: 'Ready'}, { value: false, name: 'Not ready' }],
        questions: [ { value: true, name: 'Ja'}, { value: false, name: 'Nein' }]
      }, */
      loading: false,
      category_id: 478,
    };
  },
  computed: {
    ...mapGetters("imports", { importItem: ["get_import"] }),
    ...mapGetters("categories", { categories: "get_categories" }),
    filteredItems() {
      if (!this.importItem) return [];

      /* var filtered = [];
      if(this.importItem.questions) filtered.push(this.importItem.questions);*/
      var filtered = [...this.importItem.nuggets];

      /* if (this.filter.status.length > 0) {
        filtered = filtered.filter(item => {
          return this.filter.status.includes(item.status);
        })
      }
      if (this.filter.meta_ready !== null) {
        filtered = filtered.filter(item => {
          return item.meta_ready === this.filter.meta_ready;
        })
      }
      if (this.filter.content_ready !== null) {
        filtered = filtered.filter(item => {
          return item.content_ready === this.filter.content_ready;
        })
      }
      if (this.filter.hasQuestions !== null) {
        filtered = filtered.filter(item => {
          if(this.filter.hasQuestions) return item.body && item.body.length > 0;
          return (!item.body || item.body.length == 0)
        })
      } */
      return filtered;
    },
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return search;
      //return search + this.filter.status.length + (this.filter.meta_ready !== null ? 1 : 0) + (this.filter.content_ready !== null ? 1 : 0) + (this.filter.hasQuestions !== null ? 1 : 0);
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "Datenimport",
          to: { name: "ImportOverview" },
        },
        {
          text: "Nuggetimporte",
          disabled: true,
        },
      ];
    },
  },
  /* watch: {
    filter: {
      deep: true,
      handler() { this.storeFilter(); }
    }
  }, */
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    this.id = Number(this.$route.params.id);
    this.getFilter();
  },
  methods: {
    ...mapActions("imports", ["fetch_import"]),
    ...mapMutations("imports", ["set_import"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_categories({ cancelToken: this.source.token }),
        await this.fetch_import({
          id: this.id,
          type: "nuggets",
          category_id: this.category_id,
        });
      this.loading = false;
    },
    unloadData() {
      this.set_import(null);
      this.set_categories(null);
    },
    rowClicked(item) {
      this.$router.push({
        name: "ImportDetailNugget",
        params: { id: this.id, nugget_id: item.id },
      });
    },
    resetFilter() {
      this.filter = {
        search: "",
      };
    },
    storeFilter() {
      localStorage.setItem("fil_imp_nug", JSON.stringify(this.filter));
      localStorage.setItem("fil_imp_nug_cat", this.category_id);
    },
    getFilter() {
      if (localStorage.getItem("fil_imp_nug")) {
        let filter = JSON.parse(localStorage.getItem("fil_imp_nug"));

        this.filter.search = filter.search ? filter.search : "";
      }
      if (localStorage.getItem("fil_imp_nug_cat")) {
        this.category_id = Number(localStorage.getItem("fil_imp_nug_cat"));
      }
    },
    async changeCategory(id) {
      this.loading = true;
      this.category_id = id;
      this.storeFilter();
      await this.fetch_import({
        id: this.id,
        type: "nuggets",
        category_id: this.category_id,
      });
      this.loading = false;
    },
    getCategoryNameById(id) {
      return this.categories.filter((category) => {
        return category.id == id;
      })[0].name;
    },
    getCategoryGroupNameById(id) {
      return this.categories.filter((category) => {
        return category.id == id;
      })[0].groups[0].name;
    },
  },
};
</script>

<style></style>
