<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.codes.plural')"
      icon="mdi-numeric"
      route-name="CodeAdd"
      :route-disabled="!['superadmin', 'admin'].includes(role)"
    />
    <ViewLoader v-if="!codes || !structures" />

    <section v-if="codes && structures">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined :loading="!substructures">
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters" class="pb-0">
                <v-row>
                  <v-col v-if="substructures" cols="12">
                    <v-autocomplete
                      v-model="filter.substructures"
                      :items="visibleSubstructures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.codes.assignable_substructures')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ substructureById(item.id).structure.name }} /
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col v-if="structures" cols="12">
                    <v-autocomplete
                      v-model="filter.structures"
                      :items="visibleStructures"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.codes.selectable_structures')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="filter.usage_count"
                      :items="options.usage_count"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.codes.usage_count.title')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      class="mt-0 pt-0"
                      prepend-icon="mdi-magnify"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      :label="$t('general.search')"
                      @click:append="filter.search = ''"
                      @keydown="filter.page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="[
                  { text: $t('resources.codes.singular'), value: 'code' },
                  {
                    text: $t('resources.codes.usage_count.title'),
                    value: 'usage_count',
                  },
                  {
                    text: $t('resources.codes.assignable_substructures'),
                    value: 'substructures',
                  },
                  {
                    text: $t('resources.codes.selectable_structures'),
                    value: 'structures',
                  },
                ]"
                :items="codesFiltered"
                :items-per-page="10"
                :page.sync="filter.page"
                :search="filter.search"
                :loading="!codes"
              >
                <template #item="code">
                  <tr @click="rowClicked(code.item)">
                    <td>
                      <span>{{ code.item.code }}</span>
                      <v-btn
                        icon
                        small
                        class="ml-1"
                        @click.stop="copyCode(code.item)"
                      >
                        <v-icon small> mdi-content-copy </v-icon>
                      </v-btn>
                    </td>
                    <td>
                      <v-chip
                        v-if="code.item.usage_count == 0"
                        x-small
                        color="error"
                      >
                        {{ $t("resources.codes.no_registrations_left") }}
                      </v-chip>
                      <v-chip
                        v-if="code.item.usage_count == -1"
                        x-small
                        color="success"
                      >
                        {{ $t("resources.codes.unlimited_registrations") }}
                      </v-chip>
                      <v-chip
                        v-if="code.item.usage_count > 0"
                        x-small
                        color="success"
                      >
                        {{ code.item.usage_count }}
                      </v-chip>
                    </td>
                    <td>
                      <div
                        v-for="(substructure, i) in code.item
                          .assign_substructure_ids"
                        :key="i"
                      >
                        <v-chip
                          v-if="i < 2"
                          x-small
                          class="mx-1 my-1"
                          :color="
                            auth &&
                            auth.related_substructures.includes(substructure)
                              ? 'primary'
                              : ''
                          "
                        >
                          {{ substructureById(substructure).structure.name }} /
                          {{ substructureById(substructure).name }}
                        </v-chip>
                        <v-chip
                          v-if="i == 2"
                          x-small
                          class="mx-1 my-1"
                          disabled
                        >
                          + {{ code.item.assign_substructure_ids.length - 2 }}
                          {{ $t("resources.invitations.moreIndicator") }}
                        </v-chip>
                      </div>
                    </td>
                    <td>
                      <div
                        v-for="(structure, i) in code.item.select_structure_ids"
                        :key="i"
                      >
                        <v-chip
                          v-if="i < 2"
                          x-small
                          color="secondary"
                          class="mx-1 my-1"
                        >
                          {{ structureById(structure).name }}
                        </v-chip>
                        <v-chip
                          v-if="i == 2"
                          x-small
                          class="mx-1 my-1"
                          disabled
                        >
                          + {{ code.item.select_structure_ids.length - 2 }}
                          {{ $t("resources.invitations.moreIndicator") }}
                        </v-chip>
                      </div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "CodeOverview",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      display: {
        filters: false,
      },
      filter: {
        structures: [],
        substructures: [],
        usage_count: [],
        search: "",
        page: 1,
      },
      options: {
        usage_count: [
          { id: -1, name: this.$t("resources.codes.usage_count.unlimited") },
          { id: 0, name: this.$t("resources.codes.usage_count.limit_reached") },
          {
            id: 1,
            name: this.$t("resources.codes.usage_count.limit_not_reached"),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("codes", { codes: "get_codes" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return (
        this.filter.usage_count.length +
        this.filter.structures.length +
        this.filter.substructures.length +
        search
      );
    },
    codesFiltered() {
      if (this.codes) {
        let filtered = this.codes;
        if (this.filter.structures.length > 0) {
          filtered = filtered.filter((code) => {
            return (
              code.select_structure_ids.filter((structure_id) => {
                return this.filter.structures.includes(Number(structure_id));
              }).length > 0
            );
          });
        }
        if (this.filter.substructures.length > 0) {
          filtered = filtered.filter((code) => {
            return (
              code.assign_substructure_ids.filter((substructure_id) => {
                return this.filter.substructures.includes(
                  Number(substructure_id)
                );
              }).length > 0
            );
          });
        }
        if (this.filter.usage_count.length > 0) {
          filtered = filtered.filter((code) => {
            let value = code.usage_count > 0 ? 1 : code.usage_count;
            return this.filter.usage_count.includes(value);
          });
        }
        return filtered.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } else {
        return [];
      }
    },
    visibleStructures() {
      if (["superadmin", "admin"].includes(this.role)) {
        return this.structures;
      }

      if (this.auth) {
        return this.structures.filter((structure) => {
          let visibleSubstructures = structure.substructures.filter(
            (substructure) => {
              return this.auth.related_substructures.includes(substructure.id);
            }
          );
          return visibleSubstructures.length > 0;
        });
      }

      return [];
    },
    visibleSubstructures() {
      if (this.auth) {
        if (["superadmin", "admin"].includes(this.role)) {
          return this.substructures;
        }
        let visibleSubstructures = this.substructures.filter((substructure) => {
          return this.auth.related_substructures.includes(substructure.id);
        });
        return visibleSubstructures;
      }

      return [];
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("resources.codes.plural"),
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
    this.getFilter();
  },
  methods: {
    ...mapActions("structures", ["fetch_structures"]),
    ...mapActions("codes", ["fetch_codes", "fetch_code_proposal"]),
    ...mapMutations("structures", ["set_structures"]),
    ...mapMutations("codes", ["set_codes"]),
    rowClicked(item) {
      this.$router.push({ name: "CodeDetail", params: { id: item.code } });
    },
    structureById(id) {
      return this.structures.filter((structure) => {
        return structure.id == id;
      })[0];
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    async fetchData() {
      await this.fetch_structures({ cancelToken: this.source.token }),
        await this.fetch_codes({ cancelToken: this.source.token });
    },
    unloadData() {
      this.set_codes(null);
      this.set_structures(null);
    },
    resetFilter() {
      this.filter = {
        structures: [],
        substructures: [],
        usage_count: [],
        search: "",
        page: 1,
      };
    },
    storeFilter() {
      localStorage.setItem("fil_cod", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_cod")) {
        let filter = JSON.parse(localStorage.getItem("fil_cod"));

        this.filter.structures = filter.structures ? filter.structures : [];
        this.filter.substructures = filter.substructures
          ? filter.substructures
          : [];
        this.filter.usage_count = filter.usage_count ? filter.usage_count : [];
        this.filter.search = filter.search ? filter.search : "";
        this.filter.page = filter.page ? filter.page : 1;
      }
    },
    copyCode(code) {
      navigator.clipboard.writeText(code.code);
      this.$notify({
        type: "success",
        title: this.$t("general.success"),
        text: this.$t("resources.codes.code_copied"),
      });
    },
  },
};
</script>

<style></style>
