<template>
  <div>
    <v-card
      v-if="item && input"
      flat
      :color="item.type === 'nugget' ? 'info' : 'primary'"
      dark
      :style="'transition: .25s'"
      :loading="loading"
    >
      <template #progress>
        <v-progress-linear indeterminate color="white" height="6" />
      </template>
      <v-card-title class="justify-space-between">
        <div class="d-flex align-center">
          <GwPartInfoDialog :item="item">
            <template #action="{ openDialog }">
              <v-tooltip right color="secondary">
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    small
                    icon
                    @click="openDialog()"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small> mdi-alert-circle </v-icon>
                  </v-btn>
                </template>
                <span> {{ $t("gw.part_info") }}</span>
              </v-tooltip>
            </template>
          </GwPartInfoDialog>
          <span class="overline"
            >{{ $t("general.x_of_y", { x: currentIndex + 1, y: itemCount }) }}:
            {{
              item.type === "question"
                ? $t("resources.questions.singular")
                : $t("resources.nuggets.singular")
            }}</span
          >
        </div>
        <div>
          <v-btn
            x-small
            depressed
            class="mr-2"
            outlined
            :disabled="currentIndex === 0 || loading"
            @click="prev()"
            >{{ $t("general.back") }}</v-btn
          >
          <v-btn
            x-small
            depressed
            outlined
            :disabled="currentIndex === itemCount - 1 || loading"
            @click="next()"
            >{{ $t("general.next") }}</v-btn
          >
        </div>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-if="item.type === 'question'"
              outlined
              :value="$t('resources.questions.types.1')"
              :label="$t('resources.questions.type')"
              dense
              readonly
              hide-details
            >
              <template #prepend-inner>
                <v-avatar size="24" class="mr-2" rounded>
                  <v-img src="/img/question_type_1.png" />
                </v-avatar>
              </template>
            </v-text-field>
            <v-text-field
              v-if="item.type === 'nugget'"
              outlined
              :value="$t('resources.nuggets.types.text')"
              :label="$t('resources.nuggets.type')"
              dense
              readonly
              hide-details
            >
              <template #prepend-inner>
                <v-avatar size="24" class="mr-2" rounded>
                  <v-icon>mdi-school</v-icon>
                </v-avatar>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-autocomplete
              dense
              outlined
              :label="$t('resources.categories.singular')"
              :items="categories"
              v-model="input.content.category_id"
              item-text="name"
              hide-details
              item-value="id"
              :disabled="loading"
              :readonly="![0].includes(item.status) || item.is_deleted"
              @change="setCategory"
            />
          </v-col>
          <v-expand-transition>
            <v-col
              cols="12"
              v-if="
                !input.content.category_id &&
                item.status === 0 &&
                !item.is_deleted
              "
            >
              <v-alert
                type="info"
                :color="!!session.defaults.category_id ? 'white' : 'error'"
                dense
                :text="!!session.defaults.category_id"
                class="mb-0"
              >
                <p
                  v-if="!!session.defaults.category_id"
                  class="mb-1 font-weight-bold"
                >
                  {{
                    $t("gw.bulk.hints.no_content_default", {
                      category: defaultCategory.name,
                    })
                  }}
                </p>
                <p v-if="!session.defaults.category_id" class="mb-1">
                  {{ $t("gw.bulk.hints.no_content_1") }}
                </p>
                <small v-if="!!session.defaults.category_id">{{
                  $t("gw.bulk.hints.no_content_2")
                }}</small>
                <small v-if="!session.defaults.category_id">{{
                  $t("gw.bulk.hints.no_content_3")
                }}</small>
              </v-alert>
            </v-col>
          </v-expand-transition>
        </v-row>

        <v-row v-if="item.type === 'question'">
          <v-col cols="12">
            <h5>{{ $t("resources.questions.singular") }}</h5>
            <v-sheet color="white" class="secondary--text pa-2">
              {{ item.content.body }}
            </v-sheet>
          </v-col>
          <v-col cols="12" class="pb-0">
            <h5>{{ $t("ng.answers") }}</h5>
          </v-col>
          <v-col cols="12" class="pt-0">
            <v-row dense>
              <v-col
                v-for="(answer, i) in item.content.answers"
                :key="i"
                cols="12"
              >
                <v-sheet color="white" class="secondary--text pa-2">
                  <v-icon small :color="answer.correct ? 'success' : 'error'">{{
                    answer.correct ? "mdi-check-circle" : "mdi-close-circle"
                  }}</v-icon>
                  {{ answer.body }}
                </v-sheet>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row v-if="item.type === 'nugget'" dense>
          <v-col cols="12">
            <h5>{{ $t("resources.nuggets.title") }}</h5>
            <v-sheet color="white" class="secondary--text pa-2 mb-4">
              {{ item.content.title }}
            </v-sheet>
          </v-col>
          <v-col cols="12">
            <h5>{{ $t("resources.nuggets.description") }}</h5>
            <v-sheet color="white" class="secondary--text pa-2 mb-4">
              {{ item.content.description }}
            </v-sheet>
          </v-col>
          <v-col cols="12">
            <h5>{{ $t("resources.nuggets.content") }}</h5>
            <v-sheet color="white" class="secondary--text pa-2 mb-4">
              {{ item.content.body }}
            </v-sheet>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn
          small
          depressed
          color="error"
          :disabled="loading || ![0].includes(item.status) || item.is_deleted"
          @click="dismissContent()"
          >{{ $t("general.reject") }}</v-btn
        >
        <v-spacer />
        <v-btn small text @click="openEditDialog()">{{
          $t("general.edit")
        }}</v-btn>
        <v-btn
          small
          depressed
          color="success"
          :disabled="
            loading ||
            ![0].includes(item.status) ||
            item.is_deleted ||
            (!item.content.category_id && !this.session.defaults.category_id)
          "
          @click="acceptContent()"
          >{{ $t("general.accept") }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <GwBulkContentDialog
      v-if="item"
      :content="session.unedited_content"
      :session-id="session.id"
      :index="currentIndex"
      :visible="showContentDialog"
      :disabled="session.status !== 7"
      @close="showContentDialog = false"
      @index-change="setIndex"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GwQuestion } from "@/models/ghostwriter/content/GwQuestion";
import { GwNugget } from "@/models/ghostwriter/content/GwNugget";
import GwBulkContentDialog from "@/components/ghostwriter/GwBulkContentDialog";
import GwPartInfoDialog from "@/components/ghostwriter/GwPartInfoDialog";

export default {
  name: "GwBulkQuestionCard",
  components: {
    GwBulkContentDialog,
    GwPartInfoDialog,
  },
  props: {
    item: {
      type: [GwQuestion, GwNugget],
      required: true,
    },
    currentIndex: {
      type: [String, Number],
      required: true,
    },
    session: {
      type: Object,
      required: true,
    },
    itemCount: {
      // TODO: remove
      type: [String, Number],
      required: false,
      default: "-",
    },
  },
  data() {
    return {
      input: null,
      loading: false,
      showContentDialog: false,
      //contentIndex: 0,
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    defaultCategory() {
      if (!this.session || !this.session.defaults.category_id) return null;
      var id = this.session.defaults.category_id;
      var cat = this.categories.filter((c) => c.id === id)[0];
      return cat ? cat : null;
    },
  },
  watch: {
    item: {
      handler: function () {
        this.setInput();
      },
      deep: true,
    },
  },
  mounted() {
    this.setInput();
  },
  methods: {
    ...mapActions("ghostwriter", ["patch_content", "delete_content"]),
    setInput() {
      this.input = { ...this.item };
    },
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    },

    setIndex(i) {
      if (i < this.currentIndex) this.$emit("prev");
      if (i > this.currentIndex) this.$emit("next");
    },
    setCategory(v) {
      var content = { ...this.input.content };
      content.category_id = v;
      var props = [{ name: "content", value: content }];
      this.patchContent(props);
    },
    acceptContent() {
      var content = { ...this.input.content };
      content.activate = true;
      var props = [
        { name: "status", value: 1 },
        { name: "content", value: content },
      ];
      if (
        !this.input.content.category_id &&
        !!this.session.defaults.category_id
      ) {
        content.category_id = this.session.defaults.category_id;
        props.push({ name: "content", value: content });
      }
      this.patchContent(props);
    },
    async dismissContent() {
      this.loading = true;
      let payload = {
        id: this.item.id,
        type: this.item.type === "question" ? `questions` : "nuggets",
        session_id: this.session.id,
        session_type: "bulk",
      };
      let res = await this.delete_content({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async patchContent(props) {
      this.loading = true;
      var payload = {
        id: this.item.id,
        type: this.item.type === "question" ? `questions` : "nuggets",
        session_id: this.session.id,
        session_type: "bulk",
        properties: props,
      };
      var res = await this.patch_content({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
      this.loading = false;
    },
    openEditDialog() {
      this.showContentDialog = true;
    },
  },
};
</script>

<style></style>
