<template>
  <div>
    <section
      v-if="user && structures && substructures && categories"
      class="mt-4"
    >
      <v-container>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar>
                  <!-- <v-icon>mdi-circle</v-icon> -->
                  <v-img :src="user.avatar_img_url" alt="user avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    `${user.first_name} ${user.last_name}`
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    user.user_name
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon :color="user.isactive ? 'success' : 'error'">{{
                    user.isactive ? "mdi-check" : "mdi-close"
                  }}</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    user.isactive
                      ? $t("resources.users.isactive")
                      : $t("resources.users.not_isactive")
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("resources.users.status")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          fab
                          x-small
                          color="primary"
                          @click="toggleUserActiveStatus"
                        >
                          <v-icon>
                            {{
                              user.isactive !== 1
                                ? "mdi-account-reactivate"
                                : "mdi-account-off"
                            }}
                          </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{
                      user.isactive !== 1
                        ? $t("resources.users.activate_user")
                        : $t("resources.users.deactivate_user")
                    }}</span>
                  </v-tooltip>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="6" md="3">
            <v-card color="secondary" dark :to="{ name: 'UserStatistics' }">
              <v-list-item>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon color="secondary">mdi-chart-areaspline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("navigation.statistics.title")
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("general.show")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon>mdi-arrow-top-right-thin</v-icon>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12" md="12">
            <SectionHeader
              :title="`${$t('resources.users.information')} & ${$t(
                'general.details'
              )}`"
              no-padding
            />
            <v-card outlined>
              <v-card-text>
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <InputTextfield
                          v-model="inputs.user_name"
                          listen-to-input
                          hide-details
                          :rules="nameRules"
                          :label="$t('resources.users.user_name')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <InputTextfield
                          v-model="inputs.email"
                          listen-to-input
                          disabled
                          hide-details
                          :label="$t('general.email')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <InputTextfield
                          v-model="inputs.first_name"
                          listen-to-input
                          :rules="nameRules"
                          hide-details
                          :label="$t('resources.users.first_name')"
                        />
                      </v-col>
                      <v-col cols="12" sm="6">
                        <InputTextfield
                          v-model="inputs.last_name"
                          listen-to-input
                          :rules="nameRules"
                          hide-details
                          :label="$t('resources.users.last_name')"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="
                        community.custom_fields &&
                        community.custom_fields.length > 0
                      "
                    >
                      <v-col
                        v-for="(field, index) in community.custom_fields"
                        :key="index"
                        cols="12"
                        sm="6"
                      >
                        <CustomField
                          :field="field"
                          :current-value="getCurrentCustomFieldValue(field)"
                          :disabled="edited"
                          @change="customFieldChange"
                        />
                      </v-col>
                    </v-row>

                    <v-row
                      v-if="
                        user.substructure_custom_fields &&
                        user.substructure_custom_fields.length > 0
                      "
                    >
                      <v-col
                        v-for="(
                          field, index
                        ) in user.substructure_custom_fields"
                        :key="index"
                        cols="12"
                        sm="6"
                      >
                        <InputTextfield
                          v-model="field.value"
                          listen-to-input
                          hide-details
                          disabled
                          :label="field.label"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="12">
                        <v-autocomplete
                          v-model="selectedSubstructures"
                          :items="substructures"
                          :disabled="!['superadmin', 'admin'].includes(role)"
                          item-text="name"
                          item-value="id"
                          attach
                          outlined
                          dense
                          background-color="white"
                          chips
                          small-chips
                          deletable-chips
                          :rules="substructureRules"
                          :label="$t('resources.categories.substructure_ids')"
                          multiple
                        >
                          <template #item="{ item }">
                            {{ item.structure.name }} / {{ item.name }}
                          </template>
                          <template #selection="{ item }">
                            <v-chip small>
                              {{ substructureById(item.id).structure.name }} /
                              {{ item.name }}
                            </v-chip>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialStructures"
                            />
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  :disabled="!edited"
                  color="error"
                  text
                  small
                  @click="resetForm"
                >
                  {{ $t("general.cancel") }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  small
                  :disabled="!valid || !edited"
                  @click="saveForm"
                >
                  {{ $t("general.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon>mdi-account-key</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    $t("resources.users.roles." + user.role_id)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("resources.users.role")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    fab
                    x-small
                    color="primary"
                    @click="showRoleDialog = true"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
              <v-card-text v-if="[2, 4].includes(user.role_id)">
                <h5 class="text-subtitle-1">
                  {{
                    user.role_id === 2
                      ? $t("resources.users.related_categories")
                      : $t("resources.users.manager_substructures")
                  }}
                </h5>

                <div v-if="user.role_id == 2" class="d-flex flex-wrap">
                  <div v-for="(cat, i) in user.related_categories" :key="i">
                    <v-chip
                      v-if="getCategoryName(cat)"
                      class="mr-2 mt-2"
                      small
                      label
                      @click="showRoleDialog = true"
                    >
                      <span>{{ getCategoryName(cat) }}</span>
                    </v-chip>
                    <!-- <v-chip
                        v-if="i == 3 && user.related_categories.length > 3"
                        color="primary"
                        small
                        label
                        class="mr-2 mt-2"
                        @click="showRoleDialog = true"
                      >
                        {{ $t("general.display_all") }}
                      </v-chip> -->
                    <v-chip
                      v-if="
                        i == 0 &&
                        user.related_categories.length == 1 &&
                        !getCategoryName(cat)
                      "
                      class="mr-2 mt-2"
                      small
                      label
                      disabled
                    >
                      <span>{{ getCategoryName(cat) }}</span>
                    </v-chip>
                  </div>
                </div>

                <div v-if="user.role_id == 4" class="d-flex flex-wrap">
                  <div v-for="(str, i) in user.related_substructures" :key="i">
                    <v-chip class="mr-2 mt-2" small label>
                      <span>{{ getSubstructureName(str) }}</span>
                    </v-chip>
                    <!-- <v-chip
                        v-if="i == 3 && user.related_substructures.length > 3"
                        color="primary"
                        small
                        label
                        class="mr-2 mt-2"
                        @click="showRoleDialog = true"
                      >
                        {{ $t("general.display_all") }}
                      </v-chip> -->
                  </div>
                </div>
                <div
                  v-if="
                    (user.role_id == 4 &&
                      user.related_substructures.length === 0) ||
                    (user.role_id == 2 && user.related_categories.length === 0)
                  "
                >
                  {{ $t("$vuetify.noDataText") }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col v-if="showGhostwriterSettings" cols="12" md="6">
            <v-card outlined>
              <v-list-item>
                <v-list-item-avatar color="grey lighten-3">
                  <v-icon>mdi-typewriter</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{
                    user.licence
                      ? user.licence.licence.name
                      : $t("gw.licences.no_licence")
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    $t("gw.licences.singular_gw")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <GwUserLicenceDialog :user="user">
                    <template #action="{ openDialog }">
                      <v-btn
                        x-small
                        fab
                        color="primary"
                        :disabled="![1, 2, 5].includes(user.role_id)"
                        @click="openDialog()"
                        ><v-icon>mdi-pencil</v-icon></v-btn
                      >
                    </template>
                  </GwUserLicenceDialog>
                </v-list-item-action>
              </v-list-item>
            </v-card>
          </v-col>

          <v-col
            v-if="
              user &&
              (['superadmin', 'admin'].includes(role) || user.role_id == 3)
            "
            cols="12"
            sm="6"
            md="3"
          >
            <SectionHeader :title="$t('ng.further_actions')" no-padding />

            <v-btn
              :disabled="user.id == auth.id"
              block
              color="error"
              @click="showDeleteDialog = true"
            >
              <v-icon color="white">mdi-delete</v-icon>
              <span class="ml-2">{{ $t("general.delete") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <UserDialogDelete
      v-if="user"
      :dialog="showDeleteDialog"
      :user="user"
      @close="closeDeleteDialog"
    />
    <UserDialogRole
      v-if="user"
      :dialog="showRoleDialog"
      :user="user"
      @close="closeRoleDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UserDialogDelete from "@/components/users/UserDialogDelete.vue";
import UserDialogRole from "@/components/users/UserDialogRole.vue";
import TutorialCarouselDialog from "@/components/tutorials/TutorialCarouselDialog";
import SectionHeader from "@/components/_layout/SectionHeader";
import CustomField from "@/components/users/CustomField";
import GwUserLicenceDialog from "@/components/ghostwriter/GwUserLicenceDialog";

export default {
  name: "UserInfo",
  components: {
    UserDialogDelete,
    UserDialogRole,
    TutorialCarouselDialog,
    SectionHeader,
    CustomField,
    GwUserLicenceDialog,
  },
  data() {
    return {
      id: 0,
      inputs: { ...this.user },
      selectedSubstructures: [],
      valid: true,
      showDeleteDialog: false,
      showRoleDialog: false,
      nameRules: [
        (v) =>
          (!!v && v.length >= 1) || this.$t("general.errors.not_enough_chars"),
      ],
      substructureRules: [
        (v) =>
          (!!v && v.length > 0) || this.$t("general.errors.select_an_item"),
      ],
      tutorialStructures: ["structures"],
      customFields: [],
    };
  },
  computed: {
    ...mapGetters("users", { user: "get_user", user_stats: "get_user_stats" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    ...mapGetters("community", { community: "get_community" }),
    ...mapGetters("categories", { categories: "get_categories" }),
    edited() {
      if (!this.user) {
        return false;
      }
      return (
        this.inputs.user_name != this.user.user_name ||
        this.inputs.email != this.user.email ||
        this.inputs.first_name != this.user.first_name ||
        this.inputs.last_name != this.user.last_name ||
        this.inputs.isactive != this.user.isactive ||
        this.selectedSubstructures.join(",") !=
          this.user.substructures_ids.join(",") //||
        //JSON.stringify(this.customFields) != JSON.stringify(this.user.custom_fields)
      );
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("resources.users.plural"),
          disabled: false,
          to: { name: "UserOverview" },
        },
      ];
    },
    showGhostwriterSettings() {
      return process.env.VUE_APP_GHOSTWRITER === "true";
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
    this.id = Number(this.$route.params.id);

    if (this.user) {
      this.inputs = { ...this.user };
      this.generateCustomFields();
      this.selectedSubstructures = this.user.substructures_ids;
    }
  },
  watch: {
    user: {
      handler: function () {
        this.inputs = { ...this.user };
        this.generateCustomFields();
        this.selectedSubstructures = this.user.substructures_ids;
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("users", ["update_user"]),
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    resetForm() {
      this.inputs = { ...this.user };
      this.selectedSubstructures = this.user.substructures_ids;
    },
    async saveForm() {
      this.inputs.substructure_ids = this.selectedSubstructures;
      this.inputs.custom_fields = JSON.stringify(this.customFields);

      let res = await this.update_user({
        payload: this.inputs,
        config: { cancelToken: this.source.token },
      });

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text:
            this.$t("resources.users.singular") +
            " " +
            this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error_msg"),
        });
      }
    },
    closeDeleteDialog(wasDeleted) {
      this.showDeleteDialog = false;
      if (wasDeleted) {
        this.$router.push({ name: "UserOverview" });
      }
    },
    closeRoleDialog() {
      this.showRoleDialog = false;
    },
    generateCustomFields() {
      if (this.inputs.custom_fields.length > 0) {
        this.customFields = this.community.custom_fields;
      } else {
        this.community.custom_fields.forEach((field) => {
          this.customFields.push({ name: field.name, value: "" });
        });
      }
    },
    async toggleUserActiveStatus() {
      // TODO: mostly a copy of the original save form, simplify it after new api endpoint is addeed
      let user = { ...this.user };
      user.isactive = user.isactive === 1 ? 0 : 1;

      user.substructure_ids = this.selectedSubstructures;
      user.custom_fields = JSON.stringify(this.customFields);

      let res = await this.update_user({
        payload: user,
        config: { cancelToken: this.source.token },
      });

      if (res) {
        let text =
          user.isactive === 1
            ? this.$t("resources.users.activate_success")
            : this.$t("resources.users.deactivate_success");
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: text,
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error_msg"),
        });
      }
    },
    getCategoryName(id) {
      if (!this.categories) return "";
      let cat = this.categories.filter((cat) => cat.id === id)[0];
      return cat ? cat.name : null;
    },
    getSubstructureName(id) {
      if (!this.substructures) return "";
      return this.substructures.filter((str) => str.id === id)[0].name;
    },
    formatDate(string) {
      let dateObj = new Date(string);
      let date = dateObj.toLocaleDateString(this.$i18n.locale);
      let time = dateObj.toLocaleTimeString(this.$i18n.locale, {
        timeStyle: "short",
      });
      return `${date} ${time}`;
    },
    getCurrentCustomFieldValue(field) {
      let userField = null;
      userField = this.user.custom_fields.filter(
        (f) => f.name === field.name
      )[0];
      return userField && (userField.value || userField.value === 0)
        ? userField.value
        : null;
    },
    async customFieldChange(e) {
      this.inputs.substructure_ids = this.selectedSubstructures;
      this.inputs.custom_fields = JSON.stringify(this.customFields);
      let customFields = [...this.customFields].map((f) => {
        let field = this.user.custom_fields.filter(
          (uf) => uf.name === f.name
        )[0];
        let value =
          field && (!!field.value || field.value === 0) ? field.value : null;
        if (e.field && e.field.name === field.name) value = e.value;
        return {
          name: f.name,
          value: value,
        };
      });

      let payload = { ...this.inputs };
      payload.custom_fields = JSON.stringify(customFields);

      let res = await this.update_user({
        payload: payload,
        config: { cancelToken: this.source.token },
      });

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>

<style></style>
