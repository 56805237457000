<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="newMail"
            :label="$t('resources.invitations.emailAdd')"
            outlined
            background-color="white"
            dense
            hide-details
            prepend-inner-icon="mdi-plus-circle"
            @keydown.enter="create"
          >
            <template #append-outer>
              <v-btn
                depressed
                @click="create"
                color="primary"
                small
                :disabled="!newMail"
              >
                {{ $t("views.dashboard.check") }}
              </v-btn>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="4" offset-md="2">
          <v-btn
            :disabled="mails.length > 0"
            color="primary"
            block
            small
            @click="showFileDialog = true"
            >{{ $t("resources.invitations.upload") }}</v-btn
          >
          <v-btn
            color="primary"
            block
            x-small
            text
            class="mt-1"
            href="/files/p3l_invitations_template.xlsx"
            target="_blank"
            >{{ $t("resources.invitations.downloadTemplate") }}</v-btn
          >
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-slide-y-transition class="py-0" leave-absolute>
            <v-card
              v-if="!mails || mails.length === 0"
              flat
              color="grey lighten-3"
            >
              <v-card-text> {{ $t("$vuetify.noDataText") }}. </v-card-text>
            </v-card>
          </v-slide-y-transition>

          <v-slide-y-transition class="py-0" group leave-absolute hide-on-leave>
            <template v-for="(mail, i) in mails">
              <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>

              <v-card :key="`${i}-${mail.email}`" flat color="grey lighten-3">
                <v-list-item>
                  <v-list-item-action>
                    <v-fade-transition hide-on-leave>
                      <v-progress-circular
                        v-if="is_validating"
                        size="16"
                        width="2"
                        color="primary"
                        indeterminate
                      />
                    </v-fade-transition>
                    <v-fade-transition hide-on-leave>
                      <v-icon
                        v-if="!is_validating"
                        :color="mail.is_valid ? 'success' : 'error'"
                      >
                        {{ mail.is_valid ? "mdi-check" : "mdi-alert" }}
                      </v-icon>
                    </v-fade-transition>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{ mail.email }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      mailStatus(mail)
                    }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-spacer></v-spacer>

                  <v-scroll-x-transition>
                    <v-btn
                      text
                      x-small
                      color="error"
                      @click="remove(mail.email)"
                      >{{ $t("general.delete") }}</v-btn
                    >
                  </v-scroll-x-transition>
                </v-list-item>
              </v-card>
            </template>
          </v-slide-y-transition>
        </v-col>
      </v-row>
    </v-container>

    <InvitationDialogFile
      v-if="showFileDialog"
      :dialog-visible="showFileDialog"
      @close="closeFileDialog"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InvitationDialogFile from "@/components/invitations/InvitationDialogFile";

export default {
  name: "InvitationMail",
  components: {
    InvitationDialogFile,
  },
  data() {
    return {
      mails: [],
      inputMails: [],
      newMail: null,
      is_validating: false,
      showFileDialog: false,
    };
  },
  computed: {
    onlyValidMails() {
      var val = true;
      this.mails.forEach((m) => {
        if (!m.is_valid) val = false;
      });
      return val;
    },
  },
  watch: {
    inputMails: {
      handler: function () {
        this.validateMails();
      },
    },
  },
  methods: {
    ...mapActions("invitations", ["validate_mails"]),
    create() {
      this.inputMails.push({
        is_valid: false,
        user_exists: false,
        invitation_exists: false,
        email: this.newMail,
      });

      this.newMail = null;
    },
    remove(mail) {
      this.inputMails = [...this.mails].filter((m) => m.email !== mail);
    },
    async validateMails() {
      if (!this.inputMails || this.inputMails.length === 0) {
        this.mails = [];
        return false;
      }
      this.is_validating = true;
      let res = await this.validate_mails({
        payload: { invitations: [...this.inputMails] },
        config: { cancelToken: this.source.token },
      });
      this.is_validating = false;
      this.mails = res.invitations;
      this.$emit("change", this.mails);
    },
    mailStatus(mail) {
      if (mail.is_valid) return this.$t("resources.invitations.valid");
      if (mail.user_exists)
        return this.$t("resources.invitations.mailError.user_exists");
      if (mail.invitation_exists)
        return this.$t("resources.invitations.mailError.invitation_exists");
      if (mail.invalid_string)
        return this.$t("resources.invitations.mailError.invalid_string");
      return "unknown error";
    },
    closeFileDialog(mails) {
      if (mails) {
        this.inputMails = mails;
      }
      this.showFileDialog = false;
    },
  },
};
</script>

<style></style>
