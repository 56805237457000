<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.nuggets.edit_nugget')"
      icon="mdi-school"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'NuggetOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="loading && !nugget && !input" />

    <section v-if="nugget && input" class="pt-4 grey lighten-3">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.generalInfo.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.generalInfo.subtitle')"
      >
        <template #action>
          <NuggetActivator :nugget="nugget" :disabled="isEditing" />
        </template>
      </SectionHeader>
      <v-container>
        <v-row dense>
          <v-col cols="12" sm="12" md="4" class="pt-2">
            <LanguageChooserInline
              small
              colored
              background-color="primary"
              chip-color="primary darken-1"
              dark
              :initial="language"
              :disabled="isEditing"
              @change="changeLanguage"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <StatisticsCardSmall
              :title="nugget.type"
              :subtitle="$t('resources.nuggets.type')"
              :icon="
                nugget.type === 'text' ? 'mdi-format-color-text' : 'mdi-video'
              "
              color="grey lighten-2"
              text-color="secondary"
              icon-color="secondary"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <StatisticsCardSmall
              :title="nugget.created_at.toLocaleDateString()"
              :subtitle="$t('general.created_at')"
              icon="mdi-calendar"
              color="grey lighten-2"
              text-color="secondary"
              icon-color="secondary"
            />
          </v-col>
          <v-col v-if="false" cols="12" sm="6" md="4">
            <NuggetPreview v-if="false" :nugget="nugget">
              <template #activator="{ showDialog }">
                <StatisticsCardSmall
                  title="Vorschau"
                  subtitle="App-Vorschau"
                  icon="mdi-application-outline"
                  color="primary"
                  text-color="white"
                  icon-color="white"
                  click-type="dialog"
                  @clicked="showDialog()"
                />
              </template>
            </NuggetPreview>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="nugget && input" class="grey lighten-3 pb-4">
      <SectionHeader
        :title="$t('resources.categories.plural')"
        :subtitle="$t('ng.nugget_categories')"
      />

      <v-container>
        <v-row>
          <v-col class="d-flex align-center">
            <v-chip
              v-if="!nugget.category"
              small
              color="secondary"
              label
              class="mr-2"
            >
              {{ $t("ng.no_category") }}
            </v-chip>

            <v-chip
              v-if="nugget.category"
              small
              color="primary"
              label
              class="mr-2"
            >
              {{ nugget.category.name }}
            </v-chip>

            <NuggetCategoryChanger :nugget="nugget" :disabled="isEditing" />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="!loading" class="mb-8" />

    <section v-if="nugget && input">
      <SectionHeader
        :title="$t('ng.add_nugget_title')"
        :subtitle="$t('ng.nuggets.content_main')"
      >
        <!-- <template #action>
          <LanguageChooserInline
            hidedetails
            small
            hide-border
            background-color="grey lighten-2"
            :initial="language"
            :disabled="isEditing"
            @change="changeLanguage"
          />
        </template> -->
      </SectionHeader>
      <v-container>
        <v-row class="mt-4">
          <v-col cols="12">
            <InputTextfield
              v-model="input.title"
              :label="$t('resources.nuggets.title')"
              :rules="input.formRules.title"
              disabled
              counter="255"
              translatable
              @is-editing="setEditing"
              @save="updateTitle"
            >
              <template
                #actions="{
                  editing,
                  startEditing,
                  cancelEditing,
                  saveInput,
                  inputChanged,
                }"
              >
                <div v-if="!editing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="isEditing || loading"
                          @click="startEditing()"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>

                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <NuggetTranslationDialog
                          prop-name="title"
                          :nugget="nugget"
                          :disabled="!input.title || loading"
                        >
                          <template #button="{ onClick }">
                            <v-btn
                              icon
                              :disabled="
                                !input.title ||
                                input.title.length === 0 ||
                                isEditing ||
                                loading
                              "
                              @click="onClick"
                            >
                              <v-icon small> mdi-translate </v-icon>
                            </v-btn>
                          </template>
                        </NuggetTranslationDialog>
                      </div>
                    </template>
                    <span>{{ $t("general.translations") }}</span>
                  </v-tooltip>
                </div>
                <div v-if="editing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon color="error" @click="cancelEditing()">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.cancel") }}</span>
                  </v-tooltip>

                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          color="success"
                          :disabled="!inputChanged"
                          @click="saveInput()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.save") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextfield>
          </v-col>
          <v-col cols="12">
            <InputTextarea
              v-model="input.description"
              :label="$t('resources.nuggets.description')"
              counter="255"
              disabled
              translatable
              :rules="input.formRules.description"
              @is-editing="setEditing"
              @save="updateDescription"
              @cancel="setInput"
            >
              <template
                #actions="{
                  editing,
                  startEditing,
                  cancelEditing,
                  saveInput,
                  //changeFormat,
                  inputChanged,
                }"
              >
                <div v-if="!editing" class="d-flex flex-column ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="isEditing || loading"
                          @click="startEditing()"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <NuggetTranslationDialog
                          prop-name="description"
                          :nugget="nugget"
                          :disabled="!nugget.description || loading"
                        >
                          <template #button="{ onClick }">
                            <v-btn
                              icon
                              :disabled="
                                ['math'].includes(nugget.body_format) ||
                                isEditing ||
                                loading
                              "
                              @click="onClick"
                            >
                              <v-icon small> mdi-translate </v-icon>
                            </v-btn>
                          </template>
                        </NuggetTranslationDialog>
                      </div>
                    </template>
                    <span>{{ $t("general.translations") }}</span>
                  </v-tooltip>
                </div>
                <div v-if="editing" class="d-flex flex-column ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon color="error" @click="cancelEditing()">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.cancel") }}</span>
                  </v-tooltip>

                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          color="success"
                          :disabled="
                            !input.description ||
                            input.description.length < 1 ||
                            !inputChanged
                          "
                          @click="saveInput()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.save") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextarea>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="!loading" />

    <section
      v-if="nugget && input && ['text', 'video'].includes(nugget.type)"
      class="grey lighten-3 py-4"
    >
      <SectionHeader
        :title="$t('ng.add_nugget_title')"
        :subtitle="`${$t('ng.format')}: ${input.body_format}`"
      >
        <template #action>
          <v-btn
            small
            depressed
            color="secondary"
            :disabled="isEditing || editModes.content"
            @click="toggleContentEditing(true)"
          >
            <v-icon small>mdi-pencil</v-icon>
            <span class="ml-2">
              {{ $t("general.edit") }}
            </span>
          </v-btn>
        </template>
      </SectionHeader>
      <v-container>
        <v-row>
          <v-col v-if="input.type === 'video'">
            <p
              v-if="input.type === 'video'"
              class="secondary--text text-body-2"
            >
              {{ $t("resources.nuggets.optional_content") }}
            </p></v-col
          >
          <v-col v-if="['text', 'video'].includes(input.type)" cols="12">
            <v-expand-transition>
              <v-alert
                v-if="input.bodyImgCount() !== input.images.length"
                text
                type="error"
                dense
                colored-border
                border="left"
              >
                <div class="caption">
                  <span
                    v-html="
                      $t('ng.image_placeholder_hint', {
                        placeholder: `<span class='primary pa-1 white--text font-weight-bold'>[_IMG_]</span>`,
                        count: input.bodyImgCount(),
                        images: input.images.length,
                      })
                    "
                  />
                </div>
              </v-alert>
            </v-expand-transition>
            <InputTextarea
              v-model="input.content"
              :label="$t('resources.nuggets.content')"
              listen-to-input
              :body-format="input.body_format"
              :custom-body-formats="['html', 'math']"
              :rules="input.formRules.content"
              html
              :preview="false"
              :disabled="!editModes.content"
              @add-image-placeholder="addImagePlaceholder"
              @change-format="setFormat"
            >
              <template
                #actions="{ changeFormat, addImagePlaceholder, togglePreview }"
              >
                <div class="d-flex flex-column ml-2" style="width: 100%">
                  <!-- <div class="caption">
                    {{ !loading ? `Format: ${input.body_format}` : "" }}
                  </div> -->
                  <div>
                    <v-tooltip left color="primary">
                      <template #activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <v-btn
                            :disabled="loading || !editModes.content"
                            icon
                            color="secondary"
                            @click="changeFormat()"
                          >
                            <v-icon small>
                              mdi-format-letter-case-upper
                            </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{
                        `${$t("ng.change_format")} : ${input.body_format}`
                      }}</span>
                    </v-tooltip>
                    <v-tooltip left color="primary">
                      <template #activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <v-btn
                            :disabled="loading || !editModes.content"
                            icon
                            color="secondary"
                            @click="addImagePlaceholder()"
                          >
                            <v-icon small> mdi-image </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t("ng.add_image_placeholder") }}</span>
                    </v-tooltip>
                    <v-tooltip left color="primary">
                      <template #activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <NuggetTranslationDialog
                            prop-name="content"
                            :nugget="nugget"
                            :disabled="!nugget.content || loading"
                            textarea
                          >
                            <template #button="{ onClick }">
                              <v-btn
                                icon
                                :disabled="
                                  ['math'].includes(nugget.body_format) ||
                                  isEditing ||
                                  loading
                                "
                                @click="onClick"
                              >
                                <v-icon small> mdi-translate </v-icon>
                              </v-btn>
                            </template>
                          </NuggetTranslationDialog>
                        </div>
                      </template>
                      <span>{{ $t("general.translations") }}</span>
                    </v-tooltip>
                    <NuggetPreview v-if="false" :nugget="input">
                      <template #activator="{ showDialog }">
                        <v-btn icon @click="showDialog()">
                          <v-icon small> mdi-file-find </v-icon>
                        </v-btn>
                      </template>
                    </NuggetPreview>

                    <v-tooltip left color="primary" v-if="false">
                      <template #activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <v-btn icon @click="togglePreview()">
                            <v-icon small> mdi-file-find </v-icon>
                          </v-btn>
                        </div>
                      </template>
                      <span>{{ $t("resources.invitations.preview") }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </template>
            </InputTextarea>
          </v-col>
        </v-row>

        <v-row v-if="['text', 'video'].includes(input.type)" dense>
          <v-col cols="12">
            <span class="caption secondary--text">
              {{ $t("ng.add_content_images") }}
            </span>
          </v-col>
          <v-col
            v-for="(hash, i) in input.images_hash"
            :key="i"
            cols="6"
            sm="4"
            md="3"
          >
            <ImageUpload
              height="120"
              :image-hash="hash"
              :label="`${$t('ng.image')} ${i + 1}`"
              :disabled="!editModes.content"
              @image-changed="contentImageChange(i, ...arguments)"
            />
          </v-col>
          <v-col cols="6" sm="4" md="3">
            <ImageUpload
              resetable
              height="120"
              :label="$t('resources.journeys.new_status')"
              card-color="grey lighten-1"
              :disabled="!editModes.content"
              @image-changed="contentImageChange(-1, ...arguments)"
            />
          </v-col>
        </v-row>

        <v-row v-if="nugget.type === 'iframe'">
          <v-col cols="12">
            <v-text-field
              v-model="input.media_uid"
              outlined
              dense
              background-color="white"
              :label="$t('resources.nuggets.iframe_link.label')"
              :hint="$t('resources.nuggets.iframe_link.hint')"
              :rules="input.formRules.content"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex align-center py-4">
            <v-expand-transition>
              <div v-if="editModes.content">
                <div
                  class="d-flex px-4 pb-1"
                  v-if="input.body_format === 'html'"
                >
                  <v-switch
                    v-model="translate_content"
                    dense
                    :disabled="input.body_format === 'math'"
                    class="mt-0"
                    hide-details
                  >
                    <template #label>
                      <span class="caption">{{
                        $t("ng.update_all_languages")
                      }}</span>
                    </template>
                  </v-switch>
                  <v-tooltip v-if="translate_content" color="primary" top>
                    <template #activator="{ attrs, on }">
                      <v-icon
                        v-on="on"
                        v-bind="attrs"
                        small
                        class="ml-2"
                        color="info"
                        >mdi-information</v-icon
                      >
                    </template>
                    <span>{{ $t("ng.translate_html_info") }}</span>
                  </v-tooltip>
                </div>
                <div
                  v-if="input.body_format === 'math'"
                  class="caption secondary--text"
                >
                  {{ $t("ng.nugget_math_translation_hint") }}
                </div>
              </div>
            </v-expand-transition>
            <v-spacer />
            <v-slide-x-transition leave-absolute>
              <v-btn
                v-if="editModes.content"
                x-small
                text
                color="error"
                :disabled="loading"
                @click="toggleContentEditing(false)"
              >
                <span>
                  {{ $t("general.cancel") }}
                </span>
              </v-btn>
            </v-slide-x-transition>
            <v-btn
              small
              depressed
              color="success"
              class="ml-2"
              :disabled="
                !contentHasChanged ||
                input.bodyImgCount() !== input.images.length ||
                loading
              "
              :loading="loading"
              @click="updateContent()"
            >
              <v-icon small>mdi-content-save</v-icon>
              <span class="ml-2">
                {{ $t("general.save") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="!loading" class="mb-8" />

    <section v-if="nugget && input">
      <SectionHeader
        :title="$t('resources.media.description')"
        :subtitle="$t('ng.nuggets.media_main')"
      />
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-card flat color="grey lighten-3 fill-height d-flex flex-column">
              <v-card-title class="overline secondary--text">{{
                $t("resources.nuggets.image")
              }}</v-card-title>
              <v-card-text>
                <ImageUpload
                  card-color="grey lighten-2"
                  :image-hash="input.image_hash"
                  :height="200"
                  :disabled="isEditing && !imageChanged"
                  @image-changed="imageChange"
                />
                <v-expand-transition>
                  <div v-show="!input.image">
                    <v-alert
                      type="info"
                      text
                      class="mt-2"
                      dense
                      colored-border
                      border="left"
                    >
                      <span class="caption">{{
                        $t("ng.nugget_image_hint")
                      }}</span>
                    </v-alert>
                  </div>
                </v-expand-transition>
              </v-card-text>
              <v-spacer />
              <v-card-actions>
                <v-spacer />
                <v-slide-x-transition>
                  <v-btn
                    v-if="imageChanged"
                    x-small
                    text
                    color="error"
                    @click="cancelImage()"
                    >{{ $t("general.cancel") }}</v-btn
                  >
                </v-slide-x-transition>
                <v-btn
                  small
                  depressed
                  color="success"
                  :disabled="!imageChanged"
                  @click="updateImage()"
                >
                  <v-icon small>mdi-content-save</v-icon>
                  <span class="ml-2">
                    {{ $t("general.save") }}
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col v-if="input.type === 'video' && nugget" cols="12" sm="6">
            <v-card flat color="grey lighten-3 fill-height d-flex flex-column">
              <v-card-title class="d-flex align-center">
                <span class="overline secondary--text">{{
                  $t("resources.nuggets.video")
                }}</span>
                <v-spacer />
                <country-flag :country="flagName(language)" size="small" />
                <span class="ml-3 caption secondary--text">{{ language }}</span>
              </v-card-title>
              <v-card-text>
                <VideoUpload
                  v-if="nugget && !loading"
                  :nugget="nugget"
                  @video-changed="videoChange"
                  card-color="grey lighten-2"
                  disabled
                  :initial-lang="language"
                  :initial-link="nugget.media_uid"
                  :initial-type="nugget.source"
                />
              </v-card-text>
              <v-spacer />
              <v-card-actions>
                <v-spacer />
                <NuggetVideoDialog
                  :nugget="nugget"
                  :language="language"
                  @close="fetchData()"
                >
                  <template #button="{ onClick }">
                    <v-btn small depressed color="secondary" @click="onClick">
                      <v-icon small>mdi-pencil</v-icon>
                      <span class="ml-2">
                        {{ $t("general.edit") }}
                      </span>
                    </v-btn>
                  </template>
                </NuggetVideoDialog>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="!loading" class="my-8" />

    <section v-if="nugget && input">
      <SectionHeader
        :title="$t('resources.nuggets.links')"
        :subtitle="$t('ng.nuggets.add_links')"
      >
        <template #action>
          <v-btn
            small
            depressed
            color="secondary"
            :disabled="isEditing || editModes.links"
            @click="toggleLinksEditing(true)"
          >
            <v-icon small>mdi-pencil</v-icon>
            <span class="ml-2">
              {{ $t("general.edit") }}
            </span>
          </v-btn>
        </template>
      </SectionHeader>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card flat color="grey lighten-3">
              <v-card-text>
                <v-csv-field
                  v-model="input.links"
                  :label="$t('resources.nuggets.addLink')"
                  :disabled="!editModes.links"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-slide-x-transition leave-absolute>
                  <v-btn
                    v-if="editModes.links"
                    x-small
                    text
                    color="error"
                    @click="toggleLinksEditing(false)"
                  >
                    <span>
                      {{ $t("general.cancel") }}
                    </span>
                  </v-btn>
                </v-slide-x-transition>
                <v-btn
                  small
                  depressed
                  color="success"
                  :disabled="!linksHaveChanged"
                  @click="updateLinks()"
                >
                  <v-icon small>mdi-content-save</v-icon>
                  <span class="ml-2">
                    {{ $t("general.save") }}
                  </span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-divider v-if="!loading" class="my-8" />

    <section v-if="nugget" class="pb-6">
      <SectionHeader
        :title="$t('ng.further_actions')"
        :subtitle="$t('ng.further_actions_descr')"
      />
      <v-container>
        <v-row>
          <v-col cols="12" class="d-flex">
            <NuggetDelete :nugget="nugget" @close="checkForRedirect" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";
import SectionHeader from "@/components/_layout/SectionHeader";
import NuggetActivator from "@/components/nuggets/NuggetActivator";
import ImageUpload from "@/components/global/ImageUpload";
import VideoUpload from "@/components/global/VideoUpload";
//import MathJaxRenderer from '@/components/global/MathJaxRenderer';
import NuggetPreview from "@/components/nuggets/NuggetPreview";
import NuggetDelete from "@/components/nuggets/NuggetDelete";
import NuggetCategoryChanger from "@/components/nuggets/NuggetCategoryChanger";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";
import NuggetTranslationDialog from "@/components/nuggets/NuggetTranslationDialog";
import NuggetVideoDialog from "@/components/nuggets/NuggetVideoDialog";
import CountryFlag from "vue-country-flag";

import { Nugget } from "@/models/Nugget.js";

export default {
  name: "NuggetDetail",
  components: {
    ViewHeader,
    ViewLoader,
    StatisticsCardSmall,
    SectionHeader,
    NuggetActivator,
    ImageUpload,
    VideoUpload,
    //MathJaxRenderer,
    NuggetPreview,
    NuggetDelete,
    NuggetCategoryChanger,
    LanguageChooserInline,
    NuggetTranslationDialog,
    NuggetVideoDialog,
    CountryFlag,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      loading: true,
      //showContentPreview: false,
      input: null,
      imageChanged: false,
      videoChanged: false,
      language: this.$i18n.locale,
      isEditing: false,
      editModes: {
        links: false,
        content: false,
      },
      translate_content: false,
    };
  },
  computed: {
    ...mapGetters("nuggets", { nugget: "get_nugget" }),
    ...mapGetters("categories", { categories: "get_categories" }),
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.nuggets.plural"),
          disabled: false,
          to: { name: "NuggetOverview" },
        },
      ];
    },
    linksHaveChanged() {
      return this.input.links !== this.nugget.links;
    },
    contentHasChanged() {
      return (
        this.input.content !== this.nugget.content ||
        this.input.body_format !== this.nugget.body_format ||
        this.input.images.length !== this.nugget.images.length
      );
    },
  },
  watch: {
    /* nugget() {
      this.setInput();
    }, */
    imageChanged: {
      handler: function (v) {
        this.isEditing = v;
      },
    },
  },
  destroyed() {
    this.removeData();
  },
  methods: {
    ...mapActions("nuggets", ["fetch_nugget", "patch_nugget"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("nuggets", ["set_nugget"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_nugget({
        id: this.id,
        language: this.language,
        config: { cancelToken: this.source.token },
      });
      this.setInput();
      await this.fetch_categories({ cancelToken: this.source.token });
      this.loading = false;
    },
    setInput() {
      if (!this.nugget) return false;
      this.input = new Nugget({ ...this.nugget });
    },
    setFormat(format) {
      this.input.body_format = format;
    },
    removeData() {
      this.set_categories(null), this.set_nugget(null);
    },
    imageChange(imageObj) {
      this.input.image = imageObj ? imageObj.filename : null;
      this.input.image_hash = imageObj ? imageObj.hash : null;
      this.input.source = "local";
      this.imageChanged = true;
    },
    videoChange(type, link, uuid) {
      this.input.source = type;
      this.input.media_link = link;
      this.input.media_uid = uuid;
      this.videoChanged = true;
    },
    resetForm() {
      this.setInput();
    },
    togglePreview() {
      this.showContentPreview = !this.showContentPreview;
    },
    contentImageChange(idx, val) {
      let images = [];
      let hashes = [];

      if (!val) {
        this.input.images.forEach((image, i) => {
          if (i !== idx) {
            images.push(image);
            hashes.push(this.input.images_hash[i]);
          }
        });
      }
      if (val) {
        images = [...this.input.images];
        hashes = [...this.input.images_hash];
        images.push(val.filename);
        hashes.push(val.hash);
      }
      this.input.images = [...images];
      this.input.images_hash = [...hashes];
    },
    addImagePlaceholder() {
      this.input.content += " [_IMG_] ";
    },
    checkForRedirect(wasDeleted) {
      if (wasDeleted) {
        this.$router.push({ name: "NuggetOverview" });
      }
    },
    cancelImage() {
      this.input.image = this.nugget.image;
      this.input.image_hash = this.nugget.image_hash;
      this.isEditing = false;
      this.imageChanged = false;
    },
    async updateImage() {
      this.loading = true;
      let properties = [
        { name: "image", value: this.input.image ? this.input.image : "" },
      ];
      let payload = {
        id: this.nugget.id,
        language: this.language,
        properties: properties,
      };

      let res = await this.patch_nugget({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;

      if (res) {
        this.imageChanged = false;
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.update_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.update_error"),
        });
      }
    },
    changeLanguage(val) {
      this.language = val;
      this.fetchData();
    },
    setEditing(val) {
      this.isEditing = val;
    },
    async updateTitle(val, translate) {
      this.loading = true;
      var payload = {
        id: this.nugget.id,
        language: this.language,
        override: translate,
        properties: [{ name: "title", value: val }],
      };

      var res = await this.patch_nugget({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.update_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.update_error"),
        });
      }
    },
    async updateDescription(val, translate) {
      this.loading = true;
      var payload = {
        id: this.nugget.id,
        language: this.language,
        override: translate,
        properties: [{ name: "description", value: val }],
      };

      var res = await this.patch_nugget({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.update_success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.update_error"),
        });
      }
    },
    toggleLinksEditing(val) {
      this.editModes.links = val;
      this.isEditing = val;
      if (!val) this.input.links = this.nugget.links;
    },
    toggleContentEditing(val) {
      this.editModes.content = val;
      this.isEditing = val;
      if (!val) this.input.content = this.nugget.content;
    },
    async updateLinks() {
      this.loading = true;
      var payload = {
        id: this.nugget.id,
        language: this.language,
        //override: translate,
        properties: [{ name: "links", value: this.input.links.split(",") }],
      };

      var res = await this.patch_nugget({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.update_success"),
        });
        this.editModes.links = false;
        this.isEditing = false;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.update_error"),
        });
      }
    },
    async updateContent() {
      this.loading = true;
      var payload = {
        id: this.nugget.id,
        language: this.language,
        override: this.translate_content,
        properties: [
          { name: "content", value: this.input.content },
          { name: "images", value: this.input.images },
          { name: "body_format", value: this.input.body_format },
        ],
      };

      var res = await this.patch_nugget({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.media.update_success"),
        });
        this.editModes.content = false;
        this.isEditing = false;
        this.translate_content = false;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("resources.media.update_error"),
        });
      }
    },
    flagName(lang) {
      if (lang === "zh-ZH") return "cn"; // chinese flag fallback
      return lang.slice(-2);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style></style>
