<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.generalInfo.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.generalInfo.subtitle')"
      >
        <template v-if="journey" #action>
          <JourneyStatusChanger :journey="journey" />
        </template>
      </SectionHeader>

      <v-container v-if="journey && substructures">
        <v-row>
          <v-col cols="12" sm="6">
            <v-img :src="journey.getImage()" />
          </v-col>
          <v-col cols="12" sm="6">
            <LanguageChooserInline
              hidedetails
              small
              :initial="language"
              @change="changeLanguage"
            />
            <h4 class="secondary--text mt-4">{{ journey.name }}</h4>
            <p class="caption">{{ journey.description }}</p>

            <v-btn
              x-small
              depressed
              color="primary"
              :to="{ name: 'JourneySettings' }"
              >{{ $t("general.edit") }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row dense class="mt-4">
          <v-col cols="12" sm="6">
            <h5 class="secondary--text">
              {{ $t("resources.journeys.propNames.start_at") }}
            </h5>
            <p>{{ startDate ? startDate : "-" }}</p>
          </v-col>
          <v-col cols="12" sm="6">
            <h5 class="secondary--text">
              {{ $t("resources.journeys.propNames.end_at") }}
            </h5>
            <p>{{ endDate ? endDate : "-" }}</p>
          </v-col>
          <v-col cols="12" sm="6">
            <h5 class="secondary--text">
              {{ $t("resources.journeys.propNames.coins") }}
            </h5>
            <p>{{ journey.coins.toLocaleString(`${$i18n.locale}`) }}</p>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <h5 class="secondary--text">
              {{ $t("resources.substructures.plural") }}
            </h5>
            <div>
              <span
                v-for="(substructure, index) in journey.substructures"
                :key="index"
              >
                <v-chip
                  v-if="index < 3"
                  x-small
                  color="grey lighten-2"
                  class="mr-2 mt-1"
                >
                  {{ getSubstructureById(substructure).structure.name }}
                  / {{ getSubstructureById(substructure).name }}
                </v-chip>
                <v-chip
                  v-if="index == 3"
                  x-small
                  color="grey lighten-2"
                  class="mr-2 mt-1"
                  :to="{
                    name: 'JourneyStructures',
                    params: { id: journey.id },
                  }"
                  >+ {{ journey.substructures.length - 3 }}
                  {{ $t("general.moreIndicator") }}</v-chip
                >
              </span>
              <small v-if="journey.substructures.length == 0">{{
                $t("resources.journeys.noStructuresYet")
              }}</small>
            </div>
          </v-col>
        </v-row>
        <v-alert
          v-if="
            journey.creator_id &&
            ['superadmin', 'admin', 'manager'].includes(role)
          "
          dense
          text
          type="info"
          color="secondary"
          class="mt-4"
        >
          <span class="caption">{{ $t("ng.journey_created_by_editor") }}</span>
        </v-alert>
      </v-container>
    </section>

    <section class="mt-4">
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.statsContent.title')"
        :subtitle="
          $t('resources.journeys.sectionHeaders.statsContent.subtitle')
        "
      />

      <JourneyStatsContent />
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import SectionHeader from "@/components/_layout/SectionHeader";
import JourneyStatusChanger from "@/components/journeys/JourneyStatusChanger";
import JourneyStatsContent from "@/components/journeys/stats/JourneyStatsContent";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";

export default {
  name: "JourneySummary",
  components: {
    SectionHeader,
    JourneyStatusChanger,
    JourneyStatsContent,
    LanguageChooserInline,
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      language: "get_selected_language",
    }),
    ...mapGetters("structures", { substructures: "get_substructures" }),
    startDate() {
      if (this.journey.start_at) {
        return new Date(this.journey.start_at).toLocaleDateString();
      }
      return "";
    },
    endDate() {
      if (this.journey.end_at) {
        return new Date(this.journey.end_at).toLocaleDateString();
      }
      return "";
    },
  },
  methods: {
    ...mapMutations("journeys", ["set_selected_language"]),
    getSubstructureById(id) {
      return this.substructures.filter(
        (substructure) => substructure.id === id
      )[0];
    },
    changeLanguage(lang) {
      this.set_selected_language(lang);
    },
  },
};
</script>

<style></style>
