<template>
  <div>
    <section v-if="!loading">
      <SectionHeader
        :title="$t('ng.question_reports_title')"
        :subtitle="$t('ng.question_reports_subtitle')"
      />
      <v-container>
        <v-row>
          <v-col v-if="!reports || reports.length === 0" cols="12">
            {{ $t("ng.question_reports_none") }}
          </v-col>
          <v-col
            v-for="report in reports"
            :key="`report-${report.id}`"
            cols="12"
          >
            <ReportCard
              :report="report"
              :question-id="question.id"
              :language="language"
              class="mb-6"
              hide-comments
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ReportCard from "@/components/questions/ReportCard";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "QuestionReports",
  components: {
    ReportCard,
    SectionHeader,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("questions", {
      question: "get_question",
      reports: "get_question_reports",
      language: "get_selected_language",
    }),
  },
  methods: {
    ...mapActions("questions", ["fetch_question_reports"]),
    ...mapMutations("questions", ["set_question_reports"]),
    async fetchData() {
      if (!this.question) return false;
      this.loading = true;
      this.fetch_question_reports({
        question_id: this.question.id,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
    },
    unloadData() {
      this.set_question_reports(null);
    },
  },
};
</script>
