<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      title="Datenimport"
      icon="mdi-database-import"
    />

    <ViewLoader v-if="!imports" />

    <section v-if="imports">
      <SectionHeader :title="`${$t('general.overview')}`" />
      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters" class="pb-0">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="page = 1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="[
                  {
                    text: 'Name',
                    value: 'name',
                    width: '20%',
                  },
                  {
                    text: 'Typ',
                    value: 'import_type',
                    width: '20%',
                  },
                  {
                    text: 'Beschreibung',
                    value: 'description',
                  },
                ]"
                :items="filteredImports"
                :items-per-page="10"
                :search="filter.search"
                :page.sync="page"
                :loading="!imports"
              >
                <template #item="request">
                  <tr
                    :class="{
                      disabled: ['categories', 'nuggets'].includes(
                        request.item.import_type
                      ),
                    }"
                    @click="rowClicked(request.item)"
                  >
                    <td>{{ request.item.name }}</td>
                    <td>{{ getTranslation(request.item.import_type) }}</td>
                    <td>{{ request.item.description }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "ImportOverview",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      page: 1,
      display: {
        filters: false,
      },
      filter: {
        search: "",
      },
    };
  },
  computed: {
    ...mapGetters("imports", { imports: ["get_imports"] }),
    filteredImports() {
      if (!this.imports) return [];
      return this.imports;
    },
    activeFilters() {
      let search = this.filter.search.length > 0 ? 1 : 0;
      return search;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "Datenimport",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin"]);
    this.getFilter();
  },
  methods: {
    ...mapActions("imports", ["fetch_imports"]),
    ...mapMutations("imports", ["set_imports"]),
    async fetchData() {
      await this.fetch_imports({ cancelToken: this.source.token });
    },
    unloadData() {
      this.set_imports(null);
    },
    rowClicked(item) {
      if (item.import_type === "questions")
        this.$router.push({
          name: "ImportDetailQuestions",
          params: { id: item.id },
        });
      //if(item.import_type === 'categories') this.$router.push({ name: 'ImportDetailCategories', params: { id: item.id } });
      //if(item.import_type === 'nuggets') this.$router.push({ name: 'ImportDetailNuggets', params: { id: item.id } });
      return false;
    },
    resetFilter() {
      this.filter = {
        substructures: [],
        isactive: [],
        isactive_nugget: [],
        search: "",
      };
    },
    storeFilter() {
      localStorage.setItem("fil_imp", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_imp")) {
        let filter = JSON.parse(localStorage.getItem("fil_imp"));

        this.filter.search = filter.search ? filter.search : "";
      }
    },
    getTranslation(type) {
      if (type === "nuggets") return this.$t("resources.nuggets.plural");
      if (type === "questions") return this.$t("resources.questions.plural");
      if (type === "categories") return this.$t("resources.categories.plural");
      return "?";
    },
  },
};
</script>

<style>
.disabled {
  opacity: 0.5;
}
</style>
