<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-card-title class="overline secondary--text">
          SSO Client bearbeiten
        </v-card-title>
        <v-card-text>
          <v-form v-model="valid">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="input.name"
                  outlined
                  :label="$t('resources.categories.name')"
                  dense
                  listen-to-input
                  disabled
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.id"
                  outlined
                  background-color="white"
                  :label="'id'"
                  dense
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.secret"
                  outlined
                  background-color="white"
                  :label="'secret'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.scope"
                  outlined
                  background-color="white"
                  :label="'scope'"
                  dense
                  listen-to-input
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="input.metadata_url"
                  outlined
                  background-color="white"
                  :label="'metadata_url'"
                  dense
                  listen-to-input
                  hint="Mit Leerzeichen trennen"
                  :rules="rules.notEmpty"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox v-model="input.is_active" label="is_active" dense />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
          <v-spacer />
          <v-btn text small color="error" @click="reset()">
            {{ $t("general.reset") }}
          </v-btn>
          <v-btn
            text
            color="success"
            small
            :loading="loading"
            :disabled="!valid || loading || !hasChanged"
            @click="updateClient()"
          >
            {{ $t("general.edit") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SSOClientDialog",
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      valid: false,
      input: null,
      rules: {
        notEmpty: [
          (v) => (!!v && v.length > 0) || this.$t("ng.add_chars_hint"),
          //(v) => !!v && v.length <= 64 || this.$t('ng.too_many_chars_hint', { count: v.length, max: 64 })
        ],
      },
    };
  },
  computed: {
    hasChanged() {
      if (!this.client || !this.input) return false;
      return (
        this.client.id !== this.input.id ||
        this.client.secret !== this.input.secret ||
        this.client.scope !== this.input.scope ||
        this.client.metadata_url !== this.input.metadata_url ||
        this.client.is_active !== this.input.is_active
      );
    },
  },
  methods: {
    ...mapActions("administration", ["update_sso_client"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.reset();
      this.showDialog = true;
    },
    reset() {
      this.input = { ...this.client };
    },
    async updateClient() {
      this.loading = true;
      let payload = { ...this.input };
      let res = await this.update_sso_client({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "Erfolgreich",
          text: "Client erfolgreich bearbeitet.",
        });
      } else {
        this.$notify({
          type: "error",
          title: "Fehler",
          text: "Fehler beim Bearbeiten des Clients.",
        });
      }
    },
  },
};
</script>
