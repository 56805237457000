export const set_session = (state, session) => (state.session = session);
export const set_sessions = (state, sessions) => (state.sessions = sessions);
export const set_bulk_session = (state, session) =>
  (state.bulk_session = session);
export const set_bulk_sessions = (state, sessions) =>
  (state.bulk_sessions = sessions);
export const set_licences = (state, licences) => (state.licences = licences);
export const set_transcriptions = (state, transcriptions) =>
  (state.transcriptions = transcriptions);
export const set_transcription = (state, transcription) =>
  (state.transcription = transcription);
export const set_credit_pool = (state, obj) => (state.credit_pool = obj);
