<template>
  <div>
    <v-container>
      <v-row>
        <v-col v-if="loading" cols="12" md="4">
          <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
        </v-col>
        <v-col v-if="!loading" cols="12" md="4">
          <v-slide-x-reverse-transition hide-on-leave>
            <div v-if="!credit_pool">
              <v-hover v-slot="{ hover }">
                <v-card
                  v-if="!loading"
                  height="120"
                  color="grey lighten-3"
                  :elevation="hover ? 8 : 0"
                  style="transition: 0.2s"
                  @click="showAddDialog = true"
                >
                  <v-card-text
                    class="fill-height d-flex flex-column align-center justify-center"
                  >
                    <v-icon x-large color="secondary">mdi-plus-circle</v-icon>
                    <div class="title mt-2 font-weight-bold secondary--text">
                      {{ $t("general.add") }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>
          </v-slide-x-reverse-transition>
          <v-slide-x-reverse-transition hide-on-leave>
            <div v-if="credit_pool">
              <v-hover v-slot="{ hover }">
                <v-card
                  height="120"
                  color="secondary"
                  dark
                  :elevation="disabled ? 0 : hover ? 8 : 2"
                  style="transition: 0.2s"
                  :style="disabled ? 'cursor: default' : ''"
                  @click="disabled ? false : (showDialog = true)"
                >
                  <v-card-text
                    class="fill-height d-flex flex-column align-center justify-center"
                  >
                    <v-icon x-large>mdi-bank</v-icon>
                    <div class="title mt-2 font-weight-bold">
                      {{ credit_pool.credits_count.toLocaleString() }}
                    </div>
                    <div class="caption">
                      {{ $t("gw.licences.date_end") }}
                      {{ credit_pool.date_end.toLocaleDateString() }}
                    </div>
                  </v-card-text>
                </v-card>
              </v-hover>
            </div>
          </v-slide-x-reverse-transition>
        </v-col>
      </v-row>
    </v-container>

    <GwAddCreditPoolDialog
      :visible="showAddDialog"
      @close="showAddDialog = false"
    />
    <GwCreditPoolDialog :visible="showDialog" @close="showDialog = false" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GwAddCreditPoolDialog from "@/components/administration/ghostwriter/GwAddCreditPoolDialog";
import GwCreditPoolDialog from "@/components/administration/ghostwriter/GwCreditPoolDialog";

export default {
  name: "GwCreditPoolManager",
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    GwAddCreditPoolDialog,
    GwCreditPoolDialog,
  },
  data() {
    return {
      loading: true,
      showAddDialog: false,
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { credit_pool: "get_credit_pool" }),
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_credit_pool"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_credit_pool({ cancelToken: this.source.token });
      this.loading = false;
    },
  },
};
</script>

<style></style>
