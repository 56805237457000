<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.questions.new')"
      icon="mdi-comment-question"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'QuestionOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>

    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <SectionHeader
        :title="`${$t('general.add')}`"
        :subtitle="$t('resources.questions.new')"
      />
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="question_type"
              :items="question_types"
              item-text="name"
              outlined
              background-color="white"
              dense
              :label="$t('resources.questions.type')"
              @change="changeQuestiontype"
            />
          </v-col>
          <v-col v-if="question" cols="6">
            <v-form ref="categoryform" v-model="category_validation">
              <v-autocomplete
                v-model="question.category_ids"
                :items="sortedCategories"
                background-color="white"
                item-value="id"
                item-text="name"
                multiple
                outlined
                :label="$t('resources.categories.plural')"
                :rules="question.formRules.categories"
                dense
                chips
                small-chips
                deletable-chips
                @change="setValid"
              >
                <template #item="{ item }">
                  <v-list-item-action>
                    <v-checkbox
                      :value="question.category_ids.includes(item.id)"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <v-chip v-if="item.groups.length === 0" x-small label>
                        Ohne Gruppe
                      </v-chip>
                      <v-chip
                        v-for="group in item.groups"
                        :key="group.id"
                        x-small
                        label
                        class="mr-2"
                      >
                        {{ group.name }}
                      </v-chip>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="!loading">
      <v-container v-if="!question_type">
        <v-alert
          type="info"
          dense
          text
          colored-border
          border="left"
          class="caption"
        >
          {{ $t("ng.question_type_needed") }}
        </v-alert>
      </v-container>
      <QuestionEstimationAdd
        v-if="question_type === 'estimation'"
        :question="question"
        :language="language"
        :disabled="loading"
        @validation="setValid"
      />
      <QuestionSingleChoiceAdd
        v-if="question_type === 'single_choice'"
        :question="question"
        :language="language"
        :disabled="loading"
        @validation="setValid"
      />
      <QuestionMultiChoiceAdd
        v-if="question_type === 'multi_choice'"
        :question="question"
        :language="language"
        :disabled="loading"
        @validation="setValid"
      />
      <QuestionGapAdd
        v-if="question_type === 'gap_text'"
        :question="question"
        :language="language"
        :disabled="loading"
        @validation="setValid"
      />
    </section>

    <section v-if="!!question_type && !loading">
      <v-container>
        <v-row>
          <v-col class="text-right">
            <v-btn
              depressed
              small
              :disabled="!valid || !category_validation || loading"
              :loading="loading"
              color="primary"
              @click="save()"
            >
              {{ $t("general.add") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { QuestionEstimation } from "@/models/content/questions/types/QuestionEstimation";
import { QuestionSingleChoice } from "@/models/content/questions/types/QuestionSingleChoice";
import { QuestionMultiChoice } from "@/models/content/questions/types/QuestionMultiChoice";
import { QuestionGap } from "@/models/content/questions/types/QuestionGap";

import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import QuestionEstimationAdd from "@/components/questions/types/QuestionEstimationAdd";
import QuestionSingleChoiceAdd from "@/components/questions/types/QuestionSingleChoiceAdd";
import QuestionMultiChoiceAdd from "@/components/questions/types/QuestionMultiChoiceAdd";
import QuestionGapAdd from "@/components/questions/types/QuestionGapAdd";

import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "QuestionAdd",
  components: {
    ViewHeader,
    ViewLoader,
    QuestionEstimationAdd,
    QuestionSingleChoiceAdd,
    QuestionMultiChoiceAdd,
    QuestionGapAdd,
    SectionHeader,
  },
  data() {
    return {
      loading: false,
      question_type: null,
      question_types: [
        {
          value: "single_choice",
          name: this.$t("resources.questions.types.6"),
        },
        { value: "multi_choice", name: this.$t("resources.questions.types.3") },
        { value: "gap_text", name: this.$t("resources.questions.types.4") },
        { value: "estimation", name: this.$t("resources.questions.types.5") },
      ],
      language: this.$i18n.locale,
      valid: false,
      question: null,
      category_validation: false,
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return (categories = categories.sort((a, b) =>
        a.name.localeCompare(b.name)
      ));
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.questions.plural"),
          disabled: false,
          to: { name: "QuestionOverview" },
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
  methods: {
    ...mapActions("questions", ["add_question"]),
    ...mapActions("categories", ["fetch_categories"]),
    async fetchData() {
      await this.fetch_categories({ cancelToken: this.source.token });
    },
    setValid(val) {
      this.$refs.categoryform.validate();
      this.valid = val;
    },
    changeQuestiontype(val) {
      if (val === "estimation") {
        this.question = new QuestionEstimation();
        return false;
      }
      if (val === "single_choice") {
        this.question = new QuestionSingleChoice();
        return false;
      }
      if (val === "multi_choice") {
        this.question = new QuestionMultiChoice();
        return false;
      }
      if (val === "gap_text") {
        this.question = new QuestionGap();
        return false;
      }
      console.error("unknown question type");
    },
    async save() {
      let payload = this.question.getPostProperties();

      this.loading = true;
      let res = await this.add_question({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.$router.push({
          name: "QuestionSummary",
          params: { id: res.question_id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
  },
};
</script>
