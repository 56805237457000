<template>
  <div>
    <v-tooltip right color="primary">
      <template #activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <slot name="activator" :open-dialog="openDialog" />
        </div>
      </template>
      <span>
        {{ $t("gw.sessionDetail.detailView") }}
      </span>
    </v-tooltip>

    <v-dialog
      v-model="showDialog"
      scrollable
      @click:outside="closeDialog()"
      max-width="800"
    >
      <v-card v-if="showDialog" color="grey lighten-3" :loading="loading">
        <template #progress>
          <v-progress-linear indeterminate color="primary" height="2" />
        </template>
        <v-toolbar color="secondary" dark extension-height="40" height="40">
          <v-toolbar-title class="overline">{{
            journey ? journey.journey.name : $t("general.loading")
          }}</v-toolbar-title>

          <template #extension>
            <v-tabs v-model="tab">
              <v-tab class="caption" :disabled="loading">{{
                $t("kpi.overview")
              }}</v-tab>
              <v-tab class="caption" :disabled="loading">{{
                $t("kpi.monthly_view")
              }}</v-tab>
              <v-tab class="caption" :disabled="loading">{{
                $t("resources.users.plural")
              }}</v-tab>
            </v-tabs>
          </template>

          <v-spacer></v-spacer>

          <v-btn icon small @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <KpiJourneyMain :id="id" :stats="stats" @change-tab="changeTab" />
            </v-tab-item>
            <v-tab-item>
              <KpiJourneyTimed :id="id">
                <template #filter>
                  <v-row dense>
                    <v-col cols="12" sm="6" offset-sm="6" md="4" offset-md="8">
                      <v-select
                        :value="selectedDate"
                        :items="labels"
                        hide-details
                        solo
                        flat
                        dense
                        background-color="grey lighten-2"
                        @change="selectNewDate"
                      />
                    </v-col>
                  </v-row>
                </template>
              </KpiJourneyTimed>
            </v-tab-item>
            <v-tab-item>
              <KpiJourneyUser :id="id" />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import KpiJourneyMain from "@/components/kpi/dialogs/journey/KpiJourneyMain";
import KpiJourneyTimed from "@/components/kpi/dialogs/journey/KpiJourneyTimed";
import KpiJourneyUser from "@/components/kpi/dialogs/journey/KpiJourneyUser";

export default {
  name: "StatisticsDialogJourney",
  components: {
    KpiJourneyMain,
    KpiJourneyTimed,
    KpiJourneyUser,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    stats: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: true,
      tab: 0,
      selectedDate: "",
    };
  },
  computed: {
    ...mapGetters("kpi", {
      journey: "get_kpi_journey",
    }),
  },
  watch: {
    selectedDate: {
      handler: function () {
        this.fetchStatistics();
      },
    },
    tab: {
      handler: function (v) {
        if (v === 0) {
          this.selectedDate = "";
          this.fetchStatistics();
        }
        if (v === 1) {
          this.selectedDate = this.labels[this.labels.length - 1];
          this.fetchStatistics();
        }
      },
    },
  },
  methods: {
    ...mapActions("kpi", ["fetch_kpi_journey"]),
    ...mapMutations("kpi", ["set_kpi_journey"]),
    openDialog() {
      this.showDialog = true;
      this.fetchStatistics();
    },
    closeDialog() {
      this.showDialog = false;
      this.tab = 0;
      this.set_kpi_journey(null);
    },
    async fetchStatistics() {
      if (!this.id || !this.showDialog) return false;
      this.loading = true;
      var payload = {
        id: this.id,
      };
      if (this.selectedDate) {
        payload.date_start = this.selectedDate.concat("-01");
      }
      await this.fetch_kpi_journey({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;
    },
    changeTab(v) {
      this.tab = v;
    },
    selectNewDate(v) {
      this.selectedDate = v;
    },
  },
};
</script>
