<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            prominent
            dense
            class="mb-0"
            :class="{ 'black--text': question.status_color === 'warning' }"
            :color="question.status_color"
          >
            <v-row>
              <v-col cols="12">
                <div v-if="question.status === 0" class="caption">
                  {{ $t("ng.question_submission_new") }}
                </div>
                <div
                  v-if="question.status === 2 && !question.user"
                  class="caption"
                >
                  {{ $t("ng.question_submission_review") }}
                </div>
                <div v-if="question.status === 3" class="caption">
                  {{ $t("ng.question_submission_edit") }}
                </div>
                <small
                  class="caption font-weight-light"
                  v-html="$t('ng.question_submission_hint')"
                />
              </v-col>
            </v-row>
            <v-row v-if="[2].includes(question.status)" class="mt-2 mb-1">
              <v-col cols="12" sm="4">
                <v-btn
                  block
                  depressed
                  small
                  color="error"
                  @click="changeStatus(-1)"
                >
                  {{ $t("resources.questions.review.reject") }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn
                  block
                  depressed
                  small
                  color="secondary"
                  @click="changeStatus(3)"
                >
                  {{ $t("resources.questions.review.needs_edition") }}
                </v-btn>
              </v-col>
              <v-col cols="12" sm="4">
                <v-btn
                  block
                  depressed
                  small
                  color="success"
                  @click="changeStatus(1)"
                >
                  {{ $t("resources.questions.review.accept") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="[2, 3].includes(question.status)">
      <v-row>
        <v-col cols="12">
          <v-card color="grey lighten-3" tile flat>
            <v-card-text>
              <div>
                {{ $t("ng.question_submission_comment_descr") }}
              </div>
              <v-expansion-panels flat accordion class="mt-4">
                <v-expansion-panel>
                  <v-expansion-panel-header dense class="grey lighten-2">
                    <span class="caption"
                      >{{ $t("general.comments") }} ({{
                        question.comments.length
                      }})</span
                    >
                    <!-- <template v-slot:actions>
                      <v-icon color="red">
                        mdi-chevron-down
                      </v-icon>
                    </template> -->
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-2 grey lighten-2">
                    <CommentSection :comments="question.comments" />
                    <v-textarea
                      v-model="newComment"
                      outlined
                      background-color="white"
                      no-resize
                      :label="$t('general.comment_placeholder')"
                      class="mt-4"
                      :placeholder="$t('general.comment_placeholder')"
                    />

                    <div class="d-flex align-center justify-end">
                      <v-btn
                        :disabled="!newComment"
                        class="ml-2"
                        small
                        text
                        color="success"
                        @click="addComment()"
                      >
                        {{ $t("general.to_comment") }}
                      </v-btn>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { QuestionSingleChoice } from "@/models/content/questions/types/QuestionSingleChoice";
import { QuestionMultiChoice } from "@/models/content/questions/types/QuestionMultiChoice";
import { QuestionGap } from "@/models/content/questions/types/QuestionGap";
import { QuestionEstimation } from "@/models/content/questions/types/QuestionEstimation";
import CommentSection from "@/components/_layout/CommentSection";

export default {
  name: "QuestionSubmission",
  components: {
    CommentSection,
  },
  props: {
    question: {
      type: [
        QuestionSingleChoice,
        QuestionMultiChoice,
        QuestionGap,
        QuestionEstimation,
      ],
      required: true,
    },
  },
  data() {
    return {
      newComment: "",
    };
  },
  computed: {
    ...mapGetters("questions", { language: "get_selected_language" }),
  },
  methods: {
    ...mapActions("questions", ["patch_question", "add_question_comment"]),
    async changeStatus(status) {
      this.loading = true;

      let properties = [{ name: "status", value: status }];

      let payload = {
        id: this.question.id,
        language: this.language,
        properties: properties,
      };

      let res = await this.patch_question({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateSuccess"
          )}`,
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateError"
          )}`,
        });
      }
    },
    async addComment() {
      this.loading = true;

      let payload = {
        question_id: this.question.id,
        language: this.language,
        message: this.newComment,
      };
      let res = await this.add_question_comment(payload, {
        cancelToken: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
  },
};
</script>
