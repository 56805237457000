<template>
  <div>
    <section>
      <SectionHeader
        :title="$t('resources.journeys.bundle.singular')"
        :subtitle="$t('resources.journeys.sectionHeaders.generalInfo.subtitle')"
      >
        <!-- <template #action>
          <v-btn
            x-small
            depressed
            color="secondary"
            :to="{ name: 'JourneyBundles', params: { journey_id: journey.id } }"
          >
            {{ $t("general.toOverview") }}
          </v-btn>
        </template> -->
        <template #action>
          <v-btn
            v-if="['superadmin', 'admin', 'editor'].includes(role)"
            color="secondary"
            depressed
            x-small
            @click="toggleEditBundleMode"
          >
            {{ editBundleMode ? $t("general.back") : $t("general.edit") }}
          </v-btn>
        </template>
      </SectionHeader>
      <v-container v-if="bundle" class="mt-2">
        <v-card color="transparent" elevation="0">
          <!-- <v-btn
            v-if="
              ['unpublished'].includes(bundle.journey.status) &&
              ['superadmin', 'admin', 'editor'].includes(role) &&
              !editBundleMode
            "
            fab
            color="primary"
            top
            right
            x-small
            absolute
            @click="toggleEditBundleMode"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn> -->
          <v-card-text class="py-0">
            <v-row v-if="!editBundleMode">
              <v-col cols="12" sm="4">
                <v-img :src="bundle.getImage()" />
                <LanguageChooserInline
                  class="mt-2"
                  hidedetails
                  small
                  :initial="language"
                  @change="changeLanguage"
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-row dense>
                  <v-col cols="12">
                    <h5 class="secondary--text">
                      {{ $t("resources.journeys.propNames.name") }}
                    </h5>
                    <p>
                      <span>{{ bundle.name }}</span>
                      <v-progress-circular
                        v-show="loading"
                        indeterminate
                        width="1"
                        size="12"
                        color="primary"
                        class="ml-2"
                      />
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <h5 class="secondary--text">
                      {{ $t("resources.journeys.propNames.description") }}
                    </h5>
                    <p>{{ bundle.description }}</p>
                  </v-col>
                  <v-col cols="12">
                    <h5 class="secondary--text">
                      {{ $t("resources.journeys.quiz_criteria") }}
                    </h5>
                    <p>
                      <span>{{
                        bundle.quiz_criteria ? bundle.quiz_criteria : "-"
                      }}</span>
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <JourneybundleSettingsForm
              v-else
              :bundle="bundle"
              @update="toggleEditBundleMode"
            />
          </v-card-text>
        </v-card>
        <v-alert
          v-if="
            hasQuizzes &&
            bundle.quiz_criteria === 0 &&
            ['unpublished'].includes(journey.status) &&
            ['superadmin', 'admin', 'editor'].includes(role)
          "
          color="error"
          border="left"
          text
          dense
          class="mt-2 caption"
        >
          <span>{{ $t("resources.journeys.no_criteria_value") }}</span>
          <a
            class="ml-2 font-weight-bold alert-link"
            @click="toggleEditBundleMode"
            >{{ $t("general.update") }}</a
          >
        </v-alert>
      </v-container>
    </section>

    <section
      v-if="
        ['unpublished'].includes(journey.status) &&
        ['superadmin', 'admin', 'editor'].includes(role)
      "
      class="mt-4"
    >
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.addSteps.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.addSteps.subtitle')"
      />
      <v-container v-if="bundle">
        <v-card color="transparent" elevation="0">
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="12" class="py-0 d-flex justify-space-around">
                <v-btn
                  small
                  depressed
                  class="mr-2"
                  color="primary"
                  :disabled="!['unpublished'].includes(journey.status)"
                  @click="showNuggetDialog = true"
                >
                  {{ $t("ng.manage_nuggets") }}
                </v-btn>
                <v-btn
                  small
                  depressed
                  color="primary"
                  :disabled="!['unpublished'].includes(journey.status)"
                  @click="showQuizDialog = true"
                >
                  {{ $t("resources.journeys.addQuiz") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </section>

    <section v-if="bundle" class="mt-4">
      <SectionHeader
        :title="$t('resources.journeys.step.plural')"
        :subtitle="$t('resources.journeys.sectionHeaders.editSteps.subtitle')"
      />
      <v-container v-if="!bundle || bundle.steps.length == 0">
        <v-row>
          <v-col cols="12">
            <v-alert color="secondary" type="info" dense text>
              <span class="body-2"
                >{{ $t("resources.journeys.noStepYet") }}.</span
              >
            </v-alert>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="bundle && bundle.steps.length > 0" class="pt-0">
        <v-card color="transparent" elevation="0">
          <v-card-text class="pa-0">
            <v-row>
              <v-col cols="12">
                <SortableList
                  :data="bundle.steps"
                  :disabled="
                    !['unpublished'].includes(journey.status) ||
                    loading ||
                    !['superadmin', 'admin', 'editor'].includes(role)
                  "
                  @changed="changeOrder"
                >
                  <template #listelement="{ item }">
                    <v-sheet
                      :elevation="journey.status === 'unpublished' ? 1 : 0"
                    >
                      <v-list-item
                        class="mb-2 sorter-list-item test"
                        dense
                        :class="{
                          inactive:
                            journey.status !== 'unpublished' ||
                            !['superadmin', 'admin', 'editor'].includes(role),
                        }"
                      >
                        <v-list-item-avatar size="44">
                          <v-icon small class="mr-1">
                            mdi-swap-vertical-bold
                          </v-icon>
                          <!-- <span class="mr-1 caption font-weight-bold secondary--text">{{ index + 1 }}</span> -->
                          <v-icon small>
                            {{
                              item.type === "nugget"
                                ? "mdi-school"
                                : "mdi-comment-question"
                            }}
                          </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>
                            <span v-if="item.type === 'nugget'">{{
                              item.nugget.title
                            }}</span>
                            <span v-else>{{
                              $t("resources.journeys.question_count", {
                                count: item.quiz.question_count,
                              })
                            }}</span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              $t(
                                "resources.journeys.stepTypes." +
                                  item.type +
                                  ".singular"
                              )
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action
                          v-if="
                            ['superadmin', 'admin', 'editor'].includes(role)
                          "
                        >
                          <div>
                            <v-btn
                              v-if="item.type == 'quiz'"
                              icon
                              small
                              :disabled="
                                !['unpublished'].includes(journey.status)
                              "
                              @click="editQuiz(item)"
                            >
                              <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn
                              v-if="item.type == 'nugget'"
                              icon
                              small
                              :disabled="
                                !['unpublished'].includes(journey.status)
                              "
                              @click="showNuggetDialog = true"
                            >
                              <v-icon small> mdi-pencil </v-icon>
                            </v-btn>
                            <v-btn
                              icon
                              small
                              color="error"
                              :disabled="
                                !['unpublished'].includes(journey.status)
                              "
                              @click="removeStep(item)"
                            >
                              <v-icon small> mdi-close </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </v-sheet>
                  </template>
                </SortableList>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-container>
    </section>

    <section
      v-if="
        ['unpublished'].includes(journey.status) &&
        ['superadmin', 'admin', 'editor'].includes(role)
      "
      class="mt-4"
    >
      <SectionHeader
        :title="$t('resources.journeys.sectionHeaders.editActions.title')"
        :subtitle="$t('resources.journeys.sectionHeaders.editActions.subtitle')"
      />
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="error"
              :disabled="
                !['unpublished', 'pushlished'].includes(journey.status)
              "
              small
              depressed
              class="ml-0"
              @click="openDeleteDialog"
            >
              {{ $t("resources.journeys.deleteBundle") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <NuggetSelector
      :show-dialog="showNuggetDialog"
      :already-in-use="bundleNuggets"
      @close="closeNuggetDialog"
    />

    <QuizCreator
      v-if="showQuizDialog"
      :show-dialog="showQuizDialog"
      :quiz="editableQuiz"
      @close="closeQuizDialog"
    />

    <JourneybundleDialogDelete
      :bundle="bundle"
      :journey="journey"
      :dialog="showDeleteDialog"
      @close="closeDeleteDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import SortableList from "@/components/global/SortableList.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import NuggetSelector from "@/components/journeys/NuggetSelector";
import QuizCreator from "@/components/journeys/QuizCreator";
import JourneybundleDialogDelete from "@/components/journeys/dialogs/JourneybundleDialogDelete";
import JourneybundleSettingsForm from "@/components/journeys/forms/JourneybundleSettingsForm";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";

export default {
  name: "JourneyBundle",
  components: {
    NuggetSelector,
    QuizCreator,
    SortableList,
    SectionHeader,
    JourneybundleDialogDelete,
    JourneybundleSettingsForm,
    LanguageChooserInline,
  },
  data() {
    return {
      showNuggetDialog: false,
      showQuizDialog: false,
      stepOrderChanged: false,
      showDeleteDialog: false,
      editBundleMode: false,
      steps: [],
      id: 0,
      editableQuiz: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("journeys", {
      journey: "get_journey",
      bundle: "get_bundle",
      language: "get_selected_language",
    }),
    bundleNuggets() {
      if (!this.bundle) return [];
      let bundles = [];
      this.bundle.steps.forEach((step) => {
        if (step.type === "nugget") bundles.push(step);
      });
      return bundles;
    },
    hasQuizzes() {
      if (!this.bundle) return false;
      return this.bundle.steps.some((step) => step.type === "quiz");
    },
  },
  watch: {
    language: {
      handler: function () {
        this.loading = true;
        setTimeout(() => {
          this.fetchData();
          this.loading = false;
        }, 1000);
      },
    },
  },
  beforeMount() {
    this.id = this.$route.params.bundle_id;
  },
  methods: {
    ...mapActions("journeys", [
      "add_bundle_steps",
      "move_bundle_steps",
      "fetch_bundle",
      "fetch_journey",
      "delete_bundle_step",
      "patch_bundle_step",
    ]),
    ...mapMutations("journeys", ["set_selected_language"]),
    closeNuggetDialog(data) {
      this.showNuggetDialog = false;
      if (data && data.length > 0) this.addSteps(data, "nuggets");
    },
    closeQuizDialog(data) {
      this.showQuizDialog = false;
      if (this.editableQuiz && data) this.updateQuiz(this.editableQuiz, data);
      if (!this.editableQuiz && data && data.length > 0)
        this.addSteps(data, "quiz");
      this.editableQuiz = null;
    },
    closeDeleteDialog(wasDeleted) {
      this.showDeleteDialog = false;
      if (wasDeleted)
        this.$router.push({
          name: "JourneyBundles",
          params: { id: this.journey.id },
        });
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    editQuiz(item) {
      this.editableQuiz = item;
      this.showQuizDialog = true;
    },
    async fetchData() {
      if (this.id && this.source) {
        await this.fetch_bundle({
          id: this.id,
          language: this.language,
          cancelToken: this.source.token,
        });
      }
    },
    async fetchJourney() {
      await this.fetch_journey({
        id: this.journey.id,
        language: this.language,
        cancelToken: this.source.token,
      });
      this.fetchData();
    },
    async addSteps(steps, type) {
      this.loading = true;
      let apiSteps = [];
      let payload = { bundle_id: this.bundle.id, journey_id: this.journey.id };
      let order = this.editableQuiz
        ? this.bundle.steps.length
        : this.bundle.steps.length + 1;
      steps.forEach((step) => {
        let apiStep = { order: order };
        if (type == "nuggets") {
          apiStep.type_id = 1;
          apiStep.nugget_id = step.id;
        }
        if (type == "quiz") {
          apiStep.type_id = 2;
          apiStep.question_id = step.id;
        }
        apiSteps.push(apiStep);
        if (type == "nuggets") order++;
      });
      payload.steps = apiSteps;

      let res = await this.add_bundle_steps({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("general.errors.create_failed"),
        });
      }

      this.loading = false;
    },
    async removeStep(step) {
      let payload = {
        bundle_id: this.bundle.id,
        step_id: step.id,
      };
      let res = await this.delete_bundle_step({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res) {
        this.fetchJourney();
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async updateQuiz(step, questions) {
      let question_ids = [];
      questions.forEach((question) => question_ids.push(question.id));
      let payload = {
        bundle_id: this.bundle.id,
        step_id: step.id,
        question_ids: question_ids,
      };
      let res = await this.patch_bundle_step({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    changeOrder(steps) {
      let changed = false;
      steps.forEach((step, index) => {
        if (step.id !== this.bundle.steps[index].id) changed = true;
      });
      this.steps = steps;
      this.stepOrderChanged = changed;

      if (changed) this.moveSteps();
    },
    async moveSteps() {
      this.loading = true;
      let apiSteps = [];
      this.steps.forEach((step, index) => {
        apiSteps.push({ id: step.id, order: index + 1 });
      });
      let payload = {
        bundle_id: this.bundle.id,
        steps: apiSteps,
      };
      let res = await this.move_bundle_steps({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.journeys.moveSuccess"),
        });
        this.stepOrderChanged = false;
        this.$emit("update");
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.journeys.moveError"),
        });
      }
      this.loading = false;
    },
    toggleEditBundleMode() {
      this.editBundleMode = !this.editBundleMode;
    },
    async changeLanguage(lang) {
      this.set_selected_language(lang);
    },
  },
};
</script>

<style>
.alert-link {
  color: #f34335 !important;
}
.sorter-list-item {
  border-left: 4px solid #617d8b;
  background: white;
  cursor: move;
}
.test.sorter-list-item.inactive {
  opacity: 0.75;
  cursor: no-drop;
}
</style>
