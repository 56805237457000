<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.journeys.new')"
      icon="mdi-comment-question"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          color="grey lighten-2"
          exact
          :to="{ name: 'JourneyCurrent' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-form ref="addJourneyForm" v-model="valid">
        <SectionHeader
          :title="$t('resources.journeys.sectionHeaders.editJourney.title')"
          :subtitle="
            $t('resources.journeys.sectionHeaders.editJourney.subtitle')
          "
        />
        <v-container>
          <v-row dense>
            <v-col v-if="$i18n.availableLocales.length > 1" cols="12">
              <LanguageChooserInline
                :label="$t('ng.source_language')"
                :hint="$t('ng.source_language_hint')"
                persistent-hint
                background-color="white"
                colored
                @change="changeLanguage"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="input.name"
                :counter="64"
                :rules="rules.name"
                required
                dense
                outlined
                background-color="white"
                :label="$t('resources.journeys.propNames.name')"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="input.description"
                :counter="200"
                :rules="rules.description"
                required
                no-resize
                dense
                outlined
                background-color="white"
                :label="$t('resources.journeys.propNames.description')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <ImageUpload
                :image-hash="input.image_token"
                :disabled="!['superadmin', 'admin', 'editor'].includes(role)"
                @image-changed="imageChange"
              />
              <span class="caption grey--text text--darken-2">{{
                $t("ng.journey_hints.image")
              }}</span>
            </v-col>
          </v-row>

          <v-divider class="my-8" />

          <v-row dense>
            <v-col cols="12" sm="6">
              <v-select
                v-model="input.attempts"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9]"
                outlined
                :label="$t('resources.journeys.propNames.attempts')"
                dense
                background-color="white"
                :hint="$t('ng.journey_hints.attempts')"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model.number="input.coins"
                :rules="rules.coins"
                required
                dense
                background-color="white"
                type="number"
                outlined
                :label="$t('resources.journeys.propNames.coins')"
                :hint="$t('ng.journey_hints.coins')"
                persistent-hint
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-dialog
                ref="startAtPicker"
                v-model="startAtPicker"
                :return-value.sync="input.start_at"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="input.start_at"
                    readonly
                    v-bind="attrs"
                    dense
                    background-color="white"
                    outlined
                    :label="$t('resources.journeys.propNames.start_at')"
                    :hint="$t('ng.journey_hints.start_at')"
                    persistent-hint
                    v-on="on"
                  >
                    <template #append>
                      <v-btn
                        icon
                        small
                        :disabled="!input.start_at"
                        @click="removeStartDate()"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="input.start_at"
                  scrollable
                  :min="minDate"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="startAtPicker = false">
                    {{ $t("general.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.startAtPicker.save(input.start_at)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="6">
              <v-dialog
                ref="endAtPicker"
                v-model="endAtPicker"
                :return-value.sync="input.end_at"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="input.end_at"
                    readonly
                    v-bind="attrs"
                    dense
                    outlined
                    background-color="white"
                    :label="$t('resources.journeys.propNames.end_at')"
                    :hint="$t('ng.journey_hints.end_at')"
                    persistent-hint
                    :disabled="!input.start_at"
                    v-on="on"
                  >
                    <template #append>
                      <v-btn
                        icon
                        small
                        :disabled="!input.end_at"
                        @click="removeEndDate()"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="input.end_at"
                  scrollable
                  :min="endAtMin"
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="endAtPicker = false">
                    {{ $t("general.cancel") }}
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.endAtPicker.save(input.end_at)"
                  >
                    {{ $t("general.apply") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>

        <SectionHeader
          :title="$t('resources.journeys.sectionHeaders.editCertificate.title')"
          :subtitle="
            $t('resources.journeys.sectionHeaders.editCertificate.subtitle')
          "
        />

        <v-container>
          <v-card color="grey lighten-3" outlined>
            <v-card-text>
              <v-row align="center">
                <v-col cols="12" sm="6">
                  <!-- <h5 class="secondary--text">
                    {{ $t('resources.journeys.propNames.cert_name') }}
                  </h5> -->
                  <v-select
                    v-model="input.certificate"
                    :items="visibleCertificates"
                    item-value="id"
                    outlined
                    background-color="white"
                    dense
                    hide-details
                    return-object
                  >
                    <template #item="{ item }">
                      <div class="d-flex align-center">
                        <span>
                          {{ item.name }}
                        </span>
                      </div>
                    </template>
                    <template #selection="{ item }">
                      <v-list-item>
                        <v-list-item-content>
                          {{ item.name }}
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    :to="{ name: 'JourneyCertificates' }"
                    depressed
                    small
                    color="primary"
                    target="_blank"
                  >
                    <v-icon small class="mr-2"> mdi-magnify </v-icon>
                    <span>{{ $t("certificate.show_certificates") }}</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>

        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn color="error" class="mr-4" small depressed @click="reset">
                {{ $t("general.clear") }}
              </v-btn>

              <v-btn
                color="success"
                :disabled="!valid"
                small
                depressed
                @click="addJourney"
              >
                {{ $t("general.add") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";
import ImageUpload from "@/components/global/ImageUpload";

export default {
  name: "JourneyAdd",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    LanguageChooserInline,
    ImageUpload,
  },
  data() {
    return {
      loading: false,
      input: {
        name: "",
        description: "",
        image: "",
        image_token: "",
        attempts: 3,
        coins: 0,
        start_at: "",
        end_at: "",
        language: this.$i18n.locale,
      },
      minDate: new Date(new Date().valueOf() + 1000 * 3600 * 24)
        .toISOString()
        .substr(0, 10),
      valid: true,
      startAtPicker: false,
      endAtPicker: false,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
            }),
          (v) =>
            (v && v.length <= 64) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t("resources.journeys.propNames.name"),
              max: 64,
            }),
        ],
        description: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
            }),
          (v) =>
            (v && v.length <= 200) ||
            this.$t("forms.rules.maxCharacters", {
              inputFieldName: this.$t(
                "resources.journeys.propNames.description"
              ),
              max: 200,
            }),
        ],
        coins: [
          (v) =>
            (typeof v === "number" && v >= 0 && v <= 10000) ||
            this.$t("forms.rules.inBetween", {
              inputFieldName: this.$t("resources.journeys.propNames.coins"),
              min: 0,
              max: 10000,
            }),
        ],
      },
    };
  },
  computed: {
    ...mapGetters("journeys", { certificates: "get_certificates" }),
    endAtMin() {
      if (this.input.start_at)
        return new Date(
          new Date(this.input.start_at).valueOf() + 1000 * 3600 * 24
        )
          .toISOString()
          .substr(0, 10);
      return this.minDate;
    },
    visibleCertificates() {
      if (!this.certificates) return [];
      var certificates = [...this.certificates];
      return certificates.sort((a, b) => a.name.localeCompare(b.name));
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("resources.journeys.journey.plural"),
          disabled: false,
          to: { name: "JourneyCurrent" },
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager", "editor"]);
  },
  destroyed() {
    this.set_certificates([]);
  },
  methods: {
    ...mapActions("journeys", ["add_journey", "fetch_certificates"]),
    ...mapMutations("journeys", ["set_certificates"]),
    validate() {
      this.$refs.addJourneyForm.validate();
    },
    reset() {
      this.$refs.addJourneyForm.reset();
    },
    resetValidation() {
      this.$refs.addJourneyForm.resetValidation();
    },
    async fetchData() {
      this.loading = true;
      await this.fetch_certificates({ cancelToken: this.source.token });
      this.setDefaultCertificate();
      this.loading = false;
    },
    async addJourney() {
      if (!this.valid) return false;

      this.input.start_at = this.input.start_at ? this.input.start_at : null;
      this.input.end_at = this.input.end_at ? this.input.end_at : null;
      this.input.certificate_id = this.input.certificate.id;
      //this.input.language = 'de-DE';

      let res = await this.add_journey({
        payload: this.input,
        config: { cancelToken: this.source.token },
      });
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.createSuccess"),
        });
        this.$router.push({
          name: "JourneySummary",
          params: { id: res.journey_id },
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.createError"),
        });
      }
    },
    removeStartDate() {
      this.input.start_at = "";
      this.input.end_at = "";
    },
    removeEndDate() {
      this.input.end_at = "";
    },
    changeLanguage(val) {
      this.input.language = val;
    },
    setDefaultCertificate() {
      var default_certificate = [...this.certificates].filter(
        (c) => c.is_default
      )[0];
      if (default_certificate) this.input.certificate = default_certificate;
    },
    imageChange(image) {
      this.input.image_token = image.hash;
      this.input.image = image.filename;
    },
  },
};
</script>

<style></style>
