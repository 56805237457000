<template>
  <div>
    <v-combobox
      v-model="selectedGroups"
      :filter="filter"
      :hide-no-data="!search"
      :items="items"
      item-text="name"
      :search-input.sync="search"
      hide-selected
      multiple
      :disabled="loading"
      outlined
      :dense="dense"
      background-color="white"
      :hint="$t('ng.category_group_hint')"
      persistent-hint
      :label="$t('ng.category_groups')"
      small-chips
      @change="setGroups"
    >
      <template v-slot:no-data>
        <v-list-item>
          <span class="subheading mr-2"
            >{{ $t("resources.journeys.new_status") }}:</span
          >
          <v-chip color="primary" label small>
            {{ search }}
          </v-chip>
          <v-spacer />
          <v-list-item-action right @click.stop>
            <v-btn icon color="success" @click.stop.prevent="addGroup()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </template>
      <template v-slot:selection="{ attrs, item, parent, selected }">
        <v-chip
          v-if="item === Object(item)"
          v-bind="attrs"
          color="primary"
          :input-value="selected"
          label
          small
        >
          <span class="pr-2">
            {{ item.name }}
          </span>
          <v-icon small @click="parent.selectItem(item)">
            mdi-close-circle
          </v-icon>
        </v-chip>
      </template>
      <template v-slot:item="{ index, item }">
        <v-text-field
          v-if="editing === item"
          v-model="editing.name"
          autofocus
          background-color="transparent"
          hide-details
          @keyup.enter="edit(index, item)"
        />
        <v-chip v-else :color="`${item.color} lighten-3`" dark label small>
          {{ item.name }}
        </v-chip>
        <v-spacer />
        <v-list-item-action @click.stop>
          <v-btn icon @click.stop.prevent="edit(index, item)">
            <v-icon>{{ editing !== item ? "mdi-pencil" : "mdi-check" }}</v-icon>
          </v-btn>
        </v-list-item-action>
      </template>
    </v-combobox>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "CategorygroupSelector",
  props: {
    input: {
      type: Array,
      required: false,
      default: () => [],
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      editing: null,
      editingIndex: -1,
      search: null,
      loading: false,
      groups: [],
    };
  },
  computed: {
    ...mapGetters("categories", { categorygroups: "get_categorygroups" }),
    items() {
      let groups = [{ header: this.$t("ng.add_or_select_group") }];
      if (this.categorygroups) {
        this.categorygroups.forEach((group) =>
          groups.push({
            id: group.id,
            name: group.name,
            text: group.name,
            categories: group.categories,
          })
        );
      }
      return groups;
    },
    selectedGroups: {
      set(value) {
        this.groups = value;
      },
      get() {
        return this.groups;
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    if (this.input) this.selectedGroups = this.input;
  },
  methods: {
    ...mapActions("categories", [
      "fetch_categorygroups",
      "patch_categorygroup",
      "add_categorygroup",
    ]),
    ...mapMutations("categories", ["set_categorygroups"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_categorygroups({ cancelToken: this.source.token });
      this.loading = false;
    },
    unloadData() {
      this.set_categorygroups(null);
    },
    edit(index, item) {
      if (!this.editing) {
        this.editing = item;
        this.editingIndex = index;
      } else {
        this.patchGroup(item);
        this.editing = null;
        this.editingIndex = -1;
      }
    },
    filter(item, queryText, itemName) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const name = hasValue(itemName);
      const query = hasValue(queryText);

      return (
        name.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    setGroups() {
      let ids = [];
      this.selectedGroups.forEach((group) => ids.push(group.id));
      this.$emit("changed-groups", ids);
    },
    async patchGroup(group) {
      let category_ids = [];
      if (group.categories.length > 0)
        group.categories.forEach((c) => category_ids.push(c.id));
      let properties = [
        { name: "name", value: group.name },
        { name: "description", value: "" },
        {
          name: "category_ids",
          value: category_ids.length === 0 ? null : category_ids,
        },
      ];

      let payload = {
        properties: properties,
        override: true,
        language: this.$i18n.locale,
      };
      let res = await this.patch_categorygroup({
        id: group.id,
        payload: payload,
        config: { cancelToken: this.source.token },
      });

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        return true;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: `${this.$t("general.updateError")}`,
        });
        return false;
      }
    },
    async addGroup() {
      let payload = {
        name: this.search,
        description: "",
        language: this.$i18n.locale,
      };
      let res = await this.add_categorygroup({
        payload: payload,
        config: { cancelToken: this.source.token },
      });

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.groups.push({ id: res.group_id, name: this.search });
        this.search = "";
        this.setGroups();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: `${this.$t("general.updateError")}`,
        });
      }
    },
  },
};
</script>
