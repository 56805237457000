<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="640"
      scrollable
      @click:outside="closeDialog()"
    >
      <v-card v-if="showDialog" color="grey lighten-3" max-width="640">
        <v-toolbar color="secondary" dark extension-height="40" height="40">
          <v-toolbar-title class="overline">{{
            $t("gw.licences.singular_gw")
          }}</v-toolbar-title>
          <v-fade-transition>
            <v-progress-circular
              v-show="loading"
              indeterminate
              size="16"
              width="2"
              class="ml-2"
            />
          </v-fade-transition>

          <v-spacer></v-spacer>

          <v-btn icon small @click="closeDialog()">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>

          <template #extension>
            <v-tabs v-model="tab">
              <v-tab class="caption" :disabled="loading">{{
                $t("kpi.overview")
              }}</v-tab>
              <v-tab class="caption" :disabled="loading">{{
                $t("gw.licences.plural")
              }}</v-tab>
            </v-tabs>
          </template>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="licence.name"
                      :subtitle="$t('resources.categories.name')"
                      icon="mdi-file-document"
                      color="grey lighten-2"
                      text-color="secondary"
                      icon-color="secondary"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="licence.credits_count"
                      :subtitle="$t('gw.licences.credits_count')"
                      icon="mdi-circle-multiple"
                      color="grey lighten-2"
                      text-color="secondary"
                      icon-color="secondary"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="
                        licence.credits_keep
                          ? $t('general.yes')
                          : $t('general.no')
                      "
                      :subtitle="'credits_keep'"
                      :icon="
                        licence.credits_keep
                          ? 'mdi-hand-coin'
                          : 'mdi-hand-coin-outline'
                      "
                      color="grey lighten-2"
                      text-color="secondary"
                      icon-color="secondary"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="
                        licence.can_overload
                          ? `${$t(
                              'general.yes'
                            )} (${licence.credits_overload.toLocaleString()})`
                          : $t('general.no')
                      "
                      :subtitle="$t('gw.licences.can_overload')"
                      :icon="licence.can_overload ? 'mdi-bank' : 'mdi-bank-off'"
                      color="grey lighten-2"
                      text-color="secondary"
                      icon-color="secondary"
                    />
                  </v-col>
                </v-row>

                <!-- <v-divider class="my-4" /> -->

                <v-row dense>
                  <v-col cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="
                        !licence.is_superadmin
                          ? licence.licences_total
                          : $t('gw.licences.unlimited')
                      "
                      :subtitle="$t('gw.licences.plural')"
                      icon="mdi-account-group"
                      color="secondary"
                      click-type="dialog"
                      @clicked="tab = 1"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="
                        !licence.is_superadmin
                          ? licence.available_licence_count
                          : licence.licences_used
                      "
                      :subtitle="
                        !licence.is_superadmin
                          ? $t('gw.licences.available_licences')
                          : $t('gw.licences.assigned_licences')
                      "
                      icon="mdi-account-multiple-check"
                      :color="
                        licence.available_licence_count <= 0 &&
                        !licence.is_superadmin
                          ? 'error'
                          : 'primary'
                      "
                      click-type="dialog"
                      @clicked="tab = 1"
                      :disabled="loading"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="
                        !licence.is_superadmin
                          ? licence.available_licence_count
                          : $t('gw.licences.unlimited')
                      "
                      :subtitle="$t('gw.licences.available_licences')"
                      icon="mdi-account-multiple-check"
                      :color="
                        licence.available_licence_count <= 0 &&
                        !licence.is_superadmin
                          ? 'error'
                          : 'primary'
                      "
                      :disabled="loading"
                    />
                  </v-col>
                  <v-col v-if="!licence.is_superadmin" cols="12" sm="6">
                    <StatisticsCardSmall
                      :title="licence.licences_blocked"
                      :subtitle="$t('gw.licences.blocked_licences')"
                      icon="mdi-account-multiple-check"
                      color="grey lighten-2"
                      text-color="secondary"
                      icon-color="secondary"
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="new_user_id"
                      :label="$t('gw.licences.add_user_licence')"
                      :items="visibleUsers"
                      item-text="email"
                      item-value="id"
                      item-disabled="licence_disabled"
                      dense
                      outlined
                      clearable
                      hide-details
                      background-color="white"
                    >
                      <template #item="{ item, on, attrs }">
                        <v-list-item two-line v-on="on" v-bind="attrs">
                          <v-list-item-content>
                            <v-list-item-title>{{
                              item.email
                            }}</v-list-item-title>
                            <v-list-item-subtitle>{{
                              `${item.first_name} ${item.last_name} (${item.user_name})`
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action v-show="item.licence_disabled">
                            <v-list-item-action-text>{{
                              $t("gw.licences.user_has_licence")
                            }}</v-list-item-action-text>
                            <v-icon small>mdi-alert</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-btn
                      color="secondary"
                      depressed
                      block
                      :disabled="
                        (licence.available_licence_count <= 0 &&
                          !licence.is_superadmin) ||
                        !new_user_id
                      "
                      @click="addUserLicence()"
                      >{{ $t("general.add") }}</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>

              <v-divider class="my-6" />

              <GwUserLicenceTable
                :user-licences="licence.users"
                :licence="licence"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { GwLicence } from "@/models/ghostwriter/GwLicence";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";
import GwUserLicenceTable from "@/components/ghostwriter/GwUserLicenceTable";

export default {
  name: "GwLicenceDialog",
  components: {
    StatisticsCardSmall,
    GwUserLicenceTable,
  },
  props: {
    licence: {
      type: GwLicence,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      tab: 0,
      new_user_id: null,
    };
  },
  computed: {
    ...mapGetters("users", { users: "get_users" }),
    ...mapGetters("ghostwriter", { licences: "get_licences" }),
    visibleUsers() {
      if (!this.users || !this.licences) return [];
      var users = [...this.users];
      var licences_users = [];
      this.licences.forEach((l) => {
        l.users.forEach((u) => {
          licences_users.push(u);
        });
      });
      var user_id_arr = licences_users.map((u) => u.user_id);
      users.forEach((u) => {
        u.licence_disabled = !!user_id_arr.includes(u.id);
      });
      return users;
    },
  },
  methods: {
    ...mapActions("administration", ["add_gw_user_licence"]),
    ...mapActions("users", ["fetch_users"]),
    ...mapMutations("users", ["set_users"]),
    closeDialog() {
      this.showDialog = false;
      this.new_user_id = null;
      this.set_users([]);
    },
    openDialog() {
      this.showDialog = true;
      this.fetchUsers();
    },
    async fetchUsers() {
      this.loading = true;
      await this.fetch_users({ cancelToken: this.source.token });
      this.loading = false;
    },
    async addUserLicence() {
      this.loading = true;
      var payload = {
        licence_id: this.licence.id,
        user_id: this.new_user_id,
      };
      var res = await this.add_gw_user_licence({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("gw.licences.add_user_licence_success"),
        });
        this.new_user_id = null;
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("gw.licences.add_user_licence_error"),
        });
      }
    },
  },
};
</script>
