<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.codes.new')"
      icon="mdi-numeric"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'CodeOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="!substructures || !proposal" />

    <section v-if="substructures && proposal">
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card :loading="loading" flat color="transparent">
              <v-card-text class="px-0">
                <v-form v-model="valid">
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-if="!loading"
                          v-model="proposal"
                          :label="$t('resources.codes.code')"
                          :rules="codeRules"
                          required
                          outlined
                          dense
                          disabled
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          v-if="!loading"
                          v-model="usage_count"
                          :disabled="unlimitedUsageCount"
                          :rules="usageCountRules"
                          type="number"
                          outlined
                          dense
                          background-color="white"
                          :label="$t('resources.codes.usage_count.title')"
                        />
                      </v-col>
                      <v-col cols="4">
                        <v-checkbox
                          v-model="unlimitedUsageCount"
                          dense
                          :label="$t('resources.codes.unlimited_registrations')"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="assignableSubstructures"
                          :items="availableAssignableSubstructures"
                          item-text="name"
                          item-value="id"
                          attach
                          chips
                          outlined
                          dense
                          background-color="white"
                          :rules="substructureRules"
                          :label="
                            $t('resources.codes.assignable_substructures')
                          "
                          multiple
                          @change="checkSelectableStructures"
                        >
                          <template #item="{ item }">
                            {{ item.structure.name }} / {{ item.name }}
                          </template>
                          <template #selection="{ item }">
                            <v-chip small>
                              {{ substructureById(item.id).structure.name }} /
                              {{ item.name }}
                            </v-chip>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialStructures"
                            />
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-autocomplete
                          v-model="selectableStructures"
                          :items="availableSelectableStructures"
                          item-text="name"
                          item-value="id"
                          attach
                          chips
                          outlined
                          dense
                          background-color="white"
                          :label="$t('resources.codes.selectable_structures')"
                          multiple
                        >
                          <template #item="{ item }">
                            {{ item.name }}
                          </template>
                          <template #selection="{ item }">
                            <v-chip small>
                              {{ item.name }}
                            </v-chip>
                          </template>
                          <template #append-outer>
                            <TutorialCarouselDialog
                              :templates="tutorialSelectStructures"
                            />
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
              <v-card-actions
                :class="{ 'flex-column': $vuetify.breakpoint.xs }"
              >
                <v-spacer />
                <v-btn
                  v-if="!loading"
                  color="error"
                  text
                  small
                  @click="resetForm"
                >
                  {{ $t("general.clear") }}
                </v-btn>
                <v-btn
                  v-if="!loading"
                  color="success"
                  text
                  small
                  :disabled="!valid"
                  @click="saveForm"
                >
                  {{ $t("general.save") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import TutorialCarouselDialog from "@/components/tutorials/TutorialCarouselDialog.vue";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "CodeDialogAdd",
  components: {
    TutorialCarouselDialog,
    ViewHeader,
    ViewLoader,
    SectionHeader,
  },
  data() {
    return {
      inputs: {},
      valid: true,
      loading: false,
      unlimitedUsageCount: false,
      assignableSubstructures: [],
      selectableStructures: [],
      tutorialSelectStructures: ["selectstructures"],
      usage_count: 0,
      codeRules: [
        (v) =>
          (!!v && v.length >= 10) || this.$t("general.errors.not_enough_chars"),
      ],
      usageCountRules: [
        (v) => v >= -1 || this.$t("general.errors.invalid_value"),
      ],
      substructureRules: [
        (v) =>
          (!!v && v.length >= 1) || this.$t("general.errors.select_an_item"),
      ],
      tutorialStructures: ["structures"],
    };
  },
  computed: {
    ...mapGetters("codes", { proposal: "get_code_proposal" }),
    ...mapGetters("structures", {
      structures: "get_structures",
      substructures: "get_substructures",
    }),
    pre_select_structures() {
      return process.env.VUE_APP_PRE_SELECT_STRUCTURES == ""
        ? []
        : process.env.VUE_APP_PRE_SELECT_STRUCTURES.split(",");
    },
    availableAssignableSubstructures() {
      if (!this.structures) return [];
      return this.substructures.filter((substructure) => {
        return !this.pre_select_structures.includes(
          substructure.structure.id.toString()
        );
      });
    },
    availableSelectableStructures() {
      if (!this.structures) return [];
      return this.structures.filter((structure) => {
        if (structure.substructures.length === 0) return true;
        let check = true;
        structure.substructures.forEach((substructure) => {
          if (this.assignableSubstructures.includes(substructure.id))
            check = false;
        });
        return check;
      });
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: false,
          to: { name: "Management" },
        },
        {
          text: this.$t("resources.codes.plural"),
          disabled: false,
          to: { name: "CodeOverview" },
        },
      ];
    },
  },
  mounted() {
    if (this.pre_select_structures) {
      let select_structures = [];
      this.pre_select_structures.forEach((structure) =>
        select_structures.push(parseInt(structure))
      );
      this.selectableStructures = select_structures;
    }
  },
  /* created() {
    this.fetch_code_proposal();
  }, */
  methods: {
    ...mapActions("codes", ["add_code", "fetch_code_proposal"]),
    ...mapActions("structures", ["fetch_structures"]),
    ...mapMutations("codes", ["set_code_proposal"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_structures({ cancelToken: this.source.token });
      await this.fetch_code_proposal({ cancelToken: this.source.token });
      this.loading = false;
    },
    unloadData() {
      this.set_code_proposal(null);
      this.set_structures(null);
    },
    substructureById(id) {
      return this.substructures.filter((substructure) => {
        return substructure.id == id;
      })[0];
    },
    resetForm() {
      this.inputs = {};
      this.unlimitedUsageCount = false;
      this.assignableSubstructures = [];
      this.selectableStructures = [];
      this.usage_count = 0;
    },
    async saveForm() {
      this.loading = true;
      this.inputs.code = this.proposal;
      this.inputs.community_id = this.community.id;
      this.inputs.assign_substructure_ids = this.assignableSubstructures;
      this.inputs.select_structure_ids = this.selectableStructures;
      this.inputs.usage_count = this.unlimitedUsageCount
        ? -1
        : Number(this.usage_count);

      let res = await this.add_code({
        payload: this.inputs,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
      if (res.success) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text:
            this.$t("resources.codes.singular") +
            " " +
            this.$t("general.createSuccess"),
        });
        this.$router.push({ name: "CodeOverview" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    checkSelectableStructures() {
      this.assignableSubstructures.forEach((substructure_id) => {
        this.selectableStructures = this.selectableStructures.filter(
          (structure_id) => {
            return (
              structure_id !==
              this.substructureById(substructure_id).structure.id
            );
          }
        );
      });
    },
  },
};
</script>

<style></style>
