<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('resources.questions.singular')"
      icon="mdi-comment-question"
    >
      <template #titleButton>
        <v-btn
          x-small
          depressed
          exact
          color="grey lighten-2"
          :to="{ name: 'QuestionOverview' }"
        >
          {{ $t("general.toOverview") }}
        </v-btn>
      </template>
    </ViewHeader>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-container>
        <v-row>
          <v-col cols="12" md="3" class="pa-0 d-flex">
            <ViewNavigation :nav-items="navItems" class="flex-grow-1" />
          </v-col>
          <v-col cols="12" md="9">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import ViewNavigation from "@/components/_layout/ViewNavigation";

/* import SectionHeader from '@/components/_layout/SectionHeader'; */

export default {
  name: "QuestionOverview",
  components: {
    ViewHeader,
    ViewLoader,
    ViewNavigation,
  },
  data() {
    return {
      id: parseInt(this.$route.params.id),
      loading: false,
    };
  },
  computed: {
    ...mapGetters("questions", { question: "get_question" }),
    navItems() {
      if (!this.question) return [];
      return [
        {
          title: this.$t("resources.questions.navItems.overview"),
          link: { name: "QuestionSummary" },
        },
        {
          title: this.$t("resources.invitations.settings"),
          link: { name: "QuestionSettings" },
          disabled: false,
        },
        {
          title: this.$t("general.media"),
          link: { name: "QuestionMedia" },
          disabled: false,
        },
        {
          title: this.$t("resources.questions.explanation"),
          link: { name: "QuestionExplanation" },
          disabled: false,
        },
        {
          title: this.$t("resources.questions.navItems.reports"),
          link: { name: "QuestionReports" },
          alerts:
            this.question.conversations &&
            this.question.conversations.report_count > 0,
          disabled: false,
        },
        {
          title: this.$t("resources.nuggets.plural"),
          link: { name: "QuestionNuggets" },
          disabled: false,
        },
        /* { title: this.$t('resources.questions.navItems.history'), link: { name: 'QuestionHistory' }, disabled: true } */
      ];
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: "/dashboard",
        },
        {
          text: this.$t("navigation.content.title"),
          disabled: false,
          to: { name: "Content" },
        },
        {
          text: this.$t("resources.questions.plural"),
          disabled: false,
          to: { name: "QuestionOverview" },
        },
      ];
    },
  },
  watch: {
    question: {
      handler(v) {
        if (!v) this.$router.push({ name: "QuestionOverview" });
      },
      deep: true,
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
  },
  methods: {
    ...mapActions("questions", ["fetch_question"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("questions", ["set_question", "set_selected_language"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      this.set_selected_language(this.$i18n.locale);
      await this.fetch_question({
        id: this.id,
        language: this.$i18n.locale,
        cancelToken: this.source.token,
      });
      await this.fetch_categories({ cancelToken: this.source.token });
      this.loading = false;
    },
    unloadData() {
      this.set_question(null);
      this.set_categories(null);
    },
  },
};
</script>
