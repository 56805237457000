<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.journeys.title')"
      icon="mdi-map-marker-path"
    >
      <template #button>
        <v-btn
          v-show="addButtonType === 'journey'"
          key="one"
          color="success"
          fab
          dark
          small
          absolute
          bottom
          right
          style="transition: 0.4s"
          :to="{ name: 'JourneyAdd' }"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <AddCertificateDialog v-show="addButtonType === 'certificate'">
          <template #action="{ openDialog }">
            <v-btn
              fab
              small
              color="success"
              dark
              absolute
              bottom
              right
              @click="openDialog"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </AddCertificateDialog>
      </template>
      <template #extension>
        <ViewNavigation :nav-items="navItems" only-tabs class="flex-grow-1" />
      </template>
    </ViewHeader>

    <section>
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <router-view :key="$route.fullPath" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import ViewNavigation from "@/components/_layout/ViewNavigation";
import AddCertificateDialog from "@/components/journeys/dialogs/AddCertificateDialog.vue";

export default {
  name: "JourneyOverview",
  components: {
    ViewHeader,
    ViewNavigation,
    AddCertificateDialog,
  },
  computed: {
    addButtonType() {
      if (this.$route.name === "JourneyCurrent") return "journey";
      if (this.$route.name === "JourneyCertificates") return "certificate";
      return null;
    },
    navItems() {
      let items = [
        { title: this.$t("general.current"), link: { name: "JourneyCurrent" } },
        {
          title: this.$t("certificate.plural"),
          link: { name: "JourneyCertificates" },
        },
        { title: this.$t("general.archive"), link: { name: "JourneyArchive" } },
      ];
      return items;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.journeys.title"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager", "editor"]);
  },
};
</script>
