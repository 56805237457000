<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      title="Fragenimport "
      icon="mdi-database-import"
    />
    <ViewLoader v-if="!question_import && loading" />

    <section v-if="question_import && inputs && !loading">
      <v-container>
        <v-card color="grey lighten-3" elevation="0">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <h5 class="secondary--text mt-2">Bearbeitungsstatus ändern</h5>
                <v-btn-toggle v-model="question_import.presort_status">
                  <v-btn :value="null" disabled> ? </v-btn>

                  <v-tooltip
                    v-for="s in presort_statuses"
                    :key="s.id"
                    color="primary"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :value="s.id"
                        :active-class="s.color"
                        :disabled="
                          loading || question_import.status === 'imported'
                        "
                        v-bind="attrs"
                        v-on="on"
                        @click="changePreStatus(id, s.id)"
                      >
                        {{ s.text_short }}
                      </v-btn>
                    </template>
                    <span>{{ s.text }}</span>
                  </v-tooltip>
                </v-btn-toggle>
              </v-col>
              <v-col cols="4">
                <h5 class="secondary--text mb-4">Import-Status</h5>
                <div class="d-flex align-center">
                  <v-avatar
                    size="10"
                    :color="
                      question_import.status === 'imported'
                        ? 'success'
                        : 'error'
                    "
                  />
                  <span class="mx-2 black--text">{{
                    getStatusTranslation(question_import.status)
                  }}</span>
                </div>
                <!-- <v-menu
                  offset-x
                  left
                  close-on-content-click
                >
                  <template v-slot:activator="{ on, attrs }">
                    
                    <v-btn
                      color="grey lighten-2"
                      elevation="0"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-avatar
                        size="6"
                        :color="question_import.status === 'imported' ? 'success' : 'error'"
                      />
                      <span class="mx-2 black--text">{{ getStatusTranslation(question_import.status) }}</span>
                      <v-icon
                        v-if="!loading"
                        small
                      >
                        mdi-dots-vertical
                      </v-icon>
                      <v-progress-circular
                        v-if="loading"
                        indeterminate
                        :size="12"
                        :width="2"
                        color="grey"
                      />
                    </v-btn>
                  </template>
                  <v-list
                    dense
                    class="py-0"
                  >
                    <v-subheader class="caption font-weight-bold pl-4 grey lighten-3">
                      <span class="grey--text">{{ $t('resources.journeys.changeStatus') }}</span>
                    </v-subheader>
                    <v-list-item
                      v-for="(status, index) in statuses"
                      :key="index"
                      :disabled="question_import.status === status"
                      @change="changeStatus(status)"
                    >
                      <v-list-item-title>{{ status }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu> -->
              </v-col>

              <v-col cols="4">
                <h5 class="secondary--text mb-4">Fragen-ID</h5>
                <span>{{ question_import.id }}</span>
              </v-col>

              <v-col cols="4">
                <h5 class="secondary--text mb-0">Metatags geprüft</h5>
                <v-switch
                  v-model="inputs.meta_ready"
                  :label="inputs.meta_ready ? 'Ja' : 'Nein'"
                  color="success"
                  :disabled="question_import.status === 'imported'"
                  @change="changeMetaReady()"
                />
              </v-col>

              <v-col cols="4">
                <h5 class="secondary--text">Kategoriegruppen</h5>
                <span>{{ getCategoryGroupNameById(inputs.category) }}</span>
              </v-col>

              <v-col cols="4" class="">
                <h5 class="secondary--text">Kategorie</h5>
                <span>{{ getCategoryNameById(inputs.category) }}</span>
              </v-col>
            </v-row>

            <v-row v-if="false" dense>
              <v-col class="text-right">
                <v-btn
                  color="primary"
                  :disabled="!inputs.meta_ready || !inputs.category"
                  @click="submitQuestion()"
                >
                  Im System speichern
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-expansion-panels flat multiple :value="panels" class="mt-2">
          <v-expansion-panel class="grey lighten-3">
            <v-expansion-panel-header>
              <span class="font-weight-bold">Export</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12" class="d-flex justify-space-between">
                  <h5>Bisherige Anzeige (body raw)</h5>
                  <v-btn text small @click="showBodyRawCode = !showBodyRawCode">
                    {{
                      !showBodyRawCode
                        ? "HTML Vorschau zeigen"
                        : "Quellcode zeigen"
                    }}
                  </v-btn>
                </v-col>
                <v-col>
                  <v-sheet color="grey lighten-2" class="py-2 px-4">
                    <div
                      v-if="!showBodyRawCode"
                      class="body-2"
                      v-text="question_import.body_raw"
                    />
                    <div
                      v-if="showBodyRawCode"
                      class="body-2"
                      v-html="question_import.body_raw"
                    />
                  </v-sheet>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <h5>Bisherige Antwortmöglichkeiten (answers_raw)</h5>
                  <v-list dense color="transparent">
                    <v-list-item
                      v-for="(answer, i) in question_import.answers_raw"
                      :key="i"
                      class="grey lighten-2 mb-2 body-2"
                    >
                      {{ answer }}
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <h5>meta</h5>
                  <v-sheet color="grey lighten-2" class="pa-2">
                    <h5>Source</h5>
                    <p style="word-wrap: break-word" class="body-2">
                      {{ question_import.source }}
                    </p>
                  </v-sheet>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel v-if="false" class="grey lighten-3">
            <v-expansion-panel-header>
              <span class="font-weight-bold">Medien</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="6">
                  <h5 class="secondary--text">Fragenbild</h5>
                  <ImageUpload
                    :image-hash="inputs.images_hash"
                    @image-changed="imageChange"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-end">
                  <v-btn small text color="success" @click="saveImage()">
                    Speichern
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="grey lighten-3">
            <v-expansion-panel-header>
              <span class="font-weight-bold align-center d-flex"
                >Übertragene Fragen<v-icon
                  small
                  :color="
                    question_import.body ? 'success darken-1' : 'grey lighten-1'
                  "
                  class="ml-2"
                  >mdi-check</v-icon
                ></span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    filled
                    label="Fragentyp"
                    :hint="getTypeHint(question_import.question_type)"
                    disabled
                    persistent-hint
                    :value="question_import.question_type"
                  />
                  <!-- <v-select
                    v-model="question_import.question_type"
                    dense
                    :items="['gap_text', 'single_choice', 'multi_choice']"
                    filled
                    label="Fragentyp"
                    :hint="getTypeHint(question_import.question_type)"
                    persistent-hint
                  /> -->
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    color="primary"
                    :disabled="
                      inputs.questions.length >= 1 ||
                      question_import.status === 'imported'
                    "
                    @click="initTransfer()"
                  >
                    Export übertragen
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    block
                    color="primary"
                    :disabled="
                      tabs.length <= 0 || question_import.status === 'imported'
                    "
                    @click="addQuestion()"
                  >
                    Frage hinzufügen
                  </v-btn>
                </v-col>
              </v-row>

              <v-tabs
                v-if="inputs.questions && inputs.questions.length > 0"
                v-model="tab"
              >
                <v-tab v-for="(tab, i) in tabs" :key="i">
                  Frage {{ i + 1 }}
                </v-tab>
              </v-tabs>

              <v-tabs-items
                v-if="inputs.questions.length > 0 && inputs.questions[tab]"
                v-model="tab"
                class="grey lighten-2"
              >
                <v-tab-item
                  v-for="i in inputs.questions.length"
                  :key="i"
                  class="py-2 px-4 mt-1"
                >
                  <v-row>
                    <v-col class="shrink">
                      <h5 class="text-h6 grey--text text--darken-1">Frage</h5>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn
                        small
                        color="error"
                        :disabled="
                          tabs.length <= 1 ||
                          question_import.status === 'imported'
                        "
                        @click="removeQuestion(tab)"
                      >
                        Frage löschen
                      </v-btn>
                      <v-btn
                        small
                        color="primary"
                        class="mx-2"
                        :disabled="question_import.status === 'imported'"
                        @click="duplicateQuestion(inputs.questions[tab])"
                      >
                        Frage {{ tab + 1 }} duplizieren
                      </v-btn>
                      <v-btn
                        small
                        color="success"
                        :loading="loading"
                        :disabled="
                          (!gapCountMatch &&
                            question_import.question_type === 'gap_text') ||
                          question_import.status === 'imported'
                        "
                        @click="saveQuestion()"
                      >
                        Frage(n) speichern
                      </v-btn>
                    </v-col>
                    <v-col
                      v-if="
                        !gapCountMatch &&
                        question_import.question_type === 'gap_text'
                      "
                      cols="12"
                    >
                      <v-alert color="error" text dense class="caption">
                        Die Anzahl der Lücken im Fragentext stimmt nicht mit der
                        Anzahl der Antworten überein!
                      </v-alert>
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12">
                      <v-select
                        v-model="inputs.questions[tab].body_format"
                        filled
                        dense
                        label="Inhaltsformat"
                        hint="Typ 'math' aktiviert das Rendern von Formeln"
                        persistent-hint
                        hide-details
                        :disabled="question_import.status === 'imported'"
                        :items="bodyFormats"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="inputs.hint"
                        label="Fragenhinweis (z.B. Löse nach X auf), optional"
                        filled
                        :disabled="question_import.status === 'imported'"
                        dense
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="inputs.questions[tab].body"
                        min-height="100"
                        label="Inhalt"
                        :disabled="question_import.status === 'imported'"
                        filled
                      />
                      <v-btn
                        small
                        color="primary"
                        class="mb-2"
                        @click="showBodyPreview = !showBodyPreview"
                      >
                        {{
                          showBodyPreview
                            ? "Vorschau ausblenden"
                            : "Vorschau anzeigen"
                        }}
                      </v-btn>
                    </v-col>
                    <v-col v-if="showBodyPreview" cols="12">
                      <v-alert text color="primary">
                        <div class="caption">Vorschau:</div>
                        <p
                          v-if="
                            ['text'].includes(inputs.questions[tab].body_format)
                          "
                          class="body-2 mt-2"
                          v-html="inputs.questions[tab].body"
                        />
                        <MathJaxRenderer
                          v-if="
                            ['math'].includes(inputs.questions[tab].body_format)
                          "
                          :formula="inputs.questions[tab].body"
                          :safe="false"
                          change-gaps
                        />
                        <div
                          v-if="
                            ['html'].includes(inputs.questions[tab].body_format)
                          "
                          v-html="htmlPreview"
                        />
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="inputs.time"
                        label="Antwortzeit in Sekunden (0 wenn ohne Limit)"
                        filled
                        dense
                        :disabled="question_import.status === 'imported'"
                        type="number"
                      />
                    </v-col>
                    <v-col v-if="question_import.images_raw_hashes" cols="12">
                      <v-card color="grey lighten-2" elevation="0">
                        <v-card-text>
                          <div class="d-flex">
                            <div
                              v-for="(
                                hash, i
                              ) in question_import.images_raw_hashes"
                              :key="i"
                              class="mr-2"
                            >
                              <h5 class="secondary--text">Bild {{ i + 1 }}</h5>
                              <v-img
                                :src="`${media_server_url}/i/${hash}`"
                                width="100"
                                height="100"
                                max-width="100"
                                max-height="100"
                              />
                              <v-btn
                                text
                                x-small
                                :href="`${media_server_url}/i/${hash}`"
                                target="_blank"
                              >
                                Öffnen
                              </v-btn>
                            </div>
                          </div>
                          <v-radio-group
                            v-model="imageRadioGroup[i - 1]"
                            small
                            :disabled="question_import.status === 'imported'"
                          >
                            <v-radio label="Kein Bild" :value="-1" />
                            <v-radio
                              v-for="(
                                n, i
                              ) in question_import.images_raw_hashes"
                              :key="i"
                              :value="i"
                              :label="`Bild ${i + 1}`"
                            />
                          </v-radio-group>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>

                  <h5 class="text-h6 grey--text text--darken-1 mt-4">
                    Antworten
                  </h5>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-list color="transparent">
                        <v-list-item
                          v-for="(answer, index) in inputs.questions[tab]
                            .answers"
                          :key="index"
                        >
                          <v-list-item-content two-line>
                            <div class="d-flex align-center mb-2">
                              <!-- <span class="overline primary px-2 py-1 ml-1 white--text text--darken-1 font-weight-bold">{{ answer.order + 1 }}</span> -->
                              <v-avatar size="20" color="primary" tile>
                                <span class="overline white--text">{{
                                  answer.order + 1
                                }}</span>
                              </v-avatar>
                              <v-btn
                                text
                                x-small
                                color="error"
                                :disabled="
                                  question_import.status === 'imported'
                                "
                                @click="removeAnswer(tab, index)"
                              >
                                diese Antwort löschen
                              </v-btn>
                              <v-divider vertical />
                              <v-btn
                                text
                                x-small
                                color="error"
                                @click="removeAnswers(tab, index)"
                              >
                                andere Antworten löschen
                              </v-btn>
                            </div>
                            <div class="d-flex align-center">
                              <v-text-field
                                v-model="
                                  inputs.questions[tab].answers[index].body
                                "
                                filled
                                dense
                                :disabled="
                                  question_import.status === 'imported'
                                "
                                :hint="
                                  question_import.question_type !==
                                    'gap_text' &&
                                  inputs.questions[tab].answers[index]
                                    .body_format === 'math'
                                    ? 'Formeln müssen mit > [$] < beginnen und enden!'
                                    : ''
                                "
                                :persistent-hint="
                                  inputs.questions[tab].answers[index]
                                    .body_format === 'math'
                                "
                                label="Inhalt"
                                class="caption"
                              />
                              <v-select
                                v-model="
                                  inputs.questions[tab].answers[index]
                                    .body_format
                                "
                                filled
                                dense
                                label="Inhaltsformat"
                                :items="bodyFormats"
                                :disabled="
                                  question_import.status === 'imported'
                                "
                                style="max-width: 120px"
                                class="mx-4"
                              />
                              <v-switch
                                v-model="
                                  inputs.questions[tab].answers[index]
                                    .is_correct
                                "
                                dense
                                color="success"
                                :label="
                                  inputs.questions[tab].answers[index]
                                    .is_correct
                                    ? 'Richtig '
                                    : 'Falsch '
                                "
                                hide-details
                                :disabled="
                                  question_import.question_type ===
                                    'gap_text' ||
                                  question_import.status === 'imported'
                                "
                                class="mt-0 align-self-start"
                              />
                            </div>
                            <v-alert
                              :color="
                                inputs.questions[tab].answers[index]
                                  .body_format === 'image'
                                  ? 'error'
                                  : 'primary'
                              "
                              dense
                              text
                              class="mb-0"
                            >
                              <div class="caption">Vorschau:</div>
                              <span
                                v-if="
                                  inputs.questions[tab].answers[index]
                                    .body_format === 'image'
                                "
                                class="caption"
                                >Antworten mit Bildern können aktuell noch nicht
                                bearbeitet werden. Gerne einen Hinweis im
                                Kommentarfeld hinterlassen.</span
                              >
                              <span
                                v-if="
                                  !['math', 'image'].includes(
                                    inputs.questions[tab].answers[index]
                                      .body_format
                                  )
                                "
                                class="body-2"
                                v-html="answer.body"
                              />
                              <MathJaxRenderer
                                v-if="
                                  inputs.questions[tab].answers[index]
                                    .body_format == 'math'
                                "
                                :formula="answer.body"
                                :safe="false"
                              />
                            </v-alert>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                    <v-col cols="6" offset-sm="3" class="py-0 my-0">
                      <v-btn
                        small
                        block
                        color="primary"
                        :disabled="question_import.status === 'imported'"
                        @click="addAnswer(tab)"
                      >
                        <v-icon small class="mr-2"> mdi-plus </v-icon>
                        <span>Antwort hinzufügen</span>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row class="mt-4">
                    <v-col
                      v-if="
                        !gapCountMatch &&
                        question_import.question_type === 'gap_text'
                      "
                      cols="12"
                    >
                      <v-alert color="error" text dense class="caption">
                        Die Anzahl der Lücken im Fragentext stimmt nicht mit der
                        Anzahl der Antworten überein!
                      </v-alert>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                      <v-btn
                        color="success"
                        small
                        :loading="loading"
                        :disabled="
                          (!gapCountMatch &&
                            question_import.question_type === 'gap_text') ||
                          question_import.status === 'imported'
                        "
                        @click="saveQuestion()"
                      >
                        Frage(n) speichern
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="grey lighten-3">
            <v-expansion-panel-header>
              <span class="font-weight-bold"
                >Kommentare ({{
                  question_import.comments
                    ? question_import.comments.length
                    : 0
                }})</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col
                  v-for="(comment, i) in question_import.comments"
                  :key="i"
                  cols="12"
                >
                  <v-list-item class="grey lighten-2">
                    <v-list-item-content class="body-2">
                      {{ comment }}
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex align-center">
                  <v-text-field
                    v-model="newComment"
                    hide-details
                    label="Kommentar hinzufügen"
                    dense
                    filled
                    :disabled="question_import.status === 'imported'"
                  />
                  <v-btn
                    fab
                    x-small
                    :disabled="
                      !newComment || question_import.status === 'imported'
                    "
                    class="ml-4"
                    color="success"
                    @click="addComment()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel class="grey lighten-3" disabled>
            <v-expansion-panel-header>
              <span class="font-weight-bold"
                >Metatags ({{
                  question_import.metatags
                    ? question_import.metatags.length
                    : 0
                }})</span
              >
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense>
                <v-col
                  v-for="(metatag, i) in inputs.metatags"
                  :key="i"
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-list-item class="grey lighten-2">
                    <v-list-item-content class="body-2">
                      {{ metatag }}
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                        icon
                        small
                        color="error"
                        @click="removeMetatag(metatag)"
                      >
                        <v-icon small> mdi-close </v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex align-center">
                  <v-text-field
                    v-model="newMetatag"
                    hide-details
                    label="Metatag hinzufügen"
                    dense
                    filled
                  />
                  <v-btn
                    fab
                    x-small
                    :disabled="
                      !newMetatag || question_import.status === 'imported'
                    "
                    class="ml-4"
                    color="success"
                    @click="addMetatag()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="text-right">
                  <v-btn
                    color="primary"
                    small
                    :disabled="
                      !metatagsChanged || question_import.status === 'imported'
                    "
                    @click="saveMetatags()"
                  >
                    <!--
                    :disabled="(inputs.metatags == question_import.metatags) || (!question_import.metatags && inputs.metatags.length === 0)"-->
                    Metatags speichern
                  </v-btn>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import MathJaxRenderer from "@/components/global/MathJaxRenderer";
import ImageUpload from "@/components/global/ImageUpload";

export default {
  name: "ImportDetailQuestion",
  components: {
    ViewHeader,
    ViewLoader,
    MathJaxRenderer,
    ImageUpload,
  },
  data() {
    return {
      id: 0,
      inputs: {
        meta_ready: false,
        questions: [],
        metatags: [],
        comments: [],
        category: [],
        time: 0,
        hint: "",
      },
      statuses: ["unapproved", "editing", "imported"],
      loading: false,
      tab: null,
      tabs: [],
      newMetatag: "",
      newComment: "",
      panels: [1],
      bodyFormats: ["text", "html", "math", "image"],
      showBodyPreview: false,
      showBodyRawCode: false,
      imageRadioGroup: [],
      presort_statuses: [
        {
          id: 1,
          text: "Geprüft",
          text_short: "OK",
          color: "color-ok",
          dark: false,
        },
        {
          id: 2,
          text: "Technische Prüfung (P3L)",
          text_short: "TEC",
          color: "color-tec",
          dark: true,
        },
        {
          id: 3,
          text: "FORMELN (SH)",
          text_short: "FOR",
          color: "color-for",
          dark: false,
        },
        {
          id: 4,
          text: "Überarbeitung (P3L)",
          text_short: "ÜBE",
          color: "color-ube",
          dark: false,
        },
        {
          id: 5,
          text: "Inhaltliche Prüfung (SH)",
          text_short: "INH",
          color: "color-inh",
          dark: false,
        },
        {
          id: 6,
          text: "Vorerst abgelehnt",
          text_short: "VAB",
          color: "color-vab",
          dark: true,
        },
        {
          id: 7,
          text: "Abgelehnt",
          text_short: "ABG",
          color: "color-abg",
          dark: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("imports", { question_import: ["get_import_question"] }),
    ...mapGetters("categories", { categories: "get_categories" }),
    metatagsChanged() {
      // todo: change when data format known
      if (!this.question_import.metatags) return false;
      if (this.inputs.metatags.length !== this.question_import.metatags.length)
        return true;
      let tags = [];
      this.question_import.metatags.forEach((tag) => {
        let key = Object.keys(tag);
        tags.push(tag[key]);
      });
      return JSON.stringify(tags) !== JSON.stringify([...this.inputs.metatags]);
    },
    gapCountMatch() {
      if (
        !this.question_import ||
        this.question_import.question_type !== "gap_text"
      )
        return true;
      let answer_count = this.inputs.questions[this.tab].answers.length;
      let gap_count =
        this.inputs.questions[this.tab].body.split("[_]").length - 1;
      return answer_count === gap_count;
    },
    htmlPreview() {
      let splitted = this.inputs.questions[this.tab].body.split("[_]");
      let newString = "";
      for (let i = 0; i < splitted.length - 1; i++) {
        newString += ` ${
          splitted[i]
        } <span class="primary py-1 px-2 white--text">Lücke ${i + 1}</span>`;
      }
      newString += splitted[splitted.length - 1];
      return newString;
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "Datenimport ",
          to: { name: "ImportOverview" },
        },
        {
          text: "Fragenimporte ",
          to: {
            name: "ImportDetailQuestions",
            params: { id: Number(this.$route.params.id) },
          },
        },
        {
          text: "Fragenimport ",
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    this.id = Number(this.$route.params.question_id);
  },
  methods: {
    ...mapActions("imports", [
      "fetch_import_question",
      "patch_import_question",
      "submit_question",
    ]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("imports", ["set_import_question"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_categories({ cancelToken: this.source.token }),
        await this.fetch_import_question({
          question_id: this.id,
          cancelToken: this.source.token,
        });
      this.inputs.meta_ready = this.question_import.meta_ready;
      this.inputs.category = this.question_import.category_id;
      let questions = [];
      let tabs = [];
      this.inputs.time = this.question_import.time
        ? this.question_import.time
        : 0;
      this.inputs.hint = this.question_import.hint
        ? this.question_import.hint
        : "";
      if (
        this.question_import.body !== null &&
        this.question_import.body.length > 0
      ) {
        this.question_import.body.forEach((body, i) => {
          let answers = this.question_import.answers[i];
          questions.push({
            body: body,
            body_format: this.question_import.body_format,
            answers: answers,
          });
          tabs.push(i + 1);
          if (
            this.question_import.images_raw &&
            this.question_import.images &&
            this.question_import.images.length > 0
          ) {
            this.imageRadioGroup.push(
              this.question_import.images_raw.indexOf(
                this.question_import.images[i]
              )
            );
          } else {
            this.imageRadioGroup.push(-1);
          }
        });

        this.inputs.questions = questions;
        this.tabs = tabs;
      }
      // TODO: change if data format known
      let metatags = [];
      if (this.question_import.metatags) {
        this.question_import.metatags.forEach((tag) => {
          let key = Object.keys(tag);
          metatags.push(tag[key]);
        });
      }
      this.inputs.metatags = metatags;
      this.loading = false;
    },
    unloadData() {
      this.set_import_question(null);
      this.set_categories(null);
    },
    async changeStatus(status) {
      let payload = {
        id: this.id,
        status: status,
      };

      let res = await this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    async changeMetaReady() {
      let payload = {
        id: this.id,
        meta_ready: this.inputs.meta_ready,
      };

      let res = await this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    async changePreStatus(id, status) {
      let payload = {
        id: id,
        presort_status: status,
      };

      let res = await this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    initTransfer() {
      this.tabs.push(0);
      let question = {
        body: this.question_import.body_raw,
        body_format: this.question_import.body_format,
      };
      let answers = [];
      this.question_import.answers_raw.forEach((answer) => {
        answers.push({
          order: answer.order,
          body: Number(answer.body)
            ? answer.body.replace(".", ",")
            : answer.body,
          is_correct: answer.is_correct,
          body_format: answer.body_format,
        });
      });
      question.answers = answers.sort((a, b) => a.order - b.order);
      this.imageRadioGroup = [-1];
      this.inputs.questions.push(question);
      this.inputs.time = 0;
      this.inputs.hint = "";
    },
    addQuestion() {
      this.tabs.push(this.tabs.length);
      let question = { body: this.question_import.body_raw };
      let answers = [];
      this.question_import.answers_raw.forEach((answer) => {
        answers.push({
          body: answer.body,
          is_correct: answer.is_correct,
          order: answer.order,
          body_format: answer.body_format,
        });
      });
      question.answers = answers;
      this.images.push(-1);
      this.inputs.questions.push(question);
    },
    addAnswer(questionindex) {
      let order = this.getHighestOrder(
        this.inputs.questions[questionindex].answers
      );
      this.inputs.questions[questionindex].answers.push({
        body: "",
        is_correct:
          this.question_import.question_type === "gap_text" ? true : false,
        order: order + 1,
        body_format: "text",
      });
    },
    getHighestOrder(answers) {
      let val = 0;
      answers.forEach((answer) => {
        if (answer.order > val) val = answer.order;
      });
      return val;
    },
    duplicateQuestion(question) {
      this.tabs.push(this.tabs.length);
      this.imageRadioGroup.push(-1);
      this.inputs.questions.push(JSON.parse(JSON.stringify(question)));
    },
    removeAnswer(questionindex, answerindex) {
      let answers = this.inputs.questions[questionindex].answers.filter(
        (answer, i) => i !== answerindex
      );
      let prev_order = 0;
      answers.forEach((answer, i) => {
        let order = i;
        if (order > prev_order + 1) order = prev_order + 1;
        prev_order = order;
        return (answer.order = order);
      });
      this.inputs.questions[questionindex].answers = answers;
    },
    removeAnswers(questionindex, answerindex) {
      let answers = this.inputs.questions[questionindex].answers.filter(
        (answer, i) => i === answerindex
      );
      this.inputs.questions[questionindex].answers = answers;
    },
    removeQuestion(questionindex) {
      this.tabs.splice(questionindex, 1);
      this.imageRadioGroup.splice(questionindex, 1);
      this.inputs.questions = this.inputs.questions.filter(
        (question, i) => i !== questionindex
      );
    },
    async saveQuestion() {
      try {
        let body = [];
        let answers = [];
        let images = [];
        let hashes = [];
        this.inputs.questions.forEach((question, i) => {
          answers[i] = [];
          body.push(question.body);
          question.answers.forEach((answer) => answers[i].push(answer));
          if (this.imageRadioGroup.length >= 1) {
            (this.imageRadioGroup[i] && this.imageRadioGroup[i]) !== -1
              ? images.push(
                  this.question_import.images_raw[this.imageRadioGroup[i]]
                )
              : images.push("");
            (this.imageRadioGroup[i] && this.imageRadioGroup[i]) !== -1
              ? hashes.push(
                  this.question_import.images_raw_hashes[
                    this.imageRadioGroup[i]
                  ]
                )
              : hashes.push("");
          }
        });

        let payload = {
          id: this.id,
          body: body,
          body_format: this.inputs.questions[0].body_format, // different types?
          answers: answers,
          images: images,
          image_hashes: hashes,
          time: this.inputs.time ? this.inputs.time : 0,
          hint: this.inputs.hint,
        };
        let res = await this.patch_import_question({
          payload: payload,
          cancelToken: this.source.token,
        });
        if (res._status === 200) {
          this.$notify({
            type: "success",
            title: "success ",
            text: "Frage erfolgreich gespeichert ",
          });
        } else {
          this.$notify({
            type: "error",
            title: "error ",
            text: "Speichern fehlgeschlagen ",
          });
        }
      } catch (e) {
        console.error(e);
        this.$notify({
          type: "error",
          title: "error ",
          text: "Speichern fehlgeschlagen ",
        });
      }
    },
    addMetatag() {
      this.inputs.metatags.push(this.newMetatag);
      this.newMetatag = "";
    },
    removeMetatag(metatag) {
      this.inputs.metatags = this.inputs.metatags.filter(
        (inputtag) => inputtag !== metatag
      );
    },
    async saveMetatags() {
      let metatags = this.inputs.metatags.map((metatag) => {
        return { test: metatag };
      });

      let payload = {
        id: this.id,
        metatags: metatags,
      };

      let res = await this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    getTypeHint(type) {
      if (type === "single_choice") return "Nur eine richtige Antwort erlaubt.";
      if (type === "multi_choice") return "Mehrere richtige Antworten möglich.";
      if (type === "gap_text")
        return "Die Anzahl der Antworten muss der Anzahl an Lücken entsprechen. Bitte Reihenfolge beachten.";
      return type;
    },
    async addComment() {
      let comments = this.question_import.comments || [];
      comments.push(this.newComment);
      this.newComment = "";

      let payload = {
        id: this.id,
        comments: comments,
      };

      let res = await this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Kommentar hinzugefügt",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Fehler beim Hinzufügen",
        });
      }
    },
    async changeCategories() {
      let payload = {
        id: this.id,
        categories: this.inputs.category,
      };

      let res = await this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Kategorien erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Kategorieänderungen fehlgeschlagen ",
        });
      }
    },
    submitQuestion() {
      let payload = { id: this.id };
      let res = this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });

      if (res) {
        this.$notify({
          type: "success",
          title: "success ",
          text: "Frage im System ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Fehler beim Import",
        });
      }
    },
    getCategoryById(id) {
      return this.categories.filter((category) => {
        return category.id == id;
      })[0];
    },
    getCategoryNameById(id) {
      if (!this.categories) return "";
      let cat = this.categories.filter((category) => {
        return category.id == id;
      })[0];
      if (!cat) return "";
      return cat.name;
    },
    getCategoryGroupNameById(id) {
      if (!this.categories) return "";
      let cat = this.categories.filter((category) => {
        return category.id == id;
      })[0];
      if (!cat) return "";
      return cat.groups[0].name;
    },
    getStatusTranslation(status) {
      if (status === "unapproved") return "Unbearbeitet";
      if (status === "editing") return "Editiert";
      if (status === "imported") return "Importiert";
      return "?";
    },
  },
};
</script>

<style></style>
