<template>
  <v-dialog :value="showDialog" width="500" @click:outside="closeDialog()">
    <v-card :loading="isLoading">
      <v-card-title class="text-overline pb-0">
        <span>{{ $t("resources.users.delete_title") }}</span>
      </v-card-title>

      <v-card-text class="pt-2">
        <p class="primary white--text font-weight-bold text-center pa-2">
          {{ user.user_name }} ({{ user.first_name }} {{ user.last_name }})
        </p>
        <p>
          {{ $t("resources.users.delete_description") }}
        </p>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn color="secondary" text @click="closeDialog()">
          {{ $t("general.cancel") }}
        </v-btn>
        <v-btn color="error" text @click="deleteUser">
          {{ $t("general.delete") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UserDialogDelete",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    showDialog() {
      return this.dialog;
    },
  },
  methods: {
    ...mapActions("users", ["delete_user"]),
    closeDialog(wasDeleted) {
      this.$emit("close", wasDeleted);
    },
    async deleteUser() {
      this.isLoading = true;
      let res = await this.delete_user({
        payload: this.user,
        config: { cancelToken: this.source.token },
      });
      this.isLoading = false;
      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.users.delete_success"),
        });
        this.closeDialog(true);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error_msg"),
        });
      }
    },
  },
};
</script>

<style></style>
