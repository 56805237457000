import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/middleware/auth";

// APP
import DashboardView from "@/views/app/DashboardView.vue";
import ErrorPage from "@/views/app/ErrorPage.vue";

// ADMIN
import AdministrationOverview from "@/views/administration/AdministrationOverview.vue";
import StructureAdministration from "@/views/administration/children-overview/StructureAdministration.vue";
import SSOClientAdministration from "@/views/administration/children-overview/SSOClientAdministration.vue";
import IDPClientAdministration from "@/views/administration/children-overview/IDPClientAdministration.vue";
import GhostwriterLicenceAdministration from "@/views/administration/children-overview/GhostwriterLicenceAdministration.vue";
import LicenceAdministration from "@/views/administration/children-overview/LicenceAdministration.vue";

// AUTH
import LoginView from "@/views/auth/LoginView.vue";
import CommunityView from "@/views/auth/CommunityView.vue";
import AccountView from "@/views/auth/AccountView.vue";

// MANAGEMENT
import ManagementView from "@/views/management/ManagementView";
import UserOverview from "@/views/management/users/UserOverview";
import UserDetail from "@/views/management/users/UserDetail";
import UserInfo from "@/views/management/users/UserInfo";
import UserStatistics from "@/views/management/users/UserStatistics";
import CodeOverview from "@/views/management/codes/CodeOverview";
import CodeDetail from "@/views/management/codes/CodeDetail";
import CodeAdd from "@/views/management/codes/CodeAdd";
import InvitationOverview from "@/views/management/invitations/InvitationOverview";
import InvitationDetail from "@/views/management/invitations/InvitationDetail";
import InvitationAdd from "@/views/management/invitations/InvitationAdd";

// CONTENT
import ContentView from "@/views/content/ContentView.vue";
import QuestionOverview from "@/views/content/questions/QuestionOverview";
import QuestionAdd from "@/views/content/questions/QuestionAdd";
import QuestionDetail from "@/views/content/questions/QuestionDetail";
import QuestionSummary from "@/views/content/questions/children-detail/QuestionSummary";
import QuestionSettings from "@/views/content/questions/children-detail/QuestionSettings";
import QuestionMedia from "@/views/content/questions/children-detail/QuestionMedia";
import QuestionExplanation from "@/views/content/questions/children-detail/QuestionExplanation";
import QuestionReports from "@/views/content/questions/children-detail/QuestionReports";
import QuestionHistory from "@/views/content/questions/children-detail/QuestionHistory";
import QuestionNuggets from "@/views/content/questions/children-detail/QuestionNuggets";
import CategoryOverview from "@/views/content/categories/CategoryOverview";
import CategoryAdd from "@/views/content/categories/CategoryAdd";
import CategoryDetail from "@/views/content/categories/CategoryDetail";
import NuggetOverview from "@/views/content/nuggets/NuggetOverview";
import NuggetDetail from "@/views/content/nuggets/NuggetDetail";
import NuggetAdd from "@/views/content/nuggets/NuggetAdd";

// JOURNEY
import JourneyOverview from "@/views/journeys/JourneyOverview.vue";
import JourneyCurrent from "@/views/journeys/children-overview/JourneyCurrent.vue";
import JourneyArchive from "@/views/journeys/children-overview/JourneyArchive";
import JourneyCertificates from "@/views/journeys/children-overview/JourneyCertificates";
import JourneyAdd from "@/views/journeys/JourneyAdd.vue";
import JourneyDetail from "@/views/journeys/JourneyDetail.vue";
import JourneySummary from "@/views/journeys/children-detail/JourneySummary";
import JourneySettings from "@/views/journeys/children-detail/JourneySettings";
import JourneyStatistics from "@/views/journeys/children-detail/JourneyStatistics";
import JourneyBundles from "@/views/journeys/children-detail/JourneyBundles";
import JourneyBundle from "@/views/journeys/children-detail/JourneyBundle";
import JourneyStructures from "@/views/journeys/children-detail/JourneyStructures";
import JourneyCertificate from "@/views/journeys/children-detail/JourneyCertificate";
import CertificateDetail from "@/views/journeys/CertificateDetail";

// SUPPORT
import SupportOverview from "@/views/support/SupportOverview";

// Import
import ImportOverview from "@/views/import/ImportOverview";
import ImportDetailQuestions from "@/views/import/ImportDetailQuestions";
import ImportDetailCategories from "@/views/import/ImportDetailCategories";
import ImportDetailNuggets from "@/views/import/ImportDetailNuggets";
import ImportDetailQuestion from "@/views/import/ImportDetailQuestion";
import ImportDetailNugget from "@/views/import/ImportDetailNugget";

// STATISTICS
import StatisticsView from "@/views/statistics/StatisticsView.vue";
import StatisticsOverview from "@/views/statistics/children-statistics/StatisticsOverview.vue";
import StatisticsCategories from "@/views/statistics/children-statistics/StatisticsCategories.vue";
import StatisticsQuizzes from "@/views/statistics/children-statistics/StatisticsQuizzes.vue";
import StatisticsStructures from "@/views/statistics/children-statistics/StatisticsStructures.vue";
import StatisticsUser from "@/views/statistics/children-statistics/StatisticsUser.vue";
import StatisticsTopThree from "@/views/statistics/children-statistics/StatisticsTopThree.vue";

// KPI
import KpiView from "@/views/kpi/KpiView.vue";
import KpiOverview from "@/views/kpi/children-overview/KpiOverview.vue";
import KpiContent from "@/views/kpi/children-overview/KpiContent.vue";
import KpiContentOverview from "@/views/kpi/children-content/KpiContentOverview.vue";
import KpiCharts from "@/views/kpi/children-overview/KpiCharts.vue";
import KpiPerformance from "@/views/kpi/children-overview/KpiPerformance.vue";
import KpiPerformanceOverview from "@/views/kpi/children-performance/KpiPerformanceOverview.vue";
import KpiPerformanceCompetency from "@/views/kpi/children-performance/KpiPerformanceCompetency.vue";
import KpiPerformanceRetention from "@/views/kpi/children-performance/KpiPerformanceRetention.vue";
import KpiPerformanceDiscovery from "@/views/kpi/children-performance/KpiPerformanceDiscovery.vue";
import KpiJourneys from "@/views/kpi/children-overview/KpiJourneys.vue";
import KpiJourneyOverview from "@/views/kpi/children-journeys/KpiJourneyOverview.vue";
import KpiActivity from "@/views/kpi/children-overview/KpiActivity.vue";
import KpiActivityOverview from "@/views/kpi/children-activity/KpiActivityOverview.vue";
import KpiActivitySpeed from "@/views/kpi/children-activity/KpiActivitySpeed.vue";
import KpiActivityOverallSpeed from "@/views/kpi/children-activity/KpiActivityOverallSpeed.vue";
import KpiCategory from "@/views/kpi/children-overview/KpiCategory.vue";
import KpiCategoryDiscovery from "@/views/kpi/children-category/KpiCategoryDiscovery.vue";
import KpiCategoryCompetency from "@/views/kpi/children-category/KpiCategoryCompetency.vue";
import KpiCategoryRetention from "@/views/kpi/children-category/KpiCategoryRetention.vue";

// GHOSTWRITER
import GwSessionOverview from "@/views/ghostwriter/GwSessionOverview.vue";
import GwSessionList from "@/views/ghostwriter/children-overview/GwSessionList.vue";
import GwSessionBulk from "@/views/ghostwriter/children-overview/GwSessionBulk.vue";
import GwTranscriptions from "@/views/ghostwriter/children-overview/GwTranscriptions.vue";
import GwManagement from "@/views/ghostwriter/children-overview/GwManagement.vue";
import GwSessionImport from "@/views/ghostwriter/GwSessionImport.vue";
import GwSessionDetail from "@/views/ghostwriter/GwSessionDetail.vue";
import GwSessionBulkDetail from "@/views/ghostwriter/GwSessionBulkDetail.vue";
import GwSessionBulkMain from "@/views/ghostwriter/children-bulkdetail/GwSessionBulkMain.vue";
import GwSessionBulkContent from "@/views/ghostwriter/children-bulkdetail/GwSessionBulkContent.vue";
import GwTranscriptionDetail from "@/views/ghostwriter/GwTranscriptionDetail.vue";

Vue.use(VueRouter);

const routes = [
  //
  // MAIN ROUTES
  //
  { path: "*", redirect: "/" },
  {
    path: "/error/:code?",
    name: "ErrorPage",
    component: ErrorPage,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardView,
    meta: {
      middleware: [auth],
    },
  },
  //
  // AUTH ROUTES
  //
  {
    path: "/",
    name: "Login",
    component: LoginView,
  },
  {
    path: "/community",
    name: "Community",
    component: CommunityView,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/account",
    name: "Account",
    component: AccountView,
    meta: {
      middleware: [auth],
    },
  },
  //
  // ADMINISTRATION ROUTES
  //
  {
    path: "/administration",
    component: AdministrationOverview,
    redirect: StructureAdministration,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "structures",
        name: "StructureAdministration",
        component: StructureAdministration,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "sso",
        name: "SSOClientAdministration",
        component: SSOClientAdministration,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "idp",
        name: "IDPClientAdministration",
        component: IDPClientAdministration,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "gw",
        name: "GhostwriterLicenceAdministration",
        component: GhostwriterLicenceAdministration,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "licences",
        name: "LicenceAdministration",
        component: LicenceAdministration,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  //
  // MANAGEMENT ROUTES
  //
  {
    path: "/management",
    name: "Management",
    component: ManagementView,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/management/users",
    name: "UserOverview",
    component: UserOverview,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/management/users/:id",
    name: "UserDetail",
    component: UserDetail,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "info",
        name: "UserInfo",
        component: UserInfo,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "statistics",
        name: "UserStatistics",
        component: UserStatistics,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/management/codes",
    name: "CodeOverview",
    component: CodeOverview,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/management/codes/:id",
    name: "CodeDetail",
    component: CodeDetail,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/management/addCode",
    name: "CodeAdd",
    component: CodeAdd,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/management/invitations",
    name: "InvitationOverview",
    component: InvitationOverview,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/management/invitations/:id",
    name: "InvitationDetail",
    component: InvitationDetail,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/management/addInvitation",
    name: "InvitationAdd",
    component: InvitationAdd,
    meta: {
      middleware: [auth],
    },
  },
  // CONTENT ROUTES
  {
    path: "/content",
    name: "Content",
    component: ContentView,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/questions",
    name: "QuestionOverview",
    component: QuestionOverview,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/questions/add",
    name: "QuestionAdd",
    component: QuestionAdd,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/questions/:id",
    name: "QuestionDetail",
    component: QuestionDetail,
    redirect: QuestionSummary,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "summary",
        name: "QuestionSummary",
        component: QuestionSummary,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "settings",
        name: "QuestionSettings",
        component: QuestionSettings,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "images",
        name: "QuestionMedia",
        component: QuestionMedia,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "explanation",
        name: "QuestionExplanation",
        component: QuestionExplanation,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "reports",
        name: "QuestionReports",
        component: QuestionReports,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "history",
        name: "QuestionHistory",
        component: QuestionHistory,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "nuggets",
        name: "QuestionNuggets",
        component: QuestionNuggets,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/content/categories",
    name: "CategoryOverview",
    component: CategoryOverview,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/addCategory",
    name: "CategoryAdd",
    component: CategoryAdd,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/categories/:id",
    name: "CategoryDetail",
    component: CategoryDetail,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/nuggets",
    name: "NuggetOverview",
    component: NuggetOverview,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/nuggets/:id",
    name: "NuggetDetail",
    component: NuggetDetail,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/content/addNugget",
    name: "NuggetAdd",
    component: NuggetAdd,
    meta: {
      middleware: [auth],
    },
  },
  //
  // JOURNEY ROUTES
  //
  {
    path: "/journeys",
    component: JourneyOverview,
    redirect: JourneyCurrent,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "current",
        name: "JourneyCurrent",
        component: JourneyCurrent,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "certificates",
        name: "JourneyCertificates",
        component: JourneyCertificates,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "archive",
        name: "JourneyArchive",
        component: JourneyArchive,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },

  {
    path: "/journeys/details/:id",
    component: JourneyDetail,
    redirect: JourneySummary,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "summary",
        name: "JourneySummary",
        component: JourneySummary,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "settings",
        name: "JourneySettings",
        component: JourneySettings,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "statistics",
        name: "JourneyStatistics",
        component: JourneyStatistics,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "structures",
        name: "JourneyStructures",
        component: JourneyStructures,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "certificate",
        name: "JourneyCertificate",
        component: JourneyCertificate,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "bundles",
        name: "JourneyBundles",
        component: JourneyBundles,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "bundles/:bundle_id",
        name: "JourneyBundle",
        component: JourneyBundle,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/journeys/add",
    name: "JourneyAdd",
    component: JourneyAdd,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/journeys/certificate/:id",
    name: "CertificateDetail",
    component: CertificateDetail,
    meta: {
      middleware: [auth],
    },
  },
  //
  // SUPPORT ROUTES
  //
  {
    path: "/support",
    name: "SupportOverview",
    component: SupportOverview,
    meta: {
      middleware: [auth],
    },
  },
  //
  // Import ROUTES
  //
  {
    path: "/imports",
    name: "ImportOverview",
    component: ImportOverview,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/imports/:id/questions",
    name: "ImportDetailQuestions",
    component: ImportDetailQuestions,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/imports/:id/categories",
    name: "ImportDetailCategories",
    component: ImportDetailCategories,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/imports/:id/question/:question_id",
    name: "ImportDetailQuestion",
    component: ImportDetailQuestion,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/imports/:id/nuggets",
    name: "ImportDetailNuggets",
    component: ImportDetailNuggets,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/imports/:id/nugget/:nugget_id",
    name: "ImportDetailNugget",
    component: ImportDetailNugget,
    meta: {
      middleware: [auth],
    },
  },
  //
  // Statistics ROUTES
  //
  {
    path: "/statistics/",
    component: StatisticsView,
    redirect: StatisticsOverview,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "overview",
        name: "StatisticsOverview",
        component: StatisticsOverview,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "categories",
        name: "StatisticsCategories",
        component: StatisticsCategories,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "quizzes",
        name: "StatisticsQuizzes",
        component: StatisticsQuizzes,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "structures",
        name: "StatisticsStructures",
        component: StatisticsStructures,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "user",
        name: "StatisticsUser",
        component: StatisticsUser,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "top3",
        name: "StatisticsTopThree",
        component: StatisticsTopThree,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  //
  // KPI ROUTES
  //
  {
    path: "/kpi/",
    component: KpiView,
    redirect: KpiOverview,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "overview",
        name: "KpiOverview",
        component: KpiOverview,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "content",
        component: KpiContent,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "overview",
            name: "KpiContentOverview",
            component: KpiContentOverview,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: "performance",
        component: KpiPerformance,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "overview",
            name: "KpiPerformanceOverview",
            component: KpiPerformanceOverview,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "competency",
            name: "KpiPerformanceCompetency",
            component: KpiPerformanceCompetency,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "retention",
            name: "KpiPerformanceRetention",
            component: KpiPerformanceRetention,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "discovery",
            name: "KpiPerformanceDiscovery",
            component: KpiPerformanceDiscovery,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: "activity",
        component: KpiActivity,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "overview",
            name: "KpiActivityOverview",
            component: KpiActivityOverview,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "speed",
            name: "KpiActivitySpeed",
            component: KpiActivitySpeed,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "overallspeed",
            name: "KpiActivityOverallSpeed",
            component: KpiActivityOverallSpeed,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: "journeys",
        component: KpiJourneys,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "overview",
            name: "KpiJourneyOverview",
            component: KpiJourneyOverview,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: "category",
        component: KpiCategory,
        meta: {
          middleware: [auth],
        },
        children: [
          {
            path: "discovery",
            name: "KpiCategoryDiscovery",
            component: KpiCategoryDiscovery,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "competency",
            name: "KpiCategoryCompetency",
            component: KpiCategoryCompetency,
            meta: {
              middleware: [auth],
            },
          },
          {
            path: "retention",
            name: "KpiCategoryRetention",
            component: KpiCategoryRetention,
            meta: {
              middleware: [auth],
            },
          },
        ],
      },
      {
        path: "examples",
        component: KpiCharts,
        name: "KpiCharts",
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  //
  // GHOSTWRITER ROUTES
  //
  {
    path: "/ghostwriter",
    redirect: GwSessionList,
    component: GwSessionOverview,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "sessions",
        name: "GwSessionList",
        component: GwSessionList,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "bulk",
        name: "GwSessionBulk",
        component: GwSessionBulk,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "transcriptions",
        name: "GwTranscriptions",
        component: GwTranscriptions,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "management",
        name: "GwManagement",
        component: GwManagement,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/ghostwriter/session/:id",
    name: "GwSessionDetail",
    component: GwSessionDetail,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/ghostwriter/session/:id/import",
    name: "GwSessionImport",
    component: GwSessionImport,
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/ghostwriter/bulk/:id",
    redirect: GwSessionList,
    component: GwSessionBulkDetail,
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "main",
        name: "GwSessionBulkMain",
        component: GwSessionBulkMain,
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "content",
        name: "GwSessionBulkContent",
        component: GwSessionBulkContent,
        meta: {
          middleware: [auth],
        },
      },
    ],
  },
  {
    path: "/ghostwriter/transcriptions/:id",
    name: "GwTranscriptionDetail",
    component: GwTranscriptionDetail,
    meta: {
      middleware: [auth],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// * overriding next() function for middleware usage
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

// * apply middleware
router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
