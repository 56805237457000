<template>
  <div>
    <ViewLoader v-if="loading && !statistics" height="200" />

    <section v-if="statistics">
      <v-container class="pt-0">
        <SectionHeader
          :title="$t('ng.time_settings')"
          :subtitle="$t('ng.time_settings_descr')"
          class="pl-0 mb-2"
        >
          <template #action>
            <v-btn
              x-small
              depressed
              color="primary"
              :to="{ name: 'UserOverview' }"
            >
              {{ $t("ng.go_to_users") }}
            </v-btn>
          </template>
        </SectionHeader>

        <StatisticsTimepicker />
      </v-container>
    </section>

    <section v-if="statistics && loaded">
      <v-container>
        <v-row>
          <v-col cols="12">
            <SectionHeader
              :title="$t('views.statistics.registers')"
              :subtitle="$t('ng.registers_descr')"
              class="pl-0 mb-4"
            />

            <ChartLine
              :chart-data="stats.registers"
              :options="chartOptions"
              :loaded="loaded"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="statistics" class="my-4">
      <v-container>
        <v-row dense>
          <v-col cols="6">
            <StatisticsCardSmall
              :title="accumulated_values.registers.toLocaleString()"
              :subtitle="$t('views.statistics.registers')"
              icon="mdi-account-plus"
            />
          </v-col>
          <v-col cols="6">
            <StatisticsCardSmall
              :title="accumulated_values.logins.toLocaleString()"
              :subtitle="$t('views.statistics.logins')"
              icon="mdi-account-star"
              color="secondary"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="statistics">
      <v-container>
        <v-row>
          <v-col cols="12">
            <SectionHeader
              :title="$t('views.statistics.logins')"
              :subtitle="$t('ng.logins_descr')"
              class="pl-0 mb-4"
            />
            <ChartLine
              :chart-data="stats.logins"
              :options="chartOptions"
              :loaded="loaded"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import StatisticsTimepicker from "@/components/statistics/StatisticsTimepicker.vue";
import ChartLine from "@/components/charts/ChartLine.vue";
import SectionHeader from "@/components/_layout/SectionHeader.vue";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";

export default {
  name: "StatisticsUser",
  components: {
    ViewLoader,
    StatisticsTimepicker,
    ChartLine,
    SectionHeader,
    StatisticsCardSmall,
  },
  data() {
    return {
      loading: true,
      loaded: false,
      stats: {
        logins: {
          labels: [],
          datasets: [],
        },
        registers: {
          labels: [],
          datasets: [],
        },
      },
      accumulated_values: {
        logins: 0,
        registers: 0,
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          xAxes: [
            {
              ticks: {
                autoSkip: true,
                maxRotation: 90,
                minRotation: 90,
              },
              gridLines: {
                color: "rgba(0,175,215,0.1)",
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                precision: 0,
                min: 0,
              },
              gridLines: {
                color: "rgba(0,175,215,0)",
              },
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapGetters("statistics", {
      statistics: "get_statistics",
      options: "get_options",
    }),
  },
  watch: {
    /* 'options.currentChart': function() { this.generateStatistics(); }, */
    options: {
      handler: function () {
        this.fetchStatistics();
      },
      deep: true,
    },
    statistics: function () {
      this.generateStatistics();
    },
  },
  destroyed() {
    this.set_statistics(null);
  },
  methods: {
    ...mapActions("statistics", ["fetch_statistics"]),
    ...mapMutations("statistics", ["set_statistics"]),
    async fetchData() {
      await this.fetchStatistics();
    },
    async fetchStatistics() {
      this.loading = true;
      let payload = {
        timeFrame: this.options.timeFrame,
        timePointer: this.options.timePointer,
      };
      await this.fetch_statistics(payload, { cancelToken: this.source.token });
      this.loading = false;
    },
    createDataset(data, label, color) {
      return {
        label: label,
        data: data,
        backgroundColor: "transparent",
        color: "#000000",
        borderColor: color,
        pointBackgroundColor: "transparent",
        pointBorderColor: "transparent",
        sum: data.reduce((a, b) => a + b, 0),
      };
    },
    calculateAccumulatedValues() {
      this.accumulated_values.registers =
        this.statistics.datasets.registers.reduce((a, b) => a + b, 0);
      this.accumulated_values.logins = this.statistics.datasets.logins.reduce(
        (a, b) => a + b,
        0
      );
    },
    formatLabel(label) {
      if (
        this.statistics.timeFrame === 2 ||
        (this.statistics.timeFrame === 1 && this.statistics.timePointer === 0)
      ) {
        return this.$t("general.monthNumeric." + label);
      }
      return label.slice(5);
    },
    generateStatistics() {
      if (!this.statistics) return false;
      this.loaded = false;
      this.calculateAccumulatedValues();
      let labels = this.statistics.labels.map((label) => {
        return this.formatLabel(label);
      });

      this.stats.logins.labels = labels;
      let datasets_logins = [];
      datasets_logins.push(
        this.createDataset(
          this.statistics.datasets.logins,
          this.$t("views.statistics.logins"),
          this.$vuetify.theme.themes.light.secondary
        )
      );
      this.stats.logins.datasets = datasets_logins;

      this.stats.registers.labels = labels;
      let datasets_registers = [];
      datasets_registers.push(
        this.createDataset(
          this.statistics.datasets.registers,
          this.$t("views.statistics.registers"),
          this.$vuetify.theme.themes.light.primary
        )
      );
      this.stats.registers.datasets = datasets_registers;
      this.loaded = true;
    },
  },
};
</script>
