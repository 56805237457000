<template>
  <div>
    <v-tooltip :disabled="!['editor'].includes(role)" color="primary" left>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn
            color="error"
            x-small
            depressed
            class="mr-2"
            :disabled="['editor'].includes(role)"
            @click="showDialog = true"
          >
            {{ $t("general.delete") }}
          </v-btn>
        </div>
      </template>
      <span>{{ $t("resources.categories.editor_no_delete") }}</span>
    </v-tooltip>

    <v-dialog :value="showDialog" width="500" @click:outside="closeDialog()">
      <v-card :loading="loading">
        <v-card-title class="text-overline pb-0">
          <span>{{ $t("resources.categories.delete_title") }}</span>
        </v-card-title>

        <v-card-text class="pt-2">
          <p
            class="grey lighten-3 black--text font-weight-bold text-center pa-2"
          >
            {{ category.name }}
          </p>
          <p>
            {{ $t("resources.categories.delete_description") }}
          </p>
          <v-alert type="error" dense text>
            <span
              class="caption"
              v-html="$t('resources.categories.delete_warning')"
            />
          </v-alert>
          <v-alert v-if="!category.can.delete" type="error" dense text>
            <span class="caption" v-html="$t('ng.delete_category_hint')" />
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text small @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn color="error" text small @click="deleteCategory">
            {{ $t("general.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "CategoryRemover",
  props: {
    category: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("categories", ["delete_category"]),
    closeDialog() {
      this.showDialog = false;
    },
    async deleteCategory() {
      this.loading = true;
      let res = await this.delete_category({
        id: this.category.id,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.categories.delete_success"),
        });
        this.closeDialog();
        this.$router.push({ name: "CategoryOverview" });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text:
            res._status === 400
              ? this.$t("resources.categories.delete_error_substructure")
              : this.$t("resources.categories.delete_error"),
        });
      }
    },
  },
};
</script>
