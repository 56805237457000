<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      :title="$t('navigation.management.title')"
      icon="mdi-account-cog"
    />

    <section>
      <SectionHeader :title="`${$t('general.overview')}`" />

      <v-container class="py-0">
        <v-row>
          <v-col cols="12" sm="6">
            <TextWidget
              color="grey lighten-3"
              :title="$t('resources.users.plural')"
              route-name="UserOverview"
              :route-description="$t('general.show')"
              :subtitle="$t('views.management.user_teaser')"
              link-type-text
              link-color="secondary"
              icon="mdi-account-multiple"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <TextWidget
              color="grey lighten-3"
              :title="$t('resources.invitations.plural')"
              route-name="InvitationOverview"
              :route-description="$t('general.show')"
              :subtitle="$t('views.management.invitation_teaser')"
              link-type-text
              link-color="secondary"
              icon="mdi-comment-question"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <TextWidget
              color="grey lighten-3"
              :title="$t('resources.codes.plural')"
              route-name="CodeOverview"
              :route-description="$t('general.show')"
              :subtitle="$t('views.management.code_teaser')"
              link-type-text
              link-color="secondary"
              icon="mdi-numeric"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import ViewHeader from "@/components/_layout/ViewHeader.vue";
import TextWidget from "@/components/widgets/TextWidget";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "ManagementView",
  components: {
    ViewHeader,
    TextWidget,
    SectionHeader,
  },
  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: this.$t("navigation.management.title"),
          disabled: true,
        },
      ];
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "manager"]);
  },
};
</script>

<style></style>
