<template>
  <div>
    <slot name="action" :open-dialog="openDialog" />
    <div v-if="showDialog">
      <v-dialog
        v-model="showDialog"
        max-width="640"
        scrollable
        :persistent="loading"
        @click:outside="loading ? false : closeDialog()"
      >
        <v-card v-if="!!input" color="grey lighten-3" :loading="loading">
          <v-card-title class="overline secondary--text">
            <v-icon color="secondary" class="mr-2"> mdi-certificate </v-icon>
            <span>{{ $t("certificate.add") }}</span>
          </v-card-title>
          <v-card-text>
            <p>{{ $t("certificate.add_hint") }}</p>
            <v-form v-model="valid">
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="input.name"
                    outlined
                    dense
                    :label="$t('certificate.form.labels.name')"
                    :hint="$t('certificate.form.hints.name')"
                    persistent-hint
                    clearable
                    counter="64"
                    :rules="rules.name"
                    background-color="white"
                  />
                </v-col>
                <v-col cols="12" sm="6">
                  <LanguageChooserInline
                    colored
                    dense
                    :label="$t('ng.source_language')"
                    :hint="$t('ng.source_language_hint')"
                    persistent-hint
                    class="pb-0"
                    :initial="input.language"
                    @change="changeLanguage"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              small
              text
              color="error"
              :disabled="loading"
              @click="closeDialog()"
            >
              {{ $t("general.close") }}
            </v-btn>
            <v-btn
              small
              text
              :loading="loading"
              color="success"
              :disabled="!valid || loading"
              @click="addCertificate()"
            >
              {{ $t("general.add") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Certificate } from "@/models/Certificate.js";
import LanguageChooserInline from "@/components/global/LanguageChooserInline";

export default {
  name: "AddCertificateDialog",
  components: {
    LanguageChooserInline,
  },
  data() {
    return {
      showDialog: false,
      input: null,
      valid: true,
      loading: false,
      rules: {
        name: [
          (v) =>
            !!v ||
            this.$t("forms.rules.isRequired", {
              inputFieldName: this.$t("resources.categories.name"),
            }),
          (v) =>
            (v && v.length <= 64) ||
            this.$t("forms.rules.maxCharacters", { max: 64 }),
        ],
      },
    };
  },
  computed: {
    ...mapGetters("community", { settings: "get_settings" }),
  },
  methods: {
    ...mapActions("journeys", ["add_certificate"]),
    openDialog() {
      this.setInput();
      this.showDialog = true;
    },
    closeDialog() {
      this.input = null;
      this.showDialog = false;
    },
    setInput() {
      this.input = new Certificate({});
      this.input.language = this.$i18n.locale;
    },
    async addCertificate() {
      this.loading = true;
      var payload = { ...this.input };
      payload.color_accent = this.getAccentColor();
      var res = await this.add_certificate({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          title: this.$t("general.success"),
          text: this.$t("certificate.activation.add_success"),
          type: "success",
        });
        this.$router.push({
          name: "CertificateDetail",
          params: { id: res.certificate.id },
        });
      } else {
        this.$notify({
          title: this.$t("general.error"),
          text: this.$t("certificate.activation.add_error"),
          type: "error",
        });
      }
    },
    changeLanguage(v) {
      this.input.language_short = v;
    },
    getAccentColor() {
      var theme = { ...this.settings.theme };
      var custom_color = theme.primary;

      if (!custom_color) return "#00afd7";

      let prop_type = custom_color.constructor.name;
      if (prop_type === "String") return custom_color;
      if (prop_type === "Array") {
        return custom_color[0];
      }
      console.error("color set error");
    },
  },
};
</script>
