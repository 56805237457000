import { render, staticRenderFns } from "./CategoryDetail.vue?vue&type=template&id=5e89affe&scoped=true&"
import script from "./CategoryDetail.vue?vue&type=script&lang=js&"
export * from "./CategoryDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e89affe",
  null
  
)

export default component.exports