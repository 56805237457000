<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading && structure_statistics">
      <v-container>
        <v-row dense>
          <v-col cols="6">
            <StatisticsCardSmall
              :title="structureCount"
              :subtitle="$t('ng.structures_count')"
              icon="mdi-account-multiple"
              :link="{ name: 'UserOverview' }"
            />
          </v-col>
          <v-col cols="6">
            <StatisticsCardSmall
              :title="substructureCount"
              :subtitle="$t('ng.substructures_count')"
              icon="mdi-account-multiple"
              :link="{ name: 'UserOverview' }"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="!loading && structure_statistics">
      <v-container>
        <SectionHeader
          :title="$t('ng.structure_overview')"
          :subtitle="$t('ng.structure_overview_descr')"
          class="px-0"
        />

        <v-row>
          <v-col cols="12">
            <v-list dense color="transparent" mandatory>
              <v-list-group
                v-for="(structure, i) in structure_statistics"
                :key="i"
                class="pl-0 grey lighten-3 mb-2"
              >
                <template #activator>
                  <v-list-item-title>
                    {{ structure.name }}
                  </v-list-item-title>
                </template>
                <template #appendIcon>
                  <span class="font-weight-bold secondary--text">{{
                    structure.users_count
                  }}</span>
                </template>

                <v-list-item
                  v-for="substructure in structure.substructures"
                  :key="substructure.id"
                >
                  <span class="text-caption">{{ substructure.name }}</span>
                  <v-spacer />
                  <span class="caption font-weight-bold secondary--text"
                    >{{ substructure.users_count }}
                    {{ $t("resources.users.plural") }}</span
                  >
                </v-list-item>
              </v-list-group>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader.vue";
import SectionHeader from "@/components/_layout/SectionHeader";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";

export default {
  name: "StatisticsStructures",
  components: {
    ViewLoader,
    SectionHeader,
    StatisticsCardSmall,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("structures", {
      structure_statistics: "get_structure_statistics",
    }),
    structureCount() {
      if (!this.structure_statistics) return 0;
      return this.structure_statistics.length;
    },
    substructureCount() {
      if (!this.structure_statistics) return 0;
      let count = 0;
      this.structure_statistics.forEach(
        (structure) => (count += structure.substructures.length)
      );
      return count;
    },
  },
  destroyed() {
    this.set_structures(null);
  },
  methods: {
    ...mapActions("structures", ["fetch_structures_statistics"]),
    ...mapMutations("structures", ["set_structures"]),
    async fetchData() {
      this.loading = true;
      //await this.fetch_structures({ cancelToken: this.source.token });
      await this.fetch_structures_statistics({
        cancelToken: this.source.token,
      });
      this.loading = false;
    },
  },
};
</script>
