<template>
  <div>
    <section v-if="!loading">
      <v-container>
        <SectionHeader
          :title="$t('ng.top_3')"
          :subtitle="$t('ng.data_last_month')"
          class="px-0 mb-8"
        />
        <v-row>
          <v-col cols="12" sm="6">
            <StatisticsRankingList
              :data="statistics_details.duels"
              :title="$t('ng.top3_duels_title')"
              :subtitle="$t('ng.top3_duels_subtitle')"
              item-title="user"
              another-prop="user_name"
              append-value="count"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <StatisticsRankingList
              :data="statistics_details.wins"
              :title="$t('ng.top3_wins_title')"
              :subtitle="$t('ng.top3_wins_subtitle')"
              item-title="user"
              another-prop="user_name"
              append-value="count"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <StatisticsRankingList
              :data="statistics_details.submissions"
              :title="$t('ng.top3_submissions_title')"
              :subtitle="$t('ng.top3_submissions_subtitle')"
              item-title="user"
              another-prop="user_name"
              append-value="count"
            />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import StatisticsRankingList from "@/components/statistics/StatisticsRankingList";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "StatisticsTopThree",
  components: {
    StatisticsRankingList,
    SectionHeader,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("statistics", {
      statistics_details: "get_statistics_details",
    }),
  },
  destroyed() {
    this.set_statistics_details(null);
  },
  methods: {
    ...mapActions("statistics", ["fetch_statistics_details"]),
    ...mapMutations("statistics", ["set_statistics_details"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_statistics_details({ cancelToken: this.source.token });
      this.loading = false;
    },
  },
};
</script>
