<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-switch
            v-model="input.show_chars"
            :disabled="question.is_active || loading"
            :label="$t('ng.gap_option')"
            @change="updateOptions()"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { QuestionGap } from "@/models/content/questions/types/QuestionGap";

export default {
  name: "QuestionOptionsEdit",
  props: {
    question: {
      type: [QuestionGap],
      required: true,
    },
  },
  data() {
    return {
      input: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters("questions", { language: "get_selected_language" }),
  },
  watch: {
    question() {
      this.setOptions();
    },
  },
  beforeMount() {
    this.setOptions();
  },
  methods: {
    ...mapActions("questions", ["patch_question"]),
    setOptions() {
      let options = { ...this.question.options };
      if (!options || Object.keys(options).length === 0) {
        options = {
          show_chars: false,
        };
      }
      this.input = options;
    },
    async updateOptions() {
      this.loading = true;
      let properties = [{ name: "options", value: { ...this.input } }];
      let payload = {
        id: this.question.id,
        language: this.language,
        override: false,
        properties: properties,
      };
      let res = await this.patch_question({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
  },
};
</script>
