<template>
  <div>
    <div v-for="(level, index) in levels" :key="index">
      <h5>{{ $t("resources.journeys.level") }} {{ index + 1 }}</h5>
      <v-list dense color="transparent" min-height="80px">
        <draggable
          :list="level.bundles"
          class="list-group"
          v-bind="dragOptions"
          group="bundles"
          :disabled="
            loading ||
            journey.status !== 'unpublished' ||
            !['superadmin', 'admin', 'editor'].includes(role)
          "
          :empty-insert-threshold="44"
          @start="isDragging = true"
          @end="
            isDragging = false;
            changeOrder();
          "
        >
          <transition-group type="transition" name="list">
            <v-sheet
              v-for="bundle in level.bundles"
              :key="bundle.id"
              :elevation="journey.status === 'unpublished' ? 1 : 0"
            >
              <v-list-item
                class="mb-2 sorter-list-item"
                dense
                :class="{
                  inactive:
                    journey.status !== 'unpublished' ||
                    !['superadmin', 'admin', 'editor'].includes(role),
                }"
              >
                <v-list-item-icon class="mr-3">
                  <v-icon small> mdi-swap-vertical-bold </v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ bundle.name }}</v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    text
                    x-small
                    :to="{
                      name: 'JourneyBundle',
                      params: { bundle_id: bundle.id },
                    }"
                  >
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-sheet>
          </transition-group>
        </draggable>
      </v-list>
    </div>
    <v-btn
      v-if="['superadmin', 'admin'].includes(role)"
      x-small
      depressed
      color="primary"
      :disabled="
        !['unpublished'].includes(journey.status) ||
        journey.bundles.length == 0 ||
        levels.length > bundleLevelCount
      "
      @click="addLevel"
    >
      {{ $t("resources.journeys.addLevel") }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import draggable from "vuedraggable";
import { Journey } from "@/models/journeys/Journey.js";

export default {
  name: "BundleSorter",
  components: {
    draggable,
  },
  props: {
    journey: {
      type: Journey,
      required: true,
    },
    bundles: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      levels: [],
      loading: false,
      isDragging: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "list",
        disabled: !["unpublished"].includes(this.journey.status),
        ghostClass: "ghost",
      };
    },
    bundleLevelCount() {
      let count = 0;
      this.bundles.forEach((bundle) => {
        if (bundle.level > count) count = bundle.level;
      });
      return count;
    },
  },
  watch: {
    journey() {
      this.generateLevels();
    },
    bundles() {
      this.generateLevels();
    },
  },
  beforeMount() {
    this.generateLevels();
  },
  methods: {
    ...mapActions("journeys", ["move_bundles"]),
    generateLevels() {
      let levelIDs = [];
      let levels = [];
      let bundles = this.bundles;
      bundles.forEach((bundle) => {
        if (!levelIDs.includes(bundle.level)) levelIDs.push(bundle.level);
      });
      levelIDs.forEach((level) => {
        let levelObj = {
          id: level,
          bundles: bundles.filter((bundle) => bundle.level == level),
        };
        levels.push(levelObj);
      });
      levels.sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0));
      this.levels = levels;
    },
    async changeOrder() {
      this.loading = true;
      let newBundleOrder = [];
      let levelsToDelete = [];
      let counter = 0;
      this.levels.forEach((level) => {
        if (!level.bundles || level.bundles.length == 0) {
          levelsToDelete.push(level.id);
        } else {
          counter++;
        }

        level.bundles.forEach((bundle) => {
          newBundleOrder.push({ id: bundle.id, level: counter });
        });
      });

      let payload = {
        journey_id: this.journey.id,
        bundles: newBundleOrder,
      };

      this.levels = this.levels.filter(
        (level) => !levelsToDelete.includes(level.id)
      );
      let res = await this.move_bundles({
        payload: payload,
        cancelToken: this.source.token,
      });

      if (res) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("resources.journeys.moveSuccess"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: res.error
            ? this.$t("errors." + res.error)
            : this.$t("resources.journeys.moveError"),
        });
      }
      this.loading = false;
    },
    addLevel() {
      let maxLvl = 0;
      this.levels.forEach((level) => {
        if (level.id > maxLvl) maxLvl = level.id;
      });
      this.levels.push({
        id: maxLvl + 1,
        bundles: [],
      });
    },
  },
};
</script>

<style>
.list-move {
  transition: transform 1s;
}
.no-move {
  transition: transform 1s;
}
.ghost {
  opacity: 0.5;
  background: grey;
}
.list-groups {
  min-height: 80px;
}
.sorter-list-item {
  border-left: 4px solid #617d8b;
  background: white;
  cursor: move;
}
.sorter-list-item.inactive {
  opacity: 0.75;
  cursor: no-drop;
}
</style>
