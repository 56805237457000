<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading" v-show="!!credit_pool">
      <SectionHeader
        :title="$t('gw.licences.can_overload')"
        :subtitle="$t('gw.licences.credit_overload_hint')"
      />

      <GwCreditPoolManager disabled />
    </section>

    <section v-if="!loading">
      <SectionHeader
        :title="$t('gw.licences.active_licences')"
        :subtitle="$t('gw.licences.active_licences_hint')"
      />
      <v-container>
        <v-row>
          <v-col cols="12">
            <GwLicenceList :licences="visibleLicences" />
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader";
import SectionHeader from "@/components/_layout/SectionHeader";
import GwLicenceList from "@/components/ghostwriter/GwLicenceList";
import GwCreditPoolManager from "@/components/administration/ghostwriter/GwCreditPoolManager";

export default {
  name: "GwManagement",
  components: {
    ViewLoader,
    SectionHeader,
    GwLicenceList,
    GwCreditPoolManager,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", {
      licences: "get_licences",
      credit_pool: "get_credit_pool",
    }),
    visibleLicences() {
      if (!this.licences) return [];
      var licences = [...this.licences];
      return licences.filter((l) => !l.is_superadmin);
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["fetch_licences"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_licences({ cancelToken: this.source.token });
      this.loading = false;
    },
  },
};
</script>

<style></style>
