<template>
  <div class="pt-2">
    <v-slide-y-transition hide-on-leave>
      <section v-if="!!session && !loading && session.status === 0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card type="info" color="secondary" dark flat>
                <v-card-title>
                  <v-icon class="mr-2">mdi-information-box</v-icon>
                  <span class="overline">{{ importStatus }}</span>
                </v-card-title>
                <v-card-text>
                  <p>
                    {{ $t("gw.bulk.hints.generate_session") }}
                  </p>
                  <div class="mb-2">
                    <span>{{ $t("gw.bulk.hints.generate_cost_1") }}</span>
                    <v-icon small class="ml-2 mr-1" color="#ffd700">
                      mdi-circle-multiple
                    </v-icon>
                    <span class="font-weight-bold">
                      {{
                        $t("gw.xCredits", {
                          number:
                            session.cost_prediction.credits.toLocaleString(),
                        })
                      }}
                    </span>
                    <span>{{ $t("gw.bulk.hints.generate_cost_2") }}</span>
                  </div>
                  <v-alert
                    v-if="
                      (!licences.ghostwriter.licence.can_overload &&
                        session.cost_prediction.credits >
                          licences.ghostwriter.credits_count) ||
                      (licences.ghostwriter.licence.can_overload &&
                        session.cost_prediction.credits >
                          licences.ghostwriter.credits_count +
                            licences.ghostwriter.licence.credits_overload)
                    "
                    type="info"
                    color="error"
                    dense
                    class="mb-0"
                  >
                    {{ $t("gw.bulk.hints.generate_cost_fail") }}
                  </v-alert>
                  <v-alert
                    v-if="
                      licences.ghostwriter.licence.can_overload &&
                      session.cost_prediction.credits <=
                        licences.ghostwriter.licence.credits_overload +
                          licences.ghostwriter.credits_count &&
                      session.cost_prediction.credits >
                        licences.ghostwriter.credits_count
                    "
                    type="info"
                    color="white"
                    icon="mdi-bank"
                    dense
                    text
                    class="mb-0"
                  >
                    {{ $t("gw.licences.credit_pool_usage_hint") }}
                  </v-alert>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="white"
                    depressed
                    class="secondary--text"
                    :loading="loading"
                    :disabled="
                      session.status !== 0 ||
                      licenceExpired ||
                      (!licences.ghostwriter.licence.can_overload &&
                        session.cost_prediction.credits >
                          licences.ghostwriter.credits_count) ||
                      (licences.ghostwriter.licence.can_overload &&
                        session.cost_prediction.credits >
                          licences.ghostwriter.licence.credits_overload +
                            licences.ghostwriter.credits_count) ||
                      session.has_only_empty_parts
                    "
                    @click="generateContent()"
                    >{{ $t("gw.generateContent") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-slide-y-transition>

    <v-expand-transition>
      <section v-if="!!session && !loading && [1, 6].includes(session.status)">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card type="info" color="secondary" dark flat loading>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="12" sm="6" md="6" offset-md="1">
                      <h5>{{ $t("gw.patience_please") }}</h5>
                      <p>
                        {{ $t("gw.bulk.hints.generating_time") }}
                      </p>
                      <h5>
                        {{ importStatus }}
                      </h5>
                      <div class="d-flex align-center">
                        <v-progress-linear
                          color="white"
                          :value="importProgressValue"
                          :buffer-value="0"
                          :stream="importProgressValue > 0"
                          :indeterminate="importProgressValue === 0"
                          height="8"
                        />
                        <div
                          v-if="importProgressValue > 0"
                          class="ml-2 caption font-weight-bold"
                        >
                          {{ importProgressValue }}%
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="text-center">
                      <v-avatar size="180" color="white">
                        <v-img src="@/assets/img/misc/ghostwriter.png" />
                      </v-avatar>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expand-transition>

    <v-expand-transition>
      <section v-if="!!session && !loading && [4, 5].includes(session.status)">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert type="error" color="error" prominent>
                <h5>{{ importStatus }}</h5>
                <p>
                  {{ $t("gw.bulk.hints.generating_error") }}
                </p>

                <v-btn
                  color="white"
                  depressed
                  class="secondary--text"
                  :loading="loading"
                  :disabled="
                    ![4, 5].includes(session.status) ||
                    licenceExpired ||
                    session.cost_prediction.credits >
                      licences.ghostwriter.credits_count ||
                    session.has_only_empty_parts
                  "
                  @click="generateContent()"
                  >{{ $t("gw.generateContentAgain") }}</v-btn
                >
              </v-alert>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expand-transition>

    <section v-if="!!session && session.status === 7">
      <SectionHeader
        :title="$t('gw.generated_content')"
        :subtitle="$t('resources.questions.sectionHeaders.overview.title')"
      >
        <template #action>
          <div class="d-flex align-center">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-avatar tile size="24" v-bind="attrs" v-on="on">
                  <country-flag
                    :country="session.options.language.slice(-2)"
                    size="small"
                  />
                </v-avatar>
              </template>
              <span>{{
                $t("gw.selectedLanguage", {
                  lang: $t(`languages.${session.options.language}.display`),
                })
              }}</span>
            </v-tooltip>
          </div>
        </template>
      </SectionHeader>

      <v-container>
        <v-row dense>
          <v-col cols="12" md="4">
            <StatisticsCardSmall
              :title="$t('gw.addSession.settings')"
              :subtitle="$t('general.edit')"
              icon="mdi-cogs"
              click-type="dialog"
              color="secondary"
              @clicked="showOptions = !showOptions"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <StatisticsCardSmall
              :title="`${session.question_count} (${
                session.unedited_question_count
              } ${$t('gw.unedited')})`"
              :subtitle="$t('resources.questions.plural')"
              :icon="
                session.unedited_question_count > 0
                  ? 'mdi-comment-question'
                  : 'mdi-check'
              "
              :color="
                session.unedited_question_count > 0
                  ? 'grey lighten-3'
                  : 'success'
              "
              :text-color="
                session.unedited_question_count > 0 ? 'secondary' : 'white'
              "
              :icon-color="
                session.unedited_question_count > 0 ? 'secondary' : 'white'
              "
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <StatisticsCardSmall
              :title="`${session.nugget_count} (${
                session.unedited_nugget_count
              } ${$t('gw.unedited')})`"
              :subtitle="$t('resources.nuggets.plural')"
              :icon="
                session.unedited_nugget_count > 0 ? 'mdi-school' : 'mdi-check'
              "
              :color="
                session.unedited_nugget_count > 0 ? 'grey lighten-3' : 'success'
              "
              :text-color="
                session.unedited_nugget_count > 0 ? 'secondary' : 'white'
              "
              :icon-color="
                session.unedited_nugget_count > 0 ? 'secondary' : 'white'
              "
            />
          </v-col>
        </v-row>

        <v-expand-transition>
          <v-row v-if="session.importing_items_count > 0">
            <v-col cols="12">
              <v-alert text color="info" dense type="info">
                <h5>{{ $t("gw.bulk.importing_content") }}</h5>
                <p class="mb-1">
                  {{
                    $t("gw.bulk.hints.importing_content", {
                      count: session.importing_items_count,
                    })
                  }}
                </p>
                <v-btn
                  depressed
                  x-small
                  color="info"
                  :to="{ name: 'GwSessionBulkContent' }"
                  >{{ $t("ng.goTo") }}</v-btn
                >
              </v-alert>
            </v-col>
          </v-row>
        </v-expand-transition>
      </v-container>
    </section>

    <v-divider
      v-if="!!session && ![0, 1, 2, 4, 5, 6].includes(session.status)"
      class="my-4"
    />

    <section v-if="!!session && session.status === 7" class="mt-4">
      <v-container>
        <v-row>
          <v-col cols="12" md="8" offset-md="2">
            <v-card
              v-if="session.unedited_question_count === 0"
              color="grey lighten-3"
              flat
              height="120"
            >
              <v-card-text
                class="d-flex align-center justify-center fill-height"
              >
                {{ $t("gw.bulk.hints.no_content_left") }}
              </v-card-text>
            </v-card>
            <v-expand-transition>
              <GwBulkContentCard
                v-if="session.unedited_question_count > 0"
                :item="currentItem"
                :current-index="contentIndex"
                :item-count="session.unedited_content.length"
                :session="session"
                @prev="contentIndex--"
                @next="contentIndex++"
              />
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <!-- TODO: remove start -->

    <section
      v-if="!!session && role === 'superadmin'"
      class="grey lighten-3 mt-4"
    >
      <SectionHeader
        :title="$t('gw.parts')"
        :subtitle="$t('gw.bulk.hints.show_parts')"
      >
        <template #action>
          <div class="d-flex align-center">
            <v-tooltip left color="primary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="secondary"
                  v-bind="attrs"
                  v-on="on"
                  @click="showPartPreview = !showPartPreview"
                >
                  <v-icon>
                    {{
                      showPartPreview
                        ? "mdi-chevron-up-circle"
                        : "mdi-chevron-down-circle"
                    }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("ng.show_all") }}</span>
            </v-tooltip>
          </div>
        </template>
      </SectionHeader>
      <v-expand-transition>
        <v-container v-if="showPartPreview">
          <v-row>
            <v-col v-for="(part, i) in session.parts" :key="i" cols="6">
              <v-card flat color="grey lighten-3">
                <v-card-title class="overline secondary--text"
                  >{{ $t("gw.part") }} {{ i + 1 }}</v-card-title
                >
                <v-card-text class="caption">
                  {{ part.content }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
    </section>
    <!-- TODO: remove end -->

    <GwSessionOptions
      v-if="!!session"
      :session="session"
      :categories="categories"
      :visible="showOptions"
      :disabled="
        !licences.ghostwriter ||
        licenceExpired ||
        (session && ![0, 7].includes(session.status))
      "
      class="mb-4"
      no-activation
      @close="showOptions = false"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GwBulkContentCard from "@/components/ghostwriter/GwBulkContentCard";
import SectionHeader from "@/components/_layout/SectionHeader";
import StatisticsCardSmall from "@/components/statistics/StatisticsCardSmall";
import GwSessionOptions from "@/components/ghostwriter/GwSessionOptions";
import CountryFlag from "vue-country-flag";

export default {
  name: "GwSessionBulkMain",
  components: {
    GwBulkContentCard,
    SectionHeader,
    StatisticsCardSmall,
    GwSessionOptions,
    CountryFlag,
  },
  data() {
    return {
      loading: false,
      contentIndex: 0,
      showPartPreview: false,
      showOptions: false,
      hideEditedQuestions: true,
      hideEditedNuggets: true,
      progress: null,
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { session: "get_bulk_session" }),
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    currentItem() {
      if (!this.session) return null;
      var content = [...this.session.unedited_content];
      var item = content[this.contentIndex];
      return item;
    },
    importStatus() {
      var finished_count = 0;
      this.session.parts.forEach((part) => {
        if (part.is_generated) finished_count++;
      });
      if (this.session.status === 0) return this.$t("gw.bulk.status.0");
      if (this.session.status === 1) return this.$t("gw.bulk.status.1");
      if (this.session.status === 4) return this.$t("gw.bulk.status.4");
      if (this.session.status === 5) return this.$t("gw.bulk.status.5");
      if (this.session.status === 6 && finished_count === 0)
        return this.$t("gw.bulk.status.6_unfinished");
      if (this.session.status === 6 && finished_count > 0)
        return this.$t("gw.bulk.status.6_finished");
      if (this.session.status === 7) return this.$t("gw.bulk.status.7");
      return this.$t("gw.bulk.status.unknown");
    },
    importProgressValue() {
      var part_count = this.session.parts.length;
      var finished_count = 0;
      this.session.parts.forEach((part) => {
        if (part.is_generated) finished_count++;
      });
      return Math.round((finished_count / part_count) * 100);
    },
  },
  watch: {
    session: {
      handler: function (v) {
        if (v) this.checkStatus();
      },
      deep: true,
    },
  },
  destroyed() {
    if (this.progress) this.progress = clearInterval(this.progress);
  },
  methods: {
    ...mapActions("ghostwriter", [
      "generate_bulk_content",
      "fetch_bulk_session",
    ]),
    async generateContent() {
      this.loading = true;
      var payload = {
        id: this.session.id,
      };
      var res = await this.generate_bulk_content({
        payload: payload,
        cancelToken: this.source.token,
      });
      this.loading = false;
      if (res && res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
        this.checkStatus();
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async refreshSession() {
      if (![1, 2, 6].includes(this.session.status)) {
        this.progress = clearInterval(this.progress);
        return false;
      }
      await this.fetch_bulk_session({
        id: this.session.id,
        cancelToken: this.source.token,
      });
    },
    async checkStatus() {
      if (!!this.progress || ![1, 2, 6].includes(this.session.status))
        return false;
      this.progress = setInterval(
        function () {
          this.refreshSession();
        }.bind(this),
        6000
      );
    },
  },
};
</script>

<style></style>
