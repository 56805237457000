<template>
  <div>
    <v-container>
      <v-form ref="form-body" v-model="valid.body">
        <v-row>
          <v-col cols="12">
            <InputTextarea
              v-model="input.body"
              :label="$t('resources.questions.body')"
              counter="255"
              :body-format="input.body_format"
              disabled
              translatable
              :rules="input.formRules.body"
              preview
              @is-editing="setEditing"
              @save="updateBody"
              @cancel="setQuestion"
            >
              <template
                #actions="{
                  editing,
                  startEditing,
                  cancelEditing,
                  saveInput,
                  changeFormat,
                  togglePreview,
                }"
              >
                <div v-if="!editing" class="d-flex flex-column ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="
                            isEditing || disabled || editModes.time || loading
                          "
                          @click="startEditing()"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <QuestionTranslationDialog
                          prop-name="body"
                          :question="question"
                          :disabled="!question.body || disabled || loading"
                        >
                          <template #button="{ onClick }">
                            <v-btn
                              icon
                              :disabled="
                                ['math'].includes(question.body_format) ||
                                isEditing ||
                                loading
                              "
                              @click="onClick"
                            >
                              <v-icon small> mdi-translate </v-icon>
                            </v-btn>
                          </template>
                        </QuestionTranslationDialog>
                      </div>
                    </template>
                    <span>{{ $t("general.translations") }}</span>
                  </v-tooltip>
                </div>
                <div v-if="editing" class="d-flex flex-column ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon color="error" @click="cancelEditing()">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.cancel") }}</span>
                  </v-tooltip>

                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          color="success"
                          :disabled="!input.body || input.body.length < 1"
                          @click="saveInput()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.save") }}</span>
                  </v-tooltip>
                  <v-tooltip class="align-self-end" left dense color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" v-on="on">
                        <v-btn
                          icon
                          :disabled="!editing"
                          @click="changeFormat()"
                        >
                          <v-icon small> mdi-format-letter-case-upper </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("ng.change_format") }}</span>
                  </v-tooltip>

                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon @click="togglePreview()">
                          <v-icon small> mdi-file-find </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("resources.invitations.preview") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextarea>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-container>
      <v-form ref="form-hint" v-model="valid.hint">
        <v-row>
          <v-col cols="12">
            <InputTextfield
              v-model="input.hint"
              :label="$t('ng.question_hint')"
              :rules="input.formRules.hint"
              disabled
              counter="255"
              translatable
              @is-editing="setEditing"
              @save="updateHint"
            >
              <template
                #actions="{
                  editing,
                  startEditing,
                  cancelEditing,
                  saveInput,
                  inputChanged,
                }"
              >
                <div v-if="!editing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          :disabled="isEditing || disabled || loading"
                          @click="startEditing() || editModes.time"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.edit") }}</span>
                  </v-tooltip>

                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <QuestionTranslationDialog
                          prop-name="hint"
                          :question="question"
                          :disabled="!question.hint || disabled || loading"
                        >
                          <template #button="{ onClick }">
                            <v-btn
                              icon
                              :disabled="
                                !question.hint ||
                                question.hint.length === 0 ||
                                isEditing ||
                                loading
                              "
                              @click="onClick"
                            >
                              <v-icon small> mdi-translate </v-icon>
                            </v-btn>
                          </template>
                        </QuestionTranslationDialog>
                      </div>
                    </template>
                    <span>{{ $t("general.translations") }}</span>
                  </v-tooltip>
                </div>
                <div v-if="editing" class="d-flex ml-2">
                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn icon color="error" @click="cancelEditing()">
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.cancel") }}</span>
                  </v-tooltip>

                  <v-tooltip left color="primary">
                    <template #activator="{ on, attrs }">
                      <div v-on="on" v-bind="attrs">
                        <v-btn
                          icon
                          color="success"
                          :disabled="!inputChanged"
                          @click="saveInput()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                    <span>{{ $t("general.save") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </InputTextfield>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="d-flex align-center">
            <v-slider
              v-model="input.time"
              thumb-label="always"
              min="0"
              max="180"
              color="secondary"
              track-color="secondary lighten-3"
              hide-details
              dense
              :rules="question.formRules.time"
              :disabled="!editModes.time"
            >
              <template #prepend>
                <div>
                  <v-text-field
                    v-model="input.time"
                    type="number"
                    min="0"
                    max="180"
                    outlined
                    style="width: 160px"
                    dense
                    :label="$t('ng.question_time')"
                    :background-color="editModes.time ? 'white' : 'background'"
                    hide-details
                    :rules="question.formRules.time"
                    :disabled="!editModes.time"
                    suffix="s"
                  />
                </div>
              </template>
            </v-slider>

            <div v-if="editModes.time" class="mb-3 d-flex">
              <v-tooltip left color="primary">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      icon
                      class="ml-2"
                      small
                      color="error"
                      :disabled="disabled"
                      @click="editModes.time = false"
                    >
                      <v-icon small> mdi-close </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("general.cancel") }}</span>
              </v-tooltip>

              <v-tooltip left color="primary">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      icon
                      class="ml-2"
                      small
                      color="success"
                      :disabled="disabled || input.time === question.time"
                      @click="updateTime()"
                    >
                      <v-icon small> mdi-check </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("general.save") }}</span>
              </v-tooltip>
            </div>
            <div v-if="!editModes.time" class="mb-3">
              <v-tooltip left color="primary">
                <template #activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-btn
                      icon
                      class="mx-1"
                      small
                      :disabled="isEditing || disabled || loading"
                      @click="editModes.time = true"
                    >
                      <v-icon small> mdi-pencil </v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t("general.edit") }}</span>
              </v-tooltip>
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-container>
      <v-expand-transition>
        <v-alert
          v-if="question.correctAnswerCount() !== 1"
          type="error"
          text
          dense
          colored-border
          border="left"
        >
          <div class="caption">
            <span>{{
              $t("ng.single_choice_hint", {
                count: question.correctAnswerCount(),
              })
            }}</span>
          </div>
        </v-alert>
      </v-expand-transition>

      <v-form ref="form-answers" v-model="valid.answers">
        <v-row>
          <v-col cols="12">
            <v-list class="transparent">
              <v-list-item
                v-for="(answer, i) in input.answers"
                :key="i"
                class="px-0"
              >
                <v-list-item-content>
                  <InputTextfield
                    v-model="input.answers[i].body"
                    :label="`${$t('resources.questions.answer')} ${i + 1}`"
                    :rules="input.formRules.answer"
                    counter="255"
                    disabled
                    :body-format="input.answers[i].body_format"
                    translatable
                    :original-id="input.answers[i].id"
                    correct-button
                    preview
                    :correct-value="input.answers[i].is_correct"
                    @is-editing="setEditing"
                    @save="updateAnswer"
                  >
                    <template
                      #actions="{
                        editing,
                        startEditing,
                        cancelEditing,
                        saveInput,
                        inputChanged,
                        changeFormat,
                        togglePreview,
                      }"
                    >
                      <div v-if="editing" class="ml-2 d-flex">
                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                :disabled="input.answers[i].body.length < 1"
                                @click="togglePreview()"
                              >
                                <v-icon small> mdi-file-find </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("resources.invitations.preview") }}</span>
                        </v-tooltip>

                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                :disabled="!editing"
                                @click="changeFormat()"
                              >
                                <v-icon small>
                                  mdi-format-letter-case-upper
                                </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("ng.change_format") }}</span>
                        </v-tooltip>

                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                color="error"
                                @click="cancelEditing()"
                              >
                                <v-icon small> mdi-close </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("general.cancel") }}</span>
                        </v-tooltip>

                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                color="success"
                                :disabled="!inputChanged"
                                @click="saveInput()"
                              >
                                <v-icon small> mdi-check </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("general.save") }}</span>
                        </v-tooltip>
                      </div>
                      <div v-if="!editing" class="d-flex ml-2">
                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                :disabled="isEditing || disabled || loading"
                                @click="startEditing() || editModes.time"
                              >
                                <v-icon small> mdi-pencil </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("general.edit") }}</span>
                        </v-tooltip>

                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <v-btn
                                icon
                                :disabled="
                                  question.answers.length <= 2 ||
                                  isEditing ||
                                  disabled ||
                                  loading
                                "
                                @click="deleteAnswer(answer)"
                              >
                                <v-icon small color="error">
                                  mdi-delete
                                </v-icon>
                              </v-btn>
                            </div>
                          </template>
                          <span>{{ $t("general.delete") }}</span>
                        </v-tooltip>

                        <v-tooltip left color="primary">
                          <template #activator="{ on, attrs }">
                            <div v-on="on" v-bind="attrs">
                              <AnswerTranslationDialog
                                prop-name="body"
                                :answer="answer"
                                :disabled="!answer.body || disabled || loading"
                              >
                                <template #button="{ onClick }">
                                  <v-btn
                                    icon
                                    :disabled="
                                      ['math'].includes(answer.body_format) ||
                                      isEditing ||
                                      loading
                                    "
                                    @click="onClick"
                                  >
                                    <v-icon small> mdi-translate </v-icon>
                                  </v-btn>
                                </template>
                              </AnswerTranslationDialog>
                            </div>
                          </template>
                          <span>{{ $t("general.translations") }}</span>
                        </v-tooltip>
                      </div>
                    </template>
                  </InputTextfield>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="px-0 d-flex">
                <v-list-item-content
                  v-if="!newAnswer"
                  class="d-flex justify-center"
                >
                  <v-btn
                    small
                    depressed
                    :disabled="isEditing || disabled || loading"
                    color="primary"
                    @click="addAnswer()"
                  >
                    <v-icon small class="mr-2"> mdi-plus </v-icon>
                    <span>{{ $t("ng.add_answer") }}</span>
                  </v-btn>
                </v-list-item-content>
                <v-list-item-content v-if="newAnswer">
                  <InputTextfield
                    v-model="newAnswer.body"
                    :label="$t('resources.questions.answer')"
                    :rules="input.formRules.answer"
                    counter="255"
                    :body-format="newAnswer.body_format"
                    correct-button
                    :correct-value="newAnswer.is_correct"
                    listen-to-input
                    preview
                    @change="setNewAnswer"
                  >
                    <template #actions="{ changeFormat, togglePreview }">
                      <div class="ml-2">
                        <v-btn
                          icon
                          :disabled="newAnswer.body.length < 1 || loading"
                          @click="togglePreview()"
                        >
                          <v-icon small> mdi-file-find </v-icon>
                        </v-btn>
                        <v-btn icon :disabled="loading" @click="changeFormat()">
                          <v-icon small> mdi-format-letter-case-upper </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="error"
                          :disabled="loading"
                          @click="deleteNewAnswer()"
                        >
                          <v-icon small> mdi-close </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          color="success"
                          :loading="loading"
                          @click="saveNewAnswer()"
                        >
                          <v-icon small> mdi-check </v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </InputTextfield>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { QuestionSingleChoice } from "@/models/content/questions/types/QuestionSingleChoice";
import QuestionTranslationDialog from "@/components/questions/QuestionTranslationDialog";
import AnswerTranslationDialog from "@/components/questions/AnswerTranslationDialog";

export default {
  name: "QuestionSingleChoiceEdit",
  components: {
    QuestionTranslationDialog,
    AnswerTranslationDialog,
  },
  props: {
    question: {
      type: [QuestionSingleChoice],
      required: true,
    },
    language: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      input: null,
      valid: {
        body: false,
        hint: false,
        answers: false,
      },
      loading: false,
      isEditing: false,
      newAnswer: null,
      editModes: {
        time: false,
      },
    };
  },
  computed: {
    answersHaveChanged() {
      let changed = false;
      let answers_ori = this.question.answers;
      let answers_new = this.input.answers;
      if (answers_ori.length !== answers_new.length) return (changed = true);
      answers_new.forEach((answer, i) => {
        if (answer.body !== answers_ori[i].body) changed = true;
      });
      return changed;
    },
  },
  watch: {
    question() {
      this.setQuestion();
    },
    valid(val) {
      this.$emit("is-valid", val);
    },
    "editModes.time": {
      handler: function (v) {
        this.setEditing(v);
      },
    },
  },
  beforeMount() {
    this.setQuestion();
  },
  methods: {
    ...mapActions("questions", [
      "patch_question",
      "patch_answer",
      "add_answer",
      "delete_answer",
    ]),
    setQuestion() {
      this.input = new QuestionSingleChoice(
        JSON.parse(JSON.stringify(this.question))
      );
    },
    async updateHint(val, translate) {
      this.loading = true;
      let payload = {
        id: this.question.id,
        language: this.language,
        override: translate,
        properties: { name: "hint", value: val },
      };

      let res = await this.patch_question({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateBody(val, translate, format) {
      let properties = [{ name: "body", value: val }];
      if (format) properties.push({ name: "body_format", value: format });
      this.loading = true;
      let payload = {
        id: this.question.id,
        language: this.language,
        override: translate,
        properties: properties,
      };

      let res = await this.patch_question({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateTime() {
      let properties = [{ name: "time", value: this.input.time }];
      this.loading = true;
      let payload = {
        id: this.question.id,
        language: this.language,
        override: false,
        properties: properties,
      };

      let res = await this.patch_question({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.editModes.time = false;
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    async updateAnswer(val, translate, format, answerId, correct) {
      let properties = [
        { name: "body", value: val },
        { name: "body_format", value: format },
        { name: "is_correct", value: correct },
      ];
      this.loading = true;

      let payload = {
        id: answerId,
        questionId: this.question.id,
        language: this.language,
        override: translate,
        properties: properties,
      };

      let res = await this.patch_answer({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    setEditing(val) {
      this.isEditing = val;
    },
    addAnswer() {
      this.newAnswer = { body: "", body_format: "text", is_correct: false };
      this.isEditing = true;
    },
    async saveNewAnswer() {
      this.loading = true;
      let payload = {
        question_id: this.question.id,
        language: this.language,
        body: this.newAnswer.body,
        body_format: this.newAnswer.body_format,
        is_correct: this.newAnswer.is_correct,
        order: this.question.answers.length,
      };

      let res = await this.add_answer({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.updateSuccess"),
        });
        this.deleteNewAnswer();
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.updateError"),
        });
      }
    },
    deleteNewAnswer() {
      this.newAnswer = null;
      this.isEditing = false;
    },
    setNewAnswer(body, format, correct) {
      this.newAnswer = { body: body, body_format: format, is_correct: correct };
    },
    async deleteAnswer(answer) {
      this.loading = true;
      let confirm = window.confirm(
        "Willst Du die Antwort wirklich löschen? Dies kann nicht rückgängig gemacht werden."
      );
      if (!confirm) {
        this.loading = false;
        return false;
      }
      let payload = {
        id: answer.id,
        question_id: this.question.id,
        language: this.language,
      };
      let res = await this.delete_answer({
        payload: payload,
        token: this.source.token,
      });
      this.loading = false;
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.deleteSuccess"),
        });
        this.deleteNewAnswer();
        //this.$emit('editing', false);
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.deleteError"),
        });
      }
    },
  },
};
</script>
