<template>
  <div>
    <ViewLoader v-if="loading" />

    <section v-if="!loading">
      <v-container>
        <v-alert
          v-if="!licences.ghostwriter || licenceExpired"
          text
          type="error"
          dense
          border="left"
        >
          <span class="caption">{{ $t("gw.no_licence") }}</span>
        </v-alert>
        <v-row>
          <v-col cols="12">
            <v-data-table
              id="gw-sessions"
              class="grey lighten-3"
              :headers="table_headers"
              :items="visibleTranscriptions"
              :items-per-page="10"
            >
              <template #item="{ item }">
                <tr
                  class="hover"
                  :class="{ invalid: item.disabled }"
                  @click="goToDetails(item)"
                >
                  <td>
                    <v-tooltip right color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex align-center"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            v-if="![1, 2, 6].includes(item.status)"
                            :color="item.status_color"
                            small
                          >
                            {{ item.status_icon }}
                          </v-icon>
                          <v-progress-circular
                            v-if="[1, 2, 6].includes(item.status)"
                            indeterminate
                            color="primary"
                            size="16"
                            width="2"
                          />
                        </div>
                      </template>
                      <span>{{
                        item.disabled
                          ? $t("gw.invalid_session")
                          : item.status === 3
                          ? $t("gw.transcriptions.status_pending")
                          : $t(`gw.status.${item.status}`)
                      }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <span
                      v-if="
                        !item.language ||
                        (item.language && item.language.length === 2)
                      "
                      class="caption"
                      >LANG ERR ({{ item.language }})</span
                    >
                    <v-tooltip
                      v-if="
                        !item.disabled &&
                        item.language &&
                        item.language.length > 2
                      "
                      right
                      color="primary"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-avatar tile size="24" v-bind="attrs" v-on="on">
                          <country-flag
                            :country="
                              item.language.length === 2
                                ? item.language
                                : item.language.slice(-2)
                            "
                            size="small"
                          />
                        </v-avatar>
                      </template>
                      <span>{{
                        $t("gw.selectedLanguage", {
                          lang: $t(`languages.${item.language}.display`),
                        })
                      }}</span>
                    </v-tooltip>
                  </td>
                  <td class="py-4">
                    <div class="font-weight-bold secondary--text">
                      {{ item.file_name }}
                    </div>
                    <div class="caption secondary--text">
                      {{ item.description ? item.description : "-" }}
                    </div>
                  </td>
                  <td>
                    <v-tooltip left color="primary">
                      <template v-slot:activator="{ on, attrs }">
                        <div
                          class="d-flex align-center"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            :color="
                              item.type === 'youtube' ? 'error' : 'primary'
                            "
                            small
                          >
                            {{
                              item.type === "youtube"
                                ? "mdi-youtube"
                                : "mdi-upload"
                            }}
                          </v-icon>
                        </div>
                      </template>
                      <span>{{
                        item.type === "youtube"
                          ? $t("gw.transcriptions.types.youtube")
                          : $t("gw.transcriptions.fileupload")
                      }}</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <span class="caption secondary--text">{{
                      item.created_at.toLocaleDateString()
                    }}</span>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ViewLoader from "@/components/_layout/ViewLoader";
import CountryFlag from "vue-country-flag";

export default {
  name: "GwTranscriptions",
  components: {
    ViewLoader,
    CountryFlag,
  },
  data() {
    return {
      loading: true,
      table_headers: [
        {
          text: this.$t("resources.users.status"),
          value: "status",
          width: "50px",
        },
        {
          text: this.$t("gw.target_language"),
          value: "options.language",
          width: "50px",
        },
        {
          text: this.$t("resources.categories.name"),
          value: "name",
          width: "auto",
        },
        {
          text: this.$t("resources.nuggets.type"),
          value: "type",
          width: "50px",
        },
        {
          text: this.$t("resources.invitations.created_at"),
          value: "created_at",
          width: "80px",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("ghostwriter", { transcriptions: "get_transcriptions" }),
    ...mapGetters("auth", { licences: "get_licences" }),
    licenceExpired() {
      if (!this.licences || !this.licences.ghostwriter) return true;
      let licence = this.licences.ghostwriter;
      let now = new Date();
      let date = new Date(licence.date_end);
      return now > date;
    },
    visibleTranscriptions() {
      if (!this.transcriptions) return [];
      var transcriptions = [...this.transcriptions];
      return transcriptions.sort((a, b) => b.created_at - a.created_at);
    },
  },
  methods: {
    ...mapActions("ghostwriter", [
      "fetch_transcriptions",
      "fetch_gw_media_upload_token",
    ]),
    goToDetails(item) {
      this.$router.push({
        name: "GwTranscriptionDetail",
        params: { id: item.id },
      });
    },
    async fetchData() {
      this.loading = true;
      var token = await this.fetch_gw_media_upload_token({
        config: { cancelToken: this.source.token },
      });
      var config = {
        cancelToken: this.source.token,
        headers: {
          gwtoken: token,
        },
      };
      await this.fetch_transcriptions({ config: config });
      this.loading = false;
    },
  },
};
</script>

<style></style>
