export const state = {
  session: null,
  bulk_session: null,
  sessions: [],
  bulk_sessions: [],
  licences: [],
  transcriptions: [],
  transcription: null,
  credit_pool: null,
};
