<template>
  <div>
    <v-tooltip v-if="disabled" left color="primary">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-btn color="grey lighten-3" elevation="0" small disabled>
            <v-avatar
              size="6"
              :color="question.is_active ? 'success' : 'error'"
            />
            <span class="mx-2 black--text">{{
              question.is_active
                ? $t("resources.questions.isactive")
                : $t("resources.questions.not_isactive")
            }}</span>
            <v-icon v-if="!loading" small> mdi-dots-vertical </v-icon>
          </v-btn>
        </div>
      </template>
      <span>{{
        !disabled
          ? $t("resources.questions.activationHint_cannotBeEdited")
          : $t("ng.no_activation_question")
      }}</span>
    </v-tooltip>

    <v-menu v-if="!disabled" offset-x left close-on-content-click>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="grey lighten-3"
          elevation="0"
          small
          v-bind="attrs"
          v-on="on"
        >
          <v-avatar
            size="6"
            :color="question.is_active ? 'success' : 'error'"
          />
          <span class="mx-2 black--text">{{
            question.is_active
              ? $t("resources.questions.isactive")
              : $t("resources.questions.not_isactive")
          }}</span>
          <v-icon v-if="!loading" small> mdi-dots-vertical </v-icon>
          <v-progress-circular
            v-if="loading"
            indeterminate
            :size="12"
            :width="2"
            color="grey"
          />
        </v-btn>
      </template>
      <v-list dense class="py-0">
        <v-subheader class="caption font-weight-bold pl-4 grey lighten-3">
          <span class="grey--text">{{
            $t("resources.journeys.changeStatus")
          }}</span>
        </v-subheader>
        <v-list-item
          v-for="(status, index) in statuses"
          :key="index"
          :disabled="
            (question.is_active && status.type == 'active') ||
            (!question.is_active && status.type == 'inactive') ||
            !question.canBeActivated()
          "
          @click="changeStatus(status.type)"
        >
          <v-list-item-title>{{ status.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog :value="showDialog" width="500" @click:outside="closeDialog()">
      <v-card>
        <v-card-title class="text-overline pb-0">
          <span>{{ $t("resources.questions.deactivateQuestion") }}</span>
        </v-card-title>

        <v-card-text class="pt-2">
          <v-sheet
            color="primary"
            class="pt-2 pb-1 mb-2 white--text font-weight-bold text-center"
          >
            <div v-html="question.body" />
          </v-sheet>
          <v-alert
            v-if="!question.can.deactivate"
            color="error"
            text
            bordered
            type="error"
            dense
            class="caption"
          >
            <div v-html="$t('ng.deactivate_question_hint')" />
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary" text small @click="closeDialog()">
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn color="error" text small @click="patchStatus(true)">
            {{ $t("resources.questions.deactivateQuestion") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { QuestionSingleChoice } from "@/models/content/questions/types/QuestionSingleChoice";
import { QuestionMultiChoice } from "@/models/content/questions/types/QuestionMultiChoice";
import { QuestionGap } from "@/models/content/questions/types/QuestionGap";
import { QuestionEstimation } from "@/models/content/questions/types/QuestionEstimation";

export default {
  name: "QuestionActivator",
  props: {
    question: {
      type: [
        QuestionSingleChoice,
        QuestionMultiChoice,
        QuestionGap,
        QuestionEstimation,
      ],
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      statuses: [
        { type: "active", name: this.$t("resources.questions.isactive") },
        { type: "inactive", name: this.$t("resources.questions.not_isactive") },
      ],
      loading: false,
      showDialog: false,
    };
  },
  computed: {
    ...mapGetters("questions", { language: "get_selected_language" }),
  },
  methods: {
    ...mapActions("questions", ["patch_question"]),
    changeStatus(status) {
      if (status === "inactive" && !this.question.can.deactivate) {
        this.showDialog = true;
        return false;
      }
      this.patchStatus();
    },
    closeDialog() {
      this.showDialog = false;
    },
    async patchStatus() {
      this.loading = true;
      let properties = [{ name: "is_active", value: !this.question.is_active }];

      let payload = {
        id: this.question.id,
        language: this.language,
        properties: properties,
      };

      let res = await this.patch_question({
        payload: payload,
        config: { cancelToken: this.source.token },
      });
      this.loading = false;
      this.closeDialog();

      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateSuccess"
          )}`,
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: `${this.$t("resources.categories.singular")} ${this.$t(
            "general.updateError"
          )}`,
        });
      }
    },
  },
};
</script>

<style></style>
