<template>
  <div>
    <section v-if="question">
      <SectionHeader
        :title="$t('resources.questions.editQuestion')"
        :subtitle="$t('ng.edit_question_subtitle')"
      >
        <template #action>
          <LanguageChooserInline
            hidedetails
            hide-border
            small
            :initial="language"
            @change="changeLanguage"
          />
        </template>
      </SectionHeader>
      <v-container>
        <v-alert
          v-if="question.is_active"
          type="info"
          text
          dense
          colored-border
          border="left"
        >
          <div class="caption">
            <span>{{ $t("ng.question_active_hint") }}</span>
          </div>
        </v-alert>
      </v-container>

      <QuestionEstimationEdit
        v-if="question.question_type === 'estimation'"
        :question="question"
        :disabled="loading || question.is_active || !question.can_edit"
        :language="language"
      />
      <!-- @editing="setEditing" -->

      <QuestionSingleChoiceEdit
        v-if="question.question_type === 'single_choice'"
        :question="question"
        :disabled="loading || question.is_active || !question.can_edit"
        :language="language"
      />
      <!-- @editing="setEditing" -->

      <QuestionMultiChoiceEdit
        v-if="question.question_type === 'multi_choice'"
        :question="question"
        :disabled="loading || question.is_active || !question.can_edit"
        :language="language"
      />
      <!-- @editing="setEditing" -->

      <QuestionGapEdit
        v-if="question.question_type === 'gap_text'"
        :question="question"
        :disabled="loading || question.is_active || !question.can_edit"
        :language="language"
      />
      <!-- @editing="setEditing" -->
    </section>

    <section v-if="question && ['gap_text'].includes(question.question_type)">
      <SectionHeader
        :title="$t('resources.invitations.settings')"
        :subtitle="$t('ng.further_actions_descr')"
      />
      <QuestionOptionsEdit :question="question" />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

import SectionHeader from "@/components/_layout/SectionHeader";
import QuestionEstimationEdit from "@/components/questions/types/QuestionEstimationEdit";
import QuestionSingleChoiceEdit from "@/components/questions/types/QuestionSingleChoiceEdit";
import QuestionMultiChoiceEdit from "@/components/questions/types/QuestionMultiChoiceEdit";
import QuestionGapEdit from "@/components/questions/types/QuestionGapEdit";
import QuestionOptionsEdit from "@/components/questions/QuestionOptionsEdit";
import LanguageChooserInline from "@/components/global/LanguageChooserInline.vue";

export default {
  name: "QuestionSettings",
  components: {
    SectionHeader,
    QuestionEstimationEdit,
    QuestionSingleChoiceEdit,
    QuestionMultiChoiceEdit,
    QuestionGapEdit,
    LanguageChooserInline,
    QuestionOptionsEdit,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters("questions", {
      question: "get_question",
      language: "get_selected_language",
    }),
  },
  methods: {
    ...mapActions("questions", ["fetch_question"]),
    ...mapMutations("questions", ["set_selected_language"]),
    async changeLanguage(lang) {
      this.set_selected_language(lang);
      this.loading = true;
      await this.fetch_question({
        id: this.question.id,
        language: this.language,
        cancelToken: this.source.token,
      });
      this.loading = false;
    },
  },
};
</script>
