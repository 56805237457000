<template>
  <div>
    <ViewLoader v-if="loading" />

    <v-expand-transition>
      <section v-if="!loading">
        <SectionHeader
          :title="$t('kpi.overview_title')"
          :subtitle="$t('kpi.overview_subtitle')"
        />

        <v-container v-if="general.current.length === 0">
          <v-alert type="error" color="error" text prominent>{{
            $t("kpi.data_error")
          }}</v-alert>
        </v-container>

        <v-container
          v-if="general.current.length > 0 || !!general.current.completion"
        >
          <v-row>
            <v-col
              v-if="general.current.completion"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSheetCard
                :value="general.current.completion[0]"
                :delta="
                  general.current.completion[0] - general.current.completion[1]
                "
                text-color="accent"
                :description="`<b class='accent--text'>${$t(
                  'kpi.discovery'
                )}</b> ${$t('kpi.discovery_description')}`"
                icon="mdi-progress-check"
                :explanation-title="$t('kpi.discovery')"
                :explanation-text="$t('kpi.discovery_description_long')"
                :to="{ name: 'KpiPerformanceDiscovery' }"
              />
            </v-col>
            <v-col
              v-if="general.current.competency"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSheetCard
                :value="general.current.competency[0]"
                :delta="
                  general.current.competency[0] - general.current.competency[1]
                "
                text-color="accent"
                :description="`<b class='accent--text'>${$t(
                  'kpi.competency'
                )}</b> ${$t('kpi.competency_description')}`"
                icon="mdi-progress-star"
                :explanation-title="$t('kpi.competency')"
                :explanation-text="$t('kpi.competency_description_long')"
                :to="{ name: 'KpiPerformanceCompetency' }"
              />
            </v-col>
            <v-col
              v-if="general.current.retention"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSheetCard
                :value="general.current.retention[0]"
                :delta="
                  general.current.retention[0] - general.current.retention[1]
                "
                text-color="accent"
                :description="`<b class='accent--text'>${$t(
                  'kpi.retention'
                )}</b> ${$t('kpi.retention_description')}`"
                icon="mdi-progress-star-four-points"
                :explanation-title="$t('kpi.retention')"
                :explanation-text="$t('kpi.retention_description_long')"
                :to="{ name: 'KpiPerformanceRetention' }"
              />
            </v-col>
            <v-col
              v-if="general.current.currentSpeed"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSheetCard
                :value="general.current.currentSpeed[0]"
                :delta="
                  general.current.currentSpeed[0] -
                  general.current.currentSpeed[1]
                "
                text-color="info"
                :description="`<b class='info--text'>${$t(
                  'kpi.speed'
                )}</b> ${$t('kpi.speed_description')}`"
                icon="mdi-play-speed"
                :explanation-title="$t('kpi.speed')"
                :explanation-text="$t('kpi.speed_description_long')"
                :to="{ name: 'KpiActivitySpeed' }"
              />
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expand-transition>

    <v-expand-transition>
      <section v-if="!loading" class="mt-4">
        <SectionHeader
          :title="$t('kpi.data_overview_title')"
          :subtitle="$t('kpi.data_overview_subtitle')"
        />
        <v-container v-if="!!general.stats">
          <v-row dense>
            <v-col
              v-if="general.stats.structures"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSimpleCard
                :value="general.stats.structures[0]"
                :delta="general.stats.structures[1]"
                :title="$t('resources.structures.plural')"
              />
            </v-col>
            <v-col
              v-if="general.stats.substructures"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSimpleCard
                :value="general.stats.substructures[0]"
                :delta="general.stats.substructures[1]"
                :title="$t('resources.substructures.plural')"
              />
            </v-col>
            <v-col v-if="general.stats.users" cols="12" sm="6" md="4" lg="3">
              <KpiStatsSimpleCard
                :value="general.stats.users[0]"
                :delta="general.stats.users[1]"
                :title="$t('resources.users.plural')"
              />
            </v-col>
            <v-col
              v-if="general.stats.categories"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSimpleCard
                :value="general.stats.categories[0]"
                :delta="general.stats.categories[1]"
                :title="$t('resources.categories.plural')"
              />
            </v-col>
            <v-col
              v-if="general.stats.questions"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <KpiStatsSimpleCard
                :value="general.stats.questions[0]"
                :delta="general.stats.questions[1]"
                :title="$t('resources.questions.plural')"
              />
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import KpiStatsSheetCard from "@/components/kpi/KpiStatsSheetCard";
import KpiStatsSimpleCard from "@/components/kpi/KpiStatsSimpleCard";
import SectionHeader from "@/components/_layout/SectionHeader";
import ViewLoader from "@/components/_layout/ViewLoader";

export default {
  name: "KpiOverview",
  components: {
    KpiStatsSheetCard,
    KpiStatsSimpleCard,
    SectionHeader,
    ViewLoader,
  },
  computed: {
    ...mapGetters("kpi", {
      general: "get_kpi_general",
      loading: "get_kpi_loading",
      version: "get_kpi_version",
    }),
  },
  beforeMount() {
    this.fetchStatistics();
  },
  destroyed() {
    this.set_kpi_general(null);
  },
  watch: {
    version: {
      handler: function () {
        this.fetchStatistics();
      },
    },
  },
  methods: {
    ...mapActions("kpi", ["fetch_kpi_general"]),
    ...mapMutations("kpi", ["set_kpi_general"]),
    async fetchStatistics() {
      await this.fetch_kpi_general({
        cancelToken: this.source.token,
      });
    },
  },
};
</script>
