<template>
  <div>
    <v-btn depressed small color="primary" @click="showDialog()">
      <v-icon small class="mr-2"> mdi-vector-combine </v-icon>
      <span>{{ $t("gw.newLinkItem") }}</span>
    </v-btn>

    <v-dialog
      v-model="dialog"
      :max-width="$vuetify.breakpoint.mdAndUp ? 800 : '100%'"
      @click:outside="closeDialog()"
    >
      <v-card color="grey lighten-3">
        <v-card-title class="overline secondary--text pb-0">
          {{ $t("gw.searchNuggets") }}
        </v-card-title>
        <v-card-text>
          <div class="caption mb-4">
            {{ $t("gw.searchNuggets_hint") }}
          </div>
          <v-row align="center">
            <v-col>
              <v-text-field
                v-model="filter.search"
                outlined
                dense
                :label="$t('general.search')"
                background-color="white"
                hide-details
              />
            </v-col>
            <v-col>
              <v-autocomplete
                v-model="filter.categories"
                :items="sortedCategories"
                item-value="id"
                item-text="name"
                outlined
                hide-details
                multiple
                small-chips
                deletable-chips
                clearable
                dense
                :label="$t('resources.categories.plural')"
                background-color="white"
              />
            </v-col>
            <v-col>
              <v-checkbox
                v-model="filter.linked"
                dense
                label="Nur verlinkte anzeigen"
              />
            </v-col>
          </v-row>
          <v-divider class="my-4" />
          <v-data-table
            v-if="nuggets"
            :items="visibleNuggets"
            :items-per-page="5"
            dense
            item-key="id"
            :loading="loading"
            class="nugget-table grey lighten-3"
          >
            <template #item="{ item }">
              <tr class="grey lighten-3">
                <td>
                  <v-icon>{{
                    item.type === "video"
                      ? "mdi-video"
                      : "mdi-format-color-text"
                  }}</v-icon>
                  <span class="mx-2">{{ item.title }}</span>
                  <v-tooltip color="primary" right>
                    <template v-slot:activator="{ on, attrs }">
                      <div v-bind="attrs" class="d-inline" v-on="on">
                        <v-btn x-small text>
                          {{ $t("resources.invitations.preview") }}
                        </v-btn>
                      </div>
                    </template>
                    <div
                      v-if="item.type === 'text'"
                      style="max-width: 600px"
                      v-html="item.content"
                    />
                    <div v-if="item.type === 'video'">
                      {{ $t("resources.nuggets.video") }}
                    </div>
                  </v-tooltip>
                </td>
                <td>
                  <v-tooltip right>
                    <template #activator="{ on, attrs }">
                      <v-avatar size="32" tile>
                        <v-img
                          v-bind="attrs"
                          :src="item.category.image"
                          v-on="on"
                        />
                      </v-avatar>
                    </template>
                    <span>{{ item.category.name }}</span>
                  </v-tooltip>
                </td>
                <td class="text-right">
                  <v-btn
                    depressed
                    x-small
                    :color="questionIncludesNugget(item) ? 'error' : 'primary'"
                    class="mr-2"
                    @click="
                      questionIncludesNugget(item)
                        ? unlinkNugget(item)
                        : linkNugget(item)
                    "
                  >
                    <v-icon small class="mr-2">
                      {{
                        questionIncludesNugget(item)
                          ? "mdi-vector-combine"
                          : "mdi-vector-link"
                      }}
                    </v-icon>
                    <span>{{
                      questionIncludesNugget(item)
                        ? $t("gw.unlinkItem")
                        : $t("gw.linkItem")
                    }}</span>
                  </v-btn>
                  <v-btn
                    depressed
                    x-small
                    color="secondary"
                    target="_blank"
                    :to="{ name: 'NuggetDetail', params: { id: item.id } }"
                  >
                    <v-icon small class="mr-2"> mdi-magnify </v-icon>
                    <span>{{ $t("general.details") }}</span>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text small color="secondary" @click="closeDialog()">
            {{ $t("general.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  name: "QuestionNuggetLinkDialog",
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: true,
      filter: {
        search: "",
        categories: [],
        linked: false,
      },
    };
  },
  computed: {
    ...mapGetters("categories", { categories: "get_visible_categories" }),
    ...mapGetters("nuggets", { nuggets: "get_nuggets" }),
    sortedCategories() {
      if (!this.categories) return [];
      let categories = [...this.categories];
      return categories.sort((a, b) => a.name.localeCompare(b.name));
    },
    visibleNuggets() {
      if (!this.nuggets) return [];
      let nuggets = [...this.nuggets];
      if (this.filter.search)
        nuggets = nuggets.filter((n) => {
          let includes = false;
          let search = this.filter.search.toLowerCase();
          if (n.title.toLowerCase().includes(search)) includes = true;
          if (n.description.toLowerCase().includes(search)) includes = true;
          if (n.content.toLowerCase().includes(search)) includes = true;

          return includes;
        });
      if (this.filter.categories && this.filter.categories.length > 0) {
        nuggets = nuggets.filter((n) =>
          this.filter.categories.includes(n.category_id)
        );
      }
      if (this.filter.linked) {
        nuggets = nuggets.filter((n) => this.questionIncludesNugget(n));
      }
      return nuggets;
    },
  },
  watch: {
    dialog(v) {
      if (v) {
        this.fetchDialogData();
      } else {
        this.unsetData();
      }
    },
  },
  methods: {
    ...mapActions("questions", [
      "link_question_nugget",
      "unlink_question_nugget",
    ]),
    ...mapActions("nuggets", ["fetch_nuggets"]),
    ...mapMutations("nuggets", ["set_nuggets"]),
    showDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    async fetchDialogData() {
      this.loading = true;
      await this.fetch_nuggets({ cancelToken: this.source.token });
      this.loading = false;
    },
    unsetData() {
      this.set_nuggets(null);
    },
    async linkNugget(nugget) {
      let payload = {
        question_id: this.question.id,
        nugget_id: nugget.id,
        language: this.$i18n.locale,
      };
      let res = await this.link_question_nugget(payload, this.source.token);
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    async unlinkNugget(nugget) {
      let payload = {
        question_id: this.question.id,
        nugget_id: nugget.id,
        language: this.$i18n.locale,
      };
      let res = await this.unlink_question_nugget(payload, this.source.token);
      if (res._status === 200) {
        this.$notify({
          type: "success",
          title: this.$t("general.success"),
          text: this.$t("general.success"),
        });
      } else {
        this.$notify({
          type: "error",
          title: this.$t("general.error"),
          text: this.$t("general.error"),
        });
      }
    },
    questionIncludesNugget(nugget) {
      let ids = [...this.question.nuggets.map((x) => x.id)];
      return ids.includes(nugget.id);
    },
  },
};
</script>

<style lang="scss">
.nugget-table tr td {
  height: 48px !important;
}
.nugget-table tr:hover td {
  background-color: rgba(0, 0, 0, 0.05);
}
.nugget-table .v-data-footer {
  background-color: transparent !important;
}
</style>
