<template>
  <div>
    <slot name="button" :open-dialog="openDialog" />

    <v-dialog
      v-model="showDialog"
      max-width="480"
      scrollable
      :persistent="loading || uploaded"
      @click:outside="closeDialog()"
    >
      <v-card
        v-if="showDialog"
        color="grey lighten-3"
        max-width="480"
        :loading="loading"
      >
        <template slot="progress">
          <v-progress-linear
            color="primary"
            height="4"
            indeterminate
          ></v-progress-linear>
        </template>

        <v-card-title class="overline secondary--text">
          {{ $t("general.extract_text") }}
        </v-card-title>
        <v-card-text>
          <v-expand-transition>
            <v-alert
              v-if="initial && initial.length > 0"
              type="info"
              color="error"
              text
              dense
              class="caption"
              >{{ $t("general.extract_text_hint") }}
            </v-alert>
          </v-expand-transition>
          <v-expand-transition>
            <v-alert
              v-if="uploaded && !text"
              type="info"
              color="error"
              text
              dense
              class="caption"
            >
              <div>{{ $t("general.extract_text_hint_2") }}</div>
              <small>{{ $t("general.extract_text_hint_3") }}</small>
            </v-alert>
          </v-expand-transition>
          <v-row>
            <v-col cols="12">
              <v-file-input
                ref="gwdocument"
                accept=".pdf"
                :label="$t('resources.invitations.fileInput')"
                outlined
                :background-color="
                  uploaded || loading ? 'grey lighten-3' : 'white'
                "
                :disabled="uploaded || loading"
                small-chips
                dense
                :rules="rules"
                clear-icon="mdi-close-circle"
                @change="selectFile"
              >
                <template #append-outer>
                  <v-slide-group>
                    <v-slide-x-reverse-transition leave-absolute>
                      <v-btn
                        v-if="!uploaded"
                        small
                        depressed
                        :disabled="!selected || uploaded"
                        :color="'primary'"
                        :loading="loading"
                        @click="uploadFile()"
                      >
                        <v-icon class="mr-2">mdi-upload</v-icon>
                        <span>Analysieren</span>
                      </v-btn>
                    </v-slide-x-reverse-transition>
                    <v-slide-x-transition>
                      <v-icon v-if="uploaded" color="success"
                        >mdi-check-circle</v-icon
                      >
                    </v-slide-x-transition>
                  </v-slide-group>
                </template>
              </v-file-input>
            </v-col>
            <v-col cols="12">
              <v-sheet
                class="d-flex align-center justify-space-between pa-2"
                color="grey lighten-2"
              >
                <div class="secondary--text">
                  {{ $t("general.supported_file_formats") }}
                </div>
                <v-tooltip right color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-file-pdf-box </v-icon>
                  </template>
                  <span>PDF</span>
                </v-tooltip>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            small
            color="secondary"
            :disabled="loading"
            @click="closeDialog()"
          >
            {{ $t("general.cancel") }}
          </v-btn>
          <v-btn
            text
            small
            color="primary"
            :disabled="!uploaded || !text"
            @click="applyText()"
            >{{ $t("general.apply_text") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "GwAddSessionBulkDialog",
  props: {
    initial: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      showDialog: false,
      loading: false,
      selected: false,
      uploaded: false,
      text: "",
      rules: [
        (value) =>
          !value ||
          value.size < 750000000 ||
          this.$t("resources.media.validation", { size: "750" }),
      ],
    };
  },
  watch: {
    showDialog(val) {
      if (!val) this.reset();
    },
  },
  methods: {
    ...mapActions("ghostwriter", ["upload_document"]),
    closeDialog() {
      this.showDialog = false;
      this.reset();
    },
    openDialog() {
      this.showDialog = true;
    },
    applyText() {
      this.$emit("apply", this.text);
      this.closeDialog();
    },
    selectFile(file) {
      this.selected = !!file;
    },
    async uploadFile() {
      this.loading = true;
      let inputs = new FormData();
      inputs.append("file", this.$refs.gwdocument.initialValue);
      let res = await this.upload_document({
        payload: inputs,
        config: {
          cancelToken: this.source.token,
          headers: { "Content-Type": "multipart/form-data" },
        },
      });
      this.loading = false;
      if (res) {
        this.text = res.text;
        this.uploaded = true;
        this.$notify({
          title: res ? this.$t("general.success") : this.$t("general.error"),
          type: res ? "success" : "warning",
          text: res ? this.$t("general.success") : this.$t("general.error"),
        });
      } else {
        this.$notify({
          title: this.$t("general.error"),
          type: "error",
          text: this.$t("resources.invitations.fileError"),
        });
        this.fileUploaded = false;
      }
    },
    reset() {
      this.text = "";
      this.uploaded = false;
      this.selected = false;
    },
  },
};
</script>
