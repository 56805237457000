<template>
  <div>
    <ViewHeader
      :breadcrumbs="breadcrumbs"
      title="Fragenimporte"
      icon="mdi-database-import"
    />
    <ViewLoader v-if="!importItem" />

    <section v-if="importItem">
      <SectionHeader :title="`${$t('general.overview')}`" />
      <v-container class="py-0">
        <v-row>
          <v-col cols="12">
            <v-card outlined>
              <v-card-title class="py-1">
                <v-chip
                  small
                  :close="activeFilters > 0"
                  :color="activeFilters ? 'primary' : ''"
                  @click:close="resetFilter"
                >
                  {{ activeFilters }} {{ $t("general.filtersSelected") }}
                </v-chip>
                <v-spacer />
                <v-btn
                  v-if="display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
                <v-btn
                  v-if="!display.filters"
                  icon
                  @click="display.filters = !display.filters"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text v-if="display.filters" class="pb-0">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.status"
                      :items="options.status"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.journeys.status')"
                      prepend-icon="mdi-list-status"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ getStatusTranslation(item) }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.presort_status"
                      :items="presort_statuses"
                      item-text="text"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      label="Vorprüfung"
                      prepend-icon="mdi-list-status"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        <small class="caption mr-2">{{
                          item.text_short
                        }}</small>
                        {{ item.text }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.hasQuestions"
                      :items="options.questions"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      label="Hat bearbeitete Fragen"
                      item-text="name"
                      item-value="value"
                      prepend-icon="mdi-list-status"
                      :placeholder="$t('general.all')"
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      v-model="filter.meta_ready"
                      :items="options.meta"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      label="Meta ready"
                      item-text="name"
                      item-value="value"
                      prepend-icon="mdi-list-status"
                      :placeholder="$t('general.all')"
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        {{ item.name }}
                      </template>
                    </v-select>
                  </v-col>
                  <v-col v-if="categories" cols="12">
                    <v-autocomplete
                      v-model="filter.categories"
                      :items="categories"
                      item-text="name"
                      item-value="id"
                      attach
                      chips
                      small-chips
                      deletable-chips
                      :label="$t('resources.categories.plural')"
                      prepend-icon="mdi-select-group"
                      :placeholder="$t('general.all')"
                      multiple
                      outlined
                      dense
                      hide-details
                    >
                      <template #item="{ item }">
                        <span class="caption">{{ item.id }}</span>
                        <span class="mx-1 secondary--text"
                          >{{ getCategoryGroupNameById(item.id) }} -
                        </span>
                        <span>{{ getCategoryNameById(item.id) }}</span>
                      </template>

                      <!-- <template
                        #selection="{ item }"
                      >
                        <v-chip
                          x-small
                          color="primary"
                        >
                          <span>{{ item.id }} - </span>
                          <span class="mx-1">({{ getCategoryGroupNameById(item.id) }})</span>
                          <span>{{ getCategoryNameById(item.id) }}</span>
                        </v-chip>
                      </template> -->
                    </v-autocomplete>
                  </v-col>
                  <!-- <v-col cols="12">
                    <v-text-field
                      v-model="filter.search"
                      prepend-icon="mdi-magnify"
                      :label="$t('general.search')"
                      outlined
                      dense
                      hide-details
                      append-icon="mdi-close"
                      @click:append="filter.search = ''"
                      @keydown="page = 1"
                    />
                  </v-col> -->
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="pt-0">
        <v-row
          v-if="
            recentStatusChanges &&
            recentStatusChanges.length > 0 &&
            auth.email === 'p3lingo@platform3l.com'
          "
          dense
        >
          <v-col class="d-flex align-start">
            <span class="caption mr-2 secondary--text"
              >last presort_statuses (table view only):</span
            >
            <div
              v-for="(changeId, i) in recentStatusChanges"
              :key="i"
              class="caption mx-1"
            >
              <router-link
                :to="{
                  name: 'ImportDetailQuestion',
                  params: { id: id, question_id: changeId },
                }"
                target="_blank"
              >
                {{ changeId }}
              </router-link>
            </div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-card outlined>
              <v-data-table
                :headers="headers"
                :items="filteredItems"
                :items-per-page="10"
                :search="filter.search"
                :page.sync="filter.page"
                :loading="loading"
                single-expand
                :expanded.sync="expanded[id]"
                item-key="id"
                show-expand
                @item-expanded="expandItem"
              >
                <template v-slot:[`item.status`]="{ item }">
                  <span class="caption mr-1">{{ item.id }}</span>
                  <v-chip
                    x-small
                    :color="
                      item.status === 'imported' ? 'success' : 'grey lighten-3'
                    "
                    class="font-weight-bold"
                  >
                    {{ getStatusTranslation(item.status) }}
                  </v-chip>
                </template>

                <template v-slot:[`item.presort_status`]="{ item }">
                  <v-tooltip v-if="!loading" right color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        x-small
                        :class="getPresortStatus(item.presort_status).color"
                        :dark="getPresortStatus(item.presort_status).dark"
                        label
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ getPresortStatus(item.presort_status).text_short }}
                      </v-chip>
                    </template>
                    <span>{{
                      getPresortStatus(item.presort_status).text
                    }}</span>
                  </v-tooltip>
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    size="20"
                    color="primary"
                  />
                </template>

                <template v-slot:[`item.question_type`]="{ item }">
                  <span class="caption">{{
                    translateType(item.question_type)
                  }}</span>
                </template>

                <template v-slot:[`item.category_id`]="{ item }">
                  <v-chip x-small>
                    {{ getCategoryNameById(item.category_id) }}
                  </v-chip>
                </template>

                <template v-slot:[`item.comments`]="{ item }">
                  <v-tooltip v-if="!loading" bottom color="primary">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-icon x-small color="secondary" class="mr-1"
                          >mdi-comment</v-icon
                        >
                        <span class="caption">{{
                          item.comments ? item.comments.length : "-"
                        }}</span>
                      </span>
                    </template>
                    <div>
                      <div v-for="(c, i) in item.comments" :key="i">
                        <span class="caption">{{ c }}</span>
                        <v-divider
                          v-if="i < item.comments.length - 1"
                          color="white"
                        />
                      </div>
                      <div v-if="!item.comments || item.comments.length === 0">
                        Keine Kommentare
                      </div>
                    </div>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.meta_ready`]="{ item }">
                  <v-icon small :color="item.meta_ready ? 'success' : 'error'">
                    {{ item.meta_ready ? "mdi-check" : "mdi-close" }}
                  </v-icon>
                </template>

                <template v-slot:[`item.id`]="{ item }">
                  <router-link
                    :to="{
                      name: 'ImportDetailQuestion',
                      params: { id: id, question_id: item.id },
                    }"
                    target="_blank"
                  >
                    <v-icon small> mdi-file-document </v-icon>
                  </router-link>
                </template>

                <template #expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="py-2">
                    <v-progress-circular
                      v-if="!question_import"
                      indeterminate
                    />

                    <h5 class="secondary--text mt-2">
                      Bearbeitungsstatus ändern
                    </h5>
                    <v-btn-toggle v-model="item.presort_status">
                      <v-btn :value="null" disabled small> ? </v-btn>
                      <v-tooltip
                        v-for="s in presort_statuses"
                        :key="s.id"
                        color="primary"
                        bottom
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            :value="s.id"
                            small
                            :active-class="s.color"
                            :disabled="loading || item.status === 'imported'"
                            v-bind="attrs"
                            v-on="on"
                            @click="changePreStatus(item.id, s.id)"
                          >
                            {{ s.text_short }}
                          </v-btn>
                        </template>
                        <span>{{ s.text }}</span>
                      </v-tooltip>
                    </v-btn-toggle>

                    <v-row v-if="question_import" class="mt-2">
                      <v-col cols="3">
                        <h5 class="secondary--text">Anzahl erstellte Fragen</h5>
                        <div>
                          {{
                            question_import.body
                              ? question_import.body.length
                              : 0
                          }}
                        </div>

                        <h5 class="secondary--text mt-2">
                          Anzahl zugewiesene Bilder
                        </h5>
                        <div>
                          {{
                            question_import.images
                              ? question_import.images.length
                              : 0
                          }}
                        </div>

                        <h5 class="secondary--text mt-2">
                          Darstellung wechseln
                        </h5>

                        <v-btn
                          block
                          small
                          :color="showExpandedPreview ? 'primary' : 'secondary'"
                          @click="showExpandedPreview = !showExpandedPreview"
                        >
                          {{ showExpandedPreview ? "HTML" : "Vorschau" }}
                        </v-btn>
                      </v-col>

                      <v-col cols="9">
                        <v-card color="grey lighten-3" elevation="0">
                          <v-card-text class="py-0">
                            <v-tabs>
                              <v-tab> Import </v-tab>
                              <v-tab v-if="!question_import.body" disabled>
                                Frage 1
                              </v-tab>
                              <v-tab
                                v-for="(q, i) in question_import.body"
                                :key="i"
                              >
                                Frage {{ i + 1 }}
                              </v-tab>

                              <v-tab-item class="pt-2 pb-1">
                                <MathJaxRenderer
                                  v-if="showExpandedPreview"
                                  :formula="question_import.body_raw"
                                  :safe="false"
                                />
                                <div v-if="!showExpandedPreview">
                                  {{ question_import.body_raw }}
                                </div>
                              </v-tab-item>

                              <v-tab-item
                                v-if="!question_import.body"
                                class="pt-2 pb-1"
                              >
                                Noch nicht bearbeitet
                              </v-tab-item>

                              <v-tab-item
                                v-for="(q, i) in question_import.body"
                                :key="i"
                                class="pt-2 pb-1"
                              >
                                <h5 class="secondary--text">
                                  Frage
                                  <span v-if="showExpandedPreview"
                                    >(Inhaltsformat:
                                    {{ question_import.body_format }})</span
                                  >
                                </h5>
                                <div>
                                  <small
                                    v-if="
                                      question_import.hint &&
                                      question_import.hint[i]
                                    "
                                    ><i>{{ question_import.hint[i] }}</i></small
                                  >
                                </div>
                                <MathJaxRenderer
                                  v-if="showExpandedPreview"
                                  :formula="question_import.body[i]"
                                  :safe="false"
                                />
                                <div v-if="!showExpandedPreview">
                                  {{ question_import.body[i] }}
                                </div>

                                <h5 class="secondary--text mt-4">
                                  Antworten
                                  <span v-if="showExpandedPreview"
                                    >(Antwort [Inhaltsformat])</span
                                  >
                                </h5>
                                <div
                                  v-for="(a, n) in question_import.answers[i]"
                                  :key="n"
                                  class="caption"
                                >
                                  <MathJaxRenderer
                                    v-if="showExpandedPreview"
                                    :formula="a.body"
                                    :safe="false"
                                  />
                                  <div v-if="!showExpandedPreview">
                                    - {{ a.body }}
                                    <i v-if="showExpandedPreview"
                                      >[{{ a.body_format }}]</i
                                    >
                                  </div>
                                </div>
                              </v-tab-item>
                            </v-tabs>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import MathJaxRenderer from "@/components/global/MathJaxRenderer";

import ViewHeader from "@/components/_layout/ViewHeader";
import ViewLoader from "@/components/_layout/ViewLoader";
import SectionHeader from "@/components/_layout/SectionHeader";

export default {
  name: "ImportDetailQuestions",
  components: {
    ViewHeader,
    ViewLoader,
    SectionHeader,
    MathJaxRenderer,
  },
  data() {
    return {
      id: Number(this.$route.params.id),
      page: 1,
      display: {
        filters: false,
      },
      filter: {
        search: "",
        status: [],
        meta_ready: null,
        hasQuestions: null,
        categories: [],
        presort_status: [],
        page: 1,
      },
      options: {
        status: ["unapproved", "editing", "imported"],
        meta: [
          { value: true, name: "Ready" },
          { value: false, name: "Not ready" },
        ],
        questions: [
          { value: true, name: "Ja" },
          { value: false, name: "Nein" },
        ],
      },
      loading: false,
      category_id: 478,
      expanded: [],
      headers: [
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Vorprüfung",
          value: "presort_status",
        },
        {
          text: "Fragentyp",
          value: "question_type",
        },
        {
          text: "Meta",
          value: "meta_ready",
        },
        {
          text: "Kategorie",
          value: "category_id",
        },
        {
          text: "Kommentare",
          value: "comments",
        },
        {
          text: "Details",
          value: "id",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
      presort_statuses: [
        {
          id: 1,
          text: "Geprüft",
          text_short: "OK",
          color: "color-ok",
          dark: false,
        },
        {
          id: 2,
          text: "Technische Prüfung (P3L)",
          text_short: "TEC",
          color: "color-tec",
          dark: true,
        },
        {
          id: 3,
          text: "FORMELN (SH)",
          text_short: "FOR",
          color: "color-for",
          dark: false,
        },
        {
          id: 4,
          text: "Überarbeitung (P3L)",
          text_short: "ÜBE",
          color: "color-ube",
          dark: false,
        },
        {
          id: 5,
          text: "Inhaltliche Prüfung (SH)",
          text_short: "INH",
          color: "color-inh",
          dark: false,
        },
        {
          id: 6,
          text: "Vorerst abgelehnt",
          text_short: "VAB",
          color: "color-vab",
          dark: true,
        },
        {
          id: 7,
          text: "Abgelehnt",
          text_short: "ABG",
          color: "color-abg",
          dark: true,
        },
      ],
      recentStatusChanges: [],
      showExpandedPreview: false, // false = html, true = Mathjaxpreview
    };
  },
  computed: {
    ...mapGetters("imports", {
      importItem: "get_import",
      question_import: "get_import_question",
    }),
    ...mapGetters("categories", { categories: "get_categories" }),
    filteredItems() {
      if (!this.importItem) return [];

      var filtered = [...this.importItem.questions];

      if (this.filter.status.length > 0) {
        filtered = filtered.filter((item) => {
          return this.filter.status.includes(item.status);
        });
      }
      if (this.filter.meta_ready !== null) {
        filtered = filtered.filter((item) => {
          return item.meta_ready === this.filter.meta_ready;
        });
      }
      if (this.filter.hasQuestions !== null) {
        filtered = filtered.filter((item) => {
          if (this.filter.hasQuestions)
            return item.body && item.body.length > 0;
          return !item.body || item.body.length == 0;
        });
      }

      if (this.filter.categories.length > 0) {
        filtered = filtered.filter((item) => {
          return this.filter.categories.includes(item.category_id);
        });
      }

      if (this.filter.presort_status.length > 0) {
        filtered = filtered.filter((item) => {
          return this.filter.presort_status.includes(item.presort_status);
        });
      }

      // TODO: Search??

      return filtered;
    },
    activeFilters() {
      // TODO: remove search?
      let search = this.filter.search.length > 0 ? 1 : 0;
      return (
        search +
        this.filter.status.length +
        (this.filter.meta_ready !== null ? 1 : 0) +
        (this.filter.hasQuestions !== null ? 1 : 0) +
        this.filter.categories.length +
        this.filter.presort_status.length
      );
    },
    breadcrumbs() {
      return [
        {
          text: this.$t("navigation.dashboard.title"),
          disabled: false,
          to: { name: "Dashboard" },
        },
        {
          text: "Datenimport",
          to: { name: "ImportOverview" },
        },
        {
          text: "Fragenimporte",
          disabled: true,
        },
      ];
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.storeFilter();
      },
    },
  },
  beforeMount() {
    this.allow_roles(["superadmin", "admin", "editor"]);
    this.id = Number(this.$route.params.id);
    this.getFilter();
    if (localStorage.getItem("import_presort_changes"))
      this.recentStatusChanges = JSON.parse(
        localStorage.getItem("import_presort_changes")
      );
  },
  methods: {
    ...mapActions("imports", [
      "fetch_import",
      "fetch_import_question",
      "patch_import_question",
    ]),
    ...mapMutations("imports", ["set_import", "set_import_question"]),
    ...mapActions("categories", ["fetch_categories"]),
    ...mapMutations("categories", ["set_categories"]),
    async fetchData() {
      this.loading = true;
      await this.fetch_categories({ cancelToken: this.source.token }),
        await this.fetch_import({
          id: this.id,
          type: "questions",
          category_id: this.category_id,
        });
      this.loading = false;
    },
    unloadData() {
      this.set_import(null);
      this.set_import_question(null);
      this.set_categories(null);
    },
    resetFilter() {
      this.filter = {
        search: "",
        status: [],
        meta_ready: null,
        hasQuestions: null,
        categories: [],
        presort_status: [],
        page: 1,
      };
    },
    storeFilter() {
      localStorage.setItem("fil_imp_que", JSON.stringify(this.filter));
    },
    getFilter() {
      if (localStorage.getItem("fil_imp_que")) {
        let filter = JSON.parse(localStorage.getItem("fil_imp_que"));

        this.filter.search = filter.search ? filter.search : "";
        this.filter.status = filter.status ? filter.status : [];
        this.filter.meta_ready = filter.meta_ready ? filter.meta_ready : null;
        this.filter.hasQuestions = filter.hasQuestions
          ? filter.hasQuestions
          : null;
        this.filter.categories = filter.categories ? filter.categories : [];
        this.filter.presort_status = filter.presort_status
          ? filter.presort_status
          : [];
        this.filter.page = filter.page ? filter.page : 1;
      }
    },
    getCategoryNameById(id) {
      let category = this.categories.filter((category) => {
        return category.id == id;
      })[0];
      return category ? category.name : "unbekannt";
    },
    getCategoryGroupNameById(id) {
      let group = this.categories.filter((category) => {
        return category.id == id;
      })[0].groups[0].name;
      return group ? group.name : "unbekannt";
    },
    translateType(type) {
      if (type === "gap_text") return "Lückentext";
      if (type === "single_choice") return "1 aus x";
      if (type === "multi_choice") return "Mehrfachauswahl";
      return "?";
    },
    getPresetIcon(status) {
      if (status === 1) return "mdi-check";
      return "mdi-help";
    },
    getPresetIconColor(status) {
      if (status === 1) return "success";
      return "secondary";
    },
    expandItem({ item }) {
      this.expanded.push(item.id);
      this.getImportQuestions(item.id);
    },
    async getImportQuestions(id) {
      this.set_import_question(null);
      await this.fetch_import_question({
        question_id: id,
        cancelToken: this.source.token,
      });
    },
    async changePreStatus(id, status) {
      let payload = {
        id: id,
        presort_status: status,
      };

      let res = await this.patch_import_question({
        payload: payload,
        cancelToken: this.source.token,
      });
      if (res._status === 200) {
        this.loading = true;
        this.setLastPresortChanges(id);
        //await this.fetch_import({ id: this.id, type: 'questions', category_id: this.category_id });
        this.loading = false;

        this.$notify({
          type: "success",
          title: "success ",
          text: "Status erfolgreich geändert ",
        });
      } else {
        this.$notify({
          type: "error",
          title: "error ",
          text: "Statusänderung fehlgeschlagen ",
        });
      }
    },
    getPresortStatus(id) {
      return this.presort_statuses.filter((s) => s.id === id)[0];
    },
    setLastPresortChanges(id) {
      if (!localStorage.getItem("import_presort_changes")) {
        localStorage.setItem("import_presort_changes", JSON.stringify([id]));
        this.recentStatusChanges = [id];
        return false;
      }
      let changes = JSON.parse(localStorage.getItem("import_presort_changes"));

      if (changes.includes(id)) return false;

      if (changes.length < 5) {
        changes.push(id);
      } else {
        changes.shift();
        changes.push(id);
      }
      localStorage.setItem("import_presort_changes", JSON.stringify(changes));
      this.recentStatusChanges = changes;
    },
    getStatusTranslation(status) {
      if (status === "unapproved") return "Unbearbeitet";
      if (status === "editing") return "Editiert";
      if (status === "imported") return "Importiert";
      return "?";
    },
  },
};
</script>

<style>
.color-ok {
  background-color: #8fd14f !important;
}
.color-tec {
  background-color: #00afd7 !important;
  color: white !important;
}
.color-for {
  background-color: #fef445 !important;
}
.color-ube {
  background-color: #fac710 !important;
}
.color-inh {
  background-color: #12cdd4 !important;
}
.color-vab {
  background-color: #9510ac !important;
  color: white !important;
}
.color-abg {
  background-color: #f24726 !important;
  color: white !important;
}
</style>
