<template>
  <div>
    <v-expand-transition>
      <section class="mt-2">
        <v-container>
          <v-row dense align="center">
            <v-col cols="12" sm="6" md="4" offset-md="4">
              <KpiFilterMenu>
                <template #filterlist>
                  <v-subheader
                    class="caption pl-4 font-weight-bold secondary--text"
                    >{{
                      $t("resources.invitations.status.general")
                    }}</v-subheader
                  >
                  <v-list-item>
                    <v-select
                      :items="options.status"
                      v-model="filter.status"
                      item-text="name"
                      item-value="type"
                      background-color="grey lighten-3"
                      solo
                      flat
                      dense
                      clearable
                    />
                  </v-list-item>
                </template>
              </KpiFilterMenu>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="4"
              :order="$vuetify.breakpoint.xsOnly ? 'first' : ''"
            >
              <v-text-field
                v-model="search"
                :label="$t('general.search')"
                flat
                solo
                background-color="grey lighten-2"
                hide-details
                dense
                :disabled="loading"
              />
            </v-col>
            <v-col cols="12">
              <v-card outlined color="grey lighten-1" tile>
                <v-data-table
                  :headers="tableHeaders"
                  :items="filteredJourneys"
                  :search="search"
                  class="grey lighten-3"
                  hide-default-header
                  :loading="
                    !journeys || (journeys && journeys.length === 0) || loading
                  "
                >
                  <template #item="{ item }">
                    <tr>
                      <td>
                        <div class="d-flex align-center">
                          <StatisticsDialogJourney
                            :id="item.id"
                            :labels="item.labels"
                            :stats="item.stats"
                          >
                            <template #activator="{ openDialog }">
                              <v-btn
                                x-small
                                fab
                                elevation="0"
                                color="primary"
                                @click="openDialog()"
                                ><v-icon small>mdi-magnify</v-icon></v-btn
                              >
                            </template>
                          </StatisticsDialogJourney>
                          <span class="mx-2">{{ item.name }}</span>
                          <v-tooltip right color="info">
                            <template #activator="{ on, attrs }">
                              <v-icon
                                small
                                :color="getStatusColor(item.status)"
                                v-bind="attrs"
                                v-on="on"
                                >{{ getStatusIcon(item.status) }}</v-icon
                              >
                            </template>
                            <span>{{
                              $t(`resources.journeys.statuses.${item.status}`)
                            }}</span>
                          </v-tooltip>
                        </div>
                      </td>
                      <td class="d-flex align-center justify-end">
                        <StatisticsTableChip
                          :value="item.stats.assigned"
                          no-delta
                          :description="$t('kpi.table_chips.journeys_assigned')"
                          icon="mdi-account-circle"
                          :to-fixed-value="0"
                        />
                        <StatisticsTableChip
                          :value="item.stats.finished"
                          no-delta
                          :description="$t('kpi.table_chips.journeys_finished')"
                          icon="mdi-check-circle"
                          tooltip-color="success"
                          :to-fixed-value="0"
                          class="ml-4"
                        />
                        <StatisticsTableChip
                          :value="item.stats.in_progress"
                          no-delta
                          :description="
                            $t('kpi.table_chips.journeys_in_progress')
                          "
                          icon="mdi-progress-clock"
                          tooltip-color="primary"
                          :to-fixed-value="0"
                          class="mx-4"
                        />
                        <StatisticsTableChip
                          :value="item.stats.failed"
                          no-delta
                          :description="$t('kpi.table_chips.journeys_failed')"
                          icon="mdi-close-circle"
                          tooltip-color="error"
                          :to-fixed-value="0"
                        />
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </section>
    </v-expand-transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import StatisticsTableChip from "@/components/kpi/StatisticsTableChip";
import StatisticsDialogJourney from "@/components/kpi/dialogs/StatisticsDialogJourney";
import KpiFilterMenu from "@/components/kpi/KpiFilterMenu";

export default {
  name: "KpiJourneyOverview",
  components: {
    StatisticsTableChip,
    StatisticsDialogJourney,
    KpiFilterMenu,
  },
  data() {
    return {
      search: "",
      tableHeaders: [
        { text: "Status", value: "status" },
        {
          text: "Name",
          align: "start",
          value: "name",
          width: "auto",
        },
        { text: "User gesamt", value: "stats.assigned" },
        { text: "Beendet", value: "stats.finished" },
        { text: "In Bearbeitung", value: "stats.in_progress" },
        { text: "Fehlgeschlagen", value: "stats.failed" },
        { text: "", width: "5%" },
        /* { text: "Competency", value: "stats.competency", width: "15%" },
        { text: "Retention", value: "stats.retention", width: "15%" },
        { text: "Completion", value: "stats.completion", width: "15%" },
        { text: "Current Speed", value: "stats.currentSpeed", width: "15%" },
        { text: "Overall Speed", value: "stats.overallSpeed", width: "15%" },
        { text: "", value: "data-table-expand", width: "5%" }, */
      ],
      filter: {
        status: null,
      },
      options: {
        status: [
          /* {
            type: "unpublished",
            name: this.$t("resources.journeys.statuses.unpublished"),
          },
          {
            type: "published",
            name: this.$t("resources.journeys.statuses.published"),
          }, */
          {
            type: "active",
            name: this.$t("resources.journeys.statuses.active"),
          },
          //{ type: 'paused', name: this.$t('resources.journeys.statuses.paused') },
          {
            type: "closed",
            name: this.$t("resources.journeys.statuses.closed"),
          },
          {
            type: "expired",
            name: this.$t("resources.journeys.statuses.expired"),
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("kpi", {
      journeys: "get_kpi_journeys",
      loading: "get_kpi_loading",
    }),
    filteredJourneys() {
      if (!this.journeys) return [];
      var journeys = [...this.journeys];
      if (this.filter.status) {
        journeys = journeys.filter((j) =>
          this.filter.status.includes(j.status)
        );
      }
      return journeys.sort((a, b) => a.status.localeCompare(b.status));
    },
  },
  beforeMount() {
    this.fetchStatistics();
  },
  destroyed() {
    this.set_kpi_journeys(null);
  },
  methods: {
    ...mapActions("kpi", ["fetch_kpi_journeys"]),
    ...mapMutations("kpi", ["set_kpi_journeys"]),
    async fetchStatistics() {
      await this.fetch_kpi_journeys({
        payload: null,
        type: "journeys",
        cancelToken: this.source.token,
      });
    },
    getStatusIcon(status) {
      if (status == "unpublished") return "mdi-circle-edit-outline";
      if (status == "published") return "mdi-play-circle-outline";
      if (status == "active") return "mdi-play-circle-outline";
      if (status == "paused") return "mdi-pause-circle-outline";
      if (status == "closed") return "mdi-checkbox-marked-circle-outline";
      if (status == "expired") return "mdi-checkbox-marked-circle-outline";
      // deleted mdi-close-circle-outline
      return "mdi-circle";
    },
    getStatusColor(status) {
      if (status == "unpublished") return "warning";
      if (status == "published") return "primary";
      if (status == "active") return "success";
      if (status == "paused") return "warning";
      if (status == "expired" || status == "closed") return "primary";
      return "primary";
    },
  },
};
</script>

<style></style>
